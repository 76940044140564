import React, { Component } from 'react';

import { WorkOrderIntConfNoViewAppointmentForm } from './WorkOrderIntConfNoViewAppointmentForm';

export class WorkOrderIntConfNoViewAppointment extends Component {
    static displayName = WorkOrderIntConfNoViewAppointment.name;

    constructor(props) {
        super(props);
      }

    render() {
        return (
            <WorkOrderIntConfNoViewAppointmentForm modal={true} />
        );

    }
}
