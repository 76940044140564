import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, InputGroup, InputGroupText, Row, Col, Alert, TabContent, Table, TabPane } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetParameterByName, GetCookie, RemoveCookie, Timeout } from './utils/Common';

import Logo from '../images/logo1.png'

import { WorkOrderIntFormEndTimeComment } from './WorkOrderIntFormEndTimeComment';

import SaveIcon from '../images/save-icon.png'
import RemoveIcon from '../images/remove-icon.png'

const moment = require('moment');

export class WorkOrderIntFormEndTime extends Component {
    static displayName = WorkOrderIntFormEndTime.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            alert: props.alert,

            modalEndTimeComment: false,
            model: props.model,

            newmodel: {
                NotAvailable: false,
                JobDate: null
            },

            localEvent: {
                isBackspaceClick: false,
                isYesClick: false
            },
            elementModel: {
                ampm: 'AM',
                display_message: '',
                disableButtons: 'disabled'
            }
        };

        //
        this.setModalEndTimeComment = this.setModalEndTimeComment.bind(this);
        //
        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        //
        this.handleChange = this.handleChange.bind(this);
        this.handleNewModelChange = this.handleNewModelChange.bind(this);
        this.handlekeyDown = this.handlekeyDown.bind(this);
        this.handleAMPMChange = this.handleAMPMChange.bind(this);
        //
        this.handleSaveEventSuccessComment = this.handleSaveEventSuccessComment.bind(this);
    }

    componentDidMount() {
        let model = { ...this.state.model }
        model.FinishTimeString = '';
        this.setState({ model });
    }

    handlekeyDown(e) {
        let model = { ...this.state.localEvent }
        // backspace
        model.isBackspaceClick = e.keyCode === 8 ? true : false;
        this.setState({ localEvent: model });
    }

    handleNewModelChange(e) {
        let model = { ...this.state.newmodel }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else {
            model[e.target.name] = e.target.value;
        }
        this.setState({ newmodel: model });
    }

    setModalEndTimeComment() {
        this.setState({ modalEndTimeComment: !this.state.modalEndTimeComment });
    }

    handleChange(e) {

        let model = { ...this.state.model }
        var finishTime = null;

        if (this.state.localEvent.isBackspaceClick === true) {
            model.FinishTimeString = e.target.value;
            this.setState({ model: model }, () => {
                this.validateFinishTime();
            });
        } else {
            if (e.target.value !== '') {
                if (e.target.value.length === 1 && e.target.value !== "1")
                    finishTime = '0' + e.target.value + ':';
                else if (e.target.value.length === 2) {
                    finishTime = e.target.value + ':';
                }
                else
                    finishTime = e.target.value;
            }

            model.FinishTimeString = finishTime;
            this.setState({ model: model }, () => {
                // validate date
                this.validateFinishTime();
            });
        }
    }

    validateFinishTime() {
        let stateModel = { ...this.state.elementModel };
        stateModel.display_message = '';
        stateModel.disableButtons = 'disabled';

        var finishTime = this.state.model.FinishTimeString;
        //console.log(finishTime);

        var jobDateTime = moment(this.props.model.JobDateTime);
        var finsiDateTimeString = moment(this.props.model.JobDateTime).format('YYYY-MM-DD ') + finishTime + ' ' + this.state.elementModel.ampm;

        var validateDate = moment(finsiDateTimeString);
        if (validateDate.isValid()) {
            //let stateModel = { ...this.state.elementModel };
            stateModel.display_message = '';
            stateModel.disableButtons = 'disabled';

            // compare dates
            var diffSeconds = validateDate.diff(jobDateTime, 'seconds');
            if (diffSeconds > 0) {
                stateModel.disableButtons = '';
            } else {
                if (finishTime !== '')
                    stateModel.display_message = 'Finish Time must be later than Start Time';
                else
                    stateModel.display_message = '';
            }

            this.setState({ elementModel: stateModel });
        } else {
            this.setState({ elementModel: stateModel });

        }
    }

    handleAMPMChange(e) {
        let model = { ...this.state.elementModel }
        model.ampm = e.target.value
        this.setState({ elementModel: model }, () => {
            this.validateFinishTime();
        });
    }

    handleYesClick() {
        let model = { ...this.state.model }
        model.FinishTimeString = model.FinishTimeString + ' ' + this.state.elementModel.ampm;

        FetchData("/EndTimeYesButtonClick", "POST", this.user.token, model).then(data => {
            if (data === "Success") {
                model.FinishTimeString = null;
                this.setState({ model: model }, () => {

                });

                let model2 = { ...this.state.localEvent }
                model2.isYesClick = true;
                this.setState({ localEvent: model2 }, () => {

                });
                
            } else {
                this.setState({
                    alert: {
                        color: "warning",
                        message: data,
                        isOpen: true
                    }
                }, () => {
                    window.setTimeout(() => {
                        this.setState({ alert: { isOpen: false } });
                    }, Timeout(alert.message));
                });
            }
        });


    }

    handleNoClick() {
        if (this.txtEndTime.value === null || this.txtEndTime.value === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please enter Finish Time.",
                    isOpen: true
                }
            });
        } else {
            let model = { ...this.state.model }
            model.FinishTimeString = model.FinishTimeString + ' ' + this.state.elementModel.ampm;

            FetchData("/EndTimeNoButtonClick", "POST", this.user.token, model).then(data => {
                if (data === "Success") {   
                    this.props.handleSaveEventSuccess({
                        message: "Finish Time has been updated.",
                        modal: false,
                        color: "success"
                    });  

                    //this.setState({
                    //    alert: {
                    //        color: "success",
                    //        message: "Finish Time has been updated.",
                    //        isOpen: true
                    //    }
                    //}, () => {
                    //    window.setTimeout(() => {
                    //        this.setState({ alert: { isOpen: false } });
                    //        this.setModalEndTimeComment();
                    //    }, Timeout(alert.message));
                    //});
                } else {
                    this.setState({
                        alert: {
                            color: "warning",
                            message: data,
                            isOpen: true
                        }
                    }, () => {
                        window.setTimeout(() => {
                            this.setState({ alert: { isOpen: false } });
                        }, Timeout(alert.message));
                    });
                }

                //this.setState({
                //    display_message: data.displayMessage
                //});
            });
        }
    }

    handleSubmitClick() {
        if (this.state.newmodel.JobDate === null || this.state.newmodel.JobDate === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please choose Date.",
                    isOpen: true
                }
            });
        } else if (this.txtEndTime2.value === null || this.txtEndTime2.value === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please enter Finish Time.",
                    isOpen: true
                }
            });
        } else {
            let model = { ...this.state.model }
            model.JobDateTime = this.state.newmodel.JobDate;
            model.FinishTimeString = model.FinishTimeString + ' ' + this.state.elementModel.ampm;

            //console.log(model.FinishTime);
            //console.log(model.FinishTimeString);

            FetchData("/EndTimeFollowUpSubmit/?isAvailable=" + this.state.newmodel.NotAvailable, "POST", this.user.token, model).then(data => {
                if (data === "Success") {
                    this.props.handleSaveEventSuccess({
                        message: "End time follow up has been submitted.",
                        modal: false,
                        color: "success",
                        showTimeout: false
                    });  
                } else {
                    this.setState({
                        alert: {
                            color: "warning",
                            message: data,
                            isOpen: true
                        }
                    }, () => {
                        window.setTimeout(() => {
                            this.setState({ alert: { isOpen: false } });
                        }, Timeout(alert.message));
                    });
                }
            });
        }
    }

    handleSaveEventSuccessComment(alert) {
        this.setState({
            modalEndTimeComment: alert.modalEndTimeComment,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));
            //if (alert.modal === false)
            //    this.setModalEndTimeComment();
        });
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "500px", width: "800px" }}>
                    <ModalHeader>Interpreter Check In #{this.props.model.JobNum}</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        {this.state.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center><img src={Logo} alt="Logo" /></center>
                                </Col>
                            </Row>
                            {!this.state.localEvent.isYesClick && <Fragment>
                                <Row>
                                    <Col>
                                        <center>
                                            <br />
                                            <p>
                                                Claimant: <b>{this.props.model.ClmLastName + ", " + this.props.model.ClmFirstName}</b> <br />
                                                On: <b>{moment(this.props.model.JobDateTime).format('dddd') + " " + moment(this.props.model.JobDateTime).format('MM/DD/YYYY')}</b> <br />
                                                at: <b>{moment(this.props.model.JobDateTime).format('hh:mm A')}</b>
                                            </p>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <p style={{ color: 'red', fontWeight: 'bold' }}>
                                                If you did NOT interpret for any reason, please Call 800-522-2320
                                            </p>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <p>Finish Time:</p>
                                            <p>
                                                <input name="FinishTimeString" value={this.state.model.FinishTimeString} type="text" placeholder="HH:MM" ref={(input) => { this.txtEndTime = input; }} onKeyDown={this.handlekeyDown} onChange={this.handleChange} style={{ width: '80px', color: this.state.elementModel.disableButtons === 'disabled' ? 'red' : 'black', borderColor: this.state.elementModel.disableButtons === 'disabled' ? 'red' : 'black' }}></input>
                                                {' '} <input name="ampm" value="AM" checked={this.state.elementModel.ampm === 'AM'} type="radio" onChange={this.handleAMPMChange}></input> AM {' '} <input name="ampm" checked={this.state.elementModel.ampm === 'PM'} value="PM" type="radio" onChange={this.handleAMPMChange}></input> PM
                                                {this.state.elementModel.display_message !== '' && <p style={{ color: 'red' }}>{this.state.elementModel.display_message}</p>}
                                            </p>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <p>
                                                Are there any follow up appointments?
                                            </p>
                                        </center>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <center>
                                            <Button color={this.state.elementModel.disableButtons === "disabled" ? "secondary" : "success"} size="sm" disabled={this.state.elementModel.disableButtons} onClick={this.handleYesClick}><img src={SaveIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Yes" /> Yes</Button> {' '}
                                            <Button color={this.state.elementModel.disableButtons === "disabled" ? "secondary" : "danger"} size="sm" disabled={this.state.elementModel.disableButtons} onClick={this.handleNoClick}><img src={RemoveIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Yes" /> No</Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Fragment>}
                            {this.state.localEvent.isYesClick && <Fragment>
                                <Row>
                                    <Col><p></p></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <input name="NotAvailable" type="checkbox" defaultChecked={this.state.newmodel.NotAvailable} onChange={this.handleNewModelChange} ></input>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <p style={{ fontWeight: 'bold' }}>
                                                Check the box above if you are NOT available for this followup appointment.
                                            </p>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <p>
                                                Date:
                                            </p>
                                            <p>
                                                <input name="JobDate" type="date" className="form-control" value={this.state.newmodel.JobDate === null ? null : moment(this.state.newmodel.JobDate).format('YYYY-MM-DD')} style={{ width: '150px' }} onChange={this.handleNewModelChange}></input>
                                            </p>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <p>New Job Time:</p>
                                            <p>
                                                <input name="FinishTimeString" value={this.state.model.FinishTimeString} type="text" placeholder="HH:MM" ref={(input) => { this.txtEndTime2 = input; }} onKeyDown={this.handlekeyDown} onChange={this.handleChange} style={{ width: '80px', color: this.state.elementModel.disableButtons === 'disabled' ? 'red' : 'black', borderColor: this.state.elementModel.disableButtons === 'disabled' ? 'red' : 'black' }}></input>
                                                {' '} <input name="ampm" value="AM" checked={this.state.elementModel.ampm === 'AM'} type="radio" onChange={this.handleAMPMChange}></input> AM {' '} <input name="ampm" checked={this.state.elementModel.ampm === 'PM'} value="PM" type="radio" onChange={this.handleAMPMChange}></input> PM
                                            </p>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <p>Please add Date and Time to your Calendar if you are available</p>
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <Button color={"success"} size="sm" onClick={this.handleSubmitClick} ><img src={SaveIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Submit" /> Submit</Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Fragment>}
                        </div>
                    </ModalBody>
                    {/*<ModalFooter>*/}

                    {/*</ModalFooter>*/}
                </Modal>

                {this.state.modalEndTimeComment &&
                    <WorkOrderIntFormEndTimeComment jobmodel={this.state.model} modal={this.state.modalEndTimeComment} alert={this.state.alert} setModal={this.setModalEndTimeComment} handleSaveEventSuccess={this.handleSaveEventSuccessComment} />
                }

            </div>
        );

    }
}
