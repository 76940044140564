import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { ClientFormSearch } from './ClientFormSearch';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DeleteIcon from '../images/delete.png'
import SearchIcon from '../images/search-icon.png'

export class UserForm extends Component {
    static displayName = UserForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            modalClientSearchForm: false,
            confirmPassword: '',

            model: props.model,
            alert: props.alert
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        //
        this.handleConfirmPass = this.handleConfirmPass.bind(this);
    }

    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        if (this.state.model.UnhashPassword === '' || this.state.confirmPassword === '') {
            this.handleSaveEventSuccess({
                message: 'Password did not match',
                modal: true,
                color: "warning"
            });
        } else if (this.state.model.UnhashPassword.toLowerCase() !== this.state.confirmPassword.toLowerCase()) {
            this.handleSaveEventSuccess({
                message: 'Password did not match',
                modal: true,
                color: "warning"
            });
        }
        else {
            FetchData("/saveuser", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }
            });
        }

        //if (model.ClientID === null) {
        //    this.handleSaveEventSuccess({
        //        message: 'Please choose a Client.',
        //        modal: true,
        //        color: "warning"
        //    });
        //} else {

        //}
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseFloat(e.target.value);
            else
                model[e.target.name] = null;
        }
        else {
            model[e.target.name] = e.target.value;
            if (e.target.name === "Email")
                model["UserName"] = e.target.value;
        }

        this.setState({ model });
    }

    handleConfirmPass(e) {               
        this.setState({ confirmPassword: e.target.value });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>New/Edit User</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        <center>
                                            {this.state.alert.message}
                                        </center>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <h4>User Information</h4>
                                </Col>
                                <Col className="col-6">
                                    <h4>Login Information</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Firstname:
                                        </InputGroupText>
                                        <input type="text" name="FirstName" className="form-control form-control-text" value={this.state.model.FirstName} maxLength={35} onChange={this.handleChange} tabIndex={1} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "120px" }}>
                                            UserName:
                                        </InputGroupText>
                                        <input type="text" name="UserName" className="form-control form-control-text" value={this.state.model.UserName} onChange={this.handleChange} tabIndex={8} />
                                    </InputGroup>                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Lastname:
                                        </InputGroupText>
                                        <input type="text" name="LastName" className="form-control form-control-text" value={this.state.model.LastName} maxLength={35} onChange={this.handleChange} tabIndex={2} />
                                    </InputGroup>
                                    
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "120px" }}>
                                            Password:
                                        </InputGroupText>
                                        <input type="password" name="UnhashPassword" className="form-control form-control-text" value={this.state.model.UnhashPassword} onChange={this.handleChange} tabIndex={9} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Email:
                                        </InputGroupText>
                                        <input type="text" name="Email" className="form-control form-control-text" value={this.state.model.Email} onChange={this.handleChange} tabIndex={3} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "120px" }}>
                                            Password Confirm:
                                        </InputGroupText>
                                        <input type="password" name="confirmPassword" className="form-control form-control-text" value={this.state.confirmPassword} onChange={this.handleConfirmPass} tabIndex={10} />
                                    </InputGroup>                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Phone:
                                        </InputGroupText>
                                        <input type="text" name="Phone" className="form-control form-control-text" value={this.state.model.Phone} onChange={this.handleChange} tabIndex={4} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Phone2:
                                        </InputGroupText>
                                        <input type="text" name="PhoneNumber" className="form-control form-control-text" value={this.state.model.PhoneNumber} onChange={this.handleChange} tabIndex={5} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">

                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Role:
                                        </InputGroupText>
                                        <select name="UserRoleName" className="form-control form-control-text" value={this.state.model.UserRoleName} onChange={this.handleChange} tabIndex={6}>
                                            <option value="">--Select Role--</option>
                                            <option value="Interpreter">Interpreter</option>
                                            <option value="Scheduler">Scheduler</option>
                                            <option value="Administrator">Administrator</option>
                                            <option value="Super Administrator">Super Administrator</option>
                                            <option value="Rep">Rep</option>
                                        </select>
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">

                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            InActive:
                                        </InputGroupText>
                                        <input name="InActiveflg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.InActiveflg} onChange={this.handleChange} tabIndex={7} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">

                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                        <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}