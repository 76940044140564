import React, { Component, } from 'react';
import { Button, Row, Col, Input, InputGroup, InputGroupText, Alert } from 'reactstrap';

import ReactTooltip from "react-tooltip";

import { WorkOrderForm } from './WorkOrderForm';
import { LoadingModal } from './LoadingModal';
import { PrintInvoiceGridDefault } from './PrintInvoiceGridDefault';
import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import { saveAs } from 'file-saver';

import PrintIcon from '../images/print-icon.png';

const moment = require('moment');

export class PrintInvoice extends Component {
    static displayName = PrintInvoice.name;

    constructor(props) {
        super(props);
        this.state = {
            workOrderList: [],
            jobHistoryList: [],
            jobInvLineList: [],

            executiveUsers: [],

            receivableList: [],
            receivableItemList: [],

            viewInvoiceNum: '',
            loading: true,
            modal: false,
            fetchingData: false,
            modalClient: false,
            modalIntFormEmail: false,
            modalRepFormEmail: false,
            modalWOJobNotes: false,
            modalViewInvoice: false,
            showInvoiced: false,
            selectedIndex: -1,
            checkAll: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                JobBalance: null
            },
            client: {
                EventType: 'update',
                ClientId: null,
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientPhoneFax: '',
                ClientName: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientCountry: '',
                ClientDiscount: null,
                ClientNotes: '',
                ClientCreditCard: '',
                ClientEmailForInvoice: '',
                ClientEmail: '',
                ClientWebsite: '',
                ClientOrg: '',
                ClientBillRateType: '',
                ClientInactiveFlg: false,
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            },
            searchModel: {
                IntId: '',
                ClmId: '',
                JobNum: '',
                StartDate: null,
                EndDate: null,
                Claimant: '',
                ClaimantNum: '',
                CLMPhone: '',
                Interpreter: '',
                Rep: '',
                ClientAbbrv: '',
                ProviderName: '',
                ShowPrinted: false,
                CreatedByUserId: null,
                MyJobs: false,
                MyJobsUser: '',
                InvoicedFlg: false,
            },
            email: {
                EmailFrom: '',
                EmailTo: '',
                EmailCC: '',
                Subject: '',
                Body: '',
                JobIds: ''
            },
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

            tableView: 'default'
        };
        //
        this.user = JSON.parse(GetCookie('user'));
        // button clicks
        //this.resetFields = this.resetFields.bind(this);
        this.setData = this.setData.bind(this);
        this.setModal = this.setModal.bind(this);
        this.viewInvoice = this.viewInvoice.bind(this);
        //
        this.renderPrintInvoiceTable = this.renderPrintInvoiceTable.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchFocus = this.handleSearchFocus.bind(this);
        this.handleSearchBlur = this.handleSearchBlur.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        // export reports
        //this.printInvoice = this.printInvoice.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.updateInvoicedFlgAndInsertReceivable = this.updateInvoicedFlgAndInsertReceivable.bind(this);
        this.resetInvoiced = this.resetInvoiced.bind(this);
        //
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.resetChecks = this.resetChecks.bind(this);
        //
        //    this.handleSortData = this.handleSortData.bind(this);
        //    this.requestSort = this.requestSort.bind(this);
        //    //
        //    this.onClickResizeColumn = this.onClickResizeColumn.bind(this);
        //    this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    }

    componentDidMount() {
        this.populatePrintInvoice();

    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    setModal() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (!this.state.modal) {
                this.setState({
                    selectedIndex: -1
                });
            }
        });
    }

    viewInvoice(woData, i) {
        let body = {
            JobIdsList: ""
        };

        body.JobIdsList = woData.jobId;
        let currentDate = new Date();
        let invoiceFileName = "Invoice " + woData.jobNum + " " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate()) + "-" + currentDate.getFullYear() + ".pdf";

        FetchFile("/printinvoices", "POST", this.user.token, body).then(blob => {
            saveAs(blob, invoiceFileName);
        })
    }

    //resetFields() {
    //    this.setState(prevState => ({
    //        model: {
    //            EventType: 'insert',
    //            JobNum: 0,
    //            JobId: null,
    //            JobDateTime: null,
    //            JobTime: null,
    //            FinishTime: null,
    //            FinishTimeString: null,
    //            FollowupDate: null,
    //            ProvId: null,
    //            ProviderName: '',
    //            ConfCallNote: '',
    //            AptName: '',
    //            AptStreet: '',
    //            AptCity: '',
    //            AptState: '',
    //            AptZip: '',
    //            AptPhone: '',
    //            AptDirection: '',
    //            Failedflg: false,
    //            CallerNote: '',
    //            InvoiceNote: '',
    //            IntCheckDate: null,
    //            IntCheckNum: null,
    //            Invoicedflg: false,
    //            JobTotal: null,
    //            JobDiscount: null,
    //            IntFee: null,
    //            IntFeeOther: null,
    //            IntOTFee: null,
    //            IntFeeNote: '',
    //            Transportationflg: false,
    //            TransConfflg: false,
    //            IntConfflg: false,
    //            AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
    //            CallerType: null,
    //            ProvConfflg: false,
    //            ClmConfflg: false,
    //            IsDeletedflg: false,
    //            BillReviewedflg: false,
    //            Auditflg: false,
    //            PurpleReportflg: false,
    //            RepAuthorizedflg: false,
    //            Selectedflg: false,
    //            ClmId: '',
    //            ClmFirstName: '',
    //            ClmLastName: '',
    //            ClmClaimNumber: '',
    //            ClmClaimNumberNoDash: '',
    //            ClmPhone: '',
    //            ClmDateOfLoss: '',
    //            ClmDateOfInjury: '',
    //            ClmNote: '',
    //            ClmDOB: null,
    //            ClmInsuredParty: '',
    //            ClmEmployer: '',
    //            ClmInvoiceCaption: '',
    //            ClmLanguage: '',
    //            ClmWCABNumber: '',
    //            ClmInvoiceType: '',
    //            ClmStreet: '',
    //            ClmCity: '',
    //            ClmState: '',
    //            ClmZip: '',
    //            ClmSSN: '',
    //            ClmOrderedByName: '',
    //            ClmDiagnosis: '',
    //            ClmEmail: '',
    //            RepID: null,
    //            RepFirstName: '',
    //            RepLastName: '',
    //            RepPhone: '',
    //            RepEmail: '',
    //            ClientId: '',
    //            ClientAbbrv: '',
    //            ClientName: '',
    //            ClientStreet: '',
    //            ClientStreet2: '',
    //            ClientCity: '',
    //            ClientState: '',
    //            ClientZip: '',
    //            ClientMailStreet: '',
    //            ClientMailStreet2: '',
    //            ClientMailCity: '',
    //            ClientMailState: '',
    //            ClientMailZip: '',
    //            ClientPhone: '',
    //            ClientPhoneFax: '',
    //            ClientPhone2: '',
    //            ClientEmail: '',
    //            ClientInactiveFlg: false,
    //            IntId: null,
    //            IntLastName: '',
    //            IntFirstName: '',
    //            IntCellTxt: '',
    //            IntPhoneOnly: '',
    //            IntEmail: '',
    //            IntNote: '',
    //            InHouseflg: false,
    //            IntCity: '',
    //            IntStreet: '',
    //            IntState: '',
    //            IntZip: '',
    //            DBA: '',
    //            Rate: 0,
    //            HourMinimum: 0,
    //            Prorate: '',
    //            BankRoute: '',
    //            BankAccount: '',
    //            IntSSINumber: '',
    //            FirstName: '',
    //            LastName: '',
    //            UserType: '',
    //            UserIntId: null,
    //            CreateDateTime: null,
    //            CreateByUserId: null,
    //            RemIntflg: false,
    //            RemClaimflg: false,
    //            RemProvflg: false,
    //            RemFinalflg: false,
    //            RemType: null,
    //            RemTypeDesc: '',
    //            RemHost: null,
    //            RemHostDesc: '',
    //            CreatedBy: '',
    //            UpdatedBy: '',
    //            MyJobTJobHistory: '',
    //            JobBalance: null
    //        },
    //        jobHistoryList: [],
    //        jobInvLineList: [],
    //        modal: !this.state.modal
    //    }));

    //    // this.setModal();
    //}

    setData(woData, rowIndex, showModal) {
        FetchData("/getworkorder?jobId=" + woData.jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.intCellTxt,
                    IntPhoneOnly: data.intPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseflg: data.inHouseflg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    MyJobWaitingOnCallBack: data.myJobWaitingOnCallBack,
                    JobBalance: data.jobBalance,
                    WorkFlowId: data.workFlowId
                },
            }), () => {
                this.setModal();
            });
        });
    }

    async openWorkOrderForm(jobId) {
        FetchData("/getworkorder?jobId=" + jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString === "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.IntCellTxt,
                    IntPhoneOnly: data.IntPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseflg: data.inHouseflg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    JobBalance: data.jobBalance
                },
                selectedIndex: 0,
                modal: true
            }));
        });
    }


    getJobHistory(jobId) {
        let model = {
            JobId: jobId
        };

        FetchData("/getjobhistory", "POST", this.user.token, model).then(data => {
            this.setState({ jobHistoryList: data, fetchingData: false });
        });
    }

    getJoInvLine(jobId) {
        let model = {
            JobId: jobId
        };
        FetchData("/getjobinvline", "POST", this.user.token, model).then(data => {
            this.setState({ jobInvLineList: data, fetchingData: false });
        });

    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, 3000);
            if (alert.modal === false)
                if (this.state.tableView === 'default')
                    this.populatePrintInvoice();
                else
                    this.populateWorkOrderPendingData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else
            model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (this.state.tableView === 'default') {
                console.log(this.state.searchModel);
                this.populatePrintInvoice();
            }
            else
                this.populateWorkOrderPendingData();
        }
    }

    handleSearchFocus(e) {
        if (e.target.value !== "") {
            let model = { ...this.state.searchModel }

            if (e.target.name === "StartDate" || e.target.name === "EndDate") {
                model[e.target.name] = moment(e.target.value).format('MM/DD/YYYY');
            }
            else
                model[e.target.name] = e.target.value;

            this.setState({ searchModel: model });
        }

        if (e.target.name === "StartDate" && e.target.type !== 'date') {
            e.target.type = 'date';
        }
        if (e.target.name === "EndDate" && e.target.type !== 'date') {
            e.target.type = 'date';
        }
    }

    handleSearchBlur(e) {
        e.target.type = (e.target.name === "StartDate" || e.target.name === "EndDate") && e.target.value !== '' ? 'date' : 'text';
    }

    handleCheckAll = () => {
        this.setState({
            checkAll: !this.state.checkAll
        }, () => {
            this.state.workOrderList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: this.state.checkAll }))
        });
    }

    handleCheckChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        }, () => {
            const uncheckedItems = this.state.workOrderList.filter((item) => !this.state["chk_" + item.jobNum]);
            this.setState({
                checkAll: uncheckedItems.length === 0 ? true : false
            });

        });
    }

    resetChecks = () => {
        this.setState({
            checkAll: false
        }, () => {
            this.state.workOrderList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: false }))
        });
    }

    //handleSortData(sortByField, sortDir) {
    //    let model = { ...this.state.searchModel }
    //    model.SortBy = sortByField;
    //    if (model.SortBy === sortByField) {
    //        if (model.SortDirection === sortDir)
    //            model.SortDirection = "DESC";
    //        else
    //            model.SortDirection = sortDir;
    //    }
    //    else
    //        model.SortDirection = sortDir;

    //    this.setState({ searchModel: model }, () => {
    //        this.populatePrintInvoice();
    //    });
    //}

    //sortData(sortBy, sortOrder, rows) {
    //    var itemsToSort = this.state.workOrderList;
    //    var sortedItems = [];
    //    var compareFn = null;

    //    compareFn = (i, j) => {
    //        if (i[sortBy] < j[sortBy]) {
    //            return sortOrder === "asc" ? -1 : 1;
    //        } else {
    //            if (i[sortBy] > j[sortBy]) {
    //                return sortOrder === "asc" ? 1 : -1;
    //            } else {
    //                return 0;
    //            }
    //        }
    //    };

    //    sortedItems = itemsToSort.sort(compareFn);
    //    return sortedItems;
    //}

    //requestSort(pSortBy) {
    //    var sortBy = this.state.searchModel.SortBy;
    //    var sortOrder = this.state.searchModel.SortDirection;
    //    return event => {
    //        if (pSortBy === this.state.searchModel.SortBy) {
    //            sortOrder = sortOrder === "asc" ? "desc" : "asc";
    //        } else {
    //            sortBy = pSortBy;
    //            sortOrder = "asc";
    //        }
    //        var sortedItems = this.sortData(sortBy, sortOrder);
    //        this.setState({
    //            searchModel: {
    //                SortBy: sortBy,
    //                SortDirection: sortOrder
    //            },
    //            workOrderList: sortedItems
    //        });
    //    };
    //}

    renderPrintInvoiceTable(workOrders) {
        return (
            <PrintInvoiceGridDefault
                state={this.state}
                handleCheckChange={this.handleCheckChange}
                handleCheckAll={this.handleCheckAll}
                workOrders={this.state.workOrderList}
                viewInvoice={this.viewInvoice}
                setData={this.setData}
            ></PrintInvoiceGridDefault>
        );
    }




    async exportPDF() {
        let JobsList = [];
        let body = {
            JobIdsList: ""
        };
        let currentDate = new Date();
        let invoiceFileName = "Invoices " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate()) + "-" + currentDate.getFullYear() + ".pdf";

        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                JobsList.push(item.jobId);
            }
        });
        body.JobIdsList = JobsList.join();

        if (body.JobIdsList === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please check any work order",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message))
            });
        }
        else {
            await FetchFile("/printinvoices", "POST", this.user.token, body).then(blob => {
                saveAs(blob, invoiceFileName);
                this.updateInvoicedFlgAndInsertReceivable();
            });
        }

        this.resetChecks();
        this.populatePrintInvoice();
    }

    updateInvoicedFlgAndInsertReceivable() {
        let userModel = { ...this.user }
        let jobsList = [];

        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobsList.push(item.jobId);
            }
        });

        jobsList.forEach((invoiceId) => {

            var data = {
                JobId: invoiceId,
                UpdateByUserId: userModel.userid
            }


            FetchData("/updateinvoiceflgandinsertreceivable", "POST", this.user.token, data).then(data => {

                this.handleSaveEventSuccess({
                    message: data,
                    model: data === 'Success' ? true : false,
                    color: data === 'Success' ? "success" : "warning"
                });
            });
        });

        //this.handleSearchFocus();
        this.populatePrintInvoice();

    }

    resetInvoiced() {
        let userModel = { ...this.user }
        let jobsList = [];

        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobsList.push(item.jobId);
            }
        });

        jobsList.forEach((invoiceId) => {

            var data = {
                JobId: invoiceId,
                UpdateByUserId: userModel.userid,
                Note: ""
            }


            FetchData("/resetinvoicedflg", "POST", this.user.token, data).then(data => {
                console.log(data);

                this.handleSaveEventSuccess({
                    message: data,
                    model: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            });
        });
        this.handleSearchFocus();
        this.populatePrintInvoice();

    }

    async populatePrintInvoice() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel };
        model.StartDate = model.StartDate === "" || model.StartDate === null ? null : moment(model.StartDate).format('YYYY-MM-DD');
        model.EndDate = model.EndDate === "" || model.EndDate === null ? null : moment(model.EndDate).format('YYYY-MM-DD');
        let url = "/getinvoices";

        this.setState({ searchModel: model }, () => {
            FetchData(url, "POST", this.user.token, model).then(data => {
                this.setState({ workOrderList: data, loading: false, fetchingData: false });
            });
        });

        this.resetChecks();
    }



    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.state.tableView === 'default' ? this.renderPrintInvoiceTable(this.state.workOrderList) : this.renderPrintInvoiceTable(this.state.workOrderList);

        return (
            <div style={{ paddingTop: "12px" }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <Row>
                    <Col className="col-9">
                        <InputGroup size="small">
                            <InputGroupText className="input-group-text" style={{ width: '80px', color: 'black' }}> Show Printed </InputGroupText>
                            <Input name="ShowPrinted" type="checkbox" className="input-checkbox form-check-input" defaultChecked={this.state.searchModel.ShowPrinted} onChange={this.handleSearchChange} /><span style={{ paddingRight: "4px" }}></span>
                            <input name="JobNum" id="jobnum" type="number" className="form-control form-control-text" value={this.state.searchModel.JobNum} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Invoice #" onKeyPress={this.handleKeyPress} />
                            <input name="Rep" id="rep" type="text" className="form-control form-control-text" value={this.state.searchModel.Rep} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Rep" onKeyPress={this.handleKeyPress} />
                            <input name="Claimant" id="claimant" type="text" className="form-control form-control-text" value={this.state.searchModel.Claimant} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant" onKeyPress={this.handleKeyPress} />
                            <input name="ClaimantNum" id="claimnum" type="text" className="form-control form-control-text" value={this.state.searchModel.ClaimantNum} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant #" onKeyPress={this.handleKeyPress} />
                            <input name="ClientAbbrv" id="clientabbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                            <input name="StartDate" id="startdate" type="date" className="form-control form-control-text" value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Start Date" onKeyPress={this.handleKeyPress} />
                            <input name="EndDate" id="enddate" type="date" className="form-control form-control-text" value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="End Date" onKeyPress={this.handleKeyPress} />
                            <Button color="btn" id="printinv" onClick={this.exportPDF} size="sm"><img src={PrintIcon} alt="Print Invoices" />Print Invoices</Button>
                            <Button color="btn" id="resetinvoiced" onClick={this.resetInvoiced} size="sm"><img src={PrintIcon} alt="Reset Invoiced" />Reset Invoiced</Button>
                        </InputGroup>
                    </Col>
                </Row>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <ReactTooltip id="rtp" place="right" type="dark" effect="solid" html={true} />
                <div style={{ overflowX: "scroll" }}>
                    {contents}
                </div>


                {this.state.modal &&
                    <WorkOrderForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} workOrderList={this.state.workOrderList} jobHistoryList={this.state.jobHistoryList} jobInvLineList={this.state.jobInvLineList} setNextPrevData={this.setNextPrevData} selectedIndex={this.state.selectedIndex} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

            </div>
        );

    }
}
