import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import './ag-theme-ela.css';
import * as CellRenderers from './AgGridCellRendererComponents';
const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: "", width: 30, headerCheckboxSelection: true, checkboxSelection: true, sortable: false, filter: false, },
    { headerName: "", field: "repEmail2", cellRenderer: 'repEmail2CellRenderer', width: 30, sortable: false, filter: false, },
    { headerName: "Job#", field: "jobNum", cellRenderer: 'jobNumCellRenderer', cellRendererParams: { context: { componentParent: this } }, width: 65, },
    { headerName: "", field: "addFollowUp", cellRenderer: 'addFollowUpCellRenderer', cellRendererParams: { context: { componentParent: this } }, width: 30, sortable: false, filter: false, },
    { headerName: "Prov Conf", field: "provConfflg", cellRenderer: 'profConfCellRenderer', width: 60 },
    { headerName: "Rep Auth", field: "repAuthorizedflg", cellRenderer: 'repAuthorizedCellRenderer', width: 60 },
    { headerName: "", field: "transportationflg", cellRenderer: 'transportationCellRenderer', width: 20, },
    {
        headerName: "Date", field: "jobDateTime", cellRenderer: 'jobDateCellRenderer', tooltipValueGetter: function (params) {
            const { data } = params;
            const jobDate = data.jobDateTime ? moment(data.jobDateTime).format('MM/DD/YYYY hh:mm A') : '';
            const finishTime = data.finishTimeString ? " to " + data.finishTimeString : '';
            const tooltipContent = `CreatedBy: ${data.createdBy}\nCreated On: ${moment(data.createDateTime).format('MM/DD/YYYY hh:mm A')}\nUpdated By: ${data.updatedBy}\nUpdated On: ${moment(data.updateDateTime).format('MM/DD/YYYY hh:mm A')}`;
            return `${jobDate} ${finishTime}\n${tooltipContent}`;
        },
        width: 125
    },
    {
        headerName: "Claimant", field: "clmLastName", cellRenderer: 'claimantNameCellRenderer', tooltipValueGetter: function (params) {
            const { data } = params;
            const claimantNotes = data.clmNote === null ? '' : data.clmNote;
            const tooltipContent = `${claimantNotes}`;
            return tooltipContent;
        },
        width: 125
    },
    { headerName: "", field: "jobNotes", cellRenderer: 'jobNotesCellRenderer', width: 20, sortable: false, filter: false, cellStyle: { paddingLeft: '0px'} },
    { headerName: "CLM#", field: "clmClaimNumber", width: 105 },
    { headerName: "CLM Ph", field: "clmPhone", width: 60 },
    { headerName: "", field: "", cellRenderer: 'intEmailCellRenderer', width: 30, sortable: false, filter: false, },
    { headerName: "Interpreter", field: "intLastName", cellRenderer: 'intNameCellRenderer', width: 125 },
    { headerName: "Int Cell /Text", field: "intCellTxt", width: 80 },
    { headerName: "", cellRenderer: 'providerEmailCellRenderer', width: 30, sortable: false, filter: false, },
    { headerName: "Job Provider", field: "providerName", width: 155 },
    { headerName: "", field: "repEmail", cellRenderer: 'repEmailCellRenderer', width: 30, sortable: false, filter: false, },
    { headerName: "Rep", field: "repLastName", cellRenderer: 'repNameCellRenderer', width: 125 },
    { headerName: "Rep Ph", field: "repPhone", width: 105 },
    { headerName: "Client Ph", field: "clientPhone", width: 80, },
    { headerName: "Client", field: "clientAbbrv", width: 68, },
    { headerName: "", field: "invoicedflg", cellRenderer: 'invoicedFlgCellRenderer', width: 20 },
    { headerName: "Caller Notes", field: "callerNote", width: 125 },
];

export class WorkOrderTableDefault extends Component {

    constructor(props) {
        super(props);
        this.gridApi = null;
        this.gridColumnApi = null;
        this.previousSelection = [];
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
        }
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentDidUpdate(prevProps) {
        if (this.props.clearSelection && !prevProps.clearSelection) {
            if (this.gridApi) {
                this.gridApi.deselectAll();
            }
        }
    }

    onSelectionChanged = () => {
        if (!this.gridApi) return;

        const currentSelection = this.gridApi.getSelectedRows();
        const currentSelectionIds = currentSelection.map(item => item.jobId);
        const previousSelectionIds = this.previousSelection.map(item => item.jobId);

        const newlySelected = currentSelection.filter(node =>
            !previousSelectionIds.includes(node.jobId)
        );
        const newlyDeselected = this.previousSelection.filter(node =>
            !currentSelectionIds.includes(node.jobId)
        );

        newlySelected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: true
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        newlyDeselected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: false
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        this.previousSelection = [...currentSelection];
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (this.props.onGridReady) {
            this.props.onGridReady(params);
        }
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns();
    };

    autoSizeUnspecifiedColumns = () => {
        if (!this.gridColumnApi) return;

        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });
            maxHeight += 10;
            gridApi.setHeaderHeight(maxHeight);
        });
    };

    handleRowDoubleClick = (event) => {
        const workorder = event.data;
        const rowIndex = event.rowIndex;
        this.props.setData(workorder, rowIndex, 'workorder');
    };

    render() {
        const { workOrders, handleCheckChange, onGridReady, ...props } = this.props;

        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: workOrders,
            context: {
                componentParent: this,
                handleCheckChange: handleCheckChange,
                gridApi: this.gridApi,
                checkboxStates: props.checkboxStates,
                addSchedule: props.addSchedule,
                setModal: props.setModal,
                setData: props.setData,
                setInterpreterEmail: props.setInterpreterEmail,
                onChange: handleCheckChange,
                state: props.state,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                jobNumCellRenderer: CellRenderers.JobNumCellRenderer,
                repEmail2CellRenderer: CellRenderers.RepEmail2CellRenderer,
                addFollowUpCellRenderer: CellRenderers.AddFollowUpCellRenderer,
                profConfCellRenderer: CellRenderers.ProvConfCellRenderer,
                repAuthorizedCellRenderer: CellRenderers.RepAuthorizedCellRenderer,
                transportationCellRenderer: CellRenderers.TransportationCellRenderer,
                jobDateCellRenderer: CellRenderers.JobDateCellRenderer,
                claimantNameCellRenderer: CellRenderers.ClaimantNameCellRenderer,
                jobNotesCellRenderer: CellRenderers.JobNotesCellRenderer,
                intEmailCellRenderer: CellRenderers.IntEmailCellRenderer,
                intNameCellRenderer: CellRenderers.IntNameCellRenderer,
                repEmailCellRenderer: CellRenderers.RepEmailCellRenderer,
                providerEmailCellRenderer: CellRenderers.ProviderEmailCellRenderer,
                repNameCellRenderer: CellRenderers.RepNameCellRenderer,
                invoicedFlgCellRenderer: CellRenderers.InvoicedFlgCellRenderer,
            },
            rowSelection: "multiple",
            onRowClicked: props.onRowClicked,
            onColumnResized: props.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleRowDoubleClick,
            onSelectionChanged: this.onSelectionChanged
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }
}

