import React, { Component, } from 'react';
import ReactTooltip from "react-tooltip";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import '../ag-theme-ela.css';

import * as CellRenderers from '../AgGridCellRendererComponents';

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: "JobDate", field: "jobDate", width: 80, },
    { headerName: "Client", field: "client", width: 60 },
    { headerName: "Claimant", field: "claimant", width: 100 },
    { headerName: "Job Number", field: "jobNum", width: 80 },
    { headerName: "Job Time", field: "jobTime", width: 80 },
    { headerName: "Interpreter", field: "interpreter", width: 100 },
    { headerName: 'Int Fee', field: 'intFee', cellRenderer: 'currencyCellRenderer', width: 70, },
    { headerName: 'OT', field: 'ot', cellRenderer: 'currencyCellRenderer', width: 70, },
    { headerName: 'Other Fee', field: 'otherFee', cellRenderer: 'currencyCellRenderer', width: 70, },
    { headerName: 'Job Total', field: 'jobTotal', cellRenderer: 'currencyCellRenderer', width: 70, },  
];

export class RptProductionTableDefault extends Component {
    static displayName = RptProductionTableDefault.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            clickCount: 0,
        };
        this.previousSelection = [];
        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
    //    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimer);
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns(false);
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    //handleDoubleClick() {
    //    console.log('Doubleclicked');
    //}

    render() {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: this.props.productionReport,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                state: this.props.state,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                currencyCellRenderer: CellRenderers.CurrencyCellRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.props.handleDoubleClick,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                    rowSelection={"multiple"}
                />
            </div>
        );

    }
}

