import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Input, Alert } from 'reactstrap';

import { WorkOrderTransportationFormEmail } from './WorkOrderTransportationFormEmail';

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

const moment = require('moment');

export class WorkOrderTransportationForm extends Component {
    static displayName = WorkOrderTransportationForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,
            modalWOTranFormEmail: false,
            alert: props.alert,
            email: {
                EmailFrom: '',
                EmailTo: '',
                EmailCC: '',
                Subject: '',
                Body: '',
                JobIds: props.model.JobId,
                FormFile: null
            }
        }

        this.onSave = this.onSave.bind(this);
        this.onSendEmailClick = this.onSendEmailClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setmodalWOTranFormEmail = this.setModalWOTranFormEmail.bind(this);
        //
        this.handleSaveEventTransportationFormEmailSuccess = this.handleSaveEventTransportationFormEmailSuccess.bind(this);
    }

    componentDidMount() {
        this.getTransportationData();
    }

    getTransportationData() {
        FetchData("/gettransportation", "POST", this.user.token, { JobId: this.props.model.JobId }).then(data => {
            if (data) {
                this.setState(prevState => ({
                    model: {
                        EventType: 'update',
                        TransportationId: data.transportationId,
                        JobId: data.jobId,
                        CallerState: data.callerState,
                        FailedTransportFlg: data.failedTransportFlg,
                        CallerName: data.callerName,
                        AuthorizedBy: data.authorizedBy,
                        CallerEmail: data.callerEmail,
                        CallerCompany: data.callerCompany,
                        CallerPhone: data.callerPhone,
                        AmbulatoryFlg: data.ambulatoryFlg,
                        RoundTripFlg: data.roundTripFlg,
                        Ground: data.ground,
                        AirFlg: data.airFlg,
                        LodgingFlg: data.lodgingFlg,
                        Other: data.other
                    }
                }));
            }
        });
    }

    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        FetchData("/savetransportation", "POST", this.user.token, model).then(data => {
            this.props.handleSaveEventSuccess({
                message: data,
                modal: data === 'Success' ? false : true,
                color: data === 'Success' ? "success" : "warning"
            });
        });
    }

    onSendEmailClick() {
        let model = { ...this.state.email }
        model.Subject = 'Transportation for ' + this.props.job.ClmFirstName + ' ' + this.props.job.ClmLastName + ' CLM # ' + this.props.job.ClmClaimNumber;

        var body = 'Please arrange transportation ';
        //body += '\n\nRequested by: ';
        //body += '\nEmail: ';
        //body += '\nAuthorized By: ';
        //body += '\nCaller Company: ' + this.state.model.CallerEmail;
        //body += '\nCaller Phone: ' + this.state.model.CallerPhone;

        body += '\n\nFor ' + this.props.job.ClmFirstName + ' ' + this.props.job.ClmLastName;
        body += '\n\tClaim #: ' + this.props.job.ClmClaimNumber;
        body += '\n\tDate of Injury: ' + this.props.job.ClmDateOfInjury;
        body += '\n\tEmployer: ' + this.props.job.ClmEmployer;
        body += '\n\tDate of Birth: ' + this.props.job.ClmDOB == null ? null : moment(this.props.job.ClmDOB).format('hh:mm A');
        body += '\n\tPhone #: ' + this.props.job.ClmPhone;
        body += '\n\tSSN #: ' + this.props.job.ClmSSN;
        body += '\n\tOrdering Physician: ' + this.props.job.ClmOrderedByName;
        body += '\n\tDiagnosis Code: ' + this.props.job.ClmDiagnosis;
        body += '\n\tTrip Type: ';

        //---
        if (this.state.model.AmbulatoryFlg == true)
            body += '\n\t\tAmbulatory: Yes';
        else
            body += '\n\t\tAmbulatory: No';

        //---
        if (this.state.model.RoundTripFlg == true)
            body += '\n\t\tRound Trip: Yes';
        else
            body += '\n\t\tRound Trip: No';

        //---
        if (this.state.model.Ground == 1)
            body += '\n\t\tVehicle: Vehicle (Sedan/Taxi)';
        else if (this.state.model.Ground == 2)
            body += '\n\t\tVehicle: Wheelchair Van';
        else
            body += '\n\t\tVehicle: Gurney';

        body += '\n\tOther: ' + this.state.model.Other;

        if (this.state.model.AirFlg == true)
            body += '\n\tAir: Yes';
        if (this.state.model.LodgingFlg == true)
            body += '\n\tLodging: Yes';

        body += '\n\nOn ' + moment(this.props.job.JobDateTime).format('MM/DD/YYYY') + ' to arrive at: ' + moment(this.props.job.JobDateTime).format('hh:mm A');
        body += '\n\nFrom: ' + this.props.job.ClmStreet;
        body += '\n' + this.props.job.ClmCity + ' ' + this.props.job.ClmState + ' ' + this.props.job.ClmZip;

        body += '\n\nTo: ' + this.props.job.ProviderName;
        body += '\n' + this.props.job.AptStreet;
        body += '\n' + this.props.job.AptCity + ', ' + this.props.job.AptState;
        body += '\n' + this.props.job.AptZip;
        body += '\n' + this.props.job.AptPhone;
        body += '\n\nBilling to: ' + this.props.job.RepLastName + ', ' + this.props.job.RepFirstName + ' \n'
        body += this.props.job.ClientName + ' (' + this.props.job.ClientAbbrv + ')';
        body += '\nTel: ' + this.props.job.ClientPhone;
        body += '\nEmail: ' + this.props.job.RepEmail;//ClientEmail;
        body += '\nAddress: ' + this.props.job.ClientMailStreet;
        body += '\n' + this.props.job.ClientMailCity;
        body += '\n' + this.props.job.ClientMailState + ', ' + this.props.job.ClientMailZip;

        // get user info
        body += '\n\nPlease let me know if you need anything else.';
        body += '\n\nThank you,';
        body += '\n\n' + this.user.email;

        model.Body = body;

        switch (this.props.job.ClmLastName.charAt(0)) {
            case 'A':
            case 'B':
            case 'C':
            case 'D':
            case 'E':
            case 'F':
            case 'G':
            case 'H':
            case 'I':
            case 'J':
            case 'K':
            case 'L':
                //Ext.getCmp('txtTransportationEmailCC').setValue('transports@centurypacificmedical.com,mail@executivelinguist.com');
                model.EmailCC = 'transports@centurypacificmedical.com,mail@executivelinguist.com';
                break;
            case 'M':
            case 'N':
            case 'O':
            case 'P':
            case 'Q':
            case 'R':
            case 'S':
            case 'T':
            case 'U':
            case 'V':
            case 'W':
            case 'X':
            case 'Y':
            case 'Z':
                //Ext.getCmp('txtTransportationEmailCC').setValue('transports@centurypacificmedical.com,mail@executivelinguist.com');
                model.EmailCC = 'transports@centurypacificmedical.com,mail@executivelinguist.com';
                break;
        }

        //Ext.getCmp('txtTransportationEmailTo').setValue('orders@cpmi.net');
        model.EmailFrom = this.user.email;
        model.EmailTo = 'orders@cpmi.net';

        this.setState({
            email: model
        }, () => {
            this.setmodalWOTranFormEmail();
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.value === 'true')
            model[e.target.name] = true;
        else if (e.target.value === 'false')
            model[e.target.name] = false;
        else if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.name === 'Ground')
            model[e.target.name] = parseInt(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ model: model });
    }

    setModalWOTranFormEmail() {
        this.setState({ modalWOTranFormEmail: !this.state.modalWOTranFormEmail });
    }

    handleSaveEventTransportationFormEmailSuccess(alert) {
        this.setState({
            modalClaimant: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, 3000);
            this.setModalWOTranFormEmail();
        });
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "800px", maxHeight: "500px" }} backdrop="static">
                    <ModalHeader><span style={{ fontSize: "14px" }}>Transportation Form</span></ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        {this.state.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text" style={{ width: '94px' }}>
                                            JobId:
                                        </InputGroupText>
                                        <Input name="JobNum" type="text" className="form-control form-control-text" value={this.state.model.JobNum} readOnly onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text">
                                            Caller State:
                                        </InputGroupText>
                                        <Input name="CallerState" type="text" className="form-control form-control-text" maxLength={2} value={this.state.model.CallerState} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text">
                                            Failed:
                                        </InputGroupText>
                                        <Input name="FailedTransportFlg" type="checkbox" className="input-checkbox" checked={this.state.model.FailedTransportFlg} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        <InputGroup size="sm">*/}
                            {/*            <InputGroupText className="input-group-text" style={{ width: '94px' }}>*/}
                            {/*                Caller Name:*/}
                            {/*            </InputGroupText>*/}
                            {/*            <Input name="CallerName" type="text" className="form-control form-control-text" maxLength={30} value={this.state.model.CallerName} onChange={this.handleChange} />*/}
                            {/*        </InputGroup>*/}
                            {/*    </Col>*/}
                            {/*    <Col>*/}
                            {/*        <InputGroup size="sm">*/}
                            {/*            <InputGroupText className="input-group-text">*/}
                            {/*                Authorized By:*/}
                            {/*            </InputGroupText>*/}
                            {/*            <Input name="AuthorizedBy" type="text" className="form-control form-control-text" maxLength={50} value={this.state.model.AuthorizedBy} onChange={this.handleChange} />*/}
                            {/*        </InputGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        <InputGroup size="sm">*/}
                            {/*            <InputGroupText className="input-group-text" style={{ width: '94px' }}>*/}
                            {/*                Caller Email:*/}
                            {/*            </InputGroupText>*/}
                            {/*            <Input name="CallerEmail" type="text" className="form-control form-control-text" maxLength={50} value={this.state.model.CallerEmail} onChange={this.handleChange} />*/}
                            {/*        </InputGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        <InputGroup size="sm">*/}
                            {/*            <InputGroupText className="input-group-text">*/}
                            {/*                Caller Company:*/}
                            {/*            </InputGroupText>*/}
                            {/*            <Input name="CallerCompany" type="text" className="form-control form-control-text" maxLength={50} value={this.state.model.CallerCompany} onChange={this.handleChange} />*/}
                            {/*        </InputGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        <InputGroup size="sm">*/}
                            {/*            <InputGroupText className="input-group-text" style={{ width: '94px' }}>*/}
                            {/*                Caller Phone:*/}
                            {/*            </InputGroupText>*/}
                            {/*            <Input name="CallerPhone" type="text" className="form-control form-control-text" maxLength={50} value={this.state.model.CallerPhone} onChange={this.handleChange} />*/}
                            {/*        </InputGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text" style={{ width: '94px' }}>
                                            Ambulatory:
                                        </InputGroupText>
                                        <Input name="AmbulatoryFlg" type="checkbox" className="input-checkbox" checked={this.state.model.AmbulatoryFlg} onChange={this.handleChange} />
                                        {/*<select name="AmbulatoryFlg" className="form-control form-control-text" value={this.state.model.AmbulatoryFlg} onChange={this.handleChange}>*/}
                                        {/*    <option value={true}>Yes</option>*/}
                                        {/*    <option value={false}>No</option>*/}
                                        {/*</select>*/}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text" style={{ width: '80px' }}>
                                            Round Trip:
                                        </InputGroupText>
                                        <Input name="RoundTripFlg" type="checkbox" className="input-checkbox" checked={this.state.model.RoundTripFlg} onChange={this.handleChange} />
                                        {/*<select name="RoundTripFlg" className="form-control form-control-text" value={this.state.model.RoundTripFlg}  onChange={this.handleChange}>*/}
                                        {/*    <option value={true}>Yes</option>*/}
                                        {/*    <option value={false}>No</option>*/}
                                        {/*</select>*/}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text" style={{ width: '94px' }}>
                                            Vehicle:
                                        </InputGroupText>

                                        <select name="Ground" className="form-control form-control-text" value={this.state.model.Ground } onChange={this.handleChange}>
                                            <option value={null} selected></option>
                                            <option value={1}>Vehicle (Sedan/Taxi)</option>
                                            <option value={2}>Wheelchair Van</option>
                                            <option value={3}>Uber/Lyft</option>
                                        </select>
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text" style={{ width: '94px' }}>

                                        </InputGroupText>

                                        <label className="input-group-text" >Air: </label>
                                        <Input name="AirFlg" type="checkbox" className="input-checkbox" checked={this.state.model.AirFlg} onChange={this.handleChange} />
                                        <span style={{ paddingRight: '10px' }} ></span>
                                        <label className="input-group-text">Lodging: </label>
                                        <Input name="LodgingFlg" type="checkbox" className="input-checkbox" checked={this.state.model.LodgingFlg} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText className="input-group-text" style={{ width: '94px' }}>
                                            Other:
                                        </InputGroupText>
                                        <Input name="Other" type="text" className="form-control form-control-text" maxLength={50} value={this.state.model.Other} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                        <Button size="sm" onClick={this.onSendEmailClick}>Email</Button>
                        <Button size="sm" onClick={this.props.setModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                {
                    this.state.modalWOTranFormEmail &&
                    <WorkOrderTransportationFormEmail model={this.state.email} modal={this.state.modalWOTranFormEmail} alert={this.props.alert} setModal={this.setmodalWOTranFormEmail} handleSaveEventSuccess={this.handleSaveEventTransportationFormEmailSuccess} />
                }
            </div>
        )
    }
}