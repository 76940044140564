import React, { Component } from 'react';
import { Table, Button, Row, Col, Input, InputGroup, Alert, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import ReactTooltip from "react-tooltip";

import { LoadingModal } from './LoadingModal';
import { RepFormEmail } from './RepFormEmail'
import { LateInvoicesAddNote } from './LateInvoicesAddNote';
import { WorkOrderForm } from './WorkOrderForm';
import { LateInvoicesTableDefault } from './LateInvoicesTableDefault';
import { LateInvoicesTableJobNotes } from './LateInvoicesTableJobNotes';
import { LateInvoicesTableReceivables } from './LateInvoicesTableReceivables';

import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';
import { saveAs } from 'file-saver';

const moment = require('moment');



export class LateInvoices extends Component {
    static displayName = LateInvoices.name;

    constructor(props) {
        super(props);
        this.state = {
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            lateInvoiceList: [],
            receivableList: [],
            receivableItemList: [],
            modalRepFormEmail: false,
            modalLateInvoiceNote: false,
            modalWorkOrder: false,
            selectedJobNum: null,
            selectedJob: null,
            fetchingData: false,
            isKeyPress: false,
            activeTab: '1',
            checkAll: false,
            selectedIndex: -1,
            selectedRowsData: [],
            receivableItem: {
                EventType: 'insert',
                JobReceivableItemId: '',
                JobId: null,
                UserInsertedFlg: true,
                ReminderDate: null,
                Notes: '',
                FileLocation: '',
                FileName: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                FormFile: null
            },
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                JobNum: null,
                Rep: '',
                Claimant: '',
                ClmClaimNumberNoDash: '',
                ClientAbbrv: '',
                DateFrom: null,
                DateTo: null,
                CreatedBy: '',
                FilterByReminderDate: true
            },
            email: {
                EmailFrom: '',
                EmailTo: '',
                EmailCC: '',
                Subject: '',
                Body: '',
                JobIds: '',
                NewReminderDate: null,
                CreateByUserId: null
            },
            job: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                JobBalance: null
            }
        };
        this.user = JSON.parse(GetCookie('user'));

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.setModalRepFormEmail = this.setModalRepFormEmail.bind(this);
        this.setModalLateInvoiceNote = this.setModalLateInvoiceNote.bind(this);
        this.setModalWorkOrder = this.setModalWorkOrder.bind(this);
        this.addNoteClick = this.addNoteClick.bind(this);
        this.handleNotesDoubleClick = this.handleNotesDoubleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.unCheckAll = this.unCheckAll.bind(this);
        this.handleLateInvoiceClick = this.handleLateInvoiceClick.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleRepEmailClick = this.handleRepEmailClick.bind(this);
        this.handleEmailIconRepClick = this.handleEmailIconRepClick.bind(this);
        this.handlePageReset = this.handlePageReset.bind(this);
        this.handleSaveEventRepEmailSuccess = this.handleSaveEventRepEmailSuccess.bind(this);
        this.handleSaveEventAddNoteSuccess = this.handleSaveEventAddNoteSuccess.bind(this);
        this.handleSaveEventWorkOrderSuccess = this.handleSaveEventWorkOrderSuccess.bind(this);
        this.renderLateInvoiceTable = this.renderLateInvoiceTable.bind(this);
        this.renderJobNotes = this.renderJobNotesTable.bind(this);
        this.renderReceivablesTable = this.renderReceivablesTable.bind(this);
        this.viewInvoice = this.viewInvoice.bind(this);
        this.viewS3File = this.viewS3File.bind(this);
        this.viewWorkOrder = this.viewWorkOrder.bind(this);
    }

    componentDidMount() {
        this.populateLateInvoiceData();
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimer);
    }

    async populateLateInvoiceData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel };
        try {
            //console.log('Fetching lateInvoices...');
            this.setState({ searchModel: model }, () => {
                FetchData("/getlateinvoice", "POST", this.user.token, model).then(data => {
                    if (this.state.isKeyPress && this.state.searchModel.JobNum != null) {
                        if (data.length === 0) {
                            this.setState({
                                alert: {
                                    color: "warning",
                                    message: 'There is no return on your search.',
                                    isOpen: true
                                }
                            }, () => {
                                window.setTimeout(() => {
                                    this.setState({ alert: { isOpen: false } });
                                }, Timeout(alert.message));
                            });
                        }
                    }
                    //console.log('Fetched data:', data);
                    this.setState({ lateInvoiceList: data, loading: false, fetchingData: false, isKeyPress: false });
                });
            });

        } catch (error) {
            console.error('Error fetching late invoices: ', error);
        }
    }

    async getJobReceivable(jobId) {
        this.setState({ fetchingData: true });

        FetchData("/getreceivables", "POST", this.user.token, { JobId: jobId }).then(data => {
            this.setState({ receivableList: data, loading: false, fetchingData: false });
        });
    }

    async getJobNotes(jobId, jobNum) {
        FetchData("/getreceivableitems", "POST", this.user.token, { JobId: jobId }).then(data => {
            this.setState({ selectedJobNum: jobNum, receivableItemList: data });
        });
    }

    handleFilterByReminderDateFlip() {
        this.setState((state, props) => ({
            searchModel: [
                {
                    FilterByReminderDate: !this.state.searchModel.FilterByReminderDate
                }
            ]
        }));
    }

    setModalRepFormEmail() {
        this.setState({ modalRepFormEmail: !this.state.modalRepFormEmail });
    }

    setModalLateInvoiceNote() {
        this.setState({ modalLateInvoiceNote: !this.state.modalLateInvoiceNote });
    }

    setModalWorkOrder() {
        this.setState({ modalWorkOrder: !this.state.modalWorkOrder });
    }

    addNoteClick(invoice, selectedRowsData) {
        var model = { ...this.state.receivableItem };
        model.EventType = 'insert';
        model.JobId = invoice.jobId;
        model.CreateByUserId = this.user.userid;

        this.setState({ selectedJob: invoice, selectedRowsData: selectedRowsData, receivableItem: model }, () => {
            this.setModalLateInvoiceNote();
        });
    }

    handleNotesDoubleClick(invoice) {
        var rowData = [];
        rowData.push(invoice);

        this.setState({
            selectedJob: invoice,
            selectedJobNum: this.state.selectedJobNum,
            selectedRowsData: rowData,
            receivableItem: {
                EventType: 'update',
                JobReceivableItemId: invoice.jobReceivableItemId,
                JobId: invoice.jobId,
                UserInsertedFlg: invoice.userInsertedFlg,
                ReminderDate: moment(invoice.reminderDate).format('YYYY-MM-DD'),
                Notes: invoice.notes,
                FileLocation: invoice.fileLocation,
                FileName: invoice.fileName,
                FileLocationS3: invoice.fileLocationS3,
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: this.user.userid,
                UpdateDateTime: null,
                IsDeletedFlg: invoice.isDeletedFlg
            }
        }, () => {
            this.setModalLateInvoiceNote();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel };
        if (model.FilterByReminderDate) model.FilterByReminderDate = false;

        if (e.target.type === 'number' && (e.target.value !== null || e.target.value !== ''))
            model[e.target.name] = parseInt(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({ receivableList: [], receivableItemList: [], isKeyPress: true }, () => {
                this.populateLateInvoiceData();
            });
        }
    }

    handleCheckAll = () => {
        this.setState({
            checkAll: !this.state.checkAll
        }, () => {
            this.state.lateInvoiceList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: this.state.checkAll }));
        });
    }

    unCheckAll = () => {
        this.state.lateInvoiceList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: false }));
    }

    handleCheckChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        }, () => {
            const uncheckedItems = this.state.lateInvoiceList.filter((item) => !this.state["chk_" + item.jobNum]);
            this.setState({
                checkAll: uncheckedItems.length === 0 ? true : false
            });
        });
    }

    handleLateInvoiceClick(job) {
        this.getJobReceivable(job.jobId);
        this.getJobNotes(job.jobId, job.jobNum);
    }

    handleEmailIconRepClick(invoice) {
        if (!this.user || !this.user.token) {
            console.error("User or user token is undefined");
            console.log("this.user:", this.user);
            console.log("this.user.token:", this.user.token);
            return;
        }

        FetchData("/getclaimantinvoicewithoutstandingbalance", "POST", this.user.token, invoice.claimantClmId)
            .then(data => {
                var d = new Date(invoice.jobDate);
                var newD;
                if (d.getDate() <= 15) {
                    newD = new Date((d.getMonth() + 1) + '/15/' + d.getFullYear());
                } else {
                    newD = new Date(d.setMonth(d.getDate() + 1)); //Ext.Date.add(d, Ext.Date.MONTH, 1);
                    newD = new Date((newD.getMonth() + 1) + '/1/' + newD.getFullYear());
                }

                switch (newD.getDay()) {
                    case 4:
                        newD = newD.setDate(newD.getDate() + 5); //Ext.Date.add(newD, Ext.Date.DAY, 5);
                        break;
                    case 5:
                        newD = newD.setDate(newD.getDate() + 4); //Ext.Date.add(newD, Ext.Date.DAY, 4);
                        break;
                    case 6:
                        newD = newD.setDate(newD.getDate() + 3); //Ext.Date.add(newD, Ext.Date.DAY, 3);
                        break;
                    default:
                        newD = newD.setDate(newD.getDate() + 2); //Ext.Date.add(newD, Ext.Date.DAY, 2);
                }

                var subject = 'Invoice #' + invoice.jobNum + ', ' + invoice.claimantFirstName + ' ' + invoice.claimantLastName + ' CLM # ' + invoice.claimantClaimNumber;
                subject += ' - ' + moment(invoice.jobDate).format('MM/DD/YYYY');

                var body = 'Dear ' + invoice.repFirstName + ',';
                body += '\n\nOur system shows the attached invoice as unpaid.\nIf it is currently in line for payment please disregard this email.\nIf not, please advise what we need to do to receive payment.';
                body += '\nPlease feel free to contact us at (800) 522-2320 if you have any questions.';

                if (data.length > 1)
                    body += '\n\nThe table shows all outstanding invoices for this claimant, for your reference.';

                if (data.length > 0) {
                    body += '\n\n';
                    body += '<table style="width:60%; border-collapse: collapse;">';
                    body += '<tbody>';
                    body += '<tr style="border: 1px solid #ddd;">';
                    body += '<td style="border: 1px solid #ddd;">INV#</td>';
                    body += '<td style="border: 1px solid #ddd;">Date</td>';
                    body += '<td style="border: 1px solid #ddd;">Balance</td>';
                    body += '</tr>';
                    for (var i = 0; i < data.length; i++) {
                        body += '<tr style="border: 1px solid #ddd;">';
                        body += '<td style="border: 1px solid #ddd;">' + data[i]["jobNum"] + '</td>';
                        body += '<td style="border: 1px solid #ddd;">' + moment(new Date(data[i]["jobDateTime"])).format('MM/DD/YYYY') + '</td>';
                        body += '<td style="border: 1px solid #ddd;">' + data[i]["jobBalance"] + '</td>';
                        body += '</tr>';
                    }
                    body += '</tbody>';
                    body += '</table>';
                }

                body += '\n\nThank you,';
                body += '\n\n' + this.user.firstname + ' ' + this.user.lastname;
                body += '\n\nExecutive Linguist Agency \n800-522-2320 | 310-376-1409 | 8am - 5pm Nationwide \nDiversified Language Services – Translation & Localization \nVideo Remote, Telephonic, and On-site Interpreting \nrequests: mail@executivelinguist.com';

                var newReminderDate = new Date();
                newReminderDate.setDate(newReminderDate.getDate() + 15);

                this.setState(prevState => ({
                    email: {
                        EmailFrom: this.user.email,
                        EmailTo: invoice.repEmail,
                        EmailCC: '',
                        Subject: subject,
                        Body: body,
                        JobIds: invoice.jobId,
                        JobNums: invoice.jobNum.toString(),
                        EmailType: 'late_invoice_email',
                        NewReminderDate: newReminderDate,
                        CreatedByUserId: this.user.userid
                    }
                }));
                this.setModalRepFormEmail();
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }

    handleRepEmailClick() {
        let jobNumsList = [];
        let repList = [];
        var sameRepSelected = true;

        this.state.lateInvoiceList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobNumsList.push(item);
                if (repList.length === 0)
                    repList.push(item.repEmail);
                else {
                    for (var i = 0; i < repList.length; i++) {
                        if (repList[i] !== item.repEmail) {
                            sameRepSelected = false;
                            break;
                        }
                    }
                }
            }
        });

        if (!sameRepSelected) {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Selected Reps are not the same.",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message));
            });
        } else {
            var body = 'Dear ' + jobNumsList[0].repFirstName + ',';

            var subject = jobNumsList[0].claimantFirstName + ' ' + jobNumsList[0].claimantLastName + ' CLM # ' + jobNumsList[0].claimantClaimNumber;

            body += '\n\n';
            body += '<table style="width:100%;border: 1px solid black;border-collapse: collapse;">';
            body += '<tr style="border: 1px solid black">';
            body += '<td style="border: 1px solid black">JobID</td><td style="border: 1px solid black">Appointment Date Time</td><td style="border: 1px solid black">Provider</td><td style="border: 1px solid black">Location</td>';
            body += '</tr>';
            for (var i = 0; i < jobNumsList.length; i++) {
                var d = new Date(jobNumsList[i].jobDate);

                body += '<tr style="border: 1px solid black">';
                body += '<td style="border: 1px solid black">' + jobNumsList[i].jobNum + '</td>';
                body += '<td style="border: 1px solid black">' + moment(jobNumsList[i].jobDate).format('MM/DD/YYYY') + ' (' + this.state.dayNames[d.getDay()] + ') ' + moment(jobNumsList[i].jobTime).format('hh:mm A') + '</td>';
                body += '<td style="border: 1px solid black">' + jobNumsList[i].jobProvider + '</td>';
                body += '<td style="border: 1px solid black">' + jobNumsList[i].jobAptStreet + ',' + jobNumsList[i].jobAptCity + ',' + jobNumsList[i].jobAptState + ',' + jobNumsList[i].jobAptZip + '</td>';
                body += '</tr>';
            }
            body += '</table>';

            body += '\n\nThank you,';
            body += '\n' + this.user.firstname + ' ' + this.user.lastname;

            body += '\n\nExecutive Linguist Agency \n800-522-2320 | Text: 310-955-4513 | 8am - 5pm Nationwide \nAfter-hours Emergencies: 310-749-6521 or 562-682-3924 \nQuestions: mail@executivelinguist.com';

            this.setState(prevState => ({
                email: {
                    EmailFrom: this.user.email,
                    EmailTo: jobNumsList[0].repEmail,
                    EmailCC: '',
                    Subject: subject,
                    Body: body,
                    JobIds: jobNumsList.map(u => u.jobId).join(','),
                    JobNums: jobNumsList.map(u => u.jobNum).join(','),
                    EmailType: 'late_invoice_email',
                    CreatedByUserId: this.user.userid
                }
            }));
            this.setModalRepFormEmail();
        }
    }

    handleSaveEventRepEmailSuccess(alert) {
        this.setState({
            modalRepFormEmail: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));
            this.unCheckAll();
        });
        this.populateLateInvoiceData();
    }

    handleSaveEventAddNoteSuccess(alert) {
        this.setState({
            modalLateInvoiceNote: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            this.getJobNotes(this.state.selectedJob.jobId, this.state.selectedJobNum);
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleSaveEventWorkOrderSuccess(alert) {
        this.setState({
            modalWorkOrder: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handlePageReset() {
        let model = this.state.searchModel;
        model.JobNum = null;
        model.Rep = '';
        model.Claimant = '';
        model.ClmClaimNumberNoDash = '';
        model.ClientAbbrv = '';
        model.DateFrom = null;
        model.DateTo = null;
        model.CreatedBy = '';
        model.FilterByReminderDate = true;

        this.txtJobNum.value = '';
        this.txtRep.value = '';
        this.txtClaimant.value = '';
        this.txtClmClaimNumberNoDash.value = '';
        this.txtClientAbbrv.value = '';
        this.txtDateFrom.value = '';
        this.txtDateTo.value = '';
        this.txtCreatedBy.value = '';

        this.setState({
            searchModel: model,
            selectedJobNum: null,
            receivableList: [],
            receivableItemList: [],
        }, () => {
            this.populateLateInvoiceData();
        });
    }

    viewInvoice(woData, i) {
        let body = {
            JobIdsList: ""
        };

        body.JobIdsList = woData.jobId;
        let currentDate = new Date();
        let invoiceFileName = "Invoice " + woData.jobNum + " " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate() + 1) + "-" + currentDate.getFullYear() + ".pdf";

        FetchFile("/printinvoices", "POST", this.user.token, body).then(blob => {
            saveAs(blob, invoiceFileName);
        })
    }

    viewS3File(noteData, i) {
        let receivableItem = {
            EventType: 'update',
            JobReceivableItemId: noteData.jobReceivableItemId,
            JobId: noteData.jobId,
            UserInsertedFlg: noteData.userInsertedFlg,
            ReminderDate: moment(noteData.reminderDate).format('YYYY-MM-DD'),
            Notes: noteData.notes,
            FileLocation: noteData.fileLocation,
            FileName: noteData.fileName,
            FileLocationS3: noteData.fileLocationS3,
            //CreateDateTime: noteData.createDateTime,
            //CreateByUserId: noteData.createByUserId,
            //UpdateByUserId: this.user.userid,
            //UpdateDateTime: noteData,
            //IsDeletedFlg: noteData.isDeletedFlg
        };

        FetchFile("/GetS3File", "POST", this.user.token, receivableItem).then(blob => {
            saveAs(blob, noteData.fileName);
        })
    }


    viewWorkOrder(jobData, showModal) {
        FetchData("/getworkorder?jobId=" + jobData.jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                job: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString === "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.intCellTxt,
                    IntPhoneOnly: data.intPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseflg: data.inHouseflg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    JobBalance: data.jobBalance,
                    WorkFlowId: data.workFlowId
                },
            }), () => {
                this.setModalWorkOrder();
            });
        });
    }

    renderLateInvoiceTable() {
        return (
            <LateInvoicesTableDefault
                state={this.state}
                addNoteClick={this.addNoteClick}
                lateInvoices={this.state.lateInvoiceList}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                setJobData={this.setJobData}
                handleLateInvoiceClick={this.handleLateInvoiceClick}
                handleCheckChange={this.handleCheckChange}
                handleEmailIconRepClick={this.handleEmailIconRepClick}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setRepEmail2={this.setRepEmail2}
                viewInvoice={this.viewInvoice}
                viewJobNotes={this.viewJobNotes}
                viewJobHistory={this.viewJobHistory}
                viewWorkOrder={this.viewWorkOrder}
            ></LateInvoicesTableDefault>
        );
    }

    renderJobNotesTable() {
        return (
            <LateInvoicesTableJobNotes
                state={this.state}
                jobNotes={this.state.receivableItemList}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                handleNotesDoubleClick={this.handleNotesDoubleClick}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setRepEmail2={this.setRepEmail2}
                viewInvoice={this.viewInvoice}
                viewS3File={this.viewS3File}
                viewJobNotes={this.viewJobNotes}
                viewJobHistory={this.viewJobHistory}
            ></LateInvoicesTableJobNotes>
        );
    }

    renderReceivablesTable() {
        return (
            <LateInvoicesTableReceivables
                state={this.state}
                receivables={this.state.receivableList}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setRepEmail2={this.setRepEmail2}
                viewInvoice={this.viewInvoice}
                viewJobNotes={this.viewJobNotes}
                viewJobHistory={this.viewJobHistory}
            ></LateInvoicesTableReceivables>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderLateInvoiceTable();

        let jobNotesContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderJobNotesTable();

        let receivablesContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderReceivablesTable();

       return (
            <div style={{ paddingTop: "12px" }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen}>
                        {this.state.alert.message}
                    </Alert>
                </center>
               <Row>
                   <Col className="col-9">
                       <InputGroup size="sm">
                           <input name="JobNum" type="number" className="form-control form-control-text" value={this.state.searchModel.JobNum} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Invoice #" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtJobNum = input; }} />
                           <input name="Rep" type="text" className="form-control form-control-text" value={this.state.searchModel.Rep} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Rep" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtRep = input; }} />
                           <input name="Claimant" type="text" className="form-control form-control-text" value={this.state.searchModel.Claimant} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtClaimant = input; }} />
                           <input name="ClmClaimNumberNoDash" type="text" className="form-control form-control-text" value={this.state.searchModel.ClmClaimNumberNoDash} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant #" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtClmClaimNumberNoDash = input; }} />
                           <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Abbrv" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtClientAbbrv = input; }} />
                           <input name="DateFrom" type="date" className="form-control form-control-text" value={this.state.searchModel.DateFrom} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Abbrv" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtDateFrom = input; }} />
                           <input name="DateTo" type="date" className="form-control form-control-text" value={this.state.searchModel.DateTo} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Abbrv" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtDateTo = input; }} />
                           <input name="CreatedBy" type="text" className="form-control form-control-text" value={this.state.searchModel.CreatedBy} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Created By" onKeyPress={this.handleKeyPress} ref={(input) => { this.txtCreatedBy = input; }} />
                           {' '}<span style={{ paddingRight: "4px" }}></span><Button color="secondary" className="btn" size="sm" outline onClick={this.handleRepEmailClick}>Rep Email</Button>
                           {' '}<span style={{ paddingRight: "4px" }}></span><Button color="secondary" className="btn" size="sm" outline onClick={this.handlePageReset}>Today's Late Invoices</Button>
                       </InputGroup>
                   </Col>
               </Row>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <ReactTooltip id="rtp" place="right" type="dark" effect="solid" />
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {contents}
                </div>

               <hr />
               <Nav tabs>
                   <NavItem>
                       <NavLink className={this.state.activeTab === '1' ? "active" : ""}
                           onClick={() => { this.setState({ activeTab: '1' }) }}>
                           Notes
                       </NavLink>
                   </NavItem>
                   <NavItem>
                       <NavLink className={this.state.activeTab === '2' ? "active" : ""}
                           onClick={() => { this.setState({ activeTab: '2' }) }}>
                           Receivables
                       </NavLink>
                   </NavItem>
               </Nav>

               <TabContent activeTab={this.state.activeTab}>
                   <TabPane tabId="1">
                       {jobNotesContents}
                   </TabPane>
                   <TabPane tabId="2">
                       {receivablesContents}
                   </TabPane>
               </TabContent>

               {this.state.modalRepFormEmail &&
                   <RepFormEmail model={this.state.email} modal={this.state.modalRepFormEmail} alert={this.state.alert} setModal={this.setModalRepFormEmail} handleSaveEventSuccess={this.handleSaveEventRepEmailSuccess} />
               }
               {this.state.modalLateInvoiceNote &&
                   <LateInvoicesAddNote model={this.state.receivableItem} modal={this.state.modalLateInvoiceNote} alert={this.state.alert} rowData={this.state.selectedRowsData} setModal={this.setModalLateInvoiceNote} handleSaveEventSuccess={this.handleSaveEventAddNoteSuccess} />
               }
               {this.state.modalWorkOrder &&
                   <WorkOrderForm model={this.state.job} modal={this.state.modalWorkOrder} alert={this.state.alert} setModal={this.setModalWorkOrder} workOrderList={[]} jobHistoryList={[]} jobInvLineList={[]} setNextPrevData={null} selectedIndex={0} handleSaveEventSuccess={this.handleSaveEventWorkOrderSuccess} />
               }
            </div>
        );
    }

}

