import React, { Component } from 'react';
import { Button, Row, Col, Alert, InputGroup } from 'reactstrap';

import { ProviderOfficeForm } from './ProviderOfficeForm';
import { ProviderOfficeTableDefault } from './ProviderOfficeTableDefault';
import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png'

export class ProviderOffice extends Component {
    static displayName = ProviderOffice.name;

    constructor(props) {
        super(props);
        this.state = {

            providerList: [],
            loading: false,
            fetchingData: false,
            modal: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                ProvId: null,
                ProvLastName: '',
                ProvFirstName: '',
                ProvTitle: '',
                ProvSpeciality: '',
                ProvReceptionist: '',
                ProvPhone: '',
                ProvPhone2: '',
                ProvStreet: '',
                ProvCity: '',
                ProvState: '',
                ProvZip: '',
                ProvNote: '',
                ProvIntNote: '',
                ProvInactiveFlg: false,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                DeletionReason: '',
                UserName: ''
            },
            searchModel: {
                ProvId: null,
                FullName: '',
                ProvLastName: '',
                ProvFirstName: '',
                ProvPhone: '',
                ProvStreet: '',
                SortBy: 'provLastName',
                SortDirection: 'asc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.setModal = this.setModal.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderProviderTable = this.renderProviderTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchFocus = this.handleSearchFocus.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        //this.populateRepData();

        document.onmousemove = this.handleOnMouseMove;
        document.onmouseup = this.handleOnMouseUp;
        return () => {
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                ProvId: null,
                ProvLastName: '',
                ProvFirstName: '',
                ProvTitle: '',
                ProvSpeciality: '',
                ProvReceptionist: '',
                ProvPhone: '',
                ProvPhone2: '',
                ProvStreet: '',
                ProvCity: '',
                ProvState: '',
                ProvZip: '',
                ProvNote: '',
                ProvIntNote: '',
                ProvInactiveFlg: false,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                DeletionReason: '',
                UserName: ''
            }
        }));

        this.setModal();
    }

    handleDoubleClick(providerData) {
        let searchModel = {
            ProvId: providerData.provId,
            FullName: '',
            ProvLastName: '',
            ProvFirstName: '',
            ProvPhone: '',
            ProvStreet: '',
        }

        FetchData("/getprovideroffices", "POST", this.user.token, searchModel).then(data => {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    ProvId: providerData.data.provId,
                    ProvLastName: providerData.data.provLastName,
                    ProvFirstName: providerData.data.provFirstName,
                    ProvTitle: providerData.data.provTitle,
                    ProvSpeciality: providerData.data.provSpeciality,
                    ProvReceptionist: providerData.data.provReceptionist,
                    ProvPhone: providerData.data.provPhone,
                    ProvPhone2: providerData.data.provPhone2,
                    ProvStreet: providerData.data.provStreet,
                    ProvCity: providerData.data.provCity,
                    ProvState: providerData.data.provState,
                    ProvZip: providerData.data.provZip,
                    ProvNote: providerData.data.provNote,
                    ProvIntNote: providerData.data.provIntNote,
                    ProvInactiveFlg: providerData.data.provInactiveFlg,
                    CreateByUserId: providerData.data.createByUserId,
                    CreateDateTime: providerData.data.createDateTime,
                    UpdateByUserId: providerData.data.updateByUserId,
                    UpdateDateTime: providerData.data.updateDateTime,
                    IsDeletedFlg: providerData.data.isDeletedFlg
                }
            }))
            this.setModal();
    
        });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            //this.populateProviderData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateProviderData();
        }
    }

    handleSearchFocus(e) {
        this.setState({
            searchModel: {
                ProvId: null,
                FullName: '',
                ProvLastName: '',
                ProvFirstName: '',
                ProvPhone: '',
                ProvStreet: ''
            }
        }, () => {

        });

        if (e.target.value !== "") {
            let model = { ...this.state.searchModel }
            model[e.target.name] = e.target.value;

            this.setState({ searchModel: model });
        }
    }


    async populateProviderData() {
        this.setState({ fetchingData: true });

        FetchData("/getprovideroffices", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ providerList: data, loading: false, fetchingData: false });
        });
    }

    renderProviderTable(providers) {
        return (
            <ProviderOfficeTableDefault
                state={this.state}
                providers={this.state.providerList}
                handleDoubleClick={this.handleDoubleClick}
            ></ProviderOfficeTableDefault>

        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderProviderTable(this.state.providerList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Provider Offices</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}</Button>
                            <input name="FullName" type="text" className="form-control form-control-text" value={this.state.searchModel.FullName} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Provider (Lastname Firstname)" onKeyPress={this.handleKeyPress} />
                            <input name="ProvPhone" type="text" className="form-control form-control-text" value={this.state.searchModel.ProvPhone} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Phone" onKeyPress={this.handleKeyPress} />
                            <input name="ProvStreet" type="text" className="form-control form-control-text" value={this.state.searchModel.ProvStreet} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Street" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <ProviderOfficeForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
