import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, InputGroup, InputGroupText, Alert } from 'reactstrap';

import { FetchData, UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

export class ProviderFormEmail extends Component {
    static displayName = ProviderFormEmail.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,
            activeTab: '1'
        };

        this.handleChange = this.handleChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    sendEmail() {
        FetchData("/sendprovideremail", "POST", this.user.token, this.state.model).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data === 'Success' ? "Email has been sent successfully" : data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            }
            else {
                this.handleSaveEventSuccess({
                    message: data === 'Success' ? "Email has been sent successfully" : data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            }
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    componentDidMount() {
        //document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        //document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px", height: "600px" }} backdrop="static">
                <ModalHeader>Provider Email</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.props.alert.color} isOpen={this.props.alert.isOpen} >
                                    {this.props.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText className="no-border">
                                        Email To:
                                    </InputGroupText>
                                    <Input name="EmailTo" className="form-control form-control-text" placeholder="Email To" value={this.state.model.EmailTo} onChange={this.handleChange} />
                                </InputGroup>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText className="no-border" style={{ width: "54px" }}>
                                        CC:
                                    </InputGroupText>
                                    <Input name="EmailCC" className="form-control form-control-text" placeholder="CC Email"  value={this.state.model.EmailCC} onChange={this.handleChange} />
                                </InputGroup>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText className="no-border" style={{ width: "54px" }}>
                                        Subject:
                                    </InputGroupText>
                                    <Input name="Subject" className="form-control form-control-text" placeholder="Subject"  value={this.state.model.Subject} onChange={this.handleChange} />
                                </InputGroup>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <textarea name="Body" rows="15" className="no-border form-control-text" style={{ width: "100%" }} onChange={this.handleChange}>{this.state.model.Body}</textarea>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" size="sm" onClick={this.sendEmail}>Send Email</Button>
                </ModalFooter>
            </Modal>
        )
    }
}