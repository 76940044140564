import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, InputGroup, InputGroupText, Row, Col, Alert, TabContent, TabPane } from 'reactstrap';
import { ClaimantFormTableItems} from './ClaimantFormTableItems';

import { ClaimantItemForm } from './ClaimantItemForm';

import { RepFormSearch } from './RepFormSearch';
import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import SearchIcon from '../images/search-icon.png'

import { saveAs } from 'file-saver';

const moment = require('moment');

export class ClaimantForm extends Component {
    static displayName = ClaimantForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));

        this.state = {
            modalRepSearchForm: false,
            model: props.model,
            activeTab: '1',

            alert: props.alert,

            modalItem: false,
            claimantItems: [],
            modelClaimantItem: {
                EventType: 'insert',
                ClaimantItemId: null,
                ClmId: props.model.ClmId,
                ItemName: '',
                Description: '',
                FileLocation: '',
                FileName: '',
                ClaimantItemType: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                FormFile: null
            },
        };

        this.getItems = this.getItems.bind(this);

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.handleSelectRep = this.handleSelectRep.bind(this);
        //
        this.setModalRepFormSearch = this.setModalRepFormSearch.bind(this);
        //
        this.setModalItem = this.setModalItem.bind(this);
        this.handleNewItemClick = this.handleNewItemClick.bind(this);
        this.handleItemViewClick = this.handleItemViewClick.bind(this);
        this.handleEditItemClick = this.handleEditItemClick.bind(this);
        this.handleSaveEventItemSuccess = this.handleSaveEventItemSuccess.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);

        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        //
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
        this.getItems();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    
    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        if (model.RepId === null) {
            this.handleSaveEventSuccess({
                message: 'Please choose Rep.',
                modal: true,
                color: "warning"
            });
        } else {
            FetchData("/saveclaimant", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                }
            });
        }
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else
            model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    keydownHandler(e) {       
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        } else if (e.keyCode === 76 && e.altKey) { // ALT + L
            let model = { ...this.state.model }
            model.ClmLanguage = 'Spanish';
            this.setState({ model }, () => {
                
            });
        }
    }

    handleSelectRep(rep) {
        let model = { ...this.state.model }
        model.RepId = rep.data.repID;
        model.ClientId = rep.data.clientID;
        model.ClientName = rep.data.clientName;
        model.ClientAbbrv = rep.data.clientAbbrv;

        this.setState({ model }, () => {
            this.setModalRepFormSearch();
        });
    }

    setModalRepFormSearch() {
        this.setState({ modalRepSearchForm: !this.state.modalRepSearchForm });
    }

    setModalItem() {
        this.setState({ modalItem: !this.state.modalItem });
    }

    getItems() {
        if (this.state.model.ClmId != null) {
            FetchData("/getclaimantitems", "POST", this.user.token, { ClmId: this.state.model.ClmId }).then(data => {
                this.setState({ claimantItems: data });
            });
        }
    }

    handleSaveEventItemSuccess(alert) {
        this.setState({
            modalItem: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
                this.getItems();
            }, 3000)
        });
    }

    handleEditItemClick(item) {
        this.setState(prevState => ({
            modelClaimantItem: {
                EventType: 'update',
                ClaimantItemId: item.claimantItemId,
                ClmId: item.clmId,
                ItemName: item.itemName,
                Description: item.description,
                //Contact: item.contact,
                //ContactEmail: item.contactEmail,
                FileLocation: item.fileLocation,
                FileName: item.fileName,
                ClaimantItemType: item.claimantItemType,
                CreateDateTime: item.createDateTime,
                CreateByUserId: item.createByUserId,
                UpdateByUserId: this.user.userid,
                UpdateDateTime: item.updateDateTime,
                IsDeletedFlg: item.isDeletedFlg,
                FormFile: null
            }
        }));
        this.setModalItem();
    }

    handleNewItemClick() {
        this.setState(prevState => ({
            modelClaimantItem: {
                EventType: 'insert',
                ClaimantItemId: null,
                ClmId: this.state.model.ClmId,
                ItemName: '',
                Description: '',
                //Contact: '',
                //ContactEmail: '',
                FileLocation: '',
                FileName: '',
                ClaimantItemType: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                FormFile: null
            }
        }));
        this.setModalItem();
    }

    async handleItemViewClick(item) {
        let body = {
            FileLocationS3: item.fileLocationS3,
            FileName: item.fileName
        };

        await FetchFile("/getclaimantitemfile", "POST", this.user.token, body).then(blob => {
            saveAs(blob, item.fileName);
        });
    }

    handleDeleteItem(item) {
        if (window.confirm("You're about to delete this record. Are you sure?")) {
            let model = {
                ItemId: item.claimantItemId,
                UserId: this.user.userid
            };

            FetchData("/deleteclaimantitem", "POST", this.user.token, model).then(data => {
                this.handleSaveEventSuccess({
                    message: 'Record has been marked as deleted',
                    modal: true,
                    color: "success"
                });
                this.getItems();
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    renderClaimantItemTable(claimantItems){
        return (
            <ClaimantFormTableItems
                claimantItems={claimantItems}
                handleItemViewClick={this.handleItemViewClick}
                handleDeleteItem={this.handleDeleteItem}
                handleEditItemClick={this.handleEditItemClick}
            ></ClaimantFormTableItems>
        )
    }
    //
    render() {
        let claimantItemContent = this.state.loading
        ? <p><em>Loading...</em></p>
        : this.renderClaimantItemTable(this.state.claimantItems);

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>New/Edit Claimant</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        <center>{this.state.alert.message}</center>
                                    </Alert>
                                </Col>
                            </Row>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={this.state.activeTab === '1' ? "active" : ""}
                                        onClick={() => { this.setState({ activeTab: '1' }) }}
                                    >
                                        Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === '2' ? "active" : ""} onClick={() => { this.setState({ activeTab: '2' }) }}>
                                        Transportation
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <hr />
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.setModalRepFormSearch}><img src={SearchIcon} style={{ width: "14px", height: "14px" }} alt="Search Rep" /></Button> {' '}
                                                    Choose Rep
                                                </InputGroupText>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-8">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Client:
                                                </InputGroupText>
                                                <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.ClientName}</label>
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Status:
                                                </InputGroupText>
                                                <select className="form-control form-control-text" name="ClmStatus" value={this.state.model.ClmStatus} onChange={this.handleChange}>
                                                    <option value="Open">Open</option>
                                                    <option value="Closed">Closed</option>
                                                </select>
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Type:
                                                </InputGroupText>
                                                <select name="ClmInvoiceType" value={this.state.model.ClmInvoiceType} className="form-control form-control-text" onChange={this.handleChange}>
                                                    <option value="CLM">CLM</option>
                                                    <option value="REF">REF</option>
                                                    <option value="POL">POL</option>
                                                    <option value="FIL">FIL</option>
                                                    <option value="PO">PO</option>
                                                    <option value="CAS">CAS</option>
                                                    <option value="SSN">SSN</option>
                                                    <option value="ID">ID</option>
                                                    <option value="EPS">EPS</option>
                                                </select>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "63px" }}>
                                                    CLM Last:
                                                </InputGroupText>
                                                <input type="text" name="ClmLastName" className="form-control form-control-text" value={this.state.model.ClmLastName} maxLength={25} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    CLM First:
                                                </InputGroupText>
                                                <input type="text" name="ClmFirstName" className="form-control form-control-text" value={this.state.model.ClmFirstName} maxLength={20} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "63px" }}>
                                                    CLM #:
                                                </InputGroupText>
                                                <input type="text" name="ClmClaimNumber" className="form-control form-control-text" value={this.state.model.ClmClaimNumber} maxLength={20} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Language:
                                                </InputGroupText>
                                                <input type="text" name="ClmLanguage" className="form-control form-control-text" value={this.state.model.ClmLanguage} maxLength={25} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Phone:
                                                </InputGroupText>
                                                <input type="text" name="ClmPhone" className="form-control form-control-text" value={this.state.model.ClmPhone} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Date of Injury:
                                                </InputGroupText>
                                                <input type="text" name="ClmDateOfInjury" className="form-control form-control-text" value={this.state.model.ClmDateOfInjury} maxLength={20} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Employer:
                                                </InputGroupText>
                                                <input type="text" name="ClmEmployer" className="form-control form-control-text" value={this.state.model.ClmEmployer} maxLength={40} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    DOB:
                                                </InputGroupText>
                                                <input type="date" name="ClmDOB" className="form-control form-control-text" value={this.state.model.ClmDOB} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Date of Loss:
                                                </InputGroupText>
                                                <input type="text" name="ClmDateOfLoss" className="form-control form-control-text" value={this.state.model.ClmDateOfLoss} maxLength={20} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-8">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Insured:
                                                </InputGroupText>
                                                <input type="text" name="ClmInsuredParty" className="form-control form-control-text" value={this.state.model.ClmInsuredParty} maxLength={40} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Email:
                                                </InputGroupText>
                                                <input type="text" name="ClmEmail" className="form-control form-control-text" value={this.state.model.ClmEmail} maxLength={200} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-6">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    WCAB #:
                                                </InputGroupText>
                                                <input type="text" name="ClmWCABNumber" className="form-control form-control-text" value={this.state.model.ClmWCABNumber} maxLength={13} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Caption:
                                                </InputGroupText>
                                                <input type="text" name="ClmInvoiceCaption" className="form-control form-control-text" value={this.state.model.ClmInvoiceCaption} maxLength={40} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Notes:
                                                </InputGroupText>
                                                <textarea name="ClmNote" className="form-control form-control-text" rows="4" maxLength={200} onChange={this.handleChange} value={this.state.model.ClmNote} ></textarea>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Claimant Address:
                                                </InputGroupText>
                                                <input type="text" name="ClmStreet" className="form-control form-control-text" placeholder="Enter claimant address for transportation jobs only" value={this.state.model.ClmStreet} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    City:
                                                </InputGroupText>
                                                <input type="text" name="ClmCity" className="form-control form-control-text" value={this.state.model.ClmCity} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    State:
                                                </InputGroupText>
                                                <input type="text" name="ClmState" className="form-control form-control-text" value={this.state.model.ClmState} maxLength={2} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Zip:
                                                </InputGroupText>
                                                <input type="text" name="ClmZip" className="form-control form-control-text" value={this.state.model.ClmZip} maxLength={12} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.handleNewItemClick}>New Item</Button>
                                    <Row className="row-padding-left-right-16">
                                        <Col className="col-12 x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: 'scroll', height: "140px", backgroundColor: 'unset' }}>
                                            {claimantItemContent}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <hr />
                                    <Row style={{ paddingBottom: '2px' }}>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <input type="radio" name="ClmGender" value={0} checked={this.state.model.ClmGender === "0"} onChange={this.handleChange} />
                                                <span className="input-group-text">Male</span>
                                                <span style={{ paddingRight: '3px' }}></span>
                                                <input type="radio" name="ClmGender" value={1} checked={this.state.model.ClmGender === "1"} onChange={this.handleChange} />
                                                <span className="input-group-text">Female</span>
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    SSN:
                                                </InputGroupText>
                                                <input type="text" name="ClmSSN" className="form-control form-control-text" value={this.state.model.ClmSSN} maxLength={25} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Ordering Pyhsician:
                                                </InputGroupText>
                                                <input type="text" name="ClmOrderedByName" className="form-control form-control-text" value={this.state.model.ClmOrderedByName} maxLength={500} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '2px' }}>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Diagnostic Code:
                                                </InputGroupText>
                                                <textarea name="ClmDiagnosis" className="form-control form-control-text" rows="4" maxLength={255} onChange={this.handleChange} value={this.state.model.ClmDiagnosis}></textarea>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '2px' }}>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: '95px' }}>
                                                    Applicant Firm:
                                                </InputGroupText>
                                                <input type="text" name="ClmAppAtty" className="form-control form-control-text" value={this.state.model.ClmAppAtty} maxLength={100} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-8">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Contact:
                                                </InputGroupText>
                                                <input type="text" name="ClmAppAttyFirm" className="form-control form-control-text" value={this.state.model.ClmAppAttyFirm} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '2px' }}>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Applicant Atty Phone:
                                                </InputGroupText>
                                                <input type="text" name="ClmAppAttyPhone" className="form-control form-control-text" value={this.state.model.ClmAppAttyPhone} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-8">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: '51px' }}>
                                                    Email:
                                                </InputGroupText>
                                                <input type="text" name="ClmAppAttyEmail" className="form-control form-control-text" value={this.state.model.ClmAppAttyEmail} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '2px' }}>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: '95px' }}>
                                                    Defense Firm:
                                                </InputGroupText>
                                                <input type="text" name="ClmDefAtty" className="form-control form-control-text" value={this.state.model.ClmDefAtty} maxLength={100} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-8">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Contact:
                                                </InputGroupText>
                                                <input type="text" name="ClmDefAttyFirm" className="form-control form-control-text" value={this.state.model.ClmDefAttyFirm} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '2px' }}>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Defense Atty Phone:
                                                </InputGroupText>
                                                <input type="text" name="ClmDefAttyPhone" className="form-control form-control-text" value={this.state.model.ClmDefAttyPhone} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-8">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: '51px' }}>
                                                    Email:
                                                </InputGroupText>
                                                <input type="text" name="ClmDefAttyEmail" className="form-control form-control-text" value={this.state.model.ClmDefAttyEmail} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>

                {
                    this.state.modalRepSearchForm &&
                    <RepFormSearch modal={this.state.modalRepSearchForm} setModal={this.setModalRepFormSearch} RepName='' handleDoubleClick={this.handleSelectRep} />
                }
                {this.state.modalItem &&
                    <ClaimantItemForm model={this.state.modelClaimantItem} modal={this.state.modalItem} alert={this.state.alert} setModal={this.setModalItem} handleSaveEventSuccess={this.handleSaveEventItemSuccess} />
                }
            </div>
        )
    }
}