import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { ClientFormSearch } from './ClientFormSearch';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DeleteIcon from '../images/delete.png'
import SearchIcon from '../images/search-icon.png'

export class RolodexForm extends Component {
    static displayName = RolodexForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            modalClientSearchForm: false,
            model: props.model,
            alert: props.alert
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
    }

    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        FetchData("/saverolodex", "POST", this.user.token, model).then(data => {
            console.log(data);
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            } else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "warning"
                });
            }
        });
    }

    onDelete() {
        let model = this.state.model;
        model.EventType = "delete";
        model.UpdateByUserId = this.user.userid;       

        FetchData("/saverolodex", "POST", this.user.token, model).then(data => {
            console.log(data);
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            } else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "warning"
                });
            }
        });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseFloat(e.target.value);
            else
                model[e.target.name] = null;
        }
        else
            model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>Rolodex Form</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        <center>{this.state.alert.message}</center>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-5">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Last:
                                        </InputGroupText>
                                        <input type="text" name="LastName" className="form-control form-control-text" value={this.state.model.LastName} maxLength={25} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-5">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            First:
                                        </InputGroupText>
                                        <input type="text" name="FirstName" className="form-control form-control-text" value={this.state.model.FirstName} maxLength={25} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Title:
                                        </InputGroupText>
                                        <input type="text" name="Title" className="form-control form-control-text" value={this.state.model.Title} maxLength={20} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Speciality:
                                        </InputGroupText>
                                        <input type="text" name="Speciality" className="form-control form-control-text" value={this.state.model.Speciality} maxLength={30} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-5">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Phone:
                                        </InputGroupText>
                                        <input type="text" name="Phone" className="form-control form-control-text" value={this.state.model.Phone} maxLength={40} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-5">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Fax:
                                        </InputGroupText>
                                        <input type="text" name="Fax" className="form-control form-control-text" value={this.state.model.Fax} maxLength={40} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Selected:
                                        </InputGroupText>
                                        <input name="Selected" type="checkbox" defaultChecked={this.state.model.Selected} onChange={this.handleChange} ></input>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Street:
                                        </InputGroupText>
                                        <input type="text" name="Street" className="form-control form-control-text" value={this.state.model.Street} maxLength={40} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            City:
                                        </InputGroupText>
                                        <input type="text" name="City" className="form-control form-control-text" value={this.state.model.City} maxLength={40} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            State:
                                        </InputGroupText>
                                        <input type="text" name="State" className="form-control form-control-text" value={this.state.model.State} maxLength={2} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Zip:
                                        </InputGroupText>
                                        <input type="text" name="Zip" className="form-control form-control-text" value={this.state.model.Zip} maxLength={10} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Note:
                                        </InputGroupText>
                                        <textarea name="Note" className="form-control form-control-text" maxLength={255} cols={5} onChange={this.handleChange} >{this.state.model.Note}</textarea>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-10">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Secretary:
                                        </InputGroupText>
                                        <input type="text" name="Secretary" className="form-control form-control-text" value={this.state.model.Secretary} maxLength={255} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            LBL:
                                        </InputGroupText>
                                        <input type="text" name="Label" className="form-control form-control-text" value={this.state.model.Label} maxLength={1} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => { if (window.confirm("Are you sure to delete this record?")) { this.onDelete() } }} size="sm">Delete</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}