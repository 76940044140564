import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, InputGroup, InputGroupText, Alert } from 'reactstrap';

import { UploadFile } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

export class WorkOrderTransportationFormEmail extends Component {
    static displayName = WorkOrderTransportationFormEmail.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,
            activeTab: '1'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    handleUploadFile(e) {
        let model = { ...this.state.model }
        model.FormFile = e.target.files[0];

        this.setState({ model }, () => {
            console.log(model);
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    sendEmail() {
        let model = this.state.model;

        var formData = new FormData();
        formData.append("EmailFrom", model.EmailFrom);
        formData.append("EmailTo", model.EmailTo);
        formData.append("EmailCC", model.EmailCC);
        formData.append("Subject", model.Subject);
        formData.append("Body", model.Body);
        formData.append("JobIds", model.JobIds);
        formData.append("FormFile", model.FormFile);

        console.log(model);
        
        UploadFile("/sendtransportationemail", this.user.token, formData).then(data => {
            this.props.handleSaveEventSuccess({
                message: data,
                modal: data === 'Success' ? false : true,
                color: data === 'Success' ? "success" : "warning"
            });
        });
    }

    componentDidMount() {
        //document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        //document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px", height: "600px" }} backdrop="static">
                <ModalHeader>Transportation Email</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.props.alert.color} isOpen={this.props.alert.isOpen} >
                                    {this.props.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText className="no-border">
                                        Email To:
                                    </InputGroupText>
                                    <Input name="EmailTo" className="form-control form-control-text" placeholder="Email To" value={this.state.model.EmailTo} onChange={this.handleChange} />
                                </InputGroup>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText className="no-border" style={{ width: "54px" }}>
                                        CC:
                                    </InputGroupText>
                                    <Input name="EmailCC" className="form-control form-control-text" placeholder="CC Email"  value={this.state.model.EmailCC} onChange={this.handleChange} />
                                </InputGroup>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText className="no-border" style={{ width: "54px" }}>
                                        Subject:
                                    </InputGroupText>
                                    <Input name="Subject" className="form-control form-control-text" placeholder="Subject"  value={this.state.model.Subject} onChange={this.handleChange} />
                                </InputGroup>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <textarea name="Body" rows="15" className="no-border form-control-text" style={{ width: "100%" }} onChange={this.handleChange}>{this.state.model.Body}</textarea>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText className="no-border">
                                        Attach File:
                                    </InputGroupText>
                                    <input type="file" name="fileUpload" className="form-control form-control-text" onChange={this.handleUploadFile} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" size="sm" onClick={this.sendEmail}>Send</Button>
                    <Button size="sm" onClick={this.props.setModal}>Cancel</Button>                    
                </ModalFooter>
            </Modal>
        )
    }
}