/* 
    Handles the form created by clicking 'New Job Note' button on the WorkOrderForm
*/

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

export class WorkOrderJobDescription extends Component {
    static displayName = WorkOrderJobDescription.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            alert: this.props.alert,

            jobInvLineCodeList: JSON.parse(localStorage.getItem('JobInvLineCodes')),
            model: this.props.model
        };
        //
        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
        return () => {
            document.removeEventListener('keydown', this.keydownHandler);
        };

    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) // Alt+S
            this.onSave();
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "number")
            model[e.target.name] = parseFloat(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }


    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        FetchData("/savejobinvline", "POST", this.user.token, model).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            } else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            }

        });
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "150px", width: "500px" }}>
                <ModalHeader>New/Edit Job Description</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "100px" }}>
                                        Job Description:
                                    </InputGroupText>
                                    <select name="JobInvLineCodeId" value={this.state.model.JobInvLineCodeId} className="form-control" onChange={this.handleChange}>
                                        <option value={null}></option>
                                        {this.state.jobInvLineCodeList.map((jobType) =>
                                            <option value={jobType.jobInvLineCodeId}>{jobType.lineDescription}</option>
                                        )}
                                    </select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "100px" }}>
                                        Line Rate:
                                    </InputGroupText>
                                    <input type="text" name="LineRate" value={this.state.model.LineRate} className="form-control" maxLength={200} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "100px" }}>
                                        Line Personnel:
                                    </InputGroupText>
                                    <input type="text" name="LinePersonnel" value={this.state.model.LinePersonnel} className="form-control" maxLength={200} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "100px" }}>
                                        Amount:
                                    </InputGroupText>
                                    <input type="number" name="Amount" value={this.state.model.Amount} className="form-control" onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                    <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                </ModalFooter>
            </Modal>
        )
    }

}