import React, { Component, Fragment } from 'react';
import { Button, Row, Col, Alert, Nav, NavItem, NavLink, InputGroup } from 'reactstrap'; //dennis

import { ClaimantForm } from './ClaimantForm';
import { LoadingModal } from './LoadingModal';
import { WorkOrderForm } from './WorkOrderForm';

import { FetchData, FetchFile } from './utils/DataFetch';
import { saveAs } from 'file-saver';

import AddIcon from '../images/add.png'

import { GetCookie, Timeout } from './utils/Common';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';


const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },

};

const claimantColumns = [
    { headerName: '', field: 'add', cellRendererFramework: 'addWorkOrderCellRenderer', width: 30, resizable: true, },
    { headerName: 'Claimant name', field: 'claimantName', cellRendererFramework: 'claimantNameCellRenderer', width: 150, resizable: true, },
    { headerName: 'Claim Number', field: 'clmClaimNumber', sortable: true, width: 150, resizable: true },
    { headerName: 'Phone', field: 'clmPhone', width: 100, resizable: true },
    { headerName: 'Employer', field: 'clmEmployer', width: 150, resizable: true },
    { headerName: 'Date of Injury', field: 'clmDateOfInjury', width: 80,  resizable: true },
    { headerName: 'DOB', field: 'clmDOB', width: 80, valueFormatter: (params) => params.value ? moment(params.value).format('MM/DD/YYYY') : '', resizable: true },
    { headerName: 'Rep Name', field: 'repName', valueGetter: (params) => `${params.data.repLastName}, ${params.data.repFirstName}`, width: 150, resizable: true },
    { headerName: 'Client Name', field: 'clientName', width: 200, resizable: true },];

const workOrderColumns = [
    { headerName: 'Invoice #', field: 'jobNum', cellRendererFramework: 'invoiceCellRenderer', width: 80 , resizable: true, },
    { headerName: 'Date', field: 'jobDate', cellRendererFramework: 'jobDateCellRenderer', width: 80, resizable: true, },
    { headerName: 'Interpreter', field: 'interpreterName', cellRendererFramework: 'intNameRenderer', width: 150, resizable: true, },
    { headerName: 'Provider', field: 'providerName',  width: 150, resizable: true, },
    { headerName: 'Job Type', field: 'jobTypeName', width: 80, resizable: true, },
    { headerName: 'Rep', field: 'repName', cellRendererFramework: 'repNameCellRenderer', width: 150, resizable: true, },
    { headerName: 'Phone', field: 'repPhone', width: 100, resizable: true, },
    { headerName: 'Email', field: 'repEmail', width: 150, resizable: true, },
]

export class Claimant extends Component {
    static displayName = Claimant.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: claimantColumns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            selectedClaimant: null,
            claimantList: [],
            clickCount: 0,
            jobList: [],
            loading: false,
            fetchingData: false,
            modal: false,
            modalWorkOrder: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                ClmId: null,
                RepId: null,
                ClientId: null,
                ClmLastName: '',
                ClmFirstName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmInvoiceType: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmNote: '',
                ClmWCABNumber: '',
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmDateOfInjury: '',
                ClmDateOfLoss: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmEmail: '',
                ClmSSN: '',
                ClmDOB: null,
                ClmDiagnosis: '',
                ClmGender: '',
                ClmAppAtty: '',
                ClmAppAttyFirm: '',
                ClmAppAttyEmail: '',
                ClmAppAttyPhone: '',
                ClmDefAtty: '',
                ClmDefAttyFirm: '',
                ClmDefAttyEmail: '',
                ClmDefAttyPhone: '',
                ClmOrderedByName: '',
                ClmHeight: '',
                ClmWeight: '',
                ClmStatus: '',
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: '',
                ClientAbbrv: '',
                RepFirstName: '',
                RepLastName: ''
            },
            searchModel: {
                ClmId: '',
                Name: '',
                ClmClaimNumberNoDash: '',
                ClientAbbrv: '',
                Phone: '',
                Employer: '',
                Insured: '',
                SortBy: 'clmLastName',
                SortDirection: 'asc'
            },
            job: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                JobBalance: null
            }
        };
        this.user = JSON.parse(GetCookie('user'));

        this.setModal = this.setModal.bind(this);
        this.setModalWorkOrder = this.setModalWorkOrder.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleSaveEventWorkOrderSuccess = this.handleSaveEventWorkOrderSuccess.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderClaimantTable = this.renderClaimantTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
        this.addWorkOrder = this.addWorkOrder.bind(this);
        this.viewWorkOrder = this.viewWorkOrder.bind(this);
        //
        this.viewInvoice = this.viewInvoice.bind(this);
        //
    }

    componentDidMount() {
        //this.populateClaimantData();
    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    setModalWorkOrder() {
        this.setState({ modalWorkOrder: !this.state.modalWorkOrder });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                ClmId: null,
                RepId: null,
                ClientId: null,
                ClmLastName: '',
                ClmFirstName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmInvoiceType: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmNote: '',
                ClmWCABNumber: '',
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmDateOfInjury: '',
                ClmDateOfLoss: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmEmail: '',
                ClmSSN: '',
                ClmDOB: null,
                ClmDiagnosis: '',
                ClmGender: '',
                ClmAppAtty: '',
                ClmAppAttyFirm: '',
                ClmAppAttyEmail: '',
                ClmAppAttyPhone: '',
                ClmDefAtty: '',
                ClmDefAttyFirm: '',
                ClmDefAttyEmail: '',
                ClmDefAttyPhone: '',
                ClmOrderedByName: '',
                ClmHeight: '',
                ClmWeight: '',
                ClmStatus: 'Open',
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: '',
                ClientAbbrv: '',
                RepFirstName: '',
                RepLastName: ''
            }
        }));

        this.setModal();
    }

    handleDoubleClick(claimantData) {

        let searchModel = {
            ClmId: claimantData.data.clmId,
            Name: '',
            ClmClaimNumberNoDash: '',
            ClientAbbrv: '',
            Phone: '',
            Employer: '',
            Insured: '',
            SortBy: '',
            SortDirection: ''
        }

        FetchData("/getclaimants", "POST", this.user.token, searchModel).then(data => {
            this.setState(prevState => ({
                alert: {
                    color: "info",
                    message: "",
                    isOpen: false
                },
                model: {
                    EventType: 'update',
                    ClmId: claimantData.data.clmId,
                    RepId: claimantData.data.repId,
                    ClientId: claimantData.data.clientId,
                    ClmLastName: claimantData.data.clmLastName,
                    ClmFirstName: claimantData.data.clmFirstName,
                    ClmClaimNumber: claimantData.data.clmClaimNumber,
                    ClmClaimNumberNoDash: claimantData.data.clmClaimNumberNoDash,
                    ClmPhone: claimantData.data.clmPhone,
                    ClmInvoiceType: claimantData.data.clmInvoiceType,
                    ClmInvoiceCaption: claimantData.data.clmInvoiceCaption,
                    ClmLanguage: claimantData.data.clmLanguage,
                    ClmNote: claimantData.data.clmNote,
                    ClmWCABNumber: claimantData.data.clmWCABNumber,
                    ClmInsuredParty: claimantData.data.clmInsuredParty,
                    ClmEmployer: claimantData.data.clmEmployer,
                    ClmDateOfInjury: claimantData.data.clmDateOfInjury,
                    ClmDateOfLoss: claimantData.data.clmDateOfLoss,
                    ClmStreet: claimantData.data.clmStreet,
                    ClmCity: claimantData.data.clmCity,
                    ClmState: claimantData.data.clmState,
                    ClmZip: claimantData.data.clmZip,
                    ClmEmail: claimantData.data.clmEmail,
                    ClmSSN: claimantData.data.clmSSN,
                    ClmDOB: claimantData.data.clmDOB,
                    ClmDiagnosis: claimantData.data.clmDiagnosis,
                    ClmGender: claimantData.data.clmGender,
                    ClmAppAtty: claimantData.data.clmAppAtty,
                    ClmAppAttyFirm: claimantData.data.clmAppAttyFirm,
                    ClmAppAttyEmail: claimantData.data.clmAppAttyEmail,
                    ClmAppAttyPhone: claimantData.data.clmAppAttyPhone,
                    ClmDefAtty: claimantData.data.clmDefAtty,
                    ClmDefAttyFirm: claimantData.data.clmDefAttyFirm,
                    ClmDefAttyEmail: claimantData.data.clmDefAttyEmail,
                    ClmDefAttyPhone: claimantData.data.clmDefAttyPhone,
                    ClmOrderedByName: claimantData.data.clmOrderedByName,
                    ClmHeight: claimantData.data.clmHeight,
                    ClmWeight: claimantData.data.clmWeight,
                    ClmStatus: claimantData.data.clmStatus,
                    CreateDateTime: claimantData.data.createDateTime,
                    CreatebyUserId: claimantData.data.createbyUserId,
                    UpdateByUserId: claimantData.data.updateByUserId,
                    UpdateDateTime: claimantData.data.updateDateTime,
                    IsDeletedFlg: claimantData.data.isDeletedFlg,
                    ClientName: claimantData.data.clientName,
                    ClientAbbrv: claimantData.data.clientAbbrv,
                    RepFirstName: claimantData.data.repFirstName,
                    RepLastName: claimantData.data.repLastName,
                }
            }))
            this.setModal();
        });

    }

    handleClick(claimantData) {
        this.setState({ clickCount: this.state.clickCount + 1 });
        this.clickTimer = setTimeout(() => {
            if (this.state.clickCount === 1) {
                this.setState({ selectedClaimant: claimantData.data, fetchingData: true });
                let model = {
                    ClmId: claimantData.data.clmId
                };
                FetchData("/getworkorders", "POST", this.user.token, model).then(data => {
                    this.setState({ jobList: data, fetchingData: false });
                });
            }
            this.setState({ clickCount: 0 });  // reset count after action
        }, 300);  // 300 ms delay
    };

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            },
            selectedClaimant: null
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateClaimantData();
        });
    }

    handleSaveEventWorkOrderSuccess(alert) {
        this.setState({
            modalWorkOrder: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({ jobList: [] })
            this.populateClaimantData();
        }
    }

    addWorkOrder(claimant) {
        this.setState(prevState => ({
            job: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: claimant.clmId,
                ClmFirstName: claimant.clmFirstName,
                ClmLastName: claimant.clmLastName,
                ClmClaimNumber: claimant.clmClaimNumber,
                ClmClaimNumberNoDash: claimant.clmClaimNumberNoDash,
                ClmPhone: claimant.clmPhone,
                ClmDateOfLoss: claimant.clmDateOfLoss,
                ClmDateOfInjury: claimant.clmDateOfInjury,
                ClmNote: claimant.clmNote,
                ClmDOB: claimant.clmDOB,
                ClmInsuredParty: claimant.clmInsuredParty,
                ClmEmployer: claimant.clmEmployer,
                ClmInvoiceCaption: claimant.clmInvoiceCaption,
                ClmLanguage: claimant.clmLanguage,
                ClmWCABNumber: claimant.clmWCABNumber,
                ClmInvoiceType: claimant.clmInvoiceType,
                ClmStreet: claimant.clmStreet,
                ClmCity: claimant.clmCity,
                ClmState: claimant.clmState,
                ClmZip: claimant.clmZip,
                ClmSSN: claimant.clmSSN,
                ClmOrderedByName: claimant.clmOrderedByName,
                ClmDiagnosis: claimant.clmDiagnosis,
                ClmEmail: claimant.clmEmail,
                RepID: claimant.repId,
                RepFirstName: claimant.repFirstName,
                RepLastName: claimant.repLastName,
                RepPhone: claimant.repPhone,
                RepEmail: claimant.repEmail,
                ClientId: claimant.clientId,
                ClientAbbrv: claimant.clientAbbrv,
                ClientName: claimant.clientName,
                ClientStreet: claimant.clientStreet,
                ClientStreet2: claimant.clientStreet2,
                ClientCity: claimant.clientCity,
                ClientState: claimant.clientState,
                ClientZip: claimant.clientZip,
                ClientMailStreet: claimant.clientMailStreet,
                ClientMailStreet2: claimant.clientMailStreet2,
                ClientMailCity: claimant.clientMailCity,
                ClientMailState: claimant.clientMailState,
                ClientMailZip: claimant.clientMailZip,
                ClientPhone: claimant.clientPhone,
                ClientPhoneFax: claimant.clientPhoneFax,
                ClientPhone2: claimant.clientPhone2,
                ClientEmail: claimant.clientEmail,
                ClientInactiveFlg: claimant.clientInactiveFlg,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                JobBalance: null
            },
        }));

        this.setModalWorkOrder();
    }

    viewWorkOrder(workOrder, showModal) {
        FetchData("/getworkorder?jobId=" + workOrder.data.jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                job: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.intCellTxt,
                    IntPhoneOnly: data.intPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseflg: data.inHouseflg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    JobBalance: data.jobBalance,
                    WorkFlowId: data.workFlowId
                },
                modalWorkOrder: showModal
            }));
        });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.autoSizeUnspecifiedColumns(false);
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = params => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths });
        }
    };

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    viewInvoice(e, woData, i) {
        e.preventDefault();

        let body = {
            JobId: ""
        };

        body.JobId = woData.jobId;
        let currentDate = new Date();
        let invoiceFileName = "Invoice " + woData.jobNum + " " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate() + 1) + "-" + currentDate.getFullYear() + ".pdf";

        FetchFile("/printinvoices", "POST", this.user.token, body).then(blob => {
            saveAs(blob, invoiceFileName);
        })
    }

    async populateClaimantData() {
        this.setState({ fetchingData: true });

        await FetchData("/getclaimants", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ claimantList: data, loading: false, fetchingData: false });
        });
    }

    renderClaimantTable(claimants) {

        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: claimantColumns,
            rowData: claimants,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                addWorkOrder: this.addWorkOrder,
                state: this.state,

            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderer
                addWorkOrderCellRenderer: AddWorkOrderCellRenderer,
                claimantNameCellRenderer: ClaimantNameCellRenderer

            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleDoubleClick,
            onRowClicked: this.handleClick,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    renderWorkOrderTable(workOrders) {

        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: workOrderColumns,
            rowData: workOrders,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                viewInvoice: this.viewInvoice,
                state: this.state,

            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderer
                invoiceCellRenderer: InvoiceCellRenderer,
                jobDateCellRenderer: JobDateCellRenderer,
                intNameCellRenderer: IntNameCellRenderer,
                repNameCellRenderer: RepNameCellRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onDoubleClicked: this.viewWorkOrder,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }


    render() {
        let claimantContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderClaimantTable(this.state.claimantList);

        let workOrderContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderWorkOrderTable(this.state.jobList);


        return (
            <div style={{ paddingTop: "12px", overflow: 'hidden' }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Claimant</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm" style={{ minWidth: '600px', overflow: 'hidden' }}>
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                            <input name="Name" type="text" className="form-control form-control-text" value={this.state.searchModel.Name} onChange={this.handleSearchChange} placeholder="Claimant (Lastname Firstname)" onKeyPress={this.handleKeyPress} />
                            <input name="ClmClaimNumberNoDash" type="text" className="form-control form-control-text" value={this.state.searchModel.ClmClaimNumberNoDash} onChange={this.handleSearchChange} placeholder="Claimant #" onKeyPress={this.handleKeyPress} />
                            <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                            <input name="Phone" type="text" className="form-control form-control-text" value={this.state.searchModel.Phone} onChange={this.handleSearchChange} placeholder="Phone" onKeyPress={this.handleKeyPress} />
                            <input name="Employer" type="text" className="form-control form-control-text" value={this.state.searchModel.Employer} onChange={this.handleSearchChange} placeholder="Employer" onKeyPress={this.handleKeyPress} />
                            <input name="Insured" type="text" className="form-control form-control-text" value={this.state.searchModel.Insured} onChange={this.handleSearchChange} placeholder="Insured" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <ClaimantForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }
                {this.state.modalWorkOrder &&
                    <WorkOrderForm model={this.state.job} modal={this.state.modalWorkOrder} alert={this.state.alert} setModal={this.setModalWorkOrder} workOrderList={[]} jobHistoryList={[]} jobInvLineList={[]} setNextPrevData={null} selectedIndex={0} handleSaveEventSuccess={this.handleSaveEventWorkOrderSuccess} />
                }

                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {claimantContents}
                </div>
                <hr />

                {this.state.selectedClaimant !== null &&
                    <Fragment>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className="active" onClick={function noRefCheck() { }}>
                                    Jobs
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {workOrderContents}
                        </div>

                    </Fragment>                }
            </div>
        );

    }
}

/************************************************************************************
 *             Cell Renderer Classes needed for Ag-Grid-React                       *
************************************************************************************/
class AddWorkOrderCellRenderer extends Component {

    handleAddClick = () => {
        // Accessing the context or props to call the function
        const { data, context } = this.props;
        context.addWorkOrder(data);
    };

    render() {
        return (
            <div className="x-grid-cell-inner">
                <img src={AddIcon}
                    onClick={this.handleAddClick}
                    style={{ width: "16px", height: "16px", cursor: 'pointer' }}
                    alt="Add" />
            </div>
        );
    }
}

class ClaimantNameCellRenderer extends Component {
    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.clmLastName}, ${data.clmFirstName},` + '(' + `${data.clientAbbrv}` + ')';

        if (data.hasClaimantDupWO) {
            return (
                <span style={{ color: 'red' }}>{name}</span>
            );
        } else {
            return (
                <span>{name}</span>
            );
        }
    }
}

class IntNameCellRenderer extends Component {
    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.intLastName}, ${data.intFirstName}`;

        return (
            <span>{name}</span>
        );
    }
}

class RepNameCellRenderer extends Component {
    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.repLastName}, ${data.repFirstName}`;

        return (
            <span>{name}</span>
        );
    }
}

class InvoiceCellRenderer extends React.Component {
    handleClick = (event) => {
        // Prevent the default anchor behavior
        event.preventDefault();

        // Assuming `viewInvoice` method and job data are passed via props
        const { viewInvoice, data, rowIndex } = this.props;
        viewInvoice(event, data, rowIndex);
    };

    render() {
        const { data } = this.props;
        return (
            <div className="x-grid-cell-inner">
                <a href="#" onClick={this.handleClick}>{data.jobNum}</a>
            </div>
        );
    }
}

class JobDateCellRenderer extends React.Component {
    render() {
        const { data } = this.props;
        const formattedJobDate = moment(data.jobDateTime).format('MM/DD/YYYY');
        const formattedFinishTime = data.finishTime ? ` to ${moment(data.finishTime).format('hh:mm A')}` : '';
        const jobStatus = data.Failedflg ? ' (Failed)' : ' (Success)';

        return (
            <div className="x-grid-cell-inner">
                {formattedJobDate}{formattedFinishTime}{jobStatus}
            </div>
        );
    }
}