import React, { Component, } from 'react';
import ReactTooltip from "react-tooltip";

import RemoveIcon from '../images/remove-icon.png'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

import * as CellRenderers from './AgGridCellRendererComponents';

const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: 'Created On', field: 'createDateTime', width: 80, },
    { headerName: 'Created By', field: 'createdBy', width: 120, },
    { headerName: 'Type:', field: 'clientItemType', width: 80, },
    { headerName: 'Note', field: 'clientItemDescription', width: 200, },
    { headerName: '', field: 'actions', cellRenderer: 'downloadOrEditRenderer', sortable: false, filter: false, width: 80 },
    { headerName: '', field: 'remove', cellRenderer: 'removeIconRenderer', sortable: false, filter: false, },
];


export class ClientFormTableItems extends Component {
    static displayName = ClientFormTableItems.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            clickCount: 0,
        };
        this.previousSelection = [];
        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns(false);
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    render() {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: this.props.clientItems,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                state: this.props.state,
                handleDeleteItem: this.props.handleDeleteItem,
                handleItemViewClick: this.props.handleItemViewClick,
                handleEditItemClick: this.props.handleEditItemClick,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                    downloadOrEditRenderer: DownloadOrEditRenderer,
                    removeIconRenderer: RemoveIconRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                    rowSelection={"multiple"}
                />
            </div>
        );

    }
}

class DownloadOrEditRenderer extends React.Component {
    handleItemViewClick = (event, item) => {
        this.props.context.handleItemViewClick(event, item);
    }

    handleEditItemClick = (item) => {
        this.props.context.handleEditItemClick(item);
    }

    render() {
        const item = this.props.data; // Access the row data
        return (
            <a href="#" onClick={(event) => { 
                event.preventDefault(); // Prevent default link behavior
                item.clientItemType === 'Attachment' 
                    ? this.handleItemViewClick(event, item) 
                    : this.handleEditItemClick(item) 
            }}>
                {item.clientItemType === 'Attachment' ? 'Click To View' : 'Edit'}
            </a>
        );
    }
}

class RemoveIconRenderer extends React.Component {
    handleDeleteItem = (item) => {
        this.props.context.handleDeleteItem(item);
    }

    render() {
        const item = this.props.data; // Access the row data
        return (
            <img src={RemoveIcon} style={{ width: "16px", cursor: 'pointer' }} onClick={() => { this.handleDeleteItem(item) }} alt="Delete" />
        );
    }
}


