import React, { Component, } from 'react';
import ReactTooltip from "react-tooltip";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const receivableColumns = [
    { headerName: 'Action', field: 'actionCode', width: 100 },
    { headerName: 'Transaction Date', field: 'createDateTime', cellRenderer: 'transactionDateCellRenderer', width: 120 },
    { headerName: 'Check Date', field: 'checkDate', cellRenderer: 'dateCellRenderer', width: 120 },
    { headerName: 'Check Number', field: 'checkNumber', width: 150 },
    { headerName: 'Transfer From', field: 'transferFrom', width: 120 },
    { headerName: 'Transfer To', field: 'transferTo', width: 120 },
    { headerName: 'Amount', field: 'amount', cellStyle: { textAlign: 'left' } },
];

export class LateInvoicesTableReceivables extends Component {
    static displayName = LateInvoicesTableReceivables.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: receivableColumns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
        };
        this.previousSelection = [];
        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    handleRowDoubleClick = (event) => {
        const workorder = event.data;
        const rowIndex = event.rowIndex;
        this.props.setData(workorder, rowIndex, 'workorder');
    }

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns(false);
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    render() {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: receivableColumns,
            rowData: this.props.receivables,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                state: this.state,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {
                dateCellRenderer: DateCellRenderer,
                transactionDateCellRenderer: TransactionDateCellRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );

    }
}

class DateCellRenderer extends React.Component {
    render() {
        const { value } = this.props;
        return <span>{value ? moment(value).format('MM/DD/YYYY') : ''}</span>;
    }
}

class TransactionDateCellRenderer extends React.Component {
    render() {
        const { value } = this.props;
        return <span>{value ? moment(value).format('MM/DD/YYYY') : ''}</span>;
    }
}
