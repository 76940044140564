import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, Nav, NavItem, NavLink, TabContent, TabPane, InputGroup } from 'reactstrap';

import { ClientForm } from './ClientForm';
import { LoadingModal } from './LoadingModal';

import { RepForm } from './RepForm';

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png';
import RemoveIcon from '../images/remove-icon.png'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
};

const clientColumns = [
    { headerName: "Org", field: "clientOrg", width: 100 },
    { headerName: "Client Abbrv", field: "clientAbbrv", width: 100 },
    { headerName: "Client Name", field: "clientName", width: 260 },
    { headerName: "Phone", field: "clientPhone", width: 100 },
    { headerName: "Discount", field: "clientDiscount", width: 100 },
    { headerName: "City", field: "clientCity", width: 100 },
    { headerName: "State", field: "clientState", width: 70 }
];

const repColumns = [
    { headerName: "", field: "delete", width: 30, cellRenderer: 'deleteButtonCellRenderer', resizable: true },
    { headerName: "Rep Name", field: "repName", width: 150, valueGetter: params => `${params.data.repLastName}, ${params.data.repFirstName} (${params.data.clientAbbrv})`, resizable: true },
    { headerName: "Phone", field: "repPhone", width: 150, resizable: true },
    { headerName: "Email", field: "repEmail", width: 200, resizable: true },
    { headerName: "Client", field: "clientName", width: 200, resizable: true },
    { headerName: "Notes", field: "repNote", width: 600, resizable: true }
];

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);
        this.state = {
            clientList: [],
            clickCount: 0,
            loading: false,
            fetchingData: false,

            modal: false,
            modalRepForm: false,

            selectedClient: null,

            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                ClientId: null,
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientPhoneFax: '',
                ClientName: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientCountry: '',
                ClientDiscount: null,
                ClientNotes: '',
                ClientCreditCard: '',
                ClientEmailForInvoice: '',
                ClientEmail: '',
                ClientWebsite: '',
                ClientOrg: '',
                ClientBillRateType: '',
                ClientInactiveFlg: false,
                ClientInvoiceNote: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            },
            rep: {
                EventType: 'insert',
                RepID: null,
                ClientID: null,
                RepLastName: '',
                RepFirstName: '',
                RepPhone: '',
                RepPhone2: '',
                RepNote: '',
                RepZip: '',
                RepEmail: '',
                RepInactiveFlg: false,
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: '',
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientEmail: ''
            },
            searchModel: {
                ClientId: '',
                ClientName: '',
                ClientAbbrv: '',
                Org: '',
                Phone: '',
                SortBy: 'clientName',
                SortDirection: 'asc'
            },
            repSearchModel: {
                RepName: '',
                RepPhone: '',
                Client: '',
                ClientId: '',
                ClientAbbrv: '',
                Inactive: false,
                SortBy: 'repLastName',
                SortDirection: 'asc'
            },
            reps: []
        };
        this.user = JSON.parse(GetCookie('user'));
        //this.clickCount = 0;
        //this.singleClickTimer = '';

        this.setModal = this.setModal.bind(this);
        this.setModalRep = this.setModalRep.bind(this);
        //
        //this.handleClicks = this.handleClicks.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleSaveRepFormEventSuccess = this.handleSaveRepFormEventSuccess.bind(this);
        //
        this.handleDoubleClickRep = this.handleDoubleClickRep.bind(this);
        this.handleDoubleClickClient = this.handleDoubleClickClient.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.addRep = this.addRep.bind(this);

        this.renderWorkOrderTable = this.renderClientTable.bind(this);
        this.renderRepTable = this.renderRepTable.bind(this);
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleRepSearchChange = this.handleRepSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyPressRepSearch = this.handleKeyPressRepSearch.bind(this);
        //
        this.handleDeleteRep = this.handleDeleteRep.bind(this);
        //
    //    this.onClickResizeColumn = this.onClickResizeColumn.bind(this);
    //    this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    }

    componentDidMount() {
        //this.populateClientData();

    }

    setModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    setModalRep() {
        this.setState({ modalRepForm: !this.state.modalRepForm });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                ClientId: null,
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientPhoneFax: '',
                ClientName: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientCountry: '',
                ClientDiscount: null,
                ClientNotes: '',
                ClientCreditCard: '',
                ClientEmailForInvoice: '',
                ClientEmail: '',
                ClientWebsite: '',
                ClientOrg: '',
                ClientBillRateType: '',
                ClientInactiveFlg: false,
                ClientInvoiceNote: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            }
        }));

        this.setModal();
    }

    addRep() {
        this.setState(prevState => ({
            rep: {
                EventType: 'insert',
                RepID: null,
                ClientID: this.state.selectedClient.clientId,
                RepLastName: '',
                RepFirstName: '',
                RepPhone: '',
                RepPhone2: '',
                RepNote: '',
                RepZip: '',
                RepEmail: '',
                RepInactiveFlg: false,
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: this.state.selectedClient.clientName,
                ClientAbbrv: this.state.selectedClient.clientAbbrv,
                ClientPhone: this.state.selectedClient.clientPhone,
                ClientPhone2: this.state.selectedClient.clientPhon2,
                ClientEmail: this.state.selectedClient.clientEmail
            }
        }));

        this.setModalRep();
    }

    //handleClicks(clientData) {
    //    this.clickCount++;
    //    if (this.clickCount === 1) {
    //        this.singleClickTimer = setTimeout(function () {
    //            this.handleClick(clientData);
    //            this.clickCount = 0;
    //        }.bind(this), 300);

    //    } else if (this.clickCount === 2) {
    //        clearTimeout(this.singleClickTimer);
    //        this.clickCount = 0;
    //        this.handleDoubleClick(clientData);
    //    }
    //}

    handleDoubleClickClient = (clientData) => {

        let searchModel = {
            ClientId: clientData.data.clientId,
            ClientName: '',
            ClientAbbrv: '',
            Org: '',
            Phone: '',
            SortBy: '',
            SortDirection: ''
        }

        FetchData("/getclients", "POST", this.user.token, searchModel).then(data => {
            this.setState({
                selectedClient: clientData.data,
                model: {
                    ...this.state.model, // Spread the existing state
                    EventType: 'update',
                    ClientId: clientData.data.clientId,
                    ClientAbbrv: clientData.data.clientAbbrv,
                    ClientPhone: clientData.data.clientPhone,
                    ClientPhone2: clientData.data.clientPhone2,
                    ClientPhoneFax: clientData.data.clientPhoneFax,
                    ClientName: clientData.data.clientName,
                    ClientMailStreet: clientData.data.clientMailStreet,
                    ClientMailStreet2: clientData.data.clientMailStreet2,
                    ClientMailCity: clientData.data.clientMailCity,
                    ClientMailState: clientData.data.clientMailState,
                    ClientMailZip: clientData.data.clientMailZip,
                    ClientStreet: clientData.data.clientStreet,
                    ClientStreet2: clientData.data.clientStreet2,
                    ClientCity: clientData.data.clientCity,
                    ClientState: clientData.data.clientState,
                    ClientZip: clientData.data.clientZip,
                    ClientCountry: clientData.data.clientCountry,
                    ClientDiscount: clientData.data.clientDiscount,
                    ClientNotes: clientData.data.clientNotes,
                    ClientCreditCard: clientData.data.clientCreditCard,
                    ClientEmailForInvoice: clientData.data.clientEmailForInvoice,
                    ClientEmail: clientData.data.clientEmail,
                    ClientWebsite: clientData.data.clientWebsite,
                    ClientOrg: clientData.data.clientOrg,
                    ClientBillRateType: clientData.data.clientBillRateType,
                    ClientInactiveFlg: clientData.data.clientInactiveFlg,
                    ClientInvoiceNote: clientData.data.clientInvoiceNote,
                    CreateDateTime: clientData.data.createDateTime,
                    CreateByUserId: clientData.data.createByUserId,
                    UpdateByUserId: clientData.data.updateByUserId,
                    UpdateDateTime: clientData.data.updateDateTime,
                    IsDeletedFlg: clientData.data.isDeletedFlg
                }
            }, () => this.setModal()); // After state update, toggle modal visibility
        });
                
    };
        

    //handleDoubleClickClient(clientData) {
    //    //alert('hi');

    //    this.setState(prevState => ({
    //        model: {
    //            EventType: 'update',
    //            ClientId: clientData.data.clientId,
    //            ClientAbbrv: clientData.data.clientAbbrv,
    //            ClientPhone: clientData.data.clientPhone,
    //            ClientPhone2: clientData.data.clientPhone2,
    //            ClientPhoneFax: clientData.data.clientPhoneFax,
    //            ClientName: clientData.data.clientName,
    //            ClientMailStreet: clientData.data.clientMailStreet,
    //            ClientMailStreet2: clientData.data.clientMailStreet2,
    //            ClientMailCity: clientData.data.clientMailCity,
    //            ClientMailState: clientData.data.clientMailState,
    //            ClientMailZip: clientData.data.clientMailZip,
    //            ClientStreet: clientData.data.clientStreet,
    //            ClientStreet2: clientData.data.clientStreet2,
    //            ClientCity: clientData.data.clientCity,
    //            ClientState: clientData.data.clientState,
    //            ClientZip: clientData.data.clientZip,
    //            ClientCountry: clientData.data.clientCountry,
    //            ClientDiscount: clientData.data.clientDiscount,
    //            ClientNotes: clientData.data.clientNotes,
    //            ClientCreditCard: clientData.data.clientCreditCard,
    //            ClientEmailForInvoice: clientData.data.clientEmailForInvoice,
    //            ClientEmail: clientData.data.clientEmail,
    //            ClientWebsite: clientData.data.clientWebsite,
    //            ClientOrg: clientData.data.clientOrg,
    //            ClientBillRateType: clientData.data.clientBillRateType,
    //            ClientInactiveFlg: clientData.data.clientInactiveFlg,
    //            ClientInvoiceNote: clientData.data.clientInvoiceNote,
    //            CreateDateTime: clientData.data.createDateTime,
    //            CreateByUserId: clientData.data.createByUserId,
    //            UpdateByUserId: clientData.data.updateByUserId,
    //            UpdateDateTime: clientData.data.updateDateTime,
    //            IsDeletedFlg: clientData.data.isDeletedFlg
    //        }
    //    }))
    //    this.setModal();
    //}

    handleDoubleClickRep(repData) {
        this.setState(prevState => ({
            rep: {
                EventType: 'update',
                ClientId: repData.data.clientId,
                RepID: repData.data.repID,
                ClientID: repData.data.clientID,
                RepLastName: repData.data.repLastName,
                RepFirstName: repData.data.repFirstName,
                RepPhone: repData.data.repPhone,
                RepPhone2: repData.data.repPhone2,
                RepNote: repData.data.repNote,
                RepZip: repData.data.repZip,
                RepEmail: repData.data.repEmail,
                RepInactiveFlg: repData.data.repInactiveFlg,
                CreateDateTime: repData.data.createDateTime,
                CreatebyUserId: repData.data.createbyUserId,
                UpdateByUserId: repData.data.updateByUserId,
                UpdateDateTime: repData.data.updateDateTime,
                IsDeletedFlg: repData.data.isDeletedFlg,
                ClientName: repData.data.clientName,
                ClientAbbrv: repData.data.clientAbbrv,
                ClientPhone: repData.data.clientPhone,
                ClientPhone2: repData.data.clientPhone2,
                ClientEmail: repData.data.clientEmail
            }
        }))
        this.setModalRep();
    }

    handleClick = (clientData) => {
        this.setState({ clickCount: this.state.clickCount + 1 });
        this.clickTimer = setTimeout(() => {
            if (this.state.clickCount === 1) {  // Single click action
                this.setState({ fetchingData: true, selectedClient: clientData.data }, () => {
                    this.populateRepData();
                });
            }
            this.setState({ clickCount: 0 });  // Reset click count after action
        }, 300);  // Delay of 300ms
    };


    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            },
            selectedClient: alert.selectedClient ? alert.selectedClient : null
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateClientData();
        });
    }

    handleSaveRepFormEventSuccess(alert) {
        this.setState({
            modalRepForm: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateRepData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }

        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleRepSearchChange(e) {
        let model = { ...this.state.repSearchModel }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else
            model[e.target.name] = e.target.value;

        this.setState({ repSearchModel: model });
    }

    handleDeleteRep(repData) {
        var model = { ...this.state.rep }
        model.EventType = 'delete';
        model.ClientId = repData.clientId;
        model.RepID = repData.repID;
        model.ClientID = repData.clientID;
        model.RepLastName = repData.repLastName;
        model.RepFirstName = repData.repFirstName;
        model.RepPhone = repData.repPhone;
        model.RepPhone2 = repData.repPhone2;
        model.RepNote = repData.repNote;
        model.RepZip = repData.repZip;
        model.RepEmail = repData.repEmail;
        model.RepInactiveFlg = repData.repInactiveFlg;
        model.CreateDateTime = repData.createDateTime;
        model.CreatebyUserId = repData.createbyUserId;
        model.UpdateByUserId = repData.updateByUserId;
        model.UpdateDateTime = repData.updateDateTime;
        model.IsDeletedFlg = true;
        model.ClientName = repData.clientName;
        model.ClientAbbrv = repData.clientAbbrv;
        model.ClientPhone = repData.clientPhone;
        model.ClientPhone2 = repData.clientPhone2;
        model.ClientEmail = repData.clientEmail

        FetchData("/saverep", "POST", this.user.token, model).then(data => {
            this.handleSaveEventSuccess({
                message: data === 'Success' ? "Rep has been deleted successfully" : data,
                modal: false,
                color: data === 'Success' ? "success" : "warning",
                selectedClient: this.state.selectedClient
            });
            this.populateRepData();
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({ reps: [] })
            this.populateClientData();
        }
    }

    handleKeyPressRepSearch(e) {
        if (e.key === 'Enter') {
            this.populateRepDataSearch();
        }
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.autoSizeUnspecifiedColumns(false);
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = params => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths });
        }
    };

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    renderClientTable(clients) {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: clientColumns,
            rowData: clients,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                state: this.state,

            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderers
                deleteButtonCellRenderer: DeleteButtonCellRenderer

            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleDoubleClickClient,
            onRowClicked: this.handleClick
        };



        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    renderRepTable(reps) {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: repColumns,
            rowData: reps,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                state: this.state,

            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderers
                deleteButtonCellRenderer: DeleteButtonCellRenderer

            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleDoubleClickRep,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    async populateClientData() {
        this.setState({ fetchingData: true });

        await FetchData("/getclients", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ clientList: data, loading: false, fetchingData: false });
        });

        //    this.requestSort("clientName");
    }

    populateRepData() {
        let model = {
            ClientId: this.state.selectedClient.clientId
        };

        FetchData("/getreps", "POST", this.user.token, model).then(data => {
            this.setState({ reps: data, fetchingData: false });
        });
    }

    populateRepDataSearch() {
        var model = { ...this.state.repSearchModel }
        model.ClientId = this.state.selectedClient.clientId;

        FetchData("/getreps", "POST", this.user.token, model).then(data => {
            this.setState({ reps: data, fetchingData: false });
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderClientTable(this.state.clientList);

        let repsContent = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRepTable(this.state.reps);


        return (
            <div style={{ paddingTop: "12px", overflow: 'hidden' }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Clients</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                            <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} placeholder="Abbrv" onKeyPress={this.handleKeyPress} />
                            <input name="ClientName" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientName} onChange={this.handleSearchChange} placeholder="Client Name" onKeyPress={this.handleKeyPress} />
                            <input name="Org" type="text" className="form-control form-control-text" value={this.state.searchModel.Org} onChange={this.handleSearchChange} placeholder="Org" onKeyPress={this.handleKeyPress} />
                            <input name="Phone" type="text" className="form-control form-control-text" value={this.state.searchModel.Phone} onChange={this.handleSearchChange} placeholder="Phone" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <ClientForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'hidden' }}>
                    {contents}
                </div>
                <hr />
                {this.state.selectedClient != null &&
                    <div>
                        <h5 id="tabelLabel" >Details</h5>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className="active"
                                    onClick={function noRefCheck() { }}
                                >
                                    Reps
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab="1">
                            <TabPane tabId="1">
                                <Row style={{ paddingTop: '5px' }}>
                                </Row>
                                <Row>
                                    <Col sm="8">
                                        <InputGroup size="sm">
                                            <Button color="btn" onClick={this.addRep} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}</Button>
                                            <input name="RepPhone" type="text" className="form-control form-control-text" value={this.state.repSearchModel.RepPhone} onChange={this.handleRepSearchChange} placeholder="Phone" onKeyPress={this.handleKeyPressRepSearch} />
                                            <span className="input-group-text">Inactive:</span><input name="Inactive" className="input-checkbox" type="checkbox" defaultChecked={this.state.repSearchModel.Inactive} onChange={this.handleRepSearchChange} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            {repsContent}
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                }

                {this.state.modalRepForm &&
                    <RepForm model={this.state.rep} modal={this.state.modalRepForm} alert={this.state.alert} setModal={this.setModalRep} handleSaveEventSuccess={this.handleSaveRepFormEventSuccess} />
                }

            </div>
        );

    }
}

class DeleteButtonCellRenderer extends React.Component {
    handleDelete = () => {
        const data = this.props.data; // Data for the row
        if (window.confirm("Are you sure to delete this record?")) {
            this.props.context.componentParent.handleDeleteRep(data);
        }
    }

    render() {
        return (
            <img src={RemoveIcon} style={{ width: "16px", cursor: 'pointer' }} onClick={this.handleDelete} alt="Delete" />
        );
    }
}
