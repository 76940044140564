import React, { Component, Fragment } from 'react';
import {
    //Table, Mateo was here, Rob was here, Dennis was here
    Button, Row, Col, Input, InputGroup, Alert
} from 'reactstrap';

import ReactTooltip from "react-tooltip";

import { WorkOrderIntForm } from './WorkOrderIntForm';
import { WorkOrderIntFormEndTime } from './WorkOrderIntFormEndTime';
import { WorkOrderIntFormEndTimeComment } from './WorkOrderIntFormEndTimeComment';
import { LoadingModal } from './LoadingModal';
import { WorkOrderJobNotes } from './WorkOrderJobNotes';

import { FetchData, FetchFile } from './utils/DataFetch';
import { GetParameterByName, GetCookie, RemoveCookie, Timeout } from './utils/Common';

import { saveAs } from 'file-saver';
import { InterpreterFormEmail } from './InterpreterFormEmail'
import { RepFormEmail } from './RepFormEmail'

import AddIcon from '../images/add.png'
import RefreshIcon from '../images/merge-icon.png'

import Previous from '../images/prev.png'
import Next from '../images/next.png'

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

import { WorkOrderIntConfNo } from './WorkOrderIntConfNo'

const moment = require('moment');

export class WorkOrderInt extends Component {
    static displayName = WorkOrderInt.name;

    constructor(props) {
        super(props);
        this.state = {
            workOrderList: [],
            jobHistoryList: [],
            jobInvLineList: [],

            executiveUsers: [],

            receivableList: [],
            receivableItemList: [],

            loading: true,
            modal: false,
            modalEndingTime: false,
            modalEndTimeComment: false,

            fetchingData: false,
            modalIntFormEmail: false,
            modalRepFormEmail: false,
            modalWOJobNotes: false,
            multiFilter: false,
            selectedIndex: -1,
            checkAll: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                JobBalance: null
            },
            client: {
                EventType: 'update',
                ClientId: null,
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientPhoneFax: '',
                ClientName: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientCountry: '',
                ClientDiscount: null,
                ClientNotes: '',
                ClientCreditCard: '',
                ClientEmailForInvoice: '',
                ClientEmail: '',
                ClientWebsite: '',
                ClientOrg: '',
                ClientBillRateType: '',
                ClientInactiveFlg: false,
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            },
            searchModel: {
                IntId: '',
                ClmId: '',
                JobNum: '',
                StartDate: '',
                EndDate: '',
                Claimant: '',
                ClaimantNum: '',
                CLMPhone: '',
                Interpreter: '',
                Rep: '',
                ClientAbbrv: '',
                ProviderName: '',
                CreatedByUserId: null,
                MyJobs: false,
                MyJobsUser: '',
                SortBy: 'jobDateTime',
                SortDirection: 'desc'
            },
            email: {
                EmailFrom: '',
                EmailTo: '',
                EmailCC: '',
                Subject: '',
                Body: '',
                JobIds: ''
            },
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

            tableView: 'default',

            modalEmailConfirm: false,
            emailConfirm: {
                notify_type: '',
                JobId: null,
                IntId: null
            }
        };
        //
        this.user = JSON.parse(GetCookie('user'));

        // functions        
        this.setModalIntFormEmail = this.setModalIntFormEmail.bind(this);
        this.setModalRepFormEmail = this.setModalRepFormEmail.bind(this);
        this.setModalWOJobNotes = this.setModalWOJobNotes.bind(this);
        this.setModalEmailConfirm = this.setModalEmailConfirm.bind(this);
        this.setModalEndTimeComment = this.setModalEndTimeComment.bind(this);

        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleSaveEventIntEndTimeSuccess = this.handleSaveEventIntEndTimeSuccess.bind(this);
        this.handleSaveEventIntEmailSuccess = this.handleSaveEventIntEmailSuccess.bind(this);
        this.handleSaveEventRepEmailSuccess = this.handleSaveEventRepEmailSuccess.bind(this);
        this.handleSaveEventSuccessComment = this.handleSaveEventSuccessComment.bind(this);

        // button clicks
        this.resetFields = this.resetFields.bind(this);
        this.refreshSearch = this.refreshSearch.bind(this);
        this.setData = this.setData.bind(this);
        this.setNextPrevData = this.setNextPrevData.bind(this);
        this.setModal = this.setModal.bind(this);
        this.setModalEndingTime = this.setModalEndingTime.bind(this);
        //
        this.renderWorkOrderTableDefault = this.renderWorkOrderTableDefault.bind(this);
        this.renderWorkOrderTableSmallSize = this.renderWorkOrderTableSmallSize.bind(this);
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchFocus = this.handleSearchFocus.bind(this);
        this.handleSearchBlur = this.handleSearchBlur.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        // export reports
        this.exportPDF = this.exportPDF.bind(this);
        this.exportPurpleReport = this.exportPurpleReport.bind(this);
        //
        this.addSchedule = this.addSchedule.bind(this);
        //
        this.setInterpreterEmail = this.setInterpreterEmail.bind(this);
        this.setRepEmail = this.setRepEmail.bind(this);
        //
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.resetChecks = this.resetChecks.bind(this);
        //
        this.handlePrevNextButton = this.handlePrevNextButton.bind(this);
        //
        this.viewJobNotes = this.viewJobNotes.bind(this);
        //
        this.handlePendingClick = this.handlePendingClick.bind(this);
        this.handleContractClick = this.handleContractClick.bind(this);
        this.handleAuditClick = this.handleAuditClick.bind(this);
        this.handlePiggyBack = this.handlePiggyBack.bind(this);
        this.handleAutoPay = this.handleAutoPay.bind(this);
        this.viewInvoice = this.viewInvoice.bind(this);
        //
        this.requestSort = this.requestSort.bind(this);
        //
        this.handleIntConfNo = this.handleIntConfNo.bind(this);
        this.handleSendSMS = this.handleSendSMS.bind(this);
        //
        this.handleEndintTimeClick = this.handleEndintTimeClick.bind(this);
    }

    componentDidMount() {
        this.populateWorkOrderData();
        if (this.props.isMyJob)
            this.populateExecutiveUsers();

        if (GetParameterByName('jobid') !== null) {
            FetchData("/ValidateJobInterpreter/?jobId=" + GetParameterByName('jobid'), "POST", this.user.token, {}).then(data => {
                if (data === "Success") {
                    this.openWorkOrderForm(GetParameterByName('jobid'));
                } else {
                    RemoveCookie('user');
                    localStorage.removeItem('RemoteTypes');
                    localStorage.removeItem('RemoteHosts');
                    localStorage.removeItem('JobTypes');
                    localStorage.removeItem('JobInvLineCodes');
                    window.location.reload();
                }
            });
        }
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    setModal() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (!this.state.modal) {
                this.setState({
                    selectedIndex: -1
                });
            }
        });
    }

    setModalEndingTime() {
        this.setState({
            modalEndingTime: !this.state.modalEndingTime
        }, () => {

        });
    }

    setModalEndTimeComment() {
        this.setState({ modalEndTimeComment: !this.state.modalEndTimeComment });
    }

    setModalIntFormEmail() {
        this.setState({ modalIntFormEmail: !this.state.modalIntFormEmail });
    }

    setModalRepFormEmail() {
        this.setState({ modalRepFormEmail: !this.state.modalRepFormEmail });
    }

    setModalWOJobNotes() {
        this.setState({ modalWOJobNotes: !this.state.modalWOJobNotes });
    }

    setModalEmailConfirm() {
        this.setState({ modalEmailConfirm: !this.state.modalEmailConfirm });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                JobBalance: null
            },
            jobHistoryList: [],
            jobInvLineList: [],
            modal: !this.state.modal
        }));

        // this.setModal();
    }

    refreshSearch() {
        if (this.state.tableView === 'default')
            this.populateWorkOrderData();
        else
            this.populateWorkOrderPendingData();
    }

    setData(woData, rowIndex, showModal) {
        this.setState(prevState => ({
            model: {
                EventType: 'update',
                JobNum: woData.jobNum,
                JobId: woData.jobId,
                JobDateTime: woData.jobDateTime,
                JobTime: woData.jobTime,
                FinishTime: woData.finishTime,
                FinishTimeString: woData.finishTimeString == "00:00" ? null : woData.finishTimeString,
                FollowupDate: woData.followupDate,
                ProvId: woData.provId,
                ProviderName: woData.providerName,
                ConfCallNote: woData.confCallNote,
                AptName: woData.aptName,
                AptStreet: woData.aptStreet,
                AptCity: woData.aptCity,
                AptState: woData.aptState,
                AptZip: woData.aptZip,
                AptPhone: woData.aptPhone,
                AptDirection: woData.aptDirection,
                Failedflg: woData.failedflg,
                CallerNote: woData.callerNote,
                InvoiceNote: woData.invoiceNote,
                IntCheckDate: woData.intCheckDate,
                IntCheckNum: woData.intCheckNum,
                Invoicedflg: woData.invoicedflg,
                JobTotal: woData.jobTotal,
                JobDiscount: woData.jobDiscount,
                IntFee: woData.intFee === null ? null : woData.intFee, //parseFloat(woData.intFee).toFixed(2),
                IntFeeOther: woData.intFeeOther === null ? null : woData.intFeeOther, //parseFloat(woData.intFeeOther).toFixed(2),
                IntOTFee: woData.intOTFee === null ? null : woData.intOTFe, //parseFloat(woData.intOTFee).toFixed(2),
                IntFeeNote: woData.intFeeNote,
                Transportationflg: woData.transportationflg,
                TransConfflg: woData.transConfflg,
                IntConfflg: woData.intConfflg,
                AppointmentTypeId: woData.appointmentTypeId,
                CallerType: woData.callerType,
                ProvConfflg: woData.provConfflg,
                ClmConfflg: woData.clmConfflg,
                IsDeletedflg: woData.isDeletedflg,
                BillReviewedflg: woData.billReviewedflg,
                Auditflg: woData.auditflg,
                PurpleReportflg: woData.purpleReportflg,
                RepAuthorizedflg: woData.repAuthorizedflg,
                Selectedflg: woData.selectedflg,
                ClmId: woData.clmId,
                ClmFirstName: woData.clmFirstName,
                ClmLastName: woData.clmLastName,
                ClmClaimNumber: woData.clmClaimNumber,
                ClmClaimNumberNoDash: woData.clmClaimNumberNoDash,
                ClmPhone: woData.clmPhone,
                ClmDateOfLoss: woData.clmDateOfLoss,
                ClmDateOfInjury: woData.clmDateOfInjury,
                ClmNote: woData.clmNote,
                ClmDOB: woData.clmDOB,
                ClmInsuredParty: woData.clmInsuredParty,
                ClmEmployer: woData.clmEmployer,
                ClmInvoiceCaption: woData.clmInvoiceCaption,
                ClmLanguage: woData.clmLanguage,
                ClmWCABNumber: woData.clmWCABNumber,
                ClmInvoiceType: woData.clmInvoiceType,
                ClmStreet: woData.clmStreet,
                ClmCity: woData.clmCity,
                ClmState: woData.clmState,
                ClmZip: woData.clmZip,
                ClmSSN: woData.clmSSN,
                ClmOrderedByName: woData.clmOrderedByName,
                ClmDiagnosis: woData.clmDiagnosis,
                ClmEmail: woData.clmEmail,
                RepID: woData.repID,
                RepFirstName: woData.repFirstName,
                RepLastName: woData.repLastName,
                RepPhone: woData.repPhone,
                RepEmail: woData.repEmail,
                ClientId: woData.clientId,
                ClientAbbrv: woData.clientAbbrv,
                ClientName: woData.clientName,
                ClientStreet: woData.clientStreet,
                ClientStreet2: woData.clientStreet2,
                ClientCity: woData.clientCity,
                ClientState: woData.clientState,
                ClientZip: woData.clientZip,
                ClientMailStreet: woData.clientMailStreet,
                ClientMailStreet2: woData.clientMailStreet2,
                ClientMailCity: woData.clientMailCity,
                ClientMailState: woData.clientMailState,
                ClientMailZip: woData.clientMailZip,
                ClientPhone: woData.clientPhone,
                ClientPhoneFax: woData.clientPhoneFax,
                ClientPhone2: woData.clientPhone2,
                ClientEmail: woData.clientEmail,
                ClientInactiveFlg: woData.clientInactiveFlg,
                IntId: woData.intId,
                IntLastName: woData.intLastName,
                IntFirstName: woData.intFirstName,
                IntCellTxt: woData.intCellTxt,
                IntPhoneOnly: woData.intPhoneOnly,
                IntEmail: woData.intEmail,
                IntNote: woData.intNote,
                InHouseflg: woData.inHouseflg,
                IntCity: woData.intCity,
                IntStreet: woData.intStreet,
                IntState: woData.intState,
                IntZip: woData.intZip,
                DBA: woData.dDBA,
                Rate: woData.rate,
                HourMinimum: woData.hourMinimum,
                Prorate: woData.prorate,
                BankRoute: woData.bankRoute,
                BankAccount: woData.bankAccount,
                IntSSINumber: woData.intSSINumber,
                FirstName: woData.firstName,
                LastName: woData.lastName,
                UserType: woData.userType,
                UserIntId: woData.userIntId,
                CreateDateTime: woData.createDateTime,
                CreateByUserId: woData.createByUserId,
                UpdateDateTime: woData.updateDateTime,
                UpdateByUserId: woData.updateByUserId,
                RemIntflg: woData.remIntflg,
                RemClaimflg: woData.remClaimflg,
                RemProvflg: woData.remProvflg,
                RemFinalflg: woData.remFinalflg,
                RemType: woData.remType,
                RemTypeDesc: woData.remTypeDesc,
                RemHost: woData.remHost,
                RemHostDesc: woData.remHostDesc,
                CreatedBy: woData.createdBy,
                UpdatedBy: woData.updatedBy,
                MyJobTJobHistory: woData.myJobTJobHistory,
                JobBalance: woData.jobBalance
            },
            selectedIndex: rowIndex,
            modal: showModal
        }));
    }

    addSchedule(woData, rowIndex) {

        let currentDate = new Date();
        let currentMonth = currentDate.getMonth() + 1;
        const time = currentDate.toLocaleTimeString();
        let callerNote = 'Scheduled From Calendar ' + currentMonth + '/' + currentDate.getDate() + '/' + currentDate.getFullYear() + ' ' + time + ' By: ' + this.user.firstname + ' ' + this.user.lastname;

        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: woData.provId,
                ProviderName: woData.providerName,
                ConfCallNote: woData.confCallNote,
                AptName: woData.aptName,
                AptStreet: woData.aptStreet,
                AptCity: woData.aptCity,
                AptState: woData.aptState,
                AptZip: woData.aptZip,
                AptPhone: woData.aptPhone,
                AptDirection: woData.aptDirection,
                Failedflg: woData.failedflg,
                CallerNote: callerNote,
                InvoiceNote: "", //woData.invoiceNote,
                IntCheckDate: null, //woData.intCheckDate,
                IntCheckNum: null, //woData.intCheckNum,
                Invoicedflg: false,
                JobTotal: 0, //woData.jobTotal,
                JobDiscount: woData.jobDiscount,
                IntFee: null,
                IntFeeOther: null,
                Transportationflg: woData.transportationflg,
                TransConfflg: woData.transConfflg,
                IntConfflg: false,
                AppointmentTypeId: woData.appointmentTypeId,
                CallerType: 4,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: woData.isDeletedflg,
                BillReviewedflg: false, //woData.billReviewedflg,
                Auditflg: woData.auditflg,
                PurpleReportflg: woData.purpleReportflg,
                RepAuthorizedflg: false,
                Selectedflg: woData.selectedflg,
                ClmId: woData.clmId,
                ClmFirstName: woData.clmFirstName,
                ClmLastName: woData.clmLastName,
                ClmClaimNumber: woData.clmClaimNumber,
                ClmClaimNumberNoDash: woData.clmClaimNumberNoDash,
                ClmPhone: woData.clmPhone,
                ClmDateOfLoss: woData.clmDateOfLoss,
                ClmDateOfInjury: woData.clmDateOfInjury,
                ClmNote: woData.clmNote,
                ClmDOB: woData.clmDOB,
                ClmInsuredParty: woData.clmInsuredParty,
                ClmEmployer: woData.clmEmployer,
                ClmInvoiceCaption: woData.clmInvoiceCaption,
                ClmLanguage: woData.clmLanguage,
                ClmWCABNumber: woData.clmWCABNumber,
                ClmInvoiceType: woData.clmInvoiceType,
                ClmStreet: woData.clmStreet,
                ClmCity: woData.clmCity,
                ClmState: woData.clmState,
                ClmZip: woData.clmZip,
                ClmSSN: woData.clmSSN,
                ClmOrderedByName: woData.clmOrderedByName,
                ClmDiagnosis: woData.clmDiagnosis,
                ClmEmail: woData.clmEmail,
                RepID: woData.repID,
                RepFirstName: woData.repFirstName,
                RepLastName: woData.repLastName,
                RepPhone: woData.repPhone,
                RepEmail: woData.repEmail,
                ClientId: woData.clientId,
                ClientAbbrv: woData.clientAbbrv,
                ClientName: woData.clientName,
                ClientStreet: woData.clientStreet,
                ClientStreet2: woData.clientStreet2,
                ClientCity: woData.clientCity,
                ClientState: woData.clientState,
                ClientZip: woData.clientZip,
                ClientMailStreet: woData.clientMailStreet,
                ClientMailStreet2: woData.clientMailStreet2,
                ClientMailCity: woData.clientMailCity,
                ClientMailState: woData.clientMailState,
                ClientMailZip: woData.clientMailZip,
                ClientPhone: woData.clientPhone,
                ClientPhoneFax: woData.clientPhoneFax,
                ClientPhone2: woData.clientPhone2,
                ClientEmail: woData.clientEmail,
                ClientInactiveFlg: woData.clientInactiveFlg,
                IntId: woData.intId,
                IntLastName: woData.intLastName,
                IntFirstName: woData.intFirstName,
                IntCellTxt: woData.intCellTxt,
                IntPhoneOnly: woData.intPhoneOnly,
                IntEmail: woData.intEmail,
                IntNote: woData.intNote,
                InHouseflg: woData.inHouseflg,
                IntCity: woData.intCity,
                IntStreet: woData.intStreet,
                IntState: woData.intState,
                IntZip: woData.intZip,
                DBA: woData.dDBA,
                Rate: woData.rate,
                HourMinimum: woData.hourMinimum,
                Prorate: woData.prorate,
                BankRoute: woData.bankRoute,
                BankAccount: woData.bankAccount,
                IntSSINumber: woData.intSSINumber,
                FirstName: woData.firstName,
                LastName: woData.lastName,
                UserType: woData.userType,
                UserIntId: woData.userIntId,
                CreateDateTime: woData.createDateTime,
                CreateByUserId: woData.createByUserId,
                UpdateDateTime: woData.updateDateTime,
                UpdateByUserId: woData.updateByUserId,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false, //woData.remFinalflg,
                RemType: null, //woData.remType,
                RemTypeDesc: woData.remTypeDesc,
                RemHost: null, //woData.remHost,
                RemHostDesc: woData.remHostDesc,
                CreatedBy: woData.createdBy,
                UpdatedBy: woData.updatedBy,
            },
            selectedIndex: rowIndex,
            jobHistoryList: [],
            jobInvLineList: []
        }));
    }

    setInterpreterEmail(woData) {
        var checkMailedOn = '';
        var d = new Date(woData.jobDateTime);
        var newD;
        if (d.getDate() <= 15) {
            newD = new Date((d.getMonth() + 1) + '/15/' + d.getFullYear());
        }
        else {
            newD = moment(d).add(1, 'M').toDate();
            //console.log(newD);
            newD = new Date((newD.getMonth() + 1) + '/1/' + newD.getFullYear());
        };

        switch (newD.getDay()) {
            //case 0:
            //    newD = Ext.Date.add(newD, Ext.Date.DAY, 1);
            //    break;
            case 4:
                newD = moment(newD).add(5, 'd').toDate(); //Ext.Date.add(newD, Ext.Date.DAY, 5);
                break;
            case 5:
                newD = moment(newD).add(4, 'd').toDate();
                break;
            case 6:
                newD = moment(newD).add(3, 'd').toDate();
                break;
            default:
                newD = moment(newD).add(2, 'd').toDate();
        }

        checkMailedOn = moment(newD).format('MM/DD/YYYY');
        var checkMailedOnDate = new Date(checkMailedOn);

        var body = 'Dear ' + woData.intFirstName + ',';
        body += '\n\nPlease ** REPLY TO THIS EMAIL ** to confirm this is in your book:';
        body += '\n\nJob: ' + woData.jobNum;
        body += '\nFor: ' + woData.clmFirstName + ' ' + woData.clmLastName;
        body += '\nOn: ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ' (' + this.state.dayNames[moment(woData.jobDateTime).day()] + ') ' + ' at ' + moment(woData.jobDateTime).format('hh:mm A');
        body += '\nAt: ' + woData.providerName;
        body += '\n    ' + woData.aptStreet;
        body += '\n    ' + woData.aptCity + ', ' + woData.aptState + ' ' + woData.aptZip;

        body += '\n\nIf there is any problem or if the patient does not appear within 15 mins of start time, please CALL US right away. If you are not required to interpret, you MUST call us BEFORE leaving the appointment.';

        if (woData.rate != null && woData.hourMinimum != null && woData.prorate != "") {
            body += '\n\nWe have your rate as $' + woData.rate + ' an hour, ';
            body += 'with a ' + woData.hourMinimum + ' hour minimum, ';
            body += 'additional time paid in ' + woData.prorate + ' increments. ';
        }

        body += 'Your payment will be deposited into your account by ' + this.state.monthNames[checkMailedOnDate.getMonth()] + ' ' + checkMailedOnDate.getDate() + ', ' + checkMailedOnDate.getFullYear() + '. ';
        body += '\nPlease refer to https://portal.e-ela.com/faq for any other questions.';
        body += '\n\nIMPORTANT COVID-19 INFORMATION';
        body += '\n\nMore medical offices are requiring proof of vaccination. If you are vaccinated, Executive Linguist recommends carrying proof of vaccination with you.';
        body += '\n\nExecutive Linguist Agency, Inc. requires its contracted interpreters to follow all local, state, and federal health guidelines and mandates. If you encounter a situation at a scheduled appointment where guidelines and mandates are not followed, please call us immediately.';

        body += '\n\nTo access our Portal and FAQ click at <a href="https://portal.e-ela.com" target="_blank">https://portal.e-ela.com</a>';

        body += '\n\nThank you,';
        body += '\n\n' + this.user.firstname + ' ' + this.user.lastname;

        body += '\n\nExecutive Linguist Agency \n800-522-2320 | Text: 310-955-4513 | 8am - 5pm Nationwide \n After-hours Emergencies: 310-749-6521 or 562-682-3924 \nQuestions: mail@executivelinguist.com';

        body += '\n\nIf you have symptoms of COVID-19 or return a job due to sickness with symptoms related to COVID-19, we will cover your assignments until you present COVID-Negative test results or 14 days have passed and you have self-isolated since your onset of symptoms. Note: Some providers may have these same requirements regardless of symptom type.';

        this.setState(prevState => ({
            email: {
                EmailFrom: this.user.email,
                EmailTo: woData.intEmail,
                EmailCC: '',
                Subject: 'Interpreting Assignment',
                Body: body,
                JobIds: woData.jobNum.toString()
            }
        }));
        this.setModalIntFormEmail();
    }

    setRepEmail(woData) {
        var checkMailedOn = '';
        var d = new Date(woData.jobDateTime);
        var newD;
        if (d.getDate() <= 15) {
            newD = new Date((d.getMonth() + 1) + '/15/' + d.getFullYear());
        }
        else {
            newD = moment(d).add(1, 'M').toDate();
            //console.log(newD);
            newD = new Date((newD.getMonth() + 1) + '/1/' + newD.getFullYear());
        };

        switch (newD.getDay()) {
            //case 0:
            //    newD = Ext.Date.add(newD, Ext.Date.DAY, 1);
            //    break;
            case 4:
                newD = moment(newD).add(5, 'd').toDate(); //Ext.Date.add(newD, Ext.Date.DAY, 5);
                break;
            case 5:
                newD = moment(newD).add(4, 'd').toDate();
                break;
            case 6:
                newD = moment(newD).add(3, 'd').toDate();
                break;
            default:
                newD = moment(newD).add(2, 'd').toDate();
        }

        checkMailedOn = moment(newD).format('MM/DD/YYYY');
        // var checkMailedOnDate = new Date(checkMailedOn);

        var subject = woData.clmFirstName + ' ' + woData.clmLastName + ' CLM # ' + woData.clmClaimNumber;
        subject += ' - ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ' at ' + moment(woData.jobDateTime).format('hh:mm A');

        var body = 'Dear ' + woData.repFirstName + ',';
        body += '\n\nPlease ** REPLY TO THIS EMAIL ** to confirm this is in your book:';
        body += '\n\nJob: ' + woData.jobNum;
        body += '\nFor: ' + woData.clmFirstName + ' ' + woData.clmLastName;
        body += '\nOn: ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ' (' + this.state.dayNames[moment(woData.jobDateTime).day()] + ') ' + ' at ' + moment(woData.jobDateTime).format('hh:mm A');
        body += '\nAt: ' + woData.providerName;
        body += '\n    ' + woData.aptStreet;
        body += '\n    ' + woData.aptCity + ', ' + woData.aptState + ' ' + woData.aptZip;

        body += '\n\nExecutive Linguist Agency, Inc. staff and its contracted interpreters are following all local, state, and federal health guidelines and mandates.';
        body += '\nIf an interpreter encounters a situation at a scheduled appointment where guidelines and mandates are not followed, the interpreter has the right to discontinue service but must notify us first.';
        body += '\nThe payor will be notified and may be charged in full if the interpreter has to be compensated for their scheduled time';
        body += '\n\n\nThank you,';
        body += '\n\n' + this.user.firstname + ' ' + this.user.lastname;

        body += '\n\nExecutive Linguist Agency \n800-522-2320 | 310-376-1409 | 8am - 5pm Nationwide \nDiversified Language Services – Translation & Localization \nVideo Remote, Telephonic, and On - site Interpreting \nrequests: mail@executivelinguist.com';

        this.setState(prevState => ({
            email: {
                EmailFrom: this.user.email,
                EmailTo: woData.intEmail,
                EmailCC: '',
                Subject: subject,
                Body: body,
                JobIds: woData.jobNum.toString()
            }
        }));
        this.setModalRepFormEmail();
    }

    setNextPrevData(rowIndex) {
        let woData = []
        if (this.state.selectedIndex + rowIndex < 0) {
            this.setState({
                selectedIndex: 0
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setData(woData, this.state.selectedIndex, true);
            })
        } else if (this.state.selectedIndex + rowIndex > this.state.workOrderList.length) {
            this.setState({
                selectedIndex: this.state.workOrderList.length
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setData(woData, this.state.selectedIndex, true);
            })
        } else {
            this.setState({
                selectedIndex: this.state.selectedIndex + rowIndex
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setData(woData, this.state.selectedIndex, true);
            })
        }
    }

    getJobHistory(jobId) {
        let model = {
            JobId: jobId
        };

        FetchData("/getjobhistory", "POST", this.user.token, model).then(data => {
            this.setState({ jobHistoryList: data, fetchingData: false });
        });
    }

    getJoInvLine(jobId) {
        let model = {
            JobId: jobId
        };
        FetchData("/getjobinvline", "POST", this.user.token, model).then(data => {
            this.setState({ jobInvLineList: data, fetchingData: false });
        });

    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));
            if (alert.modal === false)
                if (this.state.tableView === 'default')
                    this.populateWorkOrderData();
                else
                    this.populateWorkOrderPendingData();
        });
    }

    handleSaveEventIntEndTimeSuccess(alert) {
        this.setState({
            modalEndingTime: alert.modalEndingTime,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            console.log(alert);
            if (alert.showTimeout != null) {
                this.setState({ alert: { isOpen: false } })
                this.setModalEndTimeComment();
            }
            else {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                    this.setModalEndTimeComment();
                }, Timeout(alert.message));
            }
           
            if (alert.modal === false)
                this.populateWorkOrderData();
        });
    }
      
    handleSaveEventIntEmailSuccess(alert) {
        this.setState({
            modalIntFormEmail: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message))
        });
    }

    handleSaveEventRepEmailSuccess(alert) {
        this.setState({
            modalRepFormEmail: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message))
        });
    }


    handleSaveEventSuccessComment(alert) {
        this.setState({
            modalEndTimeComment: alert.modalEndTimeComment,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));            
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (this.state.tableView === 'default')
                this.populateWorkOrderData();
            else
                this.populateWorkOrderPendingData();
        }
    }

    handleSearchFocus(e) {
        if (this.state.multiFilter === false) {
            this.setState({
                searchModel: {
                    IntId: '',
                    ClmId: '',
                    JobNum: '',
                    StartDate: '',
                    EndDate: '',
                    Claimant: '',
                    ClaimantNum: '',
                    CLMPhone: '',
                    Interpreter: '',
                    Rep: '',
                    ClientAbbrv: '',
                    ProviderName: '',
                    MyJobsUser: this.state.searchModel.MyJobsUser,
                    SortBy: this.state.searchModel.SortBy,
                    SortDirection: this.state.searchModel.SortDirection
                }
            }, () => {

            });

            if (e.target.value !== "") {
                let model = { ...this.state.searchModel }

                if (e.target.name === "StartDate" || e.target.name === "EndDate") {
                    model[e.target.name] = moment(e.target.value).format('YYYY-MM-DD');
                }
                else
                    model[e.target.name] = e.target.value;

                this.setState({ searchModel: model });
            }
        }

        if (e.target.name === "StartDate" && e.target.type !== 'date') {
            e.target.type = 'date';
        }
        if (e.target.name === "EndDate" && e.target.type !== 'date') {
            e.target.type = 'date';
        }
    }

    handleSearchBlur(e) {
        //e.target.type = (e.target.name === "StartDate" || e.target.name === "EndDate") && e.target.value !== '' ? 'date' : 'text';
    }

    handleCheckAll = () => {
        this.setState({
            checkAll: !this.state.checkAll
        }, () => {
            this.state.workOrderList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: this.state.checkAll }))
        });
    }

    handleCheckChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        }, () => {
            const uncheckedItems = this.state.workOrderList.filter((item) => !this.state["chk_" + item.jobNum]);
            this.setState({
                checkAll: uncheckedItems.length === 0 ? true : false
            });

        });
    }

    resetChecks = () => {
        this.setState({
            checkAll: false
        }, () => {
            this.state.workOrderList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: false }))
        });
    }

    handlePendingClick() {
        this.populateWorkOrderPendingData();
    }

    handleContractClick() {
        this.setState({ tableView: 'contract' });
    }

    handleAuditClick() {
        this.setState({ tableView: 'audit' });
    }

    handlePiggyBack() {
        let model = { ...this.state.searchModel }
        FetchData("/piggyback", "POST", this.user.token, model).then(data => {
            if (data === 'Success') {
                this.handleSaveEventSuccess({
                    message: "PiggyBack Complete.",
                    modal: false,
                    color: "success"
                });
            } else {
                this.handleSaveEventSuccess({
                    message: "PiggyBack Failed.",
                    modal: false,
                    color: "warning"
                });
            }
        });
    }

    handleAutoPay() {
        let model = {
            JobDate: null,
            UserGID: ''
        }
        model.JobDate = this.state.searchModel.StartDate;
        model.UserGID = this.user.userid;
        FetchData("/autopay", "POST", this.user.token, model).then(data => {
            if (data === 'Success') {
                this.handleSaveEventSuccess({
                    message: "AutoPay Complete.",
                    modal: false,
                    color: "success"
                });
            } else {
                this.handleSaveEventSuccess({
                    message: "AutoPay Failed." + data,
                    modal: false,
                    color: "warning"
                });
            }
        });

    }

    handleIntConfNo(workorder) {
        this.setState({
            emailConfirm: {
                notify_type: 'auth',
                JobId: workorder.jobId,
                IntId: workorder.intId
            }
        }, () => {
            this.setModalEmailConfirm();
        });
    }

    async handleSendSMS(workorder) {
        let model = {
            User: {
                UserID: this.user.userid,
                FirstName: this.user.firstname,
                LastName: this.user.lastname,
                Email: this.user.email,
                IntId: workorder.intId
            },
            JobId: workorder.jobId
        };

        console.log(model);

        await FetchData("/RingPlanSendMessage", "POST", this.user.token, model).then(data => {
            this.setState({
                alert: {
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message))
            });
        });
    }

    handlePrevNextButton = (day) => {
        if (this.state.searchModel.StartDate === "" || this.state.searchModel.StartDate === null) {
            alert('Please enter Job Start Date');
        } else {
            let model = { ...this.state.searchModel }
            model.StartDate = moment(model.StartDate).add(day, 'd').toDate();

            this.setState({ searchModel: model }, () => {
                this.populateWorkOrderData();
            });

        }
    }

    viewJobNotes(workorder) {
        FetchData("/getreceivables", "POST", this.user.token, { JobId: workorder.jobId }).then(data => {
            this.setState({ receivableList: data });
        });
        FetchData("/getreceivableitems", "POST", this.user.token, { JobId: workorder.jobId }).then(data => {
            this.setState({ receivableItemList: data });
        });
        this.setModalWOJobNotes();
    }

    sortData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.workOrderList;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }

    requestSort(pSortBy) {
        var sortBy = this.state.searchModel.SortBy;
        var sortOrder = this.state.searchModel.SortDirection;
        return event => {
            if (pSortBy === this.state.searchModel.SortBy) {
                sortOrder = sortOrder === "asc" ? "desc" : "asc";
            } else {
                sortBy = pSortBy;
                sortOrder = "asc";
            }
            var sortedItems = this.sortData(sortBy, sortOrder);
            this.setState({
                searchModel: {
                    SortBy: sortBy,
                    SortDirection: sortOrder
                },
                workOrderList: sortedItems
            });
        };
    }

    viewInvoice(woData, i) {

        //this.setData(woData, i);

        //this.setState({
        //    modalViewInvoice: !this.state.modalViewInvoice,
        //    model: woData,

        //})
        let jobNumsList = [];

        let body = {
            JobNumbers: ""
        };

        jobNumsList.push(woData.jobNum);
        body.JobNumbers = jobNumsList.join();
        let currentDate = new Date();
        let invoiceFileName = "Invoice " + body.JobNumbers + " " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate() + 1) + "-" + currentDate.getFullYear() + ".pdf";

        FetchFile("/getreportinvoices", "POST", this.user.token, body).then(blob => {
            saveAs(blob, invoiceFileName);
        })
    }

    handleEndintTimeClick(woData, rowIndex, showModal) {
        this.setState(prevState => ({
            model: {
                EventType: 'update',
                JobNum: woData.jobNum,
                JobId: woData.jobId,
                JobDateTime: woData.jobDateTime,
                JobTime: woData.jobTime,
                FinishTime: woData.finishTime,
                FinishTimeString: woData.finishTimeString == "00:00" ? null : woData.finishTimeString,
                FollowupDate: woData.followupDate,
                ProvId: woData.provId,
                ProviderName: woData.providerName,
                ConfCallNote: woData.confCallNote,
                AptName: woData.aptName,
                AptStreet: woData.aptStreet,
                AptCity: woData.aptCity,
                AptState: woData.aptState,
                AptZip: woData.aptZip,
                AptPhone: woData.aptPhone,
                AptDirection: woData.aptDirection,
                Failedflg: woData.failedflg,
                CallerNote: woData.callerNote,
                InvoiceNote: woData.invoiceNote,
                IntCheckDate: woData.intCheckDate,
                IntCheckNum: woData.intCheckNum,
                Invoicedflg: woData.invoicedflg,
                JobTotal: woData.jobTotal,
                JobDiscount: woData.jobDiscount,
                IntFee: woData.intFee === null ? null : woData.intFee, //parseFloat(woData.intFee).toFixed(2),
                IntFeeOther: woData.intFeeOther === null ? null : woData.intFeeOther, //parseFloat(woData.intFeeOther).toFixed(2),
                IntOTFee: woData.intOTFee === null ? null : woData.intOTFe, //parseFloat(woData.intOTFee).toFixed(2),
                IntFeeNote: woData.intFeeNote,
                Transportationflg: woData.transportationflg,
                TransConfflg: woData.transConfflg,
                IntConfflg: woData.intConfflg,
                AppointmentTypeId: woData.appointmentTypeId,
                CallerType: woData.callerType,
                ProvConfflg: woData.provConfflg,
                ClmConfflg: woData.clmConfflg,
                IsDeletedflg: woData.isDeletedflg,
                BillReviewedflg: woData.billReviewedflg,
                Auditflg: woData.auditflg,
                PurpleReportflg: woData.purpleReportflg,
                RepAuthorizedflg: woData.repAuthorizedflg,
                Selectedflg: woData.selectedflg,
                ClmId: woData.clmId,
                ClmFirstName: woData.clmFirstName,
                ClmLastName: woData.clmLastName,
                ClmClaimNumber: woData.clmClaimNumber,
                ClmClaimNumberNoDash: woData.clmClaimNumberNoDash,
                ClmPhone: woData.clmPhone,
                ClmDateOfLoss: woData.clmDateOfLoss,
                ClmDateOfInjury: woData.clmDateOfInjury,
                ClmNote: woData.clmNote,
                ClmDOB: woData.clmDOB,
                ClmInsuredParty: woData.clmInsuredParty,
                ClmEmployer: woData.clmEmployer,
                ClmInvoiceCaption: woData.clmInvoiceCaption,
                ClmLanguage: woData.clmLanguage,
                ClmWCABNumber: woData.clmWCABNumber,
                ClmInvoiceType: woData.clmInvoiceType,
                ClmStreet: woData.clmStreet,
                ClmCity: woData.clmCity,
                ClmState: woData.clmState,
                ClmZip: woData.clmZip,
                ClmSSN: woData.clmSSN,
                ClmOrderedByName: woData.clmOrderedByName,
                ClmDiagnosis: woData.clmDiagnosis,
                ClmEmail: woData.clmEmail,
                RepID: woData.repID,
                RepFirstName: woData.repFirstName,
                RepLastName: woData.repLastName,
                RepPhone: woData.repPhone,
                RepEmail: woData.repEmail,
                ClientId: woData.clientId,
                ClientAbbrv: woData.clientAbbrv,
                ClientName: woData.clientName,
                ClientStreet: woData.clientStreet,
                ClientStreet2: woData.clientStreet2,
                ClientCity: woData.clientCity,
                ClientState: woData.clientState,
                ClientZip: woData.clientZip,
                ClientMailStreet: woData.clientMailStreet,
                ClientMailStreet2: woData.clientMailStreet2,
                ClientMailCity: woData.clientMailCity,
                ClientMailState: woData.clientMailState,
                ClientMailZip: woData.clientMailZip,
                ClientPhone: woData.clientPhone,
                ClientPhoneFax: woData.clientPhoneFax,
                ClientPhone2: woData.clientPhone2,
                ClientEmail: woData.clientEmail,
                ClientInactiveFlg: woData.clientInactiveFlg,
                IntId: woData.intId,
                IntLastName: woData.intLastName,
                IntFirstName: woData.intFirstName,
                IntCellTxt: woData.intCellTxt,
                IntPhoneOnly: woData.intPhoneOnly,
                IntEmail: woData.intEmail,
                IntNote: woData.intNote,
                InHouseflg: woData.inHouseflg,
                IntCity: woData.intCity,
                IntStreet: woData.intStreet,
                IntState: woData.intState,
                IntZip: woData.intZip,
                DBA: woData.dDBA,
                Rate: woData.rate,
                HourMinimum: woData.hourMinimum,
                Prorate: woData.prorate,
                BankRoute: woData.bankRoute,
                BankAccount: woData.bankAccount,
                IntSSINumber: woData.intSSINumber,
                FirstName: woData.firstName,
                LastName: woData.lastName,
                UserType: woData.userType,
                UserIntId: woData.userIntId,
                CreateDateTime: woData.createDateTime,
                CreateByUserId: woData.createByUserId,
                UpdateDateTime: woData.updateDateTime,
                UpdateByUserId: woData.updateByUserId,
                RemIntflg: woData.remIntflg,
                RemClaimflg: woData.remClaimflg,
                RemProvflg: woData.remProvflg,
                RemFinalflg: woData.remFinalflg,
                RemType: woData.remType,
                RemTypeDesc: woData.remTypeDesc,
                RemHost: woData.remHost,
                RemHostDesc: woData.remHostDesc,
                CreatedBy: woData.createdBy,
                UpdatedBy: woData.updatedBy,
                MyJobTJobHistory: woData.myJobTJobHistory,
                JobBalance: woData.jobBalance
            },
            selectedIndex: rowIndex,
            modalEndingTime: showModal
        }));
    }

    renderWorkOrderTableDefault(workOrders) {
        return (
            <Paper>
                <Table id="table-int" size="small" className="table-bordered table-striped" hover>
                    <TableHead>
                        <TableRow>
                            <TableCell width="70px">Final Conf</TableCell>
                            <TableCell width="70px">End Time</TableCell>
                            <TableCell width="70px">
                                <TableSortLabel
                                    active={this.state.searchModel.SortBy === "jobNum"}
                                    direction={this.state.searchModel.SortDirection}
                                    onClick={this.requestSort("jobNum")}
                                >Job#
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width="90px">
                                <TableSortLabel
                                    active={this.state.searchModel.SortBy === "jobDateTime"}
                                    direction={this.state.searchModel.SortDirection}
                                    onClick={this.requestSort("jobDateTime")}
                                >Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={this.state.searchModel.SortBy === "clmLastName"}
                                    direction={this.state.searchModel.SortDirection}
                                    onClick={this.requestSort("clmLastName")}
                                >Claimant
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Chk#</TableCell>
                            <TableCell>Chk Date</TableCell>
                            <TableCell>Fee</TableCell>
                            <TableCell>OT</TableCell>
                            <TableCell>OTH</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Address Info</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workOrders.map((workorder, i) =>
                            /*<tr key={workorder.jobId} onDoubleClick={() => { this.setData(workorder, i, true) }} className={this.state.selectedIndex === i ? "tableSelected" : ""}>*/
                            <tr key={workorder.jobId} className={this.state.selectedIndex === i ? "tableSelected" : ""}>
                                {/*<td className="x-grid-cell-inner">{workorder.remFinalflg === true ? "Yes" : "No"}</td>*/}
                                <td className="x-grid-cell-inner">{workorder.intConfflg === true ? null : <a href="#" onClick={() => { this.setData(workorder, i, true) }}>Final Conf</a>}</td>
                                {/*<td className="x-grid-cell-inner">{<a href="#" onClick={() => { this.setData(workorder, i, true) }}>Final Conf</a>}</td>*/}
                                <td className="x-grid-cell-inner">{workorder.finishTime === null ? workorder.intConfflg === false ? null : <a href="#" onClick={() => { this.handleEndintTimeClick(workorder, i, true) }}>End Time</a> : null}</td>
                                {/*<td className="x-grid-cell-inner">{<a href="#" onClick={() => { this.handleEndintTimeClick(workorder, i, true) }}>End Time</a>}</td>*/}
                                <td className="x-grid-cell-inner">{workorder.jobNum}</td>
                                <td className="x-grid-cell-inner"><span>{moment(workorder.jobDateTime).format('MM/DD/YYYY')}</span></td>
                                <td>{moment(workorder.jobDateTime).format('h:mm A')} {workorder.finishTime === null ? "" : " to " + moment(workorder.finishTime).format('h:mm A')} </td>
                                <td className="x-grid-cell-inner">{workorder.hasClaimantDupWO ? <span style={{ color: 'red' }}>{workorder.clmLastName}, {workorder.clmFirstName}</span> : workorder.clmLastName + ", " + workorder.clmFirstName}</td>
                                <td className="x-grid-cell-inner">{workorder.intCheckNum}</td>
                                <td className="x-grid-cell-inner">{workorder.intCheckDate === null ? "" : moment(workorder.intCheckDate).format('MM/DD/YYYY')}</td>
                                <td className="x-grid-cell-inner">{workorder.intFee === null ? null : "$" + workorder.intFee}</td>
                                <td className="x-grid-cell-inner">{workorder.intOTFee === null ? null : "$" + workorder.intOTFee}</td>
                                <td className="x-grid-cell-inner">{workorder.intFeeOther === null ? null : "$" + workorder.intFeeOther}</td>
                                <td className="x-grid-cell-inner">{workorder.jobTotal === null ? null : "$" + (workorder.intFee + workorder.intOTFee + workorder.intFeeOther)}</td>
                                <td>{workorder.aptStreet} <br /> {workorder.aptCity} {workorder.aptState} {workorder.aptZip}</td>
                            </tr>
                        )}
                    </TableBody>
                </Table>                    
            </Paper>
        );
    }

    renderWorkOrderTableSmallSize(workOrders) {
        return (
            <Paper>
                <Table id="table-int2" size="small" className="table-bordered table-striped d-md-none" hover>
                    <TableBody>
                        {workOrders.map((workorder, i) =>
                            <Fragment>
                                <tr style={{ backgroundColor: '#f7ac81' }}>
                                    <td className="x-grid-cell-inner">ID: {workorder.jobNum}</td>
                                    <td className="x-grid-cell-inner">
                                        {workorder.intConfflg === true ? null :
                                            <a href="#" onClick={() => { this.setData(workorder, i, true) }}>Final Conf</a>
                                        }
                                        {workorder.finishTime === null ? workorder.intConfflg === false ? null : <a href="#" onClick={() => { this.handleEndintTimeClick(workorder, i, true) }}>End Time</a> : null}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="x-grid-cell-inner"><b>Date:</b> <span>{moment(workorder.jobDateTime).format('MM/DD/YYYY') + ' '} {workorder.jobDateTime === null ? "" : moment(workorder.jobDateTime).format('hh:mm A')}</span></td>
                                </tr>
                                <tr>
                                    <td className="x-grid-cell-inner" colSpan={2}><b>Claimant:</b> {workorder.hasClaimantDupWO ? <span style={{ color: 'red' }}>{workorder.clmLastName}, {workorder.clmFirstName}</span> : workorder.clmLastName + ", " + workorder.clmFirstName}</td>
                                </tr>
                                <tr>
                                    <td className="x-grid-cell-inner"><b>Chk#:</b> {workorder.intCheckNum}</td>
                                    <td className="x-grid-cell-inner"><b>Chk Date:</b> {workorder.intCheckDate === null ? "" : moment(workorder.intCheckDate).format('MM/DD/YYYY')}</td>
                                </tr>
                                <tr>
                                    <td className="x-grid-cell-inner"><b>Fee:</b> {workorder.intFee === null ? null : "$" + workorder.intFee}</td>
                                    <td className="x-grid-cell-inner"><b>OT:</b> {workorder.intOTFee === null ? null : "$" + workorder.intOTFee}</td>
                                </tr>
                                <tr>
                                    <td className="x-grid-cell-inner"><b>OTH:</b> {workorder.intFeeOther === null ? null : "$" + workorder.intFeeOther}</td>
                                    <td className="x-grid-cell-inner"><b>Total:</b> {workorder.jobTotal === null ? null : "$" + workorder.jobTotal}</td>
                                </tr>
                                <tr>
                                    <td className="x-grid-cell-inner" colSpan={2}>
                                        <b>Address:</b> {workorder.aptStreet} {workorder.aptCity} {workorder.aptState} {workorder.aptZip}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                            </Fragment>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        );
    }

    async exportPDF() {
        let jobNumsList = [];
        let body = {
            JobNumbers: ""
        };

        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobNumsList.push(item.jobNum);
            }
        });
        body.JobNumbers = jobNumsList.join();

        if (body.JobNumbers === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please check any work order",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message))
            });
        }
        else {
            await FetchFile("/getinvoices", "POST", this.user.token, body).then(blob => {
                saveAs(blob, "exportpdf.pdf");
            });
        }
    }

    async exportPurpleReport() {
        let body = {
            ReportDate: "2021/07/01"
        };

        if (body === null) {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please select a start date for the report",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message))
            });
        }
        else {
            await FetchFile("/rptPurple", "POST", this.user.token, body).then(blob => {
                saveAs(blob, "purplepdf.pdf");
            });
        }
    }

    async populateWorkOrderData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel }
        model.StartDate = model.StartDate === "" || model.StartDate === null ? null : moment(model.StartDate).format('YYYY-MM-DD');
        model.EndDate = model.EndDate === "" || model.EndDate === null ? null : moment(model.EndDate).format('YYYY-MM-DD');
        model.MyJobs = this.props.isMyJob;
        model.IntId = this.user.intid;

        if (this.props.isMyJob)
            model.CreatedByUserId = this.user.userid;

        if (this.props.isMyJob) {
            this.setState({ searchModel: model }, () => {
                FetchData("/getmyjobsint?userId=" + this.user.userid, "POST", this.user.token, null).then(data => {
                    this.setState({ workOrderList: data, loading: false, fetchingData: false });
                });
            });
        } else {
            this.setState({ searchModel: model }, () => {
                FetchData("/getworkordersint", "POST", this.user.token, model).then(data => {
                    this.setState({ workOrderList: data, loading: false, fetchingData: false });
                });
            });
        }

        this.resetChecks();
        //await FetchData("/getworkordersint", "POST", this.user.token, this.state.searchModel).then(data => {
        //    this.setState({ workOrderList: data, loading: false, fetchingData: false });
        //});
    }

    async openWorkOrderForm(jobId) {
        FetchData("/getworkorderint?jobId=" + jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : data.intFee, //parseFloat(woData.intFee).toFixed(2),
                    IntFeeOther: data.intFeeOther === null ? null : data.intFeeOther, //parseFloat(woData.intFeeOther).toFixed(2),
                    IntOTFee: data.intOTFee === null ? null : data.intOTFe, //parseFloat(woData.intOTFee).toFixed(2),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.intCellTxt,
                    IntPhoneOnly: data.intPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseflg: data.inHouseflg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    JobBalance: data.jobBalance
                },
                selectedIndex: 0,
                modal: GetParameterByName('IntEndTime') === null ? true : false,
                modalEndingTime: GetParameterByName('IntEndTime') !== null ? true : false
            }));
        });
    }

    async populateWorkOrderPendingData() {
        this.setState({ fetchingData: true });

        let model = {};
        model.StartDate = this.state.searchModel.StartDate === "" || this.state.searchModel.StartDate === null ? null : moment(this.state.searchModel.StartDate).format('YYYY-MM-DD');
        model.EndDate = this.state.searchModel.EndDate === "" || this.state.searchModel.EndDate === null ? null : moment(this.state.searchModel.EndDate).format('YYYY-MM-DD');

        FetchData("/getpendingworkorders", "POST", this.user.token, model).then(data => {
            this.setState({ workOrderList: data, loading: false, fetchingData: false, tableView: 'pending' });
        });
    }

    async populateExecutiveUsers() {
        var model = {
            UserName: 'executive'
        };
        this.setState({ searchModel: model }, () => {
            FetchData("/getusers", "POST", this.user.token, model).then(data => {
                this.setState({ executiveUsers: data });
            });
        });

        this.resetChecks();
        //await FetchData("/getworkorders", "POST", this.user.token, this.state.searchModel).then(data => {
        //    this.setState({ workOrderList: data, loading: false, fetchingData: false });
        //});
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p> : this.renderWorkOrderTableDefault(this.state.workOrderList);
        let contents2 = this.state.loading
            ? <p><em>Loading...</em></p> : this.renderWorkOrderTableSmallSize(this.state.workOrderList);

        return (
            <div style={{ height: '850px', paddingTop: "12px", overflow: 'scroll' }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <Row hidden>
                    <Col>
                        <Button color="btn" onClick={this.handlePendingClick} className="btn btn-secondary" size="sm">Pending </Button><span style={{ paddingRight: "4px" }}></span>
                        <Button color="btn" onClick={this.handleContractClick} className="btn btn-secondary" size="sm">Contract </Button><span style={{ paddingRight: "4px" }}></span>
                        <Button color="btn" onClick={this.handleAuditClick} className="btn btn-secondary" size="sm">Audit </Button><span style={{ paddingRight: "4px" }}></span>
                        <Button color="btn" onClick={this.handlePiggyBack} className="btn btn-secondary" size="sm">PiggyBack</Button><span style={{ paddingRight: "4px" }}></span>
                        <Button color="btn" onClick={this.handleAutoPay} className="btn btn-secondary" size="sm">AutoPay</Button><span style={{ paddingRight: "4px" }}></span>
                    </Col>
                </Row>
                <Row hidden>
                    <Col className="col-10">
                        <InputGroup size="sm">
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" hidden /></Button>
                            <Button color="btn" onClick={this.refreshSearch} size="sm"><img src={RefreshIcon} style={{ width: "16px", height: "16px" }} alt="Refresh" /></Button>
                            <Button color="btn" onClick={() => { this.handlePrevNextButton(-1) }} size="sm"><img src={Previous} style={{ width: "16px", height: "16px" }} alt="Prev" /></Button>
                            <Button color="btn" onClick={() => { this.handlePrevNextButton(1) }} size="sm"><img src={Next} style={{ width: "16px", height: "16px" }} alt="Next" /></Button>
                            <input name="MultiFilter" type="checkbox" defaultChecked={this.state.multiFilter} onChange={() => { this.setState({ multiFilter: !this.state.multiFilter }); }} /> <span style={{ paddingRight: "4px" }}></span>
                            <input name="JobNum" type="number" className="form-control form-control-text" value={this.state.searchModel.JobNum} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Job #" onKeyPress={this.handleKeyPress} />
                            <input name="StartDate" ref={ref => this.txtStartDate = ref} type="date" className="form-control form-control-text" style={{ width: "50px", height: "26px" }} value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Start Date" onKeyPress={this.handleKeyPress} />
                            <input name="EndDate" type="date" className="form-control form-control-text" style={{ width: "50px", height: "26px" }} value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="End Date" onKeyPress={this.handleKeyPress} />
                            <input name="Claimant" type="text" className="form-control form-control-text" value={this.state.searchModel.Claimant} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant" onKeyPress={this.handleKeyPress} />
                            <input name="ClaimantNum" type="text" className="form-control form-control-text" value={this.state.searchModel.ClaimantNum} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant #" onKeyPress={this.handleKeyPress} />
                            <input name="CLMPhone" type="text" className="form-control form-control-text" value={this.state.searchModel.CLMPhone} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="CLM Phone" onKeyPress={this.handleKeyPress} />
                            <input name="Interpreter" type="text" className="form-control form-control-text" value={this.state.searchModel.Interpreter} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Interpreter" onKeyPress={this.handleKeyPress} />
                            <input name="Rep" type="text" className="form-control form-control-text" value={this.state.searchModel.Rep} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Rep" onKeyPress={this.handleKeyPress} />
                            <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                            <input name="ProviderName" type="text" className="form-control form-control-text" value={this.state.searchModel.ProviderName} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Job Provider" onKeyPress={this.handleKeyPress} />
                            {this.props.isMyJob && <span style={{ padding: "6px" }}>User: </span>}
                            {this.props.isMyJob &&
                                <select name="MyJobsUser" className="form-control form-control-text" value={this.state.searchModel.MyJobsUser} onChange={this.handleSearchChange} size="sm">
                                    <option value="" selected></option>
                                    {this.state.executiveUsers.map((usr, i) =>
                                        <option value={usr.userID}>{usr.firstName} {usr.lastName}</option>
                                    )}
                                </select>
                            }
                        </InputGroup>
                    </Col>
                </Row>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <ReactTooltip id="rtp" place="right" type="dark" effect="solid" html={true} />
                <div className="d-none d-md-block d-lg-none d-none d-lg-block d-xl-none d-none d-xl-block ">
                    {contents}
                </div>
                <div className="d-md-none">
                    {contents2}
                </div>

                {this.state.modal &&
                    <WorkOrderIntForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} workOrderList={this.state.workOrderList} jobHistoryList={this.state.jobHistoryList} jobInvLineList={this.state.jobInvLineList} setNextPrevData={this.setNextPrevData} selectedIndex={this.state.selectedIndex} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                {this.state.modalEndingTime &&
                    <WorkOrderIntFormEndTime model={this.state.model} modal={this.state.modalEndingTime} alert={this.state.alert} setModal={this.setModalEndingTime} handleSaveEventSuccess={this.handleSaveEventIntEndTimeSuccess} />
                }

                {this.state.modalEndTimeComment &&
                    <WorkOrderIntFormEndTimeComment jobmodel={this.state.model} modal={this.state.modalEndTimeComment} alert={this.state.alert} setModal={this.setModalEndTimeComment} handleSaveEventSuccess={this.handleSaveEventSuccessComment} />
                }

                {/*{this.state.modalClient &&*/}
                {/*    <ClientForm model={this.state.client} modal={this.state.modalClient} alert={this.state.alert} setModal={this.setModalClient} handleSaveEventSuccess={this.handleSaveEventClientSuccess} />*/}
                {/*}*/}

                {this.state.modalIntFormEmail &&
                    <InterpreterFormEmail model={this.state.email} modal={this.state.modalIntFormEmail} alert={this.state.alert} setModal={this.setModalIntFormEmail} handleSaveEventSuccess={this.handleSaveEventIntEmailSuccess} />
                }

                {this.state.modalRepFormEmail &&
                    <RepFormEmail model={this.state.email} modal={this.state.modalRepFormEmail} alert={this.state.alert} setModal={this.setModalRepFormEmail} handleSaveEventSuccess={this.handleSaveEventRepEmailSuccess} />
                }

                {this.state.modalWOJobNotes &&
                    <WorkOrderJobNotes receivableList={this.state.receivableList} receivableItemList={this.state.receivableItemList} modal={this.state.modalWOJobNotes} alert={this.state.alert} setModal={this.setModalWOJobNotes} />
                }

                {this.state.modalEmailConfirm &&
                    <WorkOrderIntConfNo modal={this.state.modalEmailConfirm} alert={this.state.alert} setModal={this.setModalEmailConfirm} notify_type={this.state.emailConfirm.notify_type} JobId={this.state.emailConfirm.JobId} IntId={this.state.emailConfirm.IntId} />
                }
            </div>
        );

    }
}
