import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, InputGroup, Row, Col, Table } from 'reactstrap';
import { RepFormSearchTableDefault } from './RepFormSearchTableDefault';

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

export class RepFormSearch extends Component {
    static displayName = RepFormSearch.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            repList: [],
            searchModel: {
                RepName: props.RepName === null ? "" : props.RepName,
                ClientAbbrv: ''
            }
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);

        if (props.RepName !== null && props.RepName !== '')
            this.populateRepData();
    }

    handleChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRepData();
        }
    }

    async populateRepData() {
        await FetchData("/getreps", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ repList: data, loading: false });
        });
    }

    renderRepTable(repList){
        return(
            <RepFormSearchTableDefault
                repList={repList}
                handleDoubleClick={this.props.handleDoubleClick}
            ></RepFormSearchTableDefault>
        )
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1000px", maxHeight: "500px" }} backdrop="static">
                <ModalHeader><span style={{ fontSize: "14px" }}>Search Rep</span></ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <input name="RepName" type="text" className="form-control form-control-text" value={this.state.searchModel.RepName} onChange={this.handleChange} placeholder="Search Rep Name (Lastname, Firstname)" onKeyPress={this.handleKeyPress} />
                                    <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleChange} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className=" x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: "scroll", height: "450px", backgroundColor: 'unset', width: '100%', marginLeft: '8px', marginRight: '10px' }}>
                                {this.renderRepTable(this.state.repList)}
                            </Col>
                        </Row>
                    </div>
                </ModalBody>               
            </Modal>
        )
    }
}