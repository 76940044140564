/* 
    Handles the form created by clicking 'New Item' button on the ClaimantForm
*/

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';
import DeleteIcon from '../images/delete.png'


export class ClaimantItemForm extends Component {
    static displayName = ClaimantItemForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model
        };
        //
        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    handleUploadFile(e) {

        let model = { ...this.state.model }
        model.FileName = e.target.files[0].name;
        model.FormFile = e.target.files[0];

        this.setState({ model });
    }

    handleDeleteFile() {
        this.fileUpload.value = null;

        let model = { ...this.state.model }
        model.FileName = '';
        model.FileLocation = '';

        this.setState({ model });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    async onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        var formData = new FormData();
        formData.append("EventType", model.EventType);
        formData.append("ClaimantItemId", model.ClaimantItemId);
        formData.append("ClmId", model.ClmId);
        formData.append("ItemName", model.ItemName);
        formData.append("Description", model.Description);
        //formData.append("Contact", model.Contact);
        //formData.append("ContactEmail", model.ContactEmail);
        formData.append("FileLocation", model.FileLocation);
        formData.append("FileName", model.FileName);
        formData.append("ClaimantItemType", model.ClaimantItemType);
        formData.append("CreateDateTime", model.CreateDateTime);
        formData.append("CreateByUserId", model.CreateByUserId);
        formData.append("UpdateByUserId", model.UpdateByUserId);
        formData.append("UpdateDateTime", model.UpdateDateTime);
        formData.append("IsDeletedFlg", model.IsDeletedFlg);
        formData.append("FormFile", model.FormFile);

        UploadFile("/saveclaimantitem", this.user.token, formData).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning",
                    model: model
                })
            } else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "warning"
                })
            }
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "150px", width: "500px" }} backdrop="static">
                <ModalHeader>New/Edit Claimant Item</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.props.alert.color} isOpen={this.props.alert.isOpen} >
                                    {this.props.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "70px" }}>
                                        Type:
                                    </InputGroupText>
                                    <select name="ClaimantItemType" value={this.state.model.ClaimantItemType} className="form-control" onChange={this.handleChange} disabled={this.state.model.ClaimantItemId === null ? false : true}>
                                        {/*<option value="" selected></option>*/}
                                        <option value="Note">Note</option>
                                        <option value="Attachment">Attachment</option>
                                    </select>
                                </InputGroup>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText>
                                        Description:
                                    </InputGroupText>
                                    <textarea name="Description" value={this.state.model.Description} className="form-control" onChange={this.handleChange}></textarea>
                                </InputGroup>
                            </Col>
                        </Row>
                        {this.state.model.ClaimantItemType === "Attachment" && <hr />}
                        {this.state.model.ClaimantItemType === "Attachment" && <Row>
                            <Col className={this.state.model.FileName === '' ? "col-12" : "col-11"}>
                                <input id="file" type="file" name="fileUpload" className="form-control" onChange={this.handleUploadFile} ref={ref => this.fileUpload = ref} />
                                <span style={{ left: '20px', top: '50px', position: 'absolute' }} >{this.state.model.FileName === "" ? "Drag your file here!" : this.state.model.FileName}</span>
                            </Col>
                            <Col className="col-1">
                                <img src={DeleteIcon} style={{ width: "14px", height: "14px", cursor: 'pointer', display: this.state.model.FileName === '' ? 'none' : null }} onClick={this.handleDeleteFile} alt="Delete" />
                            </Col>
                            {/*{this.state.model.ClaimantItemType === "Attachment" && <Row>*/}
                            {/*    <Col>*/}
                            {/*        <InputGroup size="sm">*/}
                            {/*            <InputGroupText>*/}
                            {/*                Attachment:*/}
                            {/*            </InputGroupText>*/}
                            {/*            <input name="fileAttachment" type="file" className="form-control" onChange={this.handleUploadFile} />*/}
                            {/*        </InputGroup>*/}
                            {/*    </Col>*/}
                            {/*</Row>}*/}
                        </Row>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                    <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                    {/*<Button color="primary" onClick={() => { if (window.confirm("Are you sure to save this record?")) { this.onSave() } }} size="sm">Save</Button>*/}
                </ModalFooter>
            </Modal>
        )
    }

}