import React, { Component, Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Input, Row, Col, Alert } from 'reactstrap';

import ReactTooltip from "react-tooltip";

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import { WorkOrderFollowUpForm } from './WorkOrderFollowUpForm';
import { WorkOrderFollowUpTableDefault } from './WorkOrderFollowUpTableDefault';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

import DeleteIcon from '../images/delete.png';

const moment = require('moment');

export class WorkOrderFollowUp extends Component {
    static displayName = WorkOrderFollowUp.name;
    constructor(props) {
        super(props);
        this.state = {
            workOrders: [],
            loading: false,
            modal: false,

            model: this.props.model,
            alert: this.props.alert,
            sortModel: {
                SortBy: 'jobDateTime',
                SortDirection: 'desc'
            }
        }
        //
        this.user = JSON.parse(GetCookie('user'));

        this.setModal = this.setModal.bind(this);
        this.populateFollowUps = this.populateFollowUps.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
    }

    componentDidMount() {
        this.populateFollowUps();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    setModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    populateFollowUps() {
        var model = {
            StartDate: moment(this.state.model.JobDateTime).add(1, 'days').format('YYYY-MM-DD'),
            EndDate: "2100-01-01",
            ClmId: this.state.model.ClmId
        };
        FetchData("/getworkorders", "POST", this.user.token, model).then(data => {
            this.setState({ workOrders: data, loading: false, fetchingData: false });
        });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateFollowUps();
            this.props.handleSaveEventSuccess(alert);
        });
    }

    renderFollowUpTable(workOrders) {
        return (
            <WorkOrderFollowUpTableDefault workOrders={workOrders} />
        );
    }

    render() {
        let followUpContents = this.state.loading
        ? <p><em>Loading...</em></p>
        : this.renderFollowUpTable(this.state.workOrders);

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "460px", width: "1280px" }} backdrop="static">
                    <ModalHeader>
                        Followup Popup
                    </ModalHeader>
                    <ModalBody style={{ height: "500px" }}>
                        <Row>
                            <Col>
                                <h4>Jobs</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <ReactTooltip className="tooltip" id="rtp" place="top" type="dark" effect="solid" html={true} />
                        <Row>
                            <Col>
                                {followUpContents}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-secondary-no-background form-control-text" onClick={this.setModal}>Add Followup</Button>
                    </ModalFooter>
                </Modal>

                {this.state.modal &&
                    <WorkOrderFollowUpForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }
            </div>
        );
    }
}