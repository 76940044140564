import React, { Component } from 'react';
import { Button, Row, Col, Alert, InputGroup, InputGroupText } from 'reactstrap';

import { FetchData } from '../utils/DataFetch';
import { GetCookie, Timeout } from '../utils/Common';
import { RptClientSummaryTableDefault } from './RptClientSummaryTableDefault';

export class RptClientSummary extends Component {
    static displayName = RptClientSummary.name;

    constructor(props) {
        super(props);
        this.state = {
            rptClientSummaryList: [],
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                ClientAbbrv: '',
                StartDate: '',
                EndDate: ''
            }
        };
        this.user = JSON.parse(GetCookie('user'));

        this.renderRptClientSummary = this.renderRptClientSummary.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateClientSummaryReportData();
        }
    }


    async populateClientSummaryReportData() { 
        this.setState({ fetchingData: true });

        FetchData("/getrptclientsummary", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ rptClientSummaryList: data, loading: false, fetchingData: false });
        });


    }

    renderRptClientSummary(rptClientSummary) {
        return (
            <RptClientSummaryTableDefault
                rptClientSummary={this.state.rptClientSummaryList}
            ></RptClientSummaryTableDefault>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRptClientSummary(this.state.rptClientSummaryList);


        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Client Summary Report</h5>
                <Row>
                    <Col className="col-4">
                        <InputGroup size="sm">
                            <input name="ClientAbbrv" ref={(input) => { this.ClientAbbrv = input; }} type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                            <InputGroupText>
                                Start Date
                            </InputGroupText>
                            <input name="StartDate" ref={ref => this.txtStartDate = ref} type="date" className="form-control form-control-text" value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Start Date" onKeyPress={this.handleKeyPress} />
                            <InputGroupText>
                                End Date
                            </InputGroupText>
                           <input name="EndDate" ref={(input) => { this.EndDate = input; }} type="date" className="form-control form-control-text"  value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="End Date" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />



                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
