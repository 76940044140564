import React, { Component } from 'react';
import { Button, Row, Col, Alert, InputGroup } from 'reactstrap';

import { TimeOffForm } from './TimeOffForm';
import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png';

import { TimeOffTableDefault } from './TimeOffTableDefault';

export class TimeOff extends Component {
    static displayName = TimeOff.name;

    constructor(props) {
        super(props);
        this.state = {
            timeOffList: [],
            dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            loading: false,
            fetchingData: false,
            modal: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                UserTimeOffId: null,
                LastName: '',
                FirstName: '',
                FullName: '',
                Status: '',
                Type: '',
                CalendarDate: null,
                Day: '',
                PartialDayFlg: false,
                Hours: 1,
                Notes: '',
                FromDate: null,
                ToDate: null,
                UserId: null,
                StatusId: null,
                TypeId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
            },
            searchModel: {
                From: null,
                To: null,
                Name: '',
                SortBy: 'calendarDate',
                SortDirection: 'desc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.setModal = this.setModal.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderTimeOffTable = this.renderTimeOffTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
        this.sortData = this.sortData.bind(this);
        this.requestSort = this.requestSort.bind(this);
    }

    componentDidMount() {
        var currenYear = new Date().getFullYear();
        this.setState({
            searchModel: {
                From: currenYear + "-01-01",
                To: currenYear + "-12-31",
                Name: ''
            }
        }, () => {
            //console.log(this.state.searchModel);
            this.populateTimeOffData();
        });
    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    resetFields() {
        this.setState(prevstate => ({
            model: {
                EventType: 'insert',
                UserTimeOffId: null,
                LastName: '',
                FirstName: '',
                FullName: '',
                Status: '',
                Type: '',
                CalendarDate: null,
                Day: '',
                PartialDayFlg: false,
                Hours: 1,
                Notes: '',
                FromDate: null,
                ToDate: null,
                UserId: null,
                StatusId: null,
                TypeId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
            }
        }));

        this.setModal();
    }

    handleEdit(timeOffData) {
        this.setState(prevState => ({
            model: {
                EventType: 'update',
                UserTimeOffId: timeOffData.userTimeOffId,
                LastName: timeOffData.lastName,
                FirstName: timeOffData.firstName,
                FullName: timeOffData.firstName + ' ' + timeOffData.LastName,
                Status: timeOffData.status,
                Type: timeOffData.type,
                CalendarDate: timeOffData.calendarDate,
                Day: timeOffData.day,
                PartialDayFlg: timeOffData.partialDayFlg,
                Hours: timeOffData.hours,
                Notes: timeOffData.notes,
                FromDate: timeOffData.fromDate,
                ToDate: timeOffData.toDate,
                UserId: timeOffData.userId,
                StatusId: timeOffData.statusId,
                TypeId: timeOffData.typeId,
                CreateDateTime: timeOffData.createDateTime,
                CreateByUserId: timeOffData.createByUserId,
                UpdateByUserId: timeOffData.updateByUserId,
                UpdateDateTime: timeOffData.updateDateTime,
                IsDeletedFlg: false,
            }
        }))
        this.setModal();
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateTimeOffData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            let searchModel = this.state.searchModel;
            if (searchModel.From === '' && searchModel.To === '' && searchModel.Name === '') {
                this.handleSaveEventSuccess({
                    message: "Please select a date range and/or a Name.",
                    modal: false,
                    color: "warning"
                });
                var currenYear = new Date().getFullYear();
                this.setState({
                    searchModel: {
                        From: currenYear + "-01-01",
                        To: currenYear + "-12-31",
                        Name: ''
                    }
                });
            } else {
                this.populateTimeOffData();
            }
        }
    }

    sortData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.timeOffList;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }

    requestSort(pSortBy) {
        var sortBy = this.state.searchModel.SortBy;
        var sortOrder = this.state.searchModel.SortDirection;
        return event => {
            if (pSortBy === this.state.searchModel.SortBy) {
                sortOrder = sortOrder === "asc" ? "desc" : "asc";
            } else {
                sortBy = pSortBy;
                sortOrder = "asc";
            }
            var sortedItems = this.sortData(sortBy, sortOrder);
            this.setState({
                searchModel: {
                    SortBy: sortBy,
                    SortDirection: sortOrder
                },
                timeOffList: sortedItems
            });
        };
    }

    async populateTimeOffData() {
        this.setState({ fetchingData: true });
        let searchModel = this.state.searchModel;
        
        FetchData("/gettimeoff", "POST", this.user.token, searchModel).then(data => {
            this.setState({ timeOffList: data, loading: false, fetchingData: false });
        });
    }

    renderTimeOffTable(timeOffs) {

        return (
            <TimeOffTableDefault
                state={this.state}
                timeOffs={this.state.timeOffList}
                handleEdit={this.handleEdit}
            ></TimeOffTableDefault>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTimeOffTable(this.state.timeOffList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Time Off</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                            <input name="From" type="date" className="form-control form-control-text" value={this.state.searchModel.From} onChange={this.handleSearchChange} onKeyPress={this.handleKeyPress} />
                            <input name="To" type="date" className="form-control form-control-text" value={this.state.searchModel.To} onChange={this.handleSearchChange} onKeyPress={this.handleKeyPress} />
                            <input name="Name" type="text" className="form-control form-control-text" value={this.state.searchModel.Name} onChange={this.handleSearchChange} placeholder="Name" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <TimeOffForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
