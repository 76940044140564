import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, InputGroup, InputGroupText, Row, Col, Alert, TabContent, Table, TabPane } from 'reactstrap';

import { UploadFile, FetchData } from './utils/DataFetch';
import { GetParameterByName, GetCookie, RemoveCookie, Timeout } from './utils/Common';

import Logo from '../images/logo1.png'

import SaveIcon from '../images/save-icon.png'
import RemoveIcon from '../images/remove-icon.png'

const moment = require('moment');

export class WorkOrderIntFormEndTimeComment extends Component {
    static displayName = WorkOrderIntFormEndTimeComment.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            alert: props.alert,

            jobmodel: props.jobmodel,
            model: {
                EventType: 'insert',
                JobHistoryId: null,
                JobId: props.jobmodel.JobId,
                JobHistoryName: '',
                Description: '',
                Contact: '',
                FileLocation: '',
                FileName: '',
                JobHistoryType: 'Int Finish',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                FormFile: null
            }
        };

        //
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        //
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
    }

    componentDidMount() {

    }

    handleSubmitClick() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } 

        var formData = new FormData();
        formData.append("EventType", model.EventType);
        formData.append("JobHistoryId", model.JobHistoryId);
        formData.append("JobId", model.JobId);
        formData.append("JobHistoryName", model.JobHistoryName);
        formData.append("Description", "Comment: "  + model.Description);
        formData.append("Contact", model.Contact);
        formData.append("ContactEmail", model.ContactEmail);
        formData.append("FileLocation", model.FileLocation);
        formData.append("FileName", model.FileName);
        formData.append("JobHistoryType", model.JobHistoryType);
        formData.append("CreateDateTime", model.CreateDateTime);
        formData.append("CreateByUserId", model.CreateByUserId);
        formData.append("UpdateByUserId", model.UpdateByUserId);
        formData.append("UpdateDateTime", model.UpdateDateTime);
        formData.append("FormFile", model.FormFile);

        UploadFile("/savejobhistory", this.user.token, formData).then(data => {
            this.props.handleSaveEventSuccess({
                message: data,
                modal: data === 'Success' ? false : true,
                color: data === 'Success' ? "success" : "warning",
                model: model
            });
        });
    }

    handleFileChange(e) {
        let model = { ...this.state.model }
        model.FileName = e.target.files[0].name;
        model.FormFile = e.target.files[0];

        this.setState({ model });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model: model }, () => {

        });
    }


    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "300px", width: "400px" }}>
                    <ModalHeader>Comment</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        {this.state.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center><img src={Logo} alt="Logo" /></center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <p style={{ color: 'red', fontWeight: 'bold' }}>
                                            Please Call 800-522-2320 if you have any questions.
                                        </p>
                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <textarea name="Description" className="form-control" placeholder="Enter comment (mileage, parking, etc.)" onChange={this.handleChange}></textarea>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <p>
                                            <small>Upload File or Photo</small> <br/>
                                            <input name="fileUpload" type="file" className="form-control" ref={ref => this.fileUpload = ref} onChange={this.handleFileChange}></input>
                                        </p>
                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <Button color={"success"} size="sm" disabled={this.state.model.Description === '' ? "disabled" : null} onClick={this.handleSubmitClick}> Submit</Button>
                                        <Button color={"danger"} onClick={this.props.setModal} size="sm"> Cancel</Button>
                                    </center>
                                </Col>
                            </Row>
                            <hr />
                        </div>
                    </ModalBody>
                    {/*<ModalFooter>*/}

                    {/*</ModalFooter>*/}
                </Modal>
            </div>
        );

    }
}
