import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

const moment = require('moment');

export class PostChecksAutoBulk extends Component {
    static displayName = PostChecksAutoBulk.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            alert: this.props.alert,
            model: {
                CheckDate: null,
                CheckNumber: '',
                Amount: null,
                Invoices: ''
            }
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
    }

    onSave() {
        let model = { ...this.state.model };

        console.log(model);

        if (this.state.model.CheckDate === null || this.state.model.CheckDate === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please choose Check Date.',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });

        } else if (this.state.model.CheckNumber === null || this.state.model.CheckNumber === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please enter Check Number',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });
        } else if (this.state.model.Amount === null) {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please enter Amount',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });
        } else if (this.state.model.Invoices === null || this.state.model.Invoices === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please enter Invoices',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });
        } else {
            model.CreateByUserId = this.user.userid;
            FetchData("/PostCheckAutoBulk", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.setState({
                        alert: {
                            color: "warning",
                            message: data,
                            isOpen: true
                        }
                    }, () => {
                        window.setTimeout(() => {
                            this.setState({ alert: { isOpen: false } });
                        }, Timeout(alert.message));
                    });
                }
            });
        }
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "number")
            model[e.target.name] = e.target.value === null || e.target.value === "" ? null : parseFloat(e.target.value);
        else if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;       
        else
            model[e.target.name] = e.target.value;

        this.setState({ model: model });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "700px" }} backdrop="static">
                <ModalHeader>Auto Bulk</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    <center>{this.state.alert.message}</center>
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Check Date:
                                    </InputGroupText>
                                    <input type="date" name="CheckDate" className="form-control form-control-text" value={this.state.model.CheckDate} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Check Number:
                                    </InputGroupText>
                                    <input type="text" name="CheckNumber" className="form-control form-control-text" value={this.state.model.CheckNumber} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Amount:
                                    </InputGroupText>
                                    <input type="number" name="Amount" className="form-control form-control-text" step="any" value={this.state.model.Amount} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Invoices:
                                    </InputGroupText>
                                    <textarea name="Invoices" cols={10} className="form-control form-control-text" value={this.state.model.Invoices} onChange={this.handleChange} ></textarea>
                                </InputGroup>
                            </Col>
                        </Row>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                    <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                </ModalFooter>
            </Modal>
        )
    }
}