import Cookies from 'js-cookie';

export async function CreateAsUserIfNotExist(token, body) {
    const response = await fetch('/createasuserifnotexist', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();

    if (data === "Unauthorized") {
        // alert('Your session is expired. You need to re-login.');
        Cookies.remove('user');
        window.location.reload();
    } else {
        return data;
    }
}

export function GetParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function GetCookie(name) {
    return Cookies.get(name);
}

export function RemoveCookie(name) {
    return Cookies.remove(name);
}

export function Timeout(alertMessage) {
    if (alertMessage === "Success") {
        return 500;
    } else {
        return 4000;
    }
}
