/* 
    Handles the form created by clicking 'New Job Note' button on the WorkOrderForm
*/

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DeleteIcon from '../images/delete.png'

export class WorkOrderNoteForm extends Component {
    static displayName = WorkOrderNoteForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,
            alert: props.alert
        };
        //
        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
        return () => {
            document.removeEventListener('keydown', this.keydownHandler);
        };
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    handleFileChange(e) {
        let model = { ...this.state.model }
        model.FileName = e.target.files[0].name;
        model.FormFile = e.target.files[0];

        this.setState({ model });
    }

    handleDeleteFile() {
        this.fileUpload.value = null;

        let model = { ...this.state.model }
        model.FileName = '';
        model.FileLocation = '';

        this.setState({ model });
    }

    async keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) // ALT+S
        {
            this.onSave();
        }
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    onSave() {
        let model = { ...this.state.model };

        if (model.EventType === "insert") {
            model.JobHistoryId = null;
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        var formData = new FormData();
        formData.append("EventType", model.EventType);
        formData.append("JobHistoryId", model.JobHistoryId);
        formData.append("JobId", model.JobId);
        formData.append("JobHistoryName", model.JobHistoryName);
        formData.append("Description", model.Description);
        formData.append("Contact", model.Contact);
        formData.append("ContactEmail", model.ContactEmail);
        formData.append("FileLocation", model.FileLocation);
        formData.append("FileName", model.FileName);
        formData.append("JobHistoryType", model.JobHistoryType);
        formData.append("CreateDateTime", model.CreateDateTime);
        formData.append("CreateByUserId", model.CreateByUserId);
        formData.append("UpdateByUserId", model.UpdateByUserId);
        formData.append("UpdateDateTime", model.UpdateDateTime);
        //formData.append("IsDeletedFlg", model.IsDeletedFlg);
        formData.append("FormFile", model.FormFile);

        UploadFile("/savejobhistory", this.user.token, formData).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning",
                    model: model
                })
            } else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "warning"
                })
            }           
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "150px", width: "500px" }} backdrop="static">
                <ModalHeader>New/Edit Job History</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "70px" }}>
                                        Type:
                                    </InputGroupText>
                                    <select name="JobHistoryType" value={this.state.model.JobHistoryType} className="form-control" onChange={this.handleChange} disabled={this.state.model?.JobHistoryId !== null && (this.state.model.JobHistoryType !== "Int Communication" && this.state.model.JobHistoryType !== "Job Note" && this.state.model.JobHistoryType !== "My Job")}>
                                        <option value="Attachment">Attachment</option>
                                        <option value="To Auth Dept">To Auth Dept</option>
                                        <option value="To Booking Dept">To Booking Dept</option>
                                        <option value="To Conf Dept">To Conf Dept</option>
                                        <option value="Job Note">Job Note</option>
                                        <option value="Authorization">Authorization</option>
                                        <option value="My Job">My Job</option>
                                        <option value="Claimant Confirmed">Claimant Confirmed</option>
                                        <option value="Int Communication">Int Communication</option>
                                        <option value="Int Final Conf">Int Final Conf</option>
                                        <option value="Int Cancelation">Int Cancelation</option>
                                        <option value="Rep Email">Rep Email</option>
                                        <option value="Provider Conf">Provider Conf</option>
                                        <option value="Schedule Change">Schedule Change</option>
                                        <option value="Scheduling Note">Scheduling Note</option>
                                        <option value="Special Billing">Special Billing</option>
                                        <option value="Special Handling">Special Handling</option>
                                        <option value="Waiting on Call Back">Waiting on Call Back</option>
                                        <option value="Transport Requested">Transport Requested</option>
                                        <option value="Transport Confirmed">Transport Confirmed</option>
                                        <option value="Remote Clm Conf">Remote Clm Conf</option>
                                        <option value="Remote Int Conf">Remote Int Conf</option>
                                        <option value="Remote Prov Conf">Remote Prov Conf</option>
                                    </select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText>
                                        Description:
                                    </InputGroupText>
                                    <textarea name="Description" value={this.state.model.Description} className="form-control" onChange={this.handleChange} disabled={this.state.model?.JobHistoryId !== null && (this.state.model.JobHistoryType !== "Int Communication" && this.state.model.JobHistoryType !== "Job Note" && this.state.model.JobHistoryType !== "My Job")}></textarea>
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <InputGroup size="sm">*/}
                        {/*            <InputGroupText>*/}
                        {/*                Drag & Drop File*/}
                        {/*            </InputGroupText>*/}
                        {/*        </InputGroup>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {this.state.model.JobHistoryType === "Attachment" && <hr />}
                        {this.state.model.JobHistoryType === "Attachment" && <Row>
                            <Col className={"col-2"} />
                            <Col className={this.state.model.FileName === '' ? "col-10" : "col-10"} >
                                <input id="file" type="file"  name="fileUpload" className="form-control" onChange={this.handleFileChange} ref={ref => this.fileUpload = ref} />
                                <span style={{ left: '20px', top: '50px', position: 'absolute' }} >{this.state.model.FileName === "" ? "Drag and Drop" : this.state.model.FileName}</span>
                            </Col>
                            <Col className="col-1">
                                <img src={DeleteIcon} style={{ width: "14px", height: "14px", cursor: 'pointer', display: this.state.model.FileName === '' ? 'none' : null }} onClick={this.handleDeleteFile} alt="Delete" />
                            </Col>
                        </Row>}                      
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                    {((this.state.model.JobHistoryId === null) || (this.state.model.JobHistoryId !== null && (this.state.model.JobHistoryType === "Int Communication" || this.state.model.JobHistoryType === "Job Note" || this.state.model.JobHistoryType === "My Job"))) && <Button color="primary" onClick={this.onSave} size="sm">Save</Button>}
                    {/*<Button color="primary" onClick={() => { if (window.confirm("Are you sure to save this record?")) { this.onSave() } }} size="sm" disabled={this.state.model?.JobHistoryId === null ? false : true}>Save</Button>*/}
                </ModalFooter>
            </Modal>
        )
    }

}