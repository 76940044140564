import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, InputGroup, Row, Col, Table, Alert } from 'reactstrap';

import { ProviderOfficeForm } from './ProviderOfficeForm';
import { ProviderFormTableDefault } from './ProviderFormTableDefault';

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png'

export class ProviderFormSearch extends Component {
    static displayName = ProviderFormSearch.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            providerList: [],

            modal: false,
            alert: this.props.alert,
            model: {
                EventType: 'insert',
                ProvId: null,
                ProvLastName: '',
                ProvFirstName: '',
                ProvTitle: '',
                ProvSpeciality: '',
                ProvReceptionist: '',
                ProvPhone: '',
                ProvPhone2: '',
                ProvStreet: '',
                ProvCity: '',
                ProvState: '',
                ProvZip: '',
                ProvNote: '',
                ProvIntNote: '',
                ProvInactiveFlg: false,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                DeletionReason: '',
                UserName: ''
            },
            searchModel: {
                FullName: props.Name === null ? "" : props.Name
            }
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setModal = this.setModal.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);

        if (props.Name !== null && props.Name !== '')
            this.populateProviderOffice();
    }

    async populateProviderOffice() {
        await FetchData("/getprovideroffices", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ providerList: data, loading: false });
        });
    }

    handleChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateProviderOffice();
        }
    }  

    handleSaveEventSuccess(alert) {

        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: true } });
            }, Timeout(alert.message));
            this.populateProviderOffice();
        });
    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                ProvId: null,
                ProvLastName: '',
                ProvFirstName: '',
                ProvTitle: '',
                ProvSpeciality: '',
                ProvReceptionist: '',
                ProvPhone: '',
                ProvPhone2: '',
                ProvStreet: '',
                ProvCity: '',
                ProvState: '',
                ProvZip: '',
                ProvNote: '',
                ProvIntNote: '',
                ProvInactiveFlg: false,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                DeletionReason: '',
                UserName: ''
            }
        }));

        this.setModal();
    }

    renderProviderTable(providerList){
        return (
            <ProviderFormTableDefault
                providerList={this.state.providerList}
                handleDoubleClick={this.props.handleDoubleClick}
                ></ProviderFormTableDefault>
        )
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "800px", maxHeight: "500px" }} backdrop="static">
                    <ModalHeader><span style={{ fontSize: "14px" }}>Search Provider</span></ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        {this.state.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <InputGroup size="sm">
                                        <input name="FullName" type="text" className="form-control form-control-text" value={this.state.searchModel.FullName} onChange={this.handleChange} placeholder="Provider Name (Lastname Firstname)" onKeyPress={this.handleKeyPress} />
                                        <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                                    </InputGroup>
                                </Col>                                
                            </Row>
                            <Row>
                                <Col className=" x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: "scroll", height: "450px", backgroundColor: 'unset', width: '100%', marginLeft: '8px', marginRight: '10px' }}>
                                    {this.renderProviderTable(this.state.providerList)}
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>

                {this.state.modal &&
                    <ProviderOfficeForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }
            </div>
        )
    }
}