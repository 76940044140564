import React, { Component } from 'react';
import { Modal, Button, ModalHeader, ModalBody, InputGroup, Row, Col, Table } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

import { ClaimantForm } from './ClaimantForm';
import { ClaimantFormSearchTableDefault } from './ClaimantFormSearchTableDefault';

import AddIcon from '../images/add.png'

export class ClaimantFormSearch extends Component {
    static displayName = ClaimantFormSearch.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            claimantList: [],

            alert: props.alert,

            modalClaimantForm: false,
            model: {
                EventType: 'insert',
                ClmId: null,
                RepId: props.workorder.RepID,
                ClmLastName: '',
                ClmFirstName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmInvoiceType: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmNote: '',
                ClmWCABNumber: '',
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmDateOfInjury: '',
                ClmDateOfLoss: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmEmail: '',
                ClmSSN: '',
                ClmDOB: null,
                ClmDiagnosis: '',
                ClmGender: '',
                ClmAppAtty: '',
                ClmAppAttyFirm: '',
                ClmAppAttyEmail: '',
                ClmAppAttyPhone: '',
                ClmDefAtty: '',
                ClmDefAttyFirm: '',
                ClmDefAttyEmail: '',
                ClmDefAttyPhone: '',
                ClmOrderedByName: '',
                ClmHeight: '',
                ClmWeight: '',
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientId: props.workorder.ClientId,
                ClientName: props.workorder.ClientName,
                ClientAbbrv: props.workorder.ClientAbbrv,
                RepFirstName: '',
                RepLastName: ''
            },
            searchModel: {
                ClmId: '',
                ClmClaimNumberNoDash: '',
                Name: this.props.Name,
                ClientAbbrv: '',
                ClientId: props.workorder.ClientId === null || props.workorder.ClientId === undefined ? '' : props.workorder.ClientId
            }
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);

        if (props.Name !== null && props.Name !== '')
            this.populateClaimantData();

        this.setModalClaimantForm = this.setModalClaimantForm.bind(this);
        this.handleClaimantSaveEventSuccess = this.handleClaimantSaveEventSuccess.bind(this);
    }

    setModalClaimantForm() {
        this.setState({ modalClaimantForm: !this.state.modalClaimantForm });
    }

    handleClaimantSaveEventSuccess(alert) {
        this.setState({
            modalClaimantForm: alert.modal
        }, () => {
            this.populateClaimantDataOnSave();
        });
    }

    handleChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateClaimantData();
        }
    }

    async populateClaimantData() {
        await FetchData("/getclaimants", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ claimantList: data, loading: false });
        });
    }

    async populateClaimantDataOnSave() {
        await FetchData("/getclaimants", "POST", this.user.token, this.state.searchModel).then(data => {
            if (data.length > 0)
            {
                var topData = [];
                topData.push(data[0]);
                this.setState({ claimantList: topData, loading: false });
            }
            else 
                this.setState({ claimantList: data, loading: false });
        });
    }

    renderClaimantTable(claimantList) {
        return (
            <ClaimantFormSearchTableDefault
                claimantList={claimantList}
                handleDoubleClick={this.props.handleDoubleClick}
            ></ClaimantFormSearchTableDefault>
        )
    }

    render() {
        return (
            <div style={{ height: '400px' }}>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "800px" }} backdrop="static">
                    <ModalHeader><span style={{ fontSize: "14px" }}>Search Claimant</span></ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <input name="ClmClaimNumberNoDash" type="text" className="form-control form-control-text" value={this.state.searchModel.ClmClaimNumberNoDash} onChange={this.handleChange} placeholder="Claim #" onKeyPress={this.handleKeyPress} />
                                        <input name="Name" type="text" className="form-control form-control-text" value={this.state.searchModel.Name} onChange={this.handleChange} placeholder="Claimant (Lastname, Firstname)" onKeyPress={this.handleKeyPress} />
                                        <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleChange} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                                        <Button color="btn" size="sm" onClick={this.setModalClaimantForm} ><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className=" x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: "scroll", height: "450px", backgroundColor: 'unset', width: '100%', marginLeft: '8px', marginRight: '10px' }}>
                                    {this.renderClaimantTable(this.state.claimantList)}
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>

                {this.state.modalClaimantForm &&
                    <ClaimantForm model={this.state.model} modal={this.state.modalClaimantForm} alert={this.state.alert} setModal={this.setModalClaimantForm} handleSaveEventSuccess={this.handleClaimantSaveEventSuccess} />
                }
            </div>
        )
    }
}