import React, { Component, } from 'react';
import ReactTooltip from "react-tooltip";

import AddIcon from '../images/add.png'
import EmailIcon from '../images/email-icon.png'
import DollarIcon from '../images/dollar-icon.png'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

import * as CellRenderers from './AgGridCellRendererComponents';

const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: 'Job#', field: 'jobNum', cellRenderer: 'jobNumCellRenderer', cellRendererParams: { context: { componentParent: this } }, width: 65, },
    { headerName: 'Int Conf?', field: 'intConf', cellRenderer: 'intConfCellRenderer', width: 120 },
    { headerName: 'Pro conf', field: 'provConfflg', cellRenderer: 'provConfCellRenderer', width: 65 },
    { headerName: 'Rep Auth', field: 'repAuthorizedflg', cellRenderer: 'repAuthorizedCellRenderer', width: 65 },
    { headerName: 'Clm Conf', field: 'clmConfflg', cellRenderer: 'clmConfCellRenderer', width: 65 },
    { headerName: 'Rem Conf', field: 'remFinalflg', cellRenderer: 'remFinalConfCellRenderer', width: 65 },
    { headerName: 'Rem Type', field: 'remTypeDesc', width: 65 },
    { headerName: 'T', field: 'transportationflg', cellRenderer: 'transportationCellRenderer', width: 20 },
    { headerName: 'Date', field: 'jobDateTime', cellRenderer: 'jobDateCellRenderer', width: 80 },
    { headerName: 'Claimant', field: 'clmLastName', cellRenderer: 'claimantNameCellRenderer', width: 125 },
    { headerName: '', field: 'jobNotes', cellRenderer: 'jobNotesCellRenderer', width: 20 },
    { headerName: 'CLM Phone', field: 'clmPhone', width: 80 },
    { headerName: '', field: 'intEmail', cellRenderer: 'intEmailCellRenderer', width: 30 },
    { headerName: 'Interpreter', field: 'intLastName', cellRenderer: 'intNameCellRenderer', width: 125 },
    { headerName: 'Int Cell /Text', field: 'intCellTxt', width: 80 },
    { headerName: 'Job Provider', field: 'providerName', cellRenderer: 'providerNameCellRenderer', width: 125 },
    { headerName: 'FU', field: 'fu', cellRenderer: 'fuCellRenderer', width: 50 },
    { headerName: 'Rep', field: 'repLastName', cellRenderer: 'repNameCellRenderer', width: 125 },
    { headerName: 'Rep Phone', field: 'repPhone', width: 80 },
    { headerName: 'Client Ph', field: 'clientPhone', width: 80 },
    { headerName: 'Client', field: 'clientAbbrv', width: 68 },
    { headerName: 'Caller Notes', field: 'callerNote', width: 125 }
];

export class WorkOrderTablePending extends Component {
    static displayName = WorkOrderTablePending.name;

    constructor(props) {
        super(props);
        this.gridApi = null;
        this.gridColumnApi = null;
        this.previousSelection = [];
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
        }
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    handleRowDoubleClick = (event) => {
        const workorder = event.data;
        const rowIndex = event.rowIndex;
        this.props.setData(workorder, rowIndex, true);
    }

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };

    onSelectionChanged = () => {
        const currentSelection = this.gridApi.getSelectedRows();
        const currentSelectionIds = currentSelection.map(item => item.jobId);
        const previousSelectionIds = this.previousSelection.map(item => item.jobId);

        //console.log('Current Selection:', currentSelection);
        //console.log('Previous Selection:', this.previousSelection);

        const newlySelected = currentSelection.filter(node =>
            !previousSelectionIds.includes(node.jobId)
        );
        const newlyDeselected = this.previousSelection.filter(node =>
            !currentSelectionIds.includes(node.jobId)
        );

        //console.log('Newly Selected:', newlySelected);
        //console.log('Newly Deselected:', newlyDeselected);

        // Handling changes based on newly selected or deselected
        newlySelected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: true
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        newlyDeselected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: false
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        // Update the previous selection with current
        this.previousSelection = [...currentSelection];
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (this.props.onGridReady) {
            this.props.onGridReady(params);
        }
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns();
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    render() {

        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: this.props.workOrders,
            context: {
                componentParent: this,
                handleCheckChange: this.props.handleCheckChange,
                gridApi: this.gridApi,
                //checkboxStates: this.props.checkboxStates,
                addSchedule: this.props.addSchedule,
                setModal: this.props.setModal,
                setInterpreterEmail: this.props.setInterpreterEmail,
                onChange: this.props.handleCheckChange,
                state: this.props.state,
            //    handleIntConfNo: this.props.handleIntConfNo,
            //    handleSendSMS: this.props.handleSendSMS,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                jobNumCellRenderer: CellRenderers.JobNumCellRenderer,  // Register the cell renderer
                intConfCellRenderer: CellRenderers.IntConfCellRenderer,
                provConfCellRenderer: CellRenderers.ProvConfCellRenderer,
                repAuthorizedCellRenderer: CellRenderers.RepAuthorizedCellRenderer,
                clmConfCellRenderer: CellRenderers.ClmConfCellRenderer,
                remFinalConfCellRenderer: CellRenderers.RemFinalConfCellRenderer,
                transportationCellRenderer: CellRenderers.TransportationCellRenderer,
                jobDateCellRenderer: CellRenderers.JobDateCellRenderer,
                claimantNameCellRenderer: CellRenderers.ClaimantNameCellRenderer,
                jobNotesCellRenderer: CellRenderers.JobNotesCellRenderer,
                intEmailCellRenderer: CellRenderers.IntEmailCellRenderer,
                intNameCellRenderer: CellRenderers.IntNameCellRenderer,
                providerNameCellRenderer: CellRenderers.ProviderNameCellRenderer,
                repNameCellRenderer: CellRenderers.RepNameCellRenderer,
                fuCellRenderer: CellRenderers.FuCellRenderer,
            },
            rowSelection: "single",
            onRowClicked: this.onRowClicked,
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleRowDoubleClick,
            onSelectionChanged: this.onSelectionChanged
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                    rowSelection={"multiple"}
                />
            </div>
        );
    }
}

