import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, InputGroup, InputGroupText, Row, Col, Alert, TabContent, Table, TabPane } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetParameterByName, GetCookie, RemoveCookie } from './utils/Common';

import Logo from '../images/logo1.png'

const moment = require('moment');

export class WorkOrderIntConfNoViewAppointmentForm extends Component {
    static displayName = WorkOrderIntConfNoViewAppointmentForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            display_message: [],
            showButtons: true
        };

        //
        this.handleYesClick = this.handleYesClick.bind(this);
    }

    componentDidMount() {
        // get the query string parameters.
        if (GetParameterByName('jobId') !== null) {

            FetchData("/ValidateJobInterpreter/?jobId=" + GetParameterByName('jobid'), "POST", this.user.token, {}).then(data => {
                if (data === "Success") {
                    FetchData("/InterpreterConfirmationPageLoad/?jobId=" + GetParameterByName('jobId'), "POST", this.user.token, {}).then(data => {
                        this.setState({
                            display_message: data.displayMessage
                        });

                        localStorage.removeItem('IsPageLoad');
                    });
                } else {
                    RemoveCookie('user');
                    localStorage.removeItem('RemoteTypes');
                    localStorage.removeItem('RemoteHosts');
                    localStorage.removeItem('JobTypes');
                    localStorage.removeItem('JobInvLineCodes');
                    window.location.reload();
                }
            });           
        } else {
            this.setState({
                display_message: 'Invalid Request.'
            });
        }
    }

    handleYesClick() {
        FetchData("/FinalConfirmationYesButton/?q=" + GetParameterByName('q') + '&userId=' + this.user.userid, "POST", this.user.token, {}).then(data => {
            var msg = [];
            msg.push(data)
            this.setState({
                display_message: msg,
                showButtons: false
            });            
        });
    }


    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "500px", width: "800px" }} backdrop="static">
                    <ModalHeader>Final Confirmation</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <center><img src={Logo} alt="Logo" /></center>
                                </Col>
                            </Row>                           
                            <Row>
                                <Col>
                                    <center>
                                        <br/>
                                        {this.state.display_message.map((str, i) =>
                                            <p>{str} <br/></p>
                                        )}
                                    </center>
                                </Col>
                            </Row>
                            {(this.state.display_message != "" && this.state.showButtons) &&
                                <Row>
                                    <Col>
                                    <center>
                                        <Button color="success" size="sm" onClick={this.handleYesClick}>Yes</Button> {' '}
                                            <Button color="danger" size="sm">No</Button>
                                        </center>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </ModalBody>
                    {/*<ModalFooter>*/}

                    {/*</ModalFooter>*/}
                </Modal>
            </div>
        );

    }
}
