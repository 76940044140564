import React from 'react';
import Cookies from 'js-cookie';

import './login.css';

import { GetLookUps } from './GetLookUps';
import { FetchData } from './utils/DataFetch';

class Login extends React.Component {
    constructor(props) {
        super(props);

        localStorage.removeItem('user');

        this.state = {
            email: '',
            password: '',
            errMsg: '',
            reset_email: '',
            isLogin: Cookies.get("user") != undefined ? true : false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
    }

    componentDidMount() {

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        let data = {
            Email: this.state.email,
            Password: this.state.password,
        }
        fetch("/login", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result.message);
                    if (result.message !== 'Ok') {
                        this.setState({ errMsg: 'Invalid Login', isLogin: false })
                    }
                    else {
                        let userInfo = JSON.stringify({
                            userid: result.data.id,
                            firstname: result.data.firstName,
                            lastname: result.data.lastName,
                            email: result.data.email,
                            token: result.data.token,
                            userrolename: result.data.userRoleName,
                            intid: result.data.intId,
                        });
                        Cookies.set('user', userInfo, { expires: 3 });

                        window.location.reload();
                    }                
                },
                (error) => {

                }
            )
    }

    handleResetPassword() {
        let data = {
            email: this.state.reset_email
        }
        fetch("/resetpassword", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result[0].Msg === "Success")
                        window.alert("Your password will be sent to your email");
                    else
                        window.alert(result[0].Msg);
                },
                (error) => {

                }
            )
    }

    render() {
        const { isLogin } = this.state;
        return (
            <div>
                {isLogin === false && (
                    <div className='card card-container'>
                        <img className='profile-img-card' src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" alt="FatCat" />
                        <p className='profile-name-card' id='profile-name'></p>
                        <form onSubmit={this.handleSubmit} method="post">
                            <div className="form-group">
                                <input type="text" name="email" value={this.state.email} onChange={this.handleChange} className="form-control" id="email" placeholder="Enter your email" />
                            </div>
                            <div className="form-group">
                                <input type="password" name="password" value={this.state.password} onChange={this.handleChange} className="form-control" id="password" placeholder="Enter your password" />
                            </div>
                            <div className="form-group">
                                <button className='btn btn-lg btn-primary btn-block btn-signin' type="submit">Submit</button>
                            </div>
                            <p style={{ color: "red" }}>
                                {this.errMsg !== "Success" ?
                                    this.state.errMsg
                                    : null}
                            </p>
                        </form>
                        {/*<a href="#" data-toggle="modal" data-target="#myModal" className="forgot-password"> Forgot the password? </a>*/}
                    </div>
                )}

                <div className="modal fade" id="myModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>
                                <h4 className="modal-title" id="myModalLabel">Reset Password</h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <input type="email" name="reset_email" className="form-control" id="reset_email" value={this.state.reset_email} onChange={this.handleChange} placeholder="Enter your email" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.handleResetPassword}>Reset</button>
                                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Login;