import React, { Component, Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Input, Row, Col, Alert } from 'reactstrap';

import { FetchData, UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

import DeleteIcon from '../images/delete.png';

const moment = require('moment');

export class WorkOrderPrintPreview extends Component {
    static displayName = WorkOrderPrintPreview.name;
    constructor(props) {
        super(props);
        this.state = {
            model: this.props.model,
            alert: this.props.alert,
            sortModel: {
                SortBy: 'jobDateTime',
                SortDirection: 'desc'
            }
        }
        //
        this.user = JSON.parse(GetCookie('user'));
        this.printWorkOrder = this.printWorkOrder.bind(this);
    }

    componentDidMount() {
        document.getElementById("woForm").className += " noPrint";
        setTimeout(
            () => this.printWorkOrder(),
            2000
        ); 
    }

    printWorkOrder() {      
        var formData = new FormData();
        formData.append("EventType", "insert");
        formData.append("JobHistoryId", null);
        formData.append("JobId", this.state.model.JobId);
        formData.append("Description", "Work Order Printed");
        formData.append("JobHistoryType", "Printed");
        formData.append("CreateByUserId", this.user.userid);

        UploadFile("/savejobhistory", this.user.token, formData).then(data => {         
            window.print();
        });
    }

    render() {
        return (
            <div>
                <Modal id="divcontents" className="printable" isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "600px", width: "1024px" }} >
                    <ModalHeader>
                        Executive Linguist Agency
                    </ModalHeader>
                    <ModalBody id="divBody" style={{ height: "900px", overflow: 'hidden' }}>
                        <Row>
                            <Col className="col-4">
                                Executive Linguist Agency, Inc <br /> Ph: 800-522-2320 Fax: 310-376-9285
                            </Col>
                            <Col className="col-4">
                                mail@executivelinguist.com
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm text-area-no-background">
                                        CLAIMANT:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmLastName}, {this.state.model.ClmFirstName}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">

                            </Col>
                            <Col className="col-4">

                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        JOB/INV NO:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.JobNum}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <center><h4>WORK ORDER</h4></center>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col className="col-3">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        Created On:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{moment(this.state.model.CreateDateTime).format('MM/DD/YYYY')}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-3">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        Created By:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.CreatedBy}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        Printed By:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.user.firstname} {this.user.lastname}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        CALLER:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.CallerNote}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        <input type="checkbox"></input> &nbsp; INTERPRETER:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.IntLastName}, {this.state.model.IntFirstName}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">

                            </Col>
                            <Col className="col-4">

                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        PHONE:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.IntPhoneOnly}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        ADJUSTER:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.RepLastName}, {this.state.model.RepFirstName}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        PHONE:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.RepPhone}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        ABBRV:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClientAbbrv}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        DAY:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{moment(this.state.model.JobDateTime).format('dddd')}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        DATE:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{moment(this.state.model.JobDateTime).format('MM/DD/YYYY')}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        TIME:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{moment(this.state.model.JobDateTime).format('hh:mm A')}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        APPOINTMENT:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ProviderName}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        Job Type:
                                    </InputGroupText>
                                    {/*<span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptName}</span>*/}
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        NAME:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptName}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        STREET:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptStreet}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        CITY:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptCity}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        STATE:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptState}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        ZIP:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptZip}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        PHONE:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptPhone}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        EXTRA INFO:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.AptDirection}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        CLAIMANT:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmLastName}, {this.state.model.ClmFirstName}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        <input type="checkbox"></input> &nbsp; LANGUAGE:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmLanguage}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        CLM PHONE:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmPhone}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        CLM NO:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmClaimNumber}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        DATE OF INJURY:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmDateOfInjury}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        DATE OF LOSS:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmDateOfLoss}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        EMPLOYER:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmEmployer === '' ? 'n/a' : this.state.model.ClmEmployer}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        INSURED:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmInsuredParty}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        DOB:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmDOB === null ? null : moment(this.state.model.ClmDOB).format('MM/DD/YYYY')}</span>
                                </InputGroup>
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        CAPTION:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmInvoiceCaption}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        WCAB #:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmWCABNumber}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm">
                                        Claimant Notes:
                                    </InputGroupText>
                                    <span style={{ paddingTop: '7px' }} className="form-control-text">{this.state.model.ClmNote}</span>
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <Table striped size="sm" className="table-bordered table-striped" hover style={{ tableLayout: "fixed", lineHeight: "8px", alignItems: 'center', padding: "0.3rem 0.3rem 0.1rem 0.3rem" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="145px">Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell width="170px">CreatedBy</TableCell>
                                            <TableCell width="165px">Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.jobHistoryList.map((jobhistory, i) =>
                                            <tr key={jobhistory.jobHistoryId}>
                                                <td className="x-grid-cell-inner">{jobhistory.jobHistoryType}</td>
                                                <td className="x-grid-cell-inner">{jobhistory.description}</td>
                                                <td className="x-grid-cell-inner">{jobhistory.createdBy}</td>
                                                <td className="x-grid-cell-inner">{moment(jobhistory.createDateTime).format('MM/DD/YYYY hh:mm A')}</td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ display: 'none' }} ref={Button => this.btnPrint = Button} className="btn-secondary-no-background form-control-text btn btn-secondary btn-sm" onClick={this.printModal}>Print</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}