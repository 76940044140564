import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup, InputGroupText } from 'reactstrap';

import { RolodexForm } from '../RolodexForm';
import { LoadingModal } from '../LoadingModal';
import { FetchData } from '../utils/DataFetch';
import { GetCookie, Timeout } from '../utils/Common';

import DeleteIcon from '../../images/remove-icon.png'
import AddIcon from '../../images/add.png'

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

const DEFAULT_MIN_WIDTH_CELL = 70;
const DEFAULT_MAX_WIDTH_CELL = 800;

const columns = [
    { headerName: "Abbrv", sortableField: "clientAbbrv", width: 80 }, // Set a smaller width for clientAbbrv
    { headerName: "Client", sortableField: "clientName", width: 100 }, // Set a smaller width for clientName
    { headerName: "First Name", sortableField: "repFirstName", width: 100 },
    { headerName: "Last Name", sortableField: "repLastName", width: 100 },
    { headerName: "Last Job", sortableField: "lastJob", width: 80 },
    { headerName: "Jobs", sortableField: "jobCount", width: 40 }
];

export class RptRepCount extends Component {
    static displayName = RptRepCount.name;

    constructor(props) {
        super(props);
        this.state = {
            columnRefs: columns.map(() => createRef()),
            isResizing: -1,

            rptRepCountList: [],
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                StartDate: '',
                EndDate: ''
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';


        this.renderRepCountTable = this.renderRepCountTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
        this.sortData = this.sortData.bind(this);
        this.requestSort = this.requestSort.bind(this);
        //
        this.onClickResizeColumn = this.onClickResizeColumn.bind(this);
        this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    }

    componentDidMount() {
        //this.populateRolodexData();

        document.onmousemove = this.handleOnMouseMove;
        document.onmouseup = this.handleOnMouseUp;
        return () => {
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }

    calculateAndSetColumnWidths() {
        const maxWidths = columns.map((col, index) => {
            const headerText = col.headerName;
            const maxContentWidth = Math.max(
                ...this.state.rptRepCountList.map(
                    entry => entry[0].reps.reduce(
                        (max, rep) => Math.max(max, rep[headerText]?.length ?? 0),
                        0
                    )
                )
            );
            return col.width ? col.width : Math.max(maxContentWidth, headerText.length * 8); // Use the specified width if available
        });

        const colRefs = this.state.columnRefs.map((ref, index) => {
            const newWidth = maxWidths[index];
            ref.current.parentElement.style.width = newWidth + "px";
            return ref;
        });

        this.setState({
            columnRefs: colRefs,
        });
    }



    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRptRepCount();
        }
    }

    sortData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.rolodexList;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }

    requestSort(pSortBy) {
        var sortBy = this.state.searchModel.SortBy;
        var sortOrder = this.state.searchModel.SortDirection;
        return event => {
            if (pSortBy === this.state.searchModel.SortBy) {
                sortOrder = sortOrder === "asc" ? "desc" : "asc";
            } else {
                sortBy = pSortBy;
                sortOrder = "asc";
            }
            var sortedItems = this.sortData(sortBy, sortOrder);
            this.setState({
                searchModel: {
                    SortBy: sortBy,
                    SortDirection: sortOrder
                },
                rolodexList: sortedItems
            });
        };
    }

    renderRepCountTable(rptRepCountList) {
        const totalJobCountForAllClients = rptRepCountList.reduce(
            (totalJobCount, entry) => {
                const reps = entry[0].reps;
                const jobCountForClient = reps.reduce(
                    (totalJobCountForClient, rep) => totalJobCountForClient + rep.jobCount,
                    0
                );
                return totalJobCount + jobCountForClient;
            },
            0
        );

        return (
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: columns[0].width + "px" }}>Client Abbreviation</TableCell>
                            <TableCell style={{ width: columns[1].width + "px" }}>Client Name</TableCell>
                            <TableCell style={{ width: "400px" }}>Representatives</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rptRepCountList.map((entry, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell>{entry[0].clientAbbrv}</TableCell>
                                    <TableCell>{entry[0].reps[0].clientName}</TableCell>
                                    <TableCell>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ width: columns[3].width + "px" }}>First Name</TableCell>
                                                    <TableCell style={{ width: columns[4].width + "px" }}>Last Name</TableCell>
                                                    <TableCell style={{ width: columns[5].width + "px" }}>JobCount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {entry[0].reps.map((rep, repIndex) => (
                                                    <TableRow key={repIndex}>
                                                        <TableCell>{rep.repFirstName}</TableCell>
                                                        <TableCell>{rep.repLastName}</TableCell>
                                                        <TableCell align="right">{rep.jobCount}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right"  colSpan={2}>Total</TableCell>
                                    <TableCell align="right">{entry[0].reps.reduce((totalJobCountForClient, rep) => totalJobCountForClient + rep.jobCount, 0)}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                        <TableRow>
                        <TableCell align="right"  colSpan={2}>Total Jobs</TableCell>
                        <TableCell align="right" >{totalJobCountForAllClients}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }

    async populateRptRepCount() {
        this.setState({ fetchingData: true });
        let model = this.state.searchModel;
        if (model.clientAbbrv == null) {
            model.clientAbbrv = '';
        };

        FetchData("/getrptrepcount", "POST", this.user.token, model).then(data => {
            const organizedData = {};

            // Iterate over the original data and organize it by clientAbbrv
            data.forEach(item => {
                const { clientAbbrv, clientName, repFirstName, repLastName, jobCount } = item;

                if (!organizedData[clientAbbrv]) {
                    // If clientAbbrv doesn't exist in the organizedData, create an empty array
                    organizedData[clientAbbrv] = [];
                }

                // Push the rep details to the corresponding clientAbbrv array
                organizedData[clientAbbrv].push({ clientName, repFirstName, repLastName, jobCount });
            });

            // Convert the organizedData object to an array of arrays
            const resultArray = Object.entries(organizedData).map(([clientAbbrv, reps]) => {
                return [
                    { clientAbbrv, reps }
                ];
            });
            this.setState({
                rptRepCountList: resultArray,
                loading: false,
                fetchingData: false
            });
        });
    }


    adjustWidthColumn = (index, width) => {
        const minWidth = columns[index]?.minWidth ?? DEFAULT_MIN_WIDTH_CELL;
        const maxWidth = this.state.columnRefs[index]?.maxWidth ?? DEFAULT_MAX_WIDTH_CELL;
        const newWidth =
            width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

        var colRefs = this.state.columnRefs;
        colRefs[index].current.parentElement.style.width = newWidth + "px";

        this.setState({
            columnRefs: colRefs
        });
    }

    setCursorDocument = (isResizing) => {
        document.body.style.cursor = isResizing ? "col-resize" : "auto";
    }

    handleOnMouseMove = (e) => {
        if (this.state.isResizing >= 0) {
            const newWidth =
                e.clientX -
                this.state.columnRefs[
                    this.state.isResizing
                ].current.parentElement?.getBoundingClientRect().left;
            this.adjustWidthColumn(this.state.isResizing, newWidth);
        }
    }

    handleOnMouseUp = () => {
        this.setState({ isResizing: -1 }, () => {
            //this.saveColumnInfoLocalStorage();
            this.setCursorDocument(false);
        });
    }

    onClickResizeColumn = (index) => {
        this.setState({ isResizing: index }, () => {
            this.setCursorDocument(true);
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRepCountTable(this.state.rptRepCountList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Rep Count Report</h5>
                <Row>
                    <Col className="col-4">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Start Date
                            </InputGroupText>
                            <input name="StartDate" ref={ref => this.txtStartDate = ref} type="date" className="form-control form-control-text"  value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Start Date" onKeyPress={this.handleKeyPress} />
                            <InputGroupText>
                                End Date
                            </InputGroupText>
                            <input name="EndDate" ref={(input) => { this.EndDate = input; }} type="date" className="form-control form-control-text" value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="End Date" onKeyPress={this.handleKeyPress} />
                            <input name="ClientAbbrv" ref={(input) => { this.ClientAbbrv = input; }} type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />


                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
