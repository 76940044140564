import React, { Component, } from 'react';
//import ReactTooltip from "react-tooltip";

import AddIcon from '../images/add.png'
import DeleteIcon from '../images/delete.png'
import DollarIcon from '../images/dollar-icon.png'
import EditIcon from '../images/edit-icon.png'
import EmailIcon from '../images/email-icon.png'
import SearchIcon from '../images/search-icon.png'


const moment = require('moment');

export class AuditFlgCellRenderer extends Component {
    render() {
        const { data } = this.props;
        const displayText = data.auditflg === true ? "Yes" : "No";

        return (
            <td className="x-grid-cell-inner">
                {displayText}
            </td>
        );
    }
}

export class AddFollowUpCellRenderer extends React.Component {
    handleClick = () => {
        const { data, context } = this.props;
        context.addSchedule(data, context.rowIndex); // Assuming you pass these functions via context
        context.setModal();
    };

    render() {
        return (
            <img
                src={AddIcon}
                style={{ width: "16px", height: "16px", cursor: 'pointer' }}
                alt="Add"
                onClick={this.handleClick}
            />
        );
    }
}

export class ClmConfCellRenderer extends Component {
    render() {
        const { value } = this.props;  // `value` is automatically provided by ag-Grid

        return (
            <span style={{ paddingRight: "40px" }}>
                {value === true ? "Yes" : <span style={{ color: 'red' }}>No</span>}
            </span>
        );
    }
}

export class ClaimantNameCellRenderer extends Component {
    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.clmLastName}, ${data.clmFirstName}`;

        if (data.hasClaimantDupWO) {
            return (
                <span style={{ color: 'red' }}>{name}</span>
            );
        } else {
            return (
                <span>{name}</span>
            );
        }
    }
}

export class CalendarDateCellRenderer extends React.Component {
    render() {
        const { value } = this.props;
        return (
            <span>
                {moment(value).format('MM/DD/YYYY')} {this.props.context.componentParent.props.state.dayNames[moment(value).day()]}
            </span>
        );
    }
}

export class CreatedByCellRenderer extends Component {
    render() {
        const { data } = this.props;
        const name = `${data.firstName} ${data.lastName}`;
        return (
            <span>{name}</span>
        )
    }
}

export class CurrencyCellRenderer extends React.Component {
    render() {
      const value = this.props.value;
      return (
        <span>
            {value === null ? "" : `$${value}`}
        </span>
        );
    }
}

export class DateCellRenderer extends React.Component {
    render() {
        let formattedDate = '';
        if (this.props.value) {
            formattedDate = moment(this.props.value).format('MM/DD/YYYY');
        }
        return (
            <div style={{ width: '200px' }}>
                {formattedDate}
            </div>
        );
    }
}

export class DeleteCellRenderer extends React.Component {
    render() {
        const { data } = this.props;
        return (
            <a href="javascript:void(0)">
                <img src={DeleteIcon} style={{ width: "14px", height: "14px", cursor: 'pointer' }} onClick={() => this.props.context.handleDeleteJobHistory(data)} alt="Delete" />
            </a>
        );
    }
}

export class DetailCellRenderer extends React.Component {
    render() {
        const { data } = this.props;
        const style = {
            color: data.jobHistoryType === "Schedule Change" ? "green" : data.jobHistoryType === "Special Billing" ? "red" : "black"
        };
        return (
            <span data-for="rtp2" style={style} name="rtp2" data-tip={data.description}>
                {data.description} &nbsp;
                {data.fileLocation !== "" && data.fileLocation !== "null" && (
                    <a href='#' onClick={() => this.props.context.handleViewJobHistoryFile(data)}>
                        {data.fileName}
                    </a>
                )}
            </span>
        );
    }
}

export class DurationCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duration: this.calculateDuration(props.data.jobDateTime, props.data.finishTime, props.data.failedflg)
        };
    }

    calculateDuration(jobDateTime, finishTime, failedflg) {
        if (failedflg === true) {
            return "FAIL";
        } else {
            if (!jobDateTime || !finishTime) return "";

            let startDate = new Date(jobDateTime);
            let finishDate = new Date(finishTime);

            // Extract hours and minutes to compare only the time portion
            let start = startDate.getHours() * 60 + startDate.getMinutes();
            let finish = finishDate.getHours() * 60 + finishDate.getMinutes();

            // Check if the start and finish times are the same, ignoring the date
            if (start === finish) {
                return "00:00";
            }

            // Handle crossing over midnight
            if (finish < start) {
                finish += 1440; // Add 24 hours worth of minutes
            }

            let durationMinutes = finish - start;
            let hoursDiff = Math.floor(durationMinutes / 60);
            let minutesDiff = durationMinutes % 60;

            return `${String(hoursDiff).padStart(2, '0')}:${String(minutesDiff).padStart(2, '0')}`;
        }
    }

    render() {
        const durationStyle = {
            color: this.state.duration === 'FAIL' ? 'red' : 'black'
        };

        return (
            <div className="x-grid-cell-inner" style={durationStyle}>
                {this.state.duration}
            </div>
        );
    }
}

export class EditViewCellRenderer extends React.Component {
    render() {
        const { data } = this.props;
        return (
            <a href="javascript:void(0)" onClick={() => this.props.context.handleNewJobNoteViewClick(data)}>
                {data.jobHistoryType === "Int Communication" || data.jobHistoryType === "Job Note" || data.jobHistoryType === "My Job" ? "Edit" : "View"}
            </a>
        );
    }
}

export class EditCellRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.onEditClicked = this.onEditClicked.bind(this);
    }

    onEditClicked() {
        this.props.context.handleEdit(this.props.data);
    }

    render() {
        return (
            <center>
                <img src={EditIcon} onClick={this.onEditClicked} style={{ width: "16px", cursor: 'pointer' }} alt="Edit" />
            </center>
        );
    }
}

export class EditDeleteCellRenderer extends React.Component {
    render() {
        const { data } = this.props;

        // Check if the row is a pinned bottom row
        if (this.props.node.rowPinned) {
            return null; // Return null to render nothing for pinned rows
        }

        return (
            <div>
                <img
                    src={EditIcon}
                    onClick={() => this.props.context.handleEditJobDescriptionClick(data)}
                    style={{ width: "16px", height: "16px", cursor: 'pointer' }}
                    alt="Edit"
                />
                {' '}
                <img
                    src={DeleteIcon}
                    onClick={() => { if (window.confirm("Are you sure to delete this record?")) { this.props.context.handleDeleteJobDescriptionClick(data); } }}
                    style={{ width: "16px", height: "16px", cursor: 'pointer' }}
                    alt="Delete"
                />
            </div>
        );
    }
}

export class JobDateCellRenderer extends Component {
    render() {
        const { data } = this.props;
        const jobDate = data.jobDateTime ? moment(data.jobDateTime).format('MM/DD/YYYY hh:mm A') : '';
        const finishTime = data.finishTimeString ? " to " + data.finishTimeString : '';
        return (
            <>
                <span data-for="jobDateTooltip">
                    {jobDate} {finishTime}
                </span>
            </>
        );
    }
}

export class JobDateSuccessCellRenderer extends React.Component {
    render() {
        const { data } = this.props;
        const jobDateTime = data.jobDateTime ? moment(data.jobDateTime).format('MM/DD/YYYY hh:mm A') : '';
        const finishTime = data.finishTime ? moment(data.finishTime).format('hh:mm A') : '';
        const status = data.failedflg === true ? " (Failed)" : " (Success)";
        const jobTimeRange = `${jobDateTime} ${finishTime ? `to ${finishTime}` : ''} ${status}`;

        return (
            <span>
                {jobTimeRange}
            </span>
        );
    }
}

export class JobHistoryCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { data } = this.props;
        // Assuming you have a method to view job history
        this.props.context.viewJobHistory(data);
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <img
                    src={SearchIcon}
                    onClick={this.handleClick}
                    style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                    alt="Job History"
                />
            </div>
        );
    }
}

export class JobNumCellRenderer extends Component {
    handleClick = (e) => {
        e.stopPropagation();
        const { data, context } = this.props;
        context.componentParent.props.viewInvoice(data);
    };

    render() {
        const { value } = this.props;

        return (
            <span onClick={this.handleClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                {value}
            </span>
        );
    }
}

export class JobNotesCellRenderer extends Component {
    handleClick = () => {
        const { data, context } = this.props;
        context.componentParent.props.viewJobNotes(data);
    };

    render() {
        return (
            <img
                src={DollarIcon}
                style={{ width: "16px", height: "16px", cursor: 'pointer' }}
                alt="Add"
                onClick={this.handleClick}
            />
        );
    }
}

export class FuCellRenderer extends Component {
    render() {
        const { data } = this.props;  // `value` is automatically provided by ag-Grid
        return (
            <span style={{ paddingRight: "40px" }}>
                {data.fu}
            </span>
        );
    }
}

export class FollowupDateCellRenderer extends Component {
    render() {
        const { data } = this.props;
        const followupDate = data.followupDate ? moment(data.followupDate).format('MM/DD/YYYY hh:mm A') : '';

        return (
            <>
                <span>
                    {followupDate}
                </span>
            </>
        );
    }
}

export class IntCheckInCellRenderer extends React.Component {
    render() {
        const { data } = this.props; // Ag-Grid passes the row data as 'data'
        const { inHouseflg, finishTime } = data;

        let content;
        if (inHouseflg === true && finishTime === null) {
            content = "NB";
        } else if (inHouseflg === false && finishTime === null) {
            content = (
                <span>
                    <a href="#" id="lnkNC" className="jWO">NC</a>
                </span>
            );
        } else if (finishTime !== null) {
            content = "CO";
        } else {
            content = null;
        }

        return (
            <span className="x-grid-cell-inner">
                {content}
            </span>
        );
    }
}

export class IntConfCellRenderer extends React.Component {
    handleIntConfNo = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { data, context } = this.props;
        context.componentParent.props.handleIntConfNo(data);
    };

    handleSendSMS = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { data, context } = this.props;
        context.componentParent.props.handleSendSMS(data);
    }

    render() {
        const { data } = this.props;
        return (
            <div className="x-grid-cell-inner">
                <span style={{ paddingRight: "40px" }}>
                    {data.intConfflg === true ? "Yes" : (
                        <span>
                            {/* Call the methods correctly using arrow functions */}
                            <a href="#" onClick={(e) => this.handleIntConfNo(e)}>No</a> |
                            <a href="#" onClick={(e) => this.handleSendSMS(e)}>Send SMS</a>
                        </span>
                    )}
                </span>
            </div>
        );
    }
}

export class IntEmailCellRenderer extends Component {
    handleClick = () => {
        const { data, context } = this.props;
        context.componentParent.props.setInterpreterEmail(data);
    };

    render() {
        return (
            <img src={EmailIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Email" onClick={this.handleClick} />
        );
    }
}
export class IntNameCellRenderer extends Component {

    handleClick = () => {
        const { data, context } = this.props;
        context.componentParent.props.openInterpreter(data.intId);
    };

    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.intLastName}, ${data.intFirstName}`;
        if (data.hasInterpreterDupWO) {
            return (
                <span onClick={this.handleClick} style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }}>
                    {name}
                </span>);
        } else {
            return (
                <span onClick={this.handleClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                    {name}
                </span>);
        }
    }
}

export class InvoicedFlgCellRenderer extends Component {
    render() {
        const { data } = this.props;
        if (data.invoicedflg) {
            return (
                <span >P</span>
            );
        } else {
            return (
                <span></span>
            );
        }

    }
}

export class PartialDayCellRenderer extends React.Component {
    render() {
        const { value } = this.props;
        return (
            <span>{value ? "Yes" : "No"}</span>
        );
    }
}

export class ProvConfCellRenderer extends Component {
    render() {
        const { value } = this.props;  // `value` is automatically provided by ag-Grid

        return (
            <span style={{ paddingRight: "40px" }}>
                {value === true ? "Yes" : <span style={{ color: 'red' }}>No</span>}
            </span>
        );
    }
}

export class ProviderEmailCellRenderer extends Component {
    handleClick = () => {
        const { data, context } = this.props;
        context.componentParent.props.setProviderEmail(data);
    };

    render() {
        return (
            <img src={EmailIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Email" onClick={this.handleClick} />
        );
    }
}

export class ProviderNameCellRenderer extends Component {


    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.providerName} (${data.aptCity})`;
        return (
            <span>{name}</span>
        );
    }
}

export class RemFinalConfCellRenderer extends Component {
    render() {
        const { value } = this.props;  // `value` is automatically provided by ag-Grid

        return (
            <span style={{ paddingRight: "40px" }}>
                {value === true ? "Yes" : <span style={{ color: 'red' }}>No</span>}
            </span>
        );
    }
}

export class RepEmailCellRenderer extends Component {
    handleClick = () => {
        const { data, context } = this.props;
        context.componentParent.props.setRepEmail(data);
    };

    render() {
        return (
            <img src={EmailIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Email" onClick={this.handleClick} />
        );
    }
}

export class RepAuthorizedCellRenderer extends Component {
    render() {
        const { value } = this.props;  // `value` is automatically provided by ag-Grid

        return (
            <span style={{ paddingRight: "40px" }}>
                {value === true ? "Yes" : <span style={{ color: 'red' }}>No</span>}
            </span>
        );
    }
}

export class RepEmail2CellRenderer extends Component {
    handleClick = () => {
        const { data, context } = this.props;
        context.componentParent.props.setRepEmail2(data);
    };

    render() {
        return (
            <img src={EmailIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Email" onClick={this.handleClick} />
        );
    }
}

export class RepNameCellRenderer extends Component {


    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.repLastName}, ${data.repFirstName} (${data.clientAbbrv})`;
        return (
            <span>{name}</span>
        );
    }
}

export class TransportationCellRenderer extends Component {
    render() {
        const { transportationflg, transConfflg } = this.props.data;

        let content;
        if (transportationflg && transConfflg) {
            content = <span style={{ color: 'red' }}>T</span>; // Both flags are true, show 'T' in red
        } else if (transportationflg && !transConfflg) {
            content = 'T'; // Only transportationflg is true, show 'T' in default color
        } else {
            content = ""; // Neither condition met, show empty
        }

        return (
            <span>{content}</span>
        );
    }
}

export class UserInactiveCellRenderer extends Component {

    render() {
        const { inActiveflg } = this.props.data;

        return (
            <span style={{ backgroundColor: inActiveflg === true ? null : 'green', color: inActiveflg === true ? null : 'white' }}>{inActiveflg === true ? "InActive" : "Active"}</span>
        )
    }
}