import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import { WorkOrderForm } from './WorkOrderForm';
import { WorkOrderClaimantJobsTableDefault } from './WorkOrderClaimantJobsTableDefault';

const moment = require('moment');

export class WorkOrderClaimantJobs extends Component {
    static displayName = WorkOrderClaimantJobs.name;
    constructor(props) {
        super(props);
        this.state = {
            workOrders: [],
            modalWorkOrder: false,
            model: this.props.model,
            alert: this.props.alert,
            sortModel: {
                SortBy: 'jobDateTime',
                SortDirection: 'desc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.populateFollowUps = this.populateFollowUps.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.setModalWorkOrder = this.setModalWorkOrder.bind(this);
        this.handleSaveEventWorkOrderSuccess = this.handleSaveEventWorkOrderSuccess.bind(this);
    }

    componentDidMount() {
        this.populateFollowUps();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    async populateFollowUps() {
        var model = { ClmId: this.state.model.ClmId };
        await FetchData("/getworkorders", "POST", this.user.token, model).then(data => {
            this.setState({ workOrders: data, loading: false, fetchingData: false });
        });
    }

    handleDoubleClick(workOrder) {
        clearTimeout(this.clickTimer);  // prevent single click action
        this.setState({ clickCount: 0 });  // reset click count


        FetchData("/getworkorder?jobId=" + workOrder.jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                job: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.intCellTxt,
                    IntPhoneOnly: data.intPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseFlg: data.inHouseFlg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    JobBalance: data.jobBalance,
                    WorkFlowId: data.workFlowId
                },
                modalWorkOrder: !this.state.modalWorkOrder
            }));
        });
    }
   
    setModalWorkOrder() {
        this.setState({ modalWorkOrder: !this.state.modalWorkOrder });
    }

    handleSaveEventWorkOrderSuccess(alert) {
        this.setState({
            modalWorkOrder: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    renderClaimantJobs(workOrders) {
        return (
            <WorkOrderClaimantJobsTableDefault 
                workOrders={workOrders}
                handleCheckChange={this.handleCheckChange}
                setData={this.setData}
                setModal={this.setModal}
                handleSaveEventWorkOrderSuccess={this.handleSaveEventWorkOrderSuccess}
                handleDoubleClick={this.handleDoubleClick}
            />
        );
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "460px", width: "1280px" }} backdrop="static">
                    <ModalHeader>
                        Claimant Jobs
                    </ModalHeader>
                    <ModalBody style={{ height: "500px", overflow: 'scroll' }}>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen}>
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <ReactTooltip className="tooltip" id="rtp" place="top" type="dark" effect="solid" html={true} />
                        <Row>
                            <Col>
                                {this.renderClaimantJobs(this.state.workOrders)}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>

                {this.state.modalWorkOrder &&
                    <WorkOrderForm model={this.state.job} modal={this.state.modalWorkOrder} alert={this.state.alert} setModal={this.setModalWorkOrder} workOrderList={[]} jobHistoryList={[]} jobInvLineList={[]} setNextPrevData={null} selectedIndex={0} handleSaveEventSuccess={this.handleSaveEventWorkOrderSuccess} />
                }
            </div>
        );
    }
}
