import React, { Component } from 'react';
import { Table, Button, Row, Col, Input, InputGroup, Alert } from 'reactstrap';
import DeleteIcon from '../images/remove-icon.png';
//import { InterpreterPaymentForm } from './InterpreterPaymentForm';
import { LoadingModal } from './LoadingModal';
import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import { saveAs } from 'file-saver';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: 'Inv #', field: 'jobNum', width: 75, },
    { headerName: 'Date', field: 'jobDate', width: 150, },
    { headerName: 'First Name', field: 'interpreterFirstName', width: 100, },
    { headerName: 'Last Name', field: 'interpreterLastName', width: 100, },
    { headerName: 'DBA', field: 'dba', width: 100, },
    { headerName: 'Bank Account', field: 'bankAccount', width: 150, },
    { headerName: 'Bank Route', field: 'bankRoute', width: 150, },
    { headerName: 'Job Total', field: 'jobTotal', width: 70, },
];


export class InterpreterPayment extends Component {
    static displayName = InterpreterPayment.name;

    constructor(props) {
        super(props);
        this.state = {
            interpreterPaymentList: [],
            loading: false,
            fetchingData: false,
            modal: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                JobID: null,
                JobNum: null,
                JobDate: null,
                IntId: null,
                InterpreterFirstName: '',
                InterpreterLastName: '',
                DBA: '',
                BankAccount: '',
                BankRoute: '',
                JobTotal: ''
            },
            searchModel: {
                StartDate: null,
                EndDate: null,
                StartCheckNumber: '',
                CommitToDB: false
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';


        this.renderInterpreterPaymentTable = this.renderInterpreterPaymentTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchEvent = this.handleSearchEvent.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);       
        this.exportELANachaTXT = this.exportELANachaTXT.bind(this);
        this.exportEDD1099TXT = this.exportEDD1099TXT.bind(this);
        this.printPhysicalCheck = this.printPhysicalCheck.bind(this);
    }

    componentDidMount() {
        //this.populateInterpreterPaymentData();
    }



    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateInterpreterData();
        });
    }

    handleSearchEvent(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        if (e.target.name === "Inactive") {
            model[e.target.name] = !this.state.searchModel.Inactive;
        }
        else if (e.target.type === "checkbox")
                model[e.target.name] = e.target.checked;
            else
                model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }


    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateInterpreterPaymentData();
        }
    }

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = params => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths });
        }
    };

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    renderInterpreterPaymentTable(interpreterPayments) {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: interpreterPayments,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                state: this.state,

            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderer

            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleDoubleClick,
            onRowClicked: this.handleClick,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
       );
    }

    async populateInterpreterPaymentData() {

        if (this.state.searchModel.StartDate == null || this.state.searchModel.EndDate == null) {
            this.handleSearchEvent({
                message: "Both Start Date and End Date are required.",
                modal: true,
                color: "warning"
            });
        }
        else {
            this.setState({ fetchingData: true });

            FetchData("/get_InterpreterPayments", "POST", this.user.token, this.state.searchModel).then(data => {
                this.setState({ interpreterPaymentList: data, loading: false, fetchingData: false });
            });

        }
    }

    async addLeadingZeros(num, totalLength){
        return String(num).padStart(totalLength, '0');
    }


    async exportELANachaTXT() {
        let body = {
            StartDate: this.state.searchModel.StartDate,
            EndDate: this.state.searchModel.EndDate,
            CreatedByUserId: this.user.userid
        };

        var currentDate = new Date();
        var currentMonth = currentDate.getMonth() + 1;
        var currentMonthWithZeros = new String();
        currentMonthWithZeros = String(currentMonth).padStart(2, '0');


        var fileName = 'ELANacha' + currentMonthWithZeros.toString() + currentDate.getDate().toString() + currentDate.getFullYear().toString() + '.txt';


        await FetchFile("/rptELANacha", "POST", this.user.token, body).then(blob => {
            saveAs(blob, fileName);
        });
    }

    async exportEDD1099TXT() {
        let body = {
            StartDate: this.state.searchModel.StartDate,
            EndDate: this.state.searchModel.EndDate,
            CreateByUserId: this.user.userid
        };

        var currentDate = new Date();
        var currentMonth = currentDate.getMonth() + 1;
        var currentMonthWithZeros = new String();
        currentMonthWithZeros = String(currentMonth).padStart(2, '0');


        var fileName = 'eddreport' /*+ currentMonthWithZeros.toString() + currentDate.getDate().toString() + currentDate.getFullYear().toString()*/ + '.txt';


        await FetchFile("/rptEDD1099", "POST", this.user.token, body).then(blob => {
            saveAs(blob, fileName);
        });
    }

    async printPhysicalCheck() {
        let body = {
            StartCheckNumber: this.state.searchModel.StartCheckNumber,
            StartDate: this.state.searchModel.StartDate,
            EndDate: this.state.searchModel.EndDate,
            CommitToDB: this.state.searchModel.CommitToDB
        }

        var currentDate = new Date();
        var currentMonth = currentDate.getMonth() + 1;
        var currentMonthWithZeros = new String();
        currentMonthWithZeros = String(currentMonth).padStart(2, '0');


        var fileName = 'InterpreterChecks' + currentMonthWithZeros.toString() + currentDate.getDate().toString() + currentDate.getFullYear().toString() + '.pdf';

        await FetchFile("/printphysicalcheck", "POST", this.user.token, body).then(blob => {
            saveAs(blob, fileName);
        });

    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderInterpreterPaymentTable(this.state.interpreterPaymentList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Interpreter Payments</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <input name="StartDate" type="date"  className="form-control form-control-text" value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} placeholder="StartDate" onKeyPress={this.handleKeyPress} />
                            <input name="EndDate" type="date" className="form-control form-control-text" value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} placeholder="EndDate" onKeyPress={this.handleKeyPress} />
                            <input name="StartCheckNumber" type="text" className="form-control form-control-text" value={this.state.searchModel.StartCheckNumber} onChange={this.handleSearchChange} placeholder="Start Check Number" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup size="sm">
                            {' '}<span style={{ paddingRight: "4px" }}></span><Button color="secondary" className="btn" size="sm" outline onClick={this.exportEDD1099TXT}>Export EDD Report</Button>
                            {' '}<span style={{ paddingRight: "4px" }}></span><Button color="secondary" className="btn" size="sm" outline onClick={this.exportELANachaTXT}>Export ELANacha</Button>
                            {' '}<span style={{ paddingRight: "4px" }}></span><Button color="secondary" className="btn" size="sm" outline onClick={this.printPhysicalCheck}>Print Physical Checks</Button>
                            <label style={{ paddingLeft: '3px' }}><input name="ShowAll" type="checkbox" className="input-checkbox" defaultChecked={this.state.searchModel.CommitToDB} onChange={this.handleSearchChange} />Commit To DB</label>
                        </InputGroup>
                    </Col>
                </Row>
                <hr/>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}

