import React, { Component } from 'react';
import { Modal, Button, ModalHeader, ModalBody, InputGroup, Row, Col, Table } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';
import { InterpreterFormSearchTableDefault } from './InterpreterFormSearchTableDefault';
import { InterpreterForm } from './InterpreterForm';
import AddIcon from '../images/add.png';

export class InterpreterFormSearch extends Component {
    static displayName = InterpreterFormSearch.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            interpreterList: [],
            alert: props.alert,
            
            modalInterpreterForm: false,
            model: {
                EventType: 'insert',
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntGender: '',
                IntPronoun: '',
                IntEmail: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntFax: '',
                IntBeeper: '',
                IntStreet: '',
                IntCity: '',
                IntState: '',
                IntZip: '',
                IntCountry: '',
                IntSSINumber: '',
                IntActiveFlg: true,
                IntCorporationFlg: false,
                DBA: '',
                InHouseFlg: false,
                UnVettedFlg: false,
                EmergencyContact: '',
                UnavailableReason: '',
                BankName: '',
                BankAccount: '',
                BankRoute: '',
                Rate: null,
                HourMinimum: null,
                Prorate: '',
                ConfCallRate: null,
                BusinessCardRequestDate: null,
                BusinessCardRequestToProcessFlg: false,
                IntNote: '',
                ReferredBy: '',
                DefaultLanguage: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                CreatedBy: '',
                UpdatedBy: ''
            },            
            searchModel: {
                Name: props.Name === null ? "" : props.Name
            }
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setModalInterpreterForm = this.setModalInterpreterForm.bind(this);
        this.handleInterpreterSaveEventSuccess = this.handleInterpreterSaveEventSuccess.bind(this);

        if (props.Name !== null && props.Name !== '')
            this.populateInterpreterData();
    }

    setModalInterpreterForm() {
        this.setState({ modalInterpreterForm: !this.state.modalInterpreterForm });
    }

    handleInterpreterSaveEventSuccess(alert) {
        this.setState({
            modalInterpreterForm: alert.modal
        }, () => {
            this.populateInterpreterDataOnSave();
        });
    }

    handleChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateInterpreterData();
        }
    }

    async populateInterpreterData() {
        await FetchData("/getinterpreters", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ interpreterList: data, loading: false });
        });
    }

    async populateInterpreterDataOnSave() {
        await FetchData("/getinterpreters", "POST", this.user.token, this.state.searchModel).then(data => {
            if (data.length > 0)
            {
                var topData = [];
                topData.push(data[0]);
                this.setState({ interpreterList: topData, loading: false });
            }
            else 
                this.setState({ interpreterList: data, loading: false });
        });
    }

    renderInterpreterTable(interpreterList) {
        return (
            <InterpreterFormSearchTableDefault
                interpreterList={interpreterList}
                handleDoubleClick={this.props.handleDoubleClick}
            ></InterpreterFormSearchTableDefault>
        )
    }

    render() {
        return (
            <div style={{ width: '400px'}}>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "800px", maxHeight: "500px" }} backdrop="static">
                    <ModalHeader><span style={{ fontSize: "14px" }}>Search Interpreter</span></ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <input name="Name" type="text" className="form-control form-control-text" value={this.state.searchModel.Name} onChange={this.handleChange} placeholder="Interpreter Name (Lastname Firstname)" onKeyPress={this.handleKeyPress} />
                                        <Button color="btn" size="sm" onClick={this.setModalInterpreterForm} ><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className=" x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: "scroll", height: "450px", backgroundColor: 'unset', width: '100%', marginLeft: '8px', marginRight: '10px' }}>
                                    {this.renderInterpreterTable(this.state.interpreterList)}
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>               
                </Modal>
                {this.state.modalInterpreterForm &&
                    <InterpreterForm model={this.state.model} modal={this.state.modalInterpreterForm} alert={this.state.alert} setModal={this.setModalInterpreterForm} handleSaveEventSuccess={this.handleInterpreterSaveEventSuccess} />
                }
            </div>
        )
    }
}