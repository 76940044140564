import React, { Component } from 'react';
//import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
//import { Link } from 'react-router-dom';
import { NavMenu } from './NavMenu';
import './NavMenu.css';

export class Layout extends Component {

    render() {
        return (
            <div>
                <NavMenu />
                {this.props.children}
                {/*<div className="sidebar x-panel x-border-item x-box-item x-panel-default">*/}
                {/*    <div className="x-panel x-autowidth-table x-grid-header-hidden x-panel-default x-tree-panel x-tree-lines x-grid">*/}
                {/*        <p style={{ padding: "0 6px 1px" }}>Navigation</p>*/}
                {/*        <Nav vertical>*/}
                {/*            <NavItem>*/}
                {/*                <NavLink tag={Link} onClick={() => { this.addTab("workorder", "Work Order", <WorkOrder />)}} className="menu-text">*/}
                {/*                    <img alt="Work Order" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}*/}
                {/*                    Work Order*/}
                {/*                </NavLink>*/}
                {/*            </NavItem>                           */}
                {/*            <NavItem>*/}
                {/*                <NavLink tag={Link} onClick={() => { this.addTab("claimant", "Claimant", <Claimant />) }} className="menu-text">*/}
                {/*                    <img alt="Rep" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}*/}
                {/*                    Claimant*/}
                {/*                </NavLink>*/}
                {/*            </NavItem>*/}
                {/*            <NavItem>*/}
                {/*                <NavLink tag={Link} to="/interpreter" className="menu-text">*/}
                {/*                    <img alt="Client" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}*/}
                {/*                    Interpreter*/}
                {/*                </NavLink>*/}
                {/*            </NavItem>*/}
                {/*            <NavItem>*/}
                {/*                <NavLink tag={Link} to="/client" className="menu-text">*/}
                {/*                    <img alt="Client" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}*/}
                {/*                    Client*/}
                {/*                </NavLink>*/}
                {/*            </NavItem>*/}
                {/*            <NavItem>*/}
                {/*                <NavLink tag={Link} to="/rep" className="menu-text">*/}
                {/*                    <img alt="Rep" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}*/}
                {/*                    Rep*/}
                {/*                </NavLink>*/}
                {/*            </NavItem>*/}
                {/*        </Nav>*/}
                {/*    </div>                    */}
                {/*</div>*/}
                {/*<div className="x-panel-body x-panel-body-default x-layout-fit x-panel-body-default">*/}
                {/*    */}{/* {this.props.children}*/}
                {/*    <Index tabList={this.state.tabList} />*/}
                {/*</div>*/}
            </div>
        );
    }
}
