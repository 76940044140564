import React, { Component, Fragment, } from 'react';
import {
    Button, Row, Col, InputGroup, Alert
} from 'reactstrap';

import ReactTooltip from "react-tooltip";

import { WorkOrderForm } from './WorkOrderForm';
import { WorkOrderTableDefault } from './WorkOrderTableDefault';
import { WorkOrderTablePending } from './WorkOrderTablePending';
import { WorkOrderTableCallback } from './WorkOrderTableCallback';
import { WorkOrderTableContract } from './WorkOrderTableContract';
import { WorkOrderTableAudit } from './WorkOrderTableAudit';
import { WorkOrderTableMyJobs } from './WorkOrderTableMyJobs';
import { WorkOrderTableDeletedJobs } from './WorkOrderTableDeletedJobs';
import { WorkOrderTableConfirmation } from './WorkOrderTableConfirmation';
import { WorkOrderTableBooking } from './WorkOrderTableBooking';
import { WorkOrderTableAuthTransport } from './WorkOrderTableAuthTransport';

import { WorkOrderCopyJob } from './WorkOrderCopyJob';

import { LoadingModal } from './LoadingModal';
import { WorkOrderJobNotes } from './WorkOrderJobNotes';
import { InterpreterForm } from './InterpreterForm';
import { WorkOrderAddJobNotes } from './WorkOrderAddJobNotes';

import { FetchData, FetchFile } from './utils/DataFetch';
import { GetParameterByName, GetCookie, Timeout } from './utils/Common';

import { saveAs } from 'file-saver';
import { InterpreterFormEmail } from './InterpreterFormEmail'
import { RepFormEmail } from './RepFormEmail'
import { ProviderFormEmail } from './ProviderFormEmail'

import AddIcon from '../images/add.png'
//import EmailIcon from '../images/email-icon.png'
//import DollarIcon from '../images/dollar-icon.png'
import RefreshIcon from '../images/merge-icon.png'

import Previous from '../images/prev.png'
import Next from '../images/next.png'

//import {
//    Table,
//    TableHead,
//    TableBody,
//    TableRow,
//    TableCell,
//    TableFooter,
//    TableSortLabel,
//    Paper
//} from "@material-ui/core";

import { WorkOrderIntConfNo } from './WorkOrderIntConfNo'

const moment = require('moment');

export class WorkOrder extends Component {
    static displayName = WorkOrder.name;

    constructor(props) {
        super(props);

        let tableView = props.TableView;

        this.state = {
            currentSelection: false,
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

            workOrderList: [],
            deletedJobsList: [],
            jobHistoryList: [],
            jobInvLineList: [],
            clientForEmail: null,

            executiveUsers: [],

            receivableList: [],
            receivableItemList: [],

            loading: true,

            modal: false,
            fetchingData: false,
            modalIntFormEmail: false,
            modalRepFormEmail: false,
            modalProviderFormEmail: false,
            modalWOJobNotes: false,
            modalInterpreter: false,
            modalAddJobNote: false,
            modalCopyJob: false,
            modalDeletedJob: false,
            modalDJJobHistory: false,

            multiFilter: false,

            selectedIndex: -1,
            selectedJob: null,

            checkAll: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                MyJobWaitingOnCallBack: '',
                JobBalance: null,
                DeletedOn: null,
                WorkFlowId: ''
            },
            client: {
                EventType: 'update',
                ClientId: null,
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientPhoneFax: '',
                ClientName: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientCountry: '',
                ClientDiscount: null,
                ClientNotes: '',
                ClientCreditCard: '',
                ClientEmailForInvoice: '',
                ClientEmail: '',
                ClientWebsite: '',
                ClientOrg: '',
                ClientBillRateType: '',
                ClientInactiveFlg: false,
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            },
            interpreter: {
                EventType: 'insert',
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntGender: '',
                IntPronoun: '',
                IntEmail: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntFax: '',
                IntBeeper: '',
                IntStreet: '',
                IntCity: '',
                IntState: '',
                IntZip: '',
                IntCountry: '',
                IntSSINumber: '',
                IntActiveFlg: false,
                IntCorporationFlg: false,
                DBA: '',
                InHouseflg: false,
                UnVettedFlg: false,
                EmergencyContact: '',
                UnavailableReason: '',
                BankName: '',
                BankAccount: '',
                BankRoute: '',
                Rate: null,
                HourMinimum: null,
                Prorate: '',
                ConfCallRate: null,
                BusinessCardRequestDate: null,
                BusinessCardRequestToProcessFlg: false,
                IntNote: '',
                ReferredBy: '',
                IntGender: '',
                DefaultLanguage: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                CreatedBy: '',
                UpdatedBy: ''
            },
            searchModel: {
                IntId: '',
                ClmId: '',
                JobNum: '',
                StartDate: props.WorkFlowId === '' ? moment() : '',
                EndDate: '',
                Claimant: '',
                ClmClaimNumberNoDash: '',
                CLMPhone: '',
                Interpreter: '',
                Rep: '',
                ClientAbbrv: '',
                ProviderName: '',
                CreatedByUserId: null,
                MyJobs: false,
                MyJobsUserId: props.CurrentUser,
                WorkFlowId: props.WorkFlowId,
            },
            email: {
                EmailFrom: '',
                EmailTo: '',
                EmailCC: '',
                Subject: '',
                Body: '',
                JobIds: ''
            },
            jobNotes: {
                JobNumbers: '',
                Type: '',
                Description: '',
                JobHistory: []
            },

            showButtons: 'cancellation,remote_email,covering_email',
            tableView: tableView,

            modalEmailConfirm: false,
            emailConfirm: {
                notify_type: '',
                JobId: null,
                IntId: null
            }
        };
        //
        this.user = JSON.parse(GetCookie('user'));

        // functions        
        this.setModalIntFormEmail = this.setModalIntFormEmail.bind(this);
        this.setModalRepFormEmail = this.setModalRepFormEmail.bind(this);
        this.setModalProviderFormEmail = this.setModalProviderFormEmail.bind(this);
        this.setModalWOJobNotes = this.setModalWOJobNotes.bind(this);
        this.setModalEmailConfirm = this.setModalEmailConfirm.bind(this);
        this.setModalInterpreter = this.setModalInterpreter.bind(this);
        this.setModalAddJobNote = this.setModalAddJobNote.bind(this);
        this.setModalCopyJob = this.setModalCopyJob.bind(this);

        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleSaveEventIntEmailSuccess = this.handleSaveEventIntEmailSuccess.bind(this);
        this.handleSaveEventRepEmailSuccess = this.handleSaveEventRepEmailSuccess.bind(this);
        this.handleSaveEventProviderEmailSuccess = this.handleSaveEventProviderEmailSuccess.bind(this);
        this.handleSaveEventInterpreterSuccess = this.handleSaveEventInterpreterSuccess.bind(this);
        this.handleSaveEventEmailConfirmSuccess = this.handleSaveEventEmailConfirmSuccess.bind(this);
        this.handleSaveEventJobNotesSuccess = this.handleSaveEventJobNotesSuccess.bind(this);
        this.handleSaveEventCopyJobSuccess = this.handleSaveEventCopyJobSuccess.bind(this);

        // button clicks
        this.resetFields = this.resetFields.bind(this);
        this.refreshSearch = this.refreshSearch.bind(this);
        this.setData = this.setData.bind(this);
        this.setDataNextPrev = this.setDataNextPrev.bind(this);
        this.setJobData = this.setJobData.bind(this);
        this.setNextPrevData = this.setNextPrevData.bind(this);
        this.setModal = this.setModal.bind(this);
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchFocus = this.handleSearchFocus.bind(this);
        this.handleSearchBlur = this.handleSearchBlur.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        // export reports
        this.exportPDF = this.exportPDF.bind(this);
        //
        this.addSchedule = this.addSchedule.bind(this);
        //
        this.setInterpreterEmail = this.setInterpreterEmail.bind(this);
        this.openInterpreter = this.openInterpreter.bind(this);
        this.setRepEmail = this.setRepEmail.bind(this);
        this.setRepEmail2 = this.setRepEmail2.bind(this);
        this.setProviderEmail = this.setProviderEmail.bind(this);
        //
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.resetChecks = this.resetChecks.bind(this);
        this.handleClearChecksOnEmailModalClose = this.handleClearChecksOnEmailModalClose.bind(this);
        //
        this.handlePrevNextButton = this.handlePrevNextButton.bind(this);
        //
        this.viewJobNotes = this.viewJobNotes.bind(this);
        //
        this.handlePendingClick = this.handlePendingClick.bind(this);
        this.handleCallbackClick = this.handleCallbackClick.bind(this);
        this.handleContractClick = this.handleContractClick.bind(this);
        this.handleAuditClick = this.handleAuditClick.bind(this);
        this.handlePiggyBack = this.handlePiggyBack.bind(this);
        this.handleAutoPay = this.handleAutoPay.bind(this);
        this.viewInvoice = this.viewInvoice.bind(this);
        //
        this.handleRepEmailClick = this.handleRepEmailClick.bind(this);
        this.handleIntEmailClick = this.handleIntEmailClick.bind(this);
        this.handleCopyJob = this.handleCopyJob.bind(this);
        this.handleAddJobNoteClick = this.handleAddJobNoteClick.bind(this);
        //
        //
        this.handleIntConfNo = this.handleIntConfNo.bind(this);
        this.handleSendSMS = this.handleSendSMS.bind(this);
        //
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.confirmActivation = this.confirmActivation.bind(this);
        this.viewJobHistory = this.viewJobHistory.bind(this);
        this.setModalDJJobHistory = this.setModalDJJobHistory.bind(this);
        //
        this.renderContent = this.renderContent.bind(this);
        //
        this.setFetchingData = this.setFetchingData.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);

        if (this.props.TableView === 'callback') {
            this.populateWorkOrderCallbackData();
        } else if (this.props.TableView === 'deletedjobs') {
            this.populateDeletedJobsData();
        } else {
            this.populateWorkOrderData();
            if (this.props.isMyJob)
                this.populateExecutiveUsers();

            if (GetParameterByName('jobid') !== null)
                this.openWorkOrderForm(GetParameterByName('jobid'));

        }
    }

    componentDidUpdate(prevProps, prevState) {
        document.addEventListener('keydown', this.keydownHandler);

        ReactTooltip.rebuild();

        if (this.state.clearSelection && !prevState.clearSelection){
            this.setState({ clearSelection: false });
        }

    }

    confirmActivation(e) {
        let model = { ...this.state.searchModel }
        let userModel = { ...this.user }
        if (this.state.searchModel.JobNum !== null && this.state.searchModel.JobNum !== '') {
            var note = window.prompt("Why are you re-activating this job?", "");
            if (note !== null) {
                var data = {
                    JobNum: model.JobNum,
                    UserId: userModel.userid,
                    Note: note
                }

                FetchData("/undelete", "POST", this.user.token, data).then(data => {
                    //console.log(data);

                    this.handleSaveEventSuccess({
                        message: data,
                        model: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                });
                //this.handleSearchFocus(e);

                //console.log(this.state);
                //this.populateDeletedJobsData();               
            }

        }
        else {
            window.confirm("Please enter a Job Number to re-activate")
        }
    }

    setModalDJJobHistory() {
        this.setState({ modalDJJobHistory: !this.state.modalDJJobHistory });
    }

    viewJobHistory(deletedJob) {
        FetchData("/getJobHistory", "POST", this.user.token, { JobId: deletedJob.jobId }).then(data => {
            this.setState({ jobHistoryList: data });
        });
        this.setModalDJJobHistory();
    }

    keydownHandler(e) {
        //console.log(e.keyCode);

        if (e.keyCode === 74 && e.altKey && this.state.modal === false) { // ALT + J
            this.JobNum.focus();
        }
        else if (e.keyCode === 76 && e.altKey && this.state.modal === false) { // ALT + L
            this.ClmClaimNumberNoDash.focus();
        }
        else if (e.keyCode === 84 && e.altKey && this.state.modal === false) { // ALT + T
            this.CLMPhone.focus();
        }
        else if (e.keyCode === 65 && e.altKey && this.state.modal === false) { // ALT + A
            this.ClientAbbrv.focus();
        }
        else if (e.keyCode === 73 && e.altKey && this.state.modal === false) { // ALT + I
            this.Interpreter.focus();
        }
        else if (e.keyCode === 80 && e.altKey && this.state.modal === false) { // ALT + P
            this.ProviderName.focus();
        }
        else if (e.keyCode === 78 && e.altKey && this.state.modal === false) { // ALT + N
            this.resetFields();
        } else if (e.keyCode === 77 && e.altKey && this.state.modal === false) { // ALT + M
            const currentDate = new Date().toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
            this.setState(prevState => ({
                searchModel: {
                    ...prevState.searchModel,
                    Interpreter: `${this.user.lastname} ${this.user.firstname}`,
                    StartDate: currentDate
                }
            }), () => {
                this.refreshSearch(); // Ensure this runs after state update
            });
        }
    }

    setModal() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (!this.state.modal) {
                this.setState({
                    selectedIndex: -1
                });
            }
        });
    }

    setModalIntFormEmail() {
        this.setState({ modalIntFormEmail: !this.state.modalIntFormEmail });
    }

    setModalRepFormEmail() {
        this.setState({ modalRepFormEmail: !this.state.modalRepFormEmail });
    }

    setModalProviderFormEmail() {
        this.setState({ modalProviderFormEmail: !this.state.modalProviderFormEmail });
    }

    setModalWOJobNotes() {
        this.setState({ modalWOJobNotes: !this.state.modalWOJobNotes });
    }

    setModalEmailConfirm() {
        this.setState({ modalEmailConfirm: !this.state.modalEmailConfirm });
    }

    setModalInterpreter() {
        this.setState({
            modalInterpreter: !this.state.modalInterpreter
        }, () => {

        });
    }

    setModalAddJobNote() {
        this.setState({ modalAddJobNote: !this.state.modalAddJobNote });
    }

    setModalCopyJob() {
        this.setState({ modalCopyJob: !this.state.modalCopyJob });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseflg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                MyJobWaitingOnCallBack: '',
                JobBalance: null,
                WorkFlowId: ''
            },
            jobHistoryList: [],
            jobInvLineList: [],
            modal: !this.state.modal
        }));

        // this.setModal();
    }

    refreshSearch() {
        if (this.state.tableView !== 'pending' && this.state.tableView !== 'callback')
            this.populateWorkOrderDataRefresh();
        else if (this.state.tebleView === 'callback') {
            this.populateWorkOrderCallbackData();
        } else if (this.state.tableView === 'pending') {
            this.populateWorkOrderPendingData();
        }
    }

    setDataNextPrev(woData, rowIndex, showModal) {
        FetchData("/getworkorder?jobId=" + woData.jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.intCellTxt,
                    IntPhoneOnly: data.intPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseflg: data.inHouseflg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    MyJobWaitingOnCallBack: data.myJobWaitingOnCallBack,
                    JobBalance: data.jobBalance,
                    WorkFlowId: data.workFlowId
                },
                selectedIndex: rowIndex,
                //modal: showModal
            }));
        });
    }

    setData(woData, rowIndex, form = 'workorder') {
        if (form === 'workorder') {
            FetchData("/getworkorder?jobId=" + woData.jobId, "POST", this.user.token, null).then(data => {
                this.setState(prevState => ({
                    model: {
                        EventType: 'update',
                        JobNum: data.jobNum,
                        JobId: data.jobId,
                        JobDateTime: data.jobDateTime,
                        JobTime: data.jobTime,
                        FinishTime: data.finishTime,
                        FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                        FollowupDate: data.followupDate,
                        ProvId: data.provId,
                        ProviderName: data.providerName,
                        ConfCallNote: data.confCallNote,
                        AptName: data.aptName,
                        AptStreet: data.aptStreet,
                        AptCity: data.aptCity,
                        AptState: data.aptState,
                        AptZip: data.aptZip,
                        AptPhone: data.aptPhone,
                        AptDirection: data.aptDirection,
                        Failedflg: data.failedflg,
                        CallerNote: data.callerNote,
                        InvoiceNote: data.invoiceNote,
                        IntCheckDate: data.intCheckDate,
                        IntCheckNum: data.intCheckNum,
                        Invoicedflg: data.invoicedflg,
                        JobTotal: data.jobTotal,
                        JobDiscount: data.jobDiscount,
                        IntFee: data.intFee === null ? null : parseInt(data.intFee),
                        IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                        IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                        IntFeeNote: data.intFeeNote,
                        Transportationflg: data.transportationflg,
                        TransConfflg: data.transConfflg,
                        IntConfflg: data.intConfflg,
                        AppointmentTypeId: data.appointmentTypeId,
                        CallerType: data.callerType,
                        ProvConfflg: data.provConfflg,
                        ClmConfflg: data.clmConfflg,
                        IsDeletedflg: data.isDeletedflg,
                        BillReviewedflg: data.billReviewedflg,
                        Auditflg: data.auditflg,
                        PurpleReportflg: data.purpleReportflg,
                        RepAuthorizedflg: data.repAuthorizedflg,
                        Selectedflg: data.selectedflg,
                        ClmId: data.clmId,
                        ClmFirstName: data.clmFirstName,
                        ClmLastName: data.clmLastName,
                        ClmClaimNumber: data.clmClaimNumber,
                        ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                        ClmPhone: data.clmPhone,
                        ClmDateOfLoss: data.clmDateOfLoss,
                        ClmDateOfInjury: data.clmDateOfInjury,
                        ClmNote: data.clmNote,
                        ClmDOB: data.clmDOB,
                        ClmInsuredParty: data.clmInsuredParty,
                        ClmEmployer: data.clmEmployer,
                        ClmInvoiceCaption: data.clmInvoiceCaption,
                        ClmLanguage: data.clmLanguage,
                        ClmWCABNumber: data.clmWCABNumber,
                        ClmInvoiceType: data.clmInvoiceType,
                        ClmStreet: data.clmStreet,
                        ClmCity: data.clmCity,
                        ClmState: data.clmState,
                        ClmZip: data.clmZip,
                        ClmSSN: data.clmSSN,
                        ClmOrderedByName: data.clmOrderedByName,
                        ClmDiagnosis: data.clmDiagnosis,
                        ClmEmail: data.clmEmail,
                        RepID: data.repID,
                        RepFirstName: data.repFirstName,
                        RepLastName: data.repLastName,
                        RepPhone: data.repPhone,
                        RepEmail: data.repEmail,
                        ClientId: data.clientId,
                        ClientAbbrv: data.clientAbbrv,
                        ClientName: data.clientName,
                        ClientStreet: data.clientStreet,
                        ClientStreet2: data.clientStreet2,
                        ClientCity: data.clientCity,
                        ClientState: data.clientState,
                        ClientZip: data.clientZip,
                        ClientMailStreet: data.clientMailStreet,
                        ClientMailStreet2: data.clientMailStreet2,
                        ClientMailCity: data.clientMailCity,
                        ClientMailState: data.clientMailState,
                        ClientMailZip: data.clientMailZip,
                        ClientPhone: data.clientPhone,
                        ClientPhoneFax: data.clientPhoneFax,
                        ClientPhone2: data.clientPhone2,
                        ClientEmail: data.clientEmail,
                        ClientInactiveFlg: data.clientInactiveFlg,
                        IntId: data.intId,
                        IntLastName: data.intLastName,
                        IntFirstName: data.intFirstName,
                        IntCellTxt: data.intCellTxt,
                        IntPhoneOnly: data.intPhoneOnly,
                        IntEmail: data.intEmail,
                        IntNote: data.intNote,
                        InHouseflg: data.inHouseflg,
                        IntCity: data.intCity,
                        IntStreet: data.intStreet,
                        IntState: data.intState,
                        IntZip: data.intZip,
                        DBA: data.dDBA,
                        Rate: data.rate,
                        HourMinimum: data.hourMinimum,
                        Prorate: data.prorate,
                        BankRoute: data.bankRoute,
                        BankAccount: data.bankAccount,
                        IntSSINumber: data.intSSINumber,
                        FirstName: data.firstName,
                        LastName: data.lastName,
                        UserType: data.userType,
                        UserIntId: data.userIntId,
                        CreateDateTime: data.createDateTime,
                        CreateByUserId: data.createByUserId,
                        UpdateDateTime: data.updateDateTime,
                        UpdateByUserId: data.updateByUserId,
                        RemIntflg: data.remIntflg,
                        RemClaimflg: data.remClaimflg,
                        RemProvflg: data.remProvflg,
                        RemFinalflg: data.remFinalflg,
                        RemType: data.remType,
                        RemTypeDesc: data.remTypeDesc,
                        RemHost: data.remHost,
                        RemHostDesc: data.remHostDesc,
                        CreatedBy: data.createdBy,
                        UpdatedBy: data.updatedBy,
                        MyJobTJobHistory: data.myJobTJobHistory,
                        MyJobWaitingOnCallBack: data.myJobWaitingOnCallBack,
                        JobBalance: data.jobBalance,
                        WorkFlowId: data.workFlowId
                    },
                    selectedIndex: rowIndex,
                    //modal: showModal
                }), () => {
                    this.setModal();
                });
            });
        } else {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    JobNum: woData.jobNum,
                    JobId: woData.jobId,
                    JobDateTime: woData.jobDateTime,
                    JobTime: woData.jobTime,
                    FinishTime: woData.finishTime,
                    FinishTimeString: woData.finishTimeString == "00:00" ? null : woData.finishTimeString,
                    FollowupDate: woData.followupDate,
                    ProvId: woData.provId,
                    ProviderName: woData.providerName,
                    ConfCallNote: woData.confCallNote,
                    AptName: woData.aptName,
                    AptStreet: woData.aptStreet,
                    AptCity: woData.aptCity,
                    AptState: woData.aptState,
                    AptZip: woData.aptZip,
                    AptPhone: woData.aptPhone,
                    AptDirection: woData.aptDirection,
                    Failedflg: woData.failedflg,
                    CallerNote: woData.callerNote,
                    InvoiceNote: woData.invoiceNote,
                    IntCheckDate: woData.intCheckDate,
                    IntCheckNum: woData.intCheckNum,
                    Invoicedflg: woData.invoicedflg,
                    JobTotal: woData.jobTotal,
                    JobDiscount: woData.jobDiscount,
                    IntFee: woData.intFee === null ? null : parseFloat(woData.intFee).toFixed(2),
                    IntFeeOther: woData.intFeeOther === null ? null : parseFloat(woData.intFeeOther).toFixed(2),
                    IntOTFee: woData.intOTFee === null ? null : parseFloat(woData.intOTFee).toFixed(2),
                    IntFeeNote: woData.intFeeNote,
                    Transportationflg: woData.transportationflg,
                    TransConfflg: woData.transConfflg,
                    IntConfflg: woData.intConfflg,
                    AppointmentTypeId: woData.appointmentTypeId,
                    CallerType: woData.callerType,
                    ProvConfflg: woData.provConfflg,
                    ClmConfflg: woData.clmConfflg,
                    IsDeletedflg: woData.isDeletedflg,
                    BillReviewedflg: woData.billReviewedflg,
                    Auditflg: woData.auditflg,
                    PurpleReportflg: woData.purpleReportflg,
                    RepAuthorizedflg: woData.repAuthorizedflg,
                    Selectedflg: woData.selectedflg,
                    ClmId: woData.clmId,
                    ClmFirstName: woData.clmFirstName,
                    ClmLastName: woData.clmLastName,
                    ClmClaimNumber: woData.clmClaimNumber,
                    ClmClaimNumberNoDash: woData.clmClaimNumberNoDash,
                    ClmPhone: woData.clmPhone,
                    ClmDateOfLoss: woData.clmDateOfLoss,
                    ClmDateOfInjury: woData.clmDateOfInjury,
                    ClmNote: woData.clmNote,
                    ClmDOB: woData.clmDOB,
                    ClmInsuredParty: woData.clmInsuredParty,
                    ClmEmployer: woData.clmEmployer,
                    ClmInvoiceCaption: woData.clmInvoiceCaption,
                    ClmLanguage: woData.clmLanguage,
                    ClmWCABNumber: woData.clmWCABNumber,
                    ClmInvoiceType: woData.clmInvoiceType,
                    ClmStreet: woData.clmStreet,
                    ClmCity: woData.clmCity,
                    ClmState: woData.clmState,
                    ClmZip: woData.clmZip,
                    ClmSSN: woData.clmSSN,
                    ClmOrderedByName: woData.clmOrderedByName,
                    ClmDiagnosis: woData.clmDiagnosis,
                    ClmEmail: woData.clmEmail,
                    RepID: woData.repID,
                    RepFirstName: woData.repFirstName,
                    RepLastName: woData.repLastName,
                    RepPhone: woData.repPhone,
                    RepEmail: woData.repEmail,
                    ClientId: woData.clientId,
                    ClientAbbrv: woData.clientAbbrv,
                    ClientName: woData.clientName,
                    ClientStreet: woData.clientStreet,
                    ClientStreet2: woData.clientStreet2,
                    ClientCity: woData.clientCity,
                    ClientState: woData.clientState,
                    ClientZip: woData.clientZip,
                    ClientMailStreet: woData.clientMailStreet,
                    ClientMailStreet2: woData.clientMailStreet2,
                    ClientMailCity: woData.clientMailCity,
                    ClientMailState: woData.clientMailState,
                    ClientMailZip: woData.clientMailZip,
                    ClientPhone: woData.clientPhone,
                    ClientPhoneFax: woData.clientPhoneFax,
                    ClientPhone2: woData.clientPhone2,
                    ClientEmail: woData.clientEmail,
                    ClientInactiveFlg: woData.clientInactiveFlg,
                    IntId: woData.intId,
                    IntLastName: woData.intLastName,
                    IntFirstName: woData.intFirstName,
                    IntCellTxt: woData.intCellTxt,
                    IntPhoneOnly: woData.intPhoneOnly,
                    IntEmail: woData.intEmail,
                    IntNote: woData.intNote,
                    InHouseflg: woData.inHouseflg,
                    IntCity: woData.intCity,
                    IntStreet: woData.intStreet,
                    IntState: woData.intState,
                    IntZip: woData.intZip,
                    DBA: woData.dDBA,
                    Rate: woData.rate,
                    HourMinimum: woData.hourMinimum,
                    Prorate: woData.prorate,
                    BankRoute: woData.bankRoute,
                    BankAccount: woData.bankAccount,
                    IntSSINumber: woData.intSSINumber,
                    FirstName: woData.firstName,
                    LastName: woData.lastName,
                    UserType: woData.userType,
                    UserIntId: woData.userIntId,
                    CreateDateTime: woData.createDateTime,
                    CreateByUserId: woData.createByUserId,
                    UpdateDateTime: woData.updateDateTime,
                    UpdateByUserId: woData.updateByUserId,
                    RemIntflg: woData.remIntflg,
                    RemClaimflg: woData.remClaimflg,
                    RemProvflg: woData.remProvflg,
                    RemFinalflg: woData.remFinalflg,
                    RemType: woData.remType,
                    RemTypeDesc: woData.remTypeDesc,
                    RemHost: woData.remHost,
                    RemHostDesc: woData.remHostDesc,
                    CreatedBy: woData.createdBy,
                    UpdatedBy: woData.updatedBy,
                    MyJobTJobHistory: woData.myJobTJobHistory,
                    JobBalance: woData.jobBalance
                },
                selectedIndex: rowIndex,
            }), () => {
                this.setModal();
            });
        }
    }

    setJobData(woData) {
        //console.log(woData);
        this.setState(prevState => ({ selectedJob: woData }));
    }

    addSchedule(woData, rowIndex) {

        let currentDate = new Date();
        let currentMonth = currentDate.getMonth() + 1;
        const time = currentDate.toLocaleTimeString();
        let callerNote = 'Scheduled From Calendar ' + currentMonth + '/' + currentDate.getDate() + '/' + currentDate.getFullYear() + ' ' + time + ' By: ' + this.user.firstname + ' ' + this.user.lastname;

        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: woData.provId,
                ProviderName: woData.providerName,
                ConfCallNote: woData.confCallNote,
                AptName: woData.aptName,
                AptStreet: woData.aptStreet,
                AptCity: woData.aptCity,
                AptState: woData.aptState,
                AptZip: woData.aptZip,
                AptPhone: woData.aptPhone,
                AptDirection: woData.aptDirection,
                Failedflg: woData.failedflg,
                CallerNote: callerNote,
                InvoiceNote: "", //woData.invoiceNote,
                IntCheckDate: null, //woData.intCheckDate,
                IntCheckNum: null, //woData.intCheckNum,
                Invoicedflg: false,
                JobTotal: 0, //woData.jobTotal,
                JobDiscount: woData.jobDiscount,
                IntFee: null,
                IntFeeOther: null,
                Transportationflg: woData.transportationflg,
                TransConfflg: woData.transConfflg,
                IntConfflg: false,
                AppointmentTypeId: woData.appointmentTypeId,
                CallerType: 4,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: woData.isDeletedflg,
                BillReviewedflg: false, //woData.billReviewedflg,
                Auditflg: woData.auditflg,
                PurpleReportflg: woData.purpleReportflg,
                RepAuthorizedflg: false,
                Selectedflg: woData.selectedflg,
                ClmId: woData.clmId,
                ClmFirstName: woData.clmFirstName,
                ClmLastName: woData.clmLastName,
                ClmClaimNumber: woData.clmClaimNumber,
                ClmClaimNumberNoDash: woData.clmClaimNumberNoDash,
                ClmPhone: woData.clmPhone,
                ClmDateOfLoss: woData.clmDateOfLoss,
                ClmDateOfInjury: woData.clmDateOfInjury,
                ClmNote: woData.clmNote,
                ClmDOB: woData.clmDOB,
                ClmInsuredParty: woData.clmInsuredParty,
                ClmEmployer: woData.clmEmployer,
                ClmInvoiceCaption: woData.clmInvoiceCaption,
                ClmLanguage: woData.clmLanguage,
                ClmWCABNumber: woData.clmWCABNumber,
                ClmInvoiceType: woData.clmInvoiceType,
                ClmStreet: woData.clmStreet,
                ClmCity: woData.clmCity,
                ClmState: woData.clmState,
                ClmZip: woData.clmZip,
                ClmSSN: woData.clmSSN,
                ClmOrderedByName: woData.clmOrderedByName,
                ClmDiagnosis: woData.clmDiagnosis,
                ClmEmail: woData.clmEmail,
                RepID: woData.repID,
                RepFirstName: woData.repFirstName,
                RepLastName: woData.repLastName,
                RepPhone: woData.repPhone,
                RepEmail: woData.repEmail,
                ClientId: woData.clientId,
                ClientAbbrv: woData.clientAbbrv,
                ClientName: woData.clientName,
                ClientStreet: woData.clientStreet,
                ClientStreet2: woData.clientStreet2,
                ClientCity: woData.clientCity,
                ClientState: woData.clientState,
                ClientZip: woData.clientZip,
                ClientMailStreet: woData.clientMailStreet,
                ClientMailStreet2: woData.clientMailStreet2,
                ClientMailCity: woData.clientMailCity,
                ClientMailState: woData.clientMailState,
                ClientMailZip: woData.clientMailZip,
                ClientPhone: woData.clientPhone,
                ClientPhoneFax: woData.clientPhoneFax,
                ClientPhone2: woData.clientPhone2,
                ClientEmail: woData.clientEmail,
                ClientInactiveFlg: woData.clientInactiveFlg,
                IntId: woData.intId,
                IntLastName: woData.intLastName,
                IntFirstName: woData.intFirstName,
                IntCellTxt: woData.IntCellTxt,
                IntPhoneOnly: woData.IntPhoneOnly,
                IntEmail: woData.intEmail,
                IntNote: woData.intNote,
                InHouseflg: woData.inHouseflg,
                IntCity: woData.intCity,
                IntStreet: woData.intStreet,
                IntState: woData.intState,
                IntZip: woData.intZip,
                DBA: woData.dDBA,
                Rate: woData.rate,
                HourMinimum: woData.hourMinimum,
                Prorate: woData.prorate,
                BankRoute: woData.bankRoute,
                BankAccount: woData.bankAccount,
                IntSSINumber: woData.intSSINumber,
                FirstName: woData.firstName,
                LastName: woData.lastName,
                UserType: woData.userType,
                UserIntId: woData.userIntId,
                CreateDateTime: woData.createDateTime,
                CreateByUserId: woData.createByUserId,
                UpdateDateTime: woData.updateDateTime,
                UpdateByUserId: woData.updateByUserId,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false, //woData.remFinalflg,
                RemType: null, //woData.remType,
                RemTypeDesc: woData.remTypeDesc,
                RemHost: null, //woData.remHost,
                RemHostDesc: woData.remHostDesc,
                CreatedBy: woData.createdBy,
                UpdatedBy: woData.updatedBy,
            },
            selectedIndex: rowIndex,
            jobHistoryList: [],
            jobInvLineList: []
        }));
    }

    setInterpreterEmail(woData) {
        var checkMailedOn = '';
        var d = new Date(woData.jobDateTime);
        var newD;
        if (d.getDate() <= 15) {
            newD = new Date((d.getMonth() + 1) + '/15/' + d.getFullYear());
        }
        else {
            newD = moment(d).add(1, 'M').toDate();
            //console.log(newD);
            newD = new Date((newD.getMonth() + 1) + '/1/' + newD.getFullYear());
        };

        switch (newD.getDay()) {
            //case 0:
            //    newD = Ext.Date.add(newD, Ext.Date.DAY, 1);
            //    break;
            case 4:
                newD = moment(newD).add(5, 'd').toDate(); //Ext.Date.add(newD, Ext.Date.DAY, 5);
                break;
            case 5:
                newD = moment(newD).add(4, 'd').toDate();
                break;
            case 6:
                newD = moment(newD).add(3, 'd').toDate();
                break;
            default:
                newD = moment(newD).add(2, 'd').toDate();
        }

        checkMailedOn = moment(newD).format('MM/DD/YYYY');
        var checkMailedOnDate = new Date(checkMailedOn);

        var body = 'Dear ' + woData.intFirstName + ',';
        body += '\n\nPlease ** REPLY TO THIS EMAIL ** to confirm this is in your book:';
        body += '\n\nJob: ' + woData.jobNum;
        body += '\nFor: ' + woData.clmFirstName + ' ' + woData.clmLastName;
        body += '\nOn: ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ' (' + this.state.dayNames[moment(woData.jobDateTime).day()] + ') ' + ' at ' + moment(woData.jobDateTime).format('hh:mm A');
        body += '\nAt: ' + woData.providerName;
        body += '\n    ' + woData.aptStreet;
        body += '\n    ' + woData.aptCity + ', ' + woData.aptState + ' ' + woData.aptZip;

        body += '\n\nIf there is any problem or if the patient does not appear within 15 mins of start time, please CALL US right away. If you are not required to interpret, you MUST call us BEFORE leaving the appointment.';

        if (woData.rate != null && woData.hourMinimum != null && woData.prorate != "") {
            body += '\n\nWe have your rate as $' + woData.rate + ' an hour, ';
            body += 'with a ' + woData.hourMinimum + ' hour minimum, ';
            body += 'additional time paid in ' + woData.prorate + ' increments. ';
        }

        body += 'Your payment will be deposited into your account by ' + this.state.monthNames[checkMailedOnDate.getMonth()] + ' ' + checkMailedOnDate.getDate() + ', ' + checkMailedOnDate.getFullYear() + '. ';
        body += '\nPlease refer to https://portal.e-ela.com/faq for any other questions.';
        body += '\n\nIMPORTANT COVID-19 INFORMATION';
        body += '\n\nMore medical offices are requiring proof of vaccination. If you are vaccinated, Executive Linguist recommends carrying proof of vaccination with you.';
        body += '\n\nExecutive Linguist Agency, Inc. requires its contracted interpreters to follow all local, state, and federal health guidelines and mandates. If you encounter a situation at a scheduled appointment where guidelines and mandates are not followed, please call us immediately.';

        body += '\n\nTo access our Portal and FAQ click at <a href="https://portal.e-ela.com" target="_blank">https://portal.e-ela.com</a>';

        body += '\n\nThank you,';
        body += '\n\n' + this.user.firstname + ' ' + this.user.lastname;

        body += '\n\nExecutive Linguist Agency \n800-522-2320 | Text: 310-955-4513 | 8am - 5pm Nationwide \n After-hours Emergencies: 310-749-6521 or 562-682-3924 \nQuestions: mail@executivelinguist.com';

        body += '\n\nIf you have symptoms of COVID-19 or return a job due to sickness with symptoms related to COVID-19, we will cover your assignments until you present COVID-Negative test results or 14 days have passed and you have self-isolated since your onset of symptoms. Note: Some providers may have these same requirements regardless of symptom type.';

        this.setState(prevState => ({
            selectedJob: woData,
            showButtons: 'cancellation,remote_email,covering_email',
            email: {
                EmailFrom: this.user.email,
                EmailTo: woData.intEmail,
                EmailCC: '',
                Subject: 'Interpreting Assignment',
                Body: body,
                JobIds: woData.jobId.toString(),
                CreatedByUserId: this.user.userid
            }
        }));
        this.setModalIntFormEmail();
    }

    openInterpreter(intId) {
        FetchData("/getinterpreters", "POST", this.user.token, { IntId: intId }).then(data => {
            this.setState(prevState => ({
                interpreter: {
                    EventType: 'update',
                    IntId: data[0].intId,
                    IntLastName: data[0].intLastName,
                    IntFirstName: data[0].intFirstName,
                    IntGender: data[0].intGender,
                    IntPronoun: data[0].intPronoun,
                    IntEmail: data[0].intEmail,
                    IntCellTxt: data[0].intCellTxt,
                    IntPhoneOnly: data[0].intPhoneOnly,
                    IntFax: data[0].intFax,
                    IntBeeper: data[0].intBeeper,
                    IntStreet: data[0].intStreet,
                    IntCity: data[0].intCity,
                    IntState: data[0].intState,
                    IntZip: data[0].intZip,
                    IntCountry: data[0].intCountry,
                    IntSSINumber: data[0].intSSINumber,
                    IntActiveFlg: data[0].intActiveFlg,
                    IntCorporationFlg: data[0].intCorporationFlg,
                    DBA: data[0].dBA,
                    InHouseflg: data[0].inHouseflg,
                    UnVettedFlg: data[0].unVettedFlg,
                    EmergencyContact: data[0].emergencyContact,
                    UnavailableReason: data[0].unavailableReason,
                    BankName: data[0].bankName,
                    BankAccount: data[0].bankAccount,
                    BankRoute: data[0].bankRoute,
                    Rate: data[0].rate,
                    HourMinimum: data[0].hourMinimum,
                    Prorate: data[0].prorate,
                    ConfCallRate: data[0].confCallRate,
                    BusinessCardRequestDate: data[0].businessCardRequestDate,
                    BusinessCardRequestToProcessFlg: data[0].businessCardRequestToProcessFlg,
                    IntNote: data[0].intNote,
                    ReferredBy: data[0].referredBy,
                    IntGender: data[0].intGender,
                    CreateDateTime: data[0].createDateTime,
                    CreateByUserId: data[0].createByUserId,
                    UpdateByUserId: data[0].updateByUserId,
                    UpdateDateTime: data[0].updateDateTime,
                    IsDeletedFlg: data[0].isDeletedFlg,
                    CreatedBy: data[0].createdBy,
                    UpdatedBy: data[0].updatedBy
                }
            }), () => {
                this.setModalInterpreter();
            });
        });
    }
    
    setRepEmail(woData) {
        var checkMailedOn = '';
        var d = new Date(woData.jobDateTime);
        var newD;
        if (d.getDate() <= 15) {
            newD = new Date((d.getMonth() + 1) + '/15/' + d.getFullYear());
        }
        else {
            newD = moment(d).add(1, 'M').toDate();
            //console.log(newD);
            newD = new Date((newD.getMonth() + 1) + '/1/' + newD.getFullYear());
        };

        switch (newD.getDay()) {
            //case 0:
            //    newD = Ext.Date.add(newD, Ext.Date.DAY, 1);
            //    break;
            case 4:
                newD = moment(newD).add(5, 'd').toDate(); //Ext.Date.add(newD, Ext.Date.DAY, 5);
                break;
            case 5:
                newD = moment(newD).add(4, 'd').toDate();
                break;
            case 6:
                newD = moment(newD).add(3, 'd').toDate();
                break;
            default:
                newD = moment(newD).add(2, 'd').toDate();
        }

        checkMailedOn = moment(newD).format('MM/DD/YYYY');
        // var checkMailedOnDate = new Date(checkMailedOn);

        var subject = woData.clmFirstName + ' ' + woData.clmLastName + ' CLM # ' + woData.clmClaimNumber;
        subject += ' - ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ' at ' + moment(woData.jobDateTime).format('hh:mm A');

        var body = 'Dear ' + woData.repFirstName + ',';
        //body += '\n\nPlease ** REPLY TO THIS EMAIL ** to confirm this is in your book:';
        body += '\n\nJob: ' + woData.jobNum;
        body += '\nFor: ' + woData.clmFirstName + ' ' + woData.clmLastName;
        body += '\nOn: ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ' (' + this.state.dayNames[moment(woData.jobDateTime).day()] + ') ' + ' at ' + moment(woData.jobDateTime).format('hh:mm A');
        body += '\nAt: ' + woData.providerName;
        body += '\n    ' + woData.aptStreet;
        body += '\n    ' + woData.aptCity + ', ' + woData.aptState + ' ' + woData.aptZip;

        body += '\n\nExecutive Linguist Agency, Inc. staff and its contracted interpreters are following all local, state, and federal health guidelines and mandates.';
        body += '\nIf an interpreter encounters a situation at a scheduled appointment where guidelines and mandates are not followed, the interpreter has the right to discontinue service but must notify us first.';
        body += '\nThe payor will be notified and may be charged in full if the interpreter has to be compensated for their scheduled time';
        body += '\n\n\nThank you,';
        body += '\n\n' + this.user.firstname + ' ' + this.user.lastname;

        body += '\n\nExecutive Linguist Agency \n800-522-2320 | 310-376-1409 | 8am - 5pm Nationwide \nDiversified Language Services – Translation & Localization \nVideo Remote, Telephonic, and On - site Interpreting \nrequests: mail@executivelinguist.com';

        console.log(woData);

        this.setState(prevState => ({
            selectedJob: woData,
            email: {
                EmailFrom: this.user.email,
                EmailTo: woData.repEmail,
                EmailCC: '',
                Subject: subject,
                Body: body,
                JobIds: woData.jobId.toString(),
                EmailType: 'email1',
                CreatedByUserId: this.user.userid
            }
        }), () => {
            this.setModalRepFormEmail();
        });
    }

    async setRepEmail2(woData) {
        var checkMailedOn = '';
        await FetchData("/getclients", "POST", this.user.token, { ClientId: woData.clientId }).then(data => {
            this.setState({ clientForEmail: data, fetchingData: false });
        });

        var client = this.state.clientForEmail[0];

        var emailAddress = '';
        if (client.clientEmailForInvoice !== null) {
            if (client.clientEmailForInvoice.includes('@')) {
                emailAddress = client.clientEmailForInvoice;
            } else {
                emailAddress = woData.repEmail;
            }
        } else {
            emailAddress = woData.repEmail;
        }

        var d = new Date(woData.jobDateTime);
        var newD;
        if (d.getDate() <= 15) {
            newD = new Date((d.getMonth() + 1) + '/15/' + d.getFullYear());
        }
        else {
            newD = moment(d).add(1, 'M').toDate();
            //console.log(newD);
            newD = new Date((newD.getMonth() + 1) + '/1/' + newD.getFullYear());
        };

        switch (newD.getDay()) {
            //case 0:
            //    newD = Ext.Date.add(newD, Ext.Date.DAY, 1);
            //    break;
            case 4:
                newD = moment(newD).add(5, 'd').toDate(); //Ext.Date.add(newD, Ext.Date.DAY, 5);
                break;
            case 5:
                newD = moment(newD).add(4, 'd').toDate();
                break;
            case 6:
                newD = moment(newD).add(3, 'd').toDate();
                break;
            default:
                newD = moment(newD).add(2, 'd').toDate();
        }

        checkMailedOn = moment(newD).format('MM/DD/YYYY');
        // var checkMailedOnDate = new Date(checkMailedOn);

        var subject = woData.clmFirstName + ' ' + woData.clmLastName + ' CLM # ' + woData.clmClaimNumber;
        subject += ' - ' + moment(woData.jobDateTime).format('MM/DD/YYYY'); //+ ' at ' + moment(woData.jobDateTime).format('hh:mm A');

        var body = 'Dear ' + woData.repFirstName + ',';
        body += '\n\nPlease remit payment at your earliest convenience. Feel free to contact us at (800) 522-2320 if you have questions.';

        body += '\n\n\nThank you,';
        body += '\n\n' + this.user.firstname + ' ' + this.user.lastname;

        body += '\n\nExecutive Linguist Agency \n800-522-2320 | 310-376-1409 | 8am - 5pm Nationwide \nDiversified Language Services – Translation & Localization \nVideo Remote, Telephonic, and On - site Interpreting \nrequests: mail@executivelinguist.com';

        this.setState(prevState => ({
            selectedJob: woData,
            email: {
                EmailFrom: this.user.email,
                EmailTo: emailAddress,
                EmailCC: '',
                Subject: subject,
                Body: body,
                JobNums: woData.jobNum.toString(),
                JobIds: woData.jobId.toString(),
                EmailType: 'email2',
                CreatedByUserId: this.user.userid
            }
        }));
        this.setModalRepFormEmail();
    }

    setProviderEmail(woData) {
        var checkMailedOn = '';
        var d = new Date(woData.jobDateTime);
        var newD;
        if (d.getDate() <= 15) {
            newD = new Date((d.getMonth() + 1) + '/15/' + d.getFullYear());
        }
        else {
            newD = moment(d).add(1, 'M').toDate();
            //console.log(newD);
            newD = new Date((newD.getMonth() + 1) + '/1/' + newD.getFullYear());
        };

        switch (newD.getDay()) {
            //case 0:
            //    newD = Ext.Date.add(newD, Ext.Date.DAY, 1);
            //    break;
            case 4:
                newD = moment(newD).add(5, 'd').toDate(); //Ext.Date.add(newD, Ext.Date.DAY, 5);
                break;
            case 5:
                newD = moment(newD).add(4, 'd').toDate();
                break;
            case 6:
                newD = moment(newD).add(3, 'd').toDate();
                break;
            default:
                newD = moment(newD).add(2, 'd').toDate();
        }

        checkMailedOn = moment(newD).format('MM/DD/YYYY');
        // var checkMailedOnDate = new Date(checkMailedOn);

        var subject = 'Confirmation Request for ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ', ' + woData.clmFirstName + ' ' + woData.clmLastName;

        var body = 'Good Day,';
        body += '\nIn order to provide interpreting services, we need to confirm that the information is correct for this appointment:';
        //body += '\n\nPlease ** REPLY TO THIS EMAIL ** to confirm this is in your book:';
        body += '\n\nJobId: ' + woData.jobNum;
        body += '\nFor: ' + woData.clmFirstName + ' ' + woData.clmLastName;
        body += '\nOn: ' + moment(woData.jobDateTime).format('MM/DD/YYYY') + ' (' + this.state.dayNames[moment(woData.jobDateTime).day()] + ') ' + ' at ' + moment(woData.jobDateTime).format('hh:mm A');
        body += '\nAt: ' + woData.providerName;
        body += '\n    ' + woData.aptStreet;
        body += '\n    ' + woData.aptCity + ', ' + woData.aptState + ' ' + woData.aptZip;
        body += '\nExpected Duration: ';

        body += '\n\n<b>Please let me know if everything is correct of if we need to make any changes.</b> ';
        body += '\n\nThank you!';


        this.setState(prevState => ({
            selectedJob: woData,
            email: {
                EmailFrom: this.user.email,
                EmailTo: woData.repEmail,
                EmailCC: '',
                Subject: subject,
                Body: body,
                JobIds: woData.jobId.toString(),
                EmailType: 'email1',
                CreatedByUserId: this.user.userid
            }
        }), () => {
            this.setModalProviderFormEmail();
        });
    }

    setNextPrevData(rowIndex) {
        let woData = []
        if (this.state.selectedIndex + rowIndex < 0) {
            this.setState({
                selectedIndex: 0
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setDataNextPrev(woData, this.state.selectedIndex, true);
            })
        } else if (this.state.selectedIndex + rowIndex > this.state.workOrderList.length) {
            this.setState({
                selectedIndex: this.state.workOrderList.length
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setDataNextPrev(woData, this.state.selectedIndex, true);
            })
        } else {
            this.setState({
                selectedIndex: this.state.selectedIndex + rowIndex
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setDataNextPrev(woData, this.state.selectedIndex, true);
            })
        }
    }

    getJobHistory(jobId) {
        let model = {
            JobId: jobId
        };

        FetchData("/getjobhistory", "POST", this.user.token, model).then(data => {
            this.setState({ jobHistoryList: data, fetchingData: false });
        });
    }

    getJoInvLine(jobId) {
        let model = {
            JobId: jobId
        };
        FetchData("/getjobinvline", "POST", this.user.token, model).then(data => {
            this.setState({ jobInvLineList: data, fetchingData: false });
        });

    }

    getClientForRepEmail(clientId) {
        let searchModel = {
            ClientId: clientId
        };


        FetchData("/getclients", "POST", this.user.token, searchModel).then(data => {
            this.setState(prevState => ({
                client: {
                    EventType: 'update',
                    ClientId: null,
                    ClientAbbrv: data.clientAbbrv,
                    ClientPhone: data.clientPhone,
                    ClientPhone2: data.clientPhone2,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientName: data.clientName,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientCountry: data.clientCountry,
                    ClientDiscount: data.clientDiscount,
                    ClientNotes: data.clientNotes,
                    ClientCreditCard: data.clientCreditCard,
                    ClientEmailForInvoice: data.clientEmailForInvoice,
                    ClientEmail: data.clientEmail,
                    ClientWebsite: data.clientWebsite,
                    ClientOrg: data.clientOrd,
                    ClientBillRateType: data.clientBillRateType,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.CreateByUserId,
                    UpdateByUserId: data.UpdateByUserId,
                    UpdateDateTime: data.UpdateDateTime,
                    IsDeletedFlg: false
                },
            }))
        });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            fetchingData: false,
            selectedJob: null,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));
            /*if (alert.modal === false)*/
            if (this.state.tableView !== 'deletedjobs' && this.state.tableView !== 'pending' && this.state.tableView !== 'callback')
                this.populateWorkOrderData();
            else if (this.state.tableView === 'callback')
                this.populateWorkOrderCallbackData();
            else if (this.state.tableView === 'deletedjobs')
                this.populateDeletedJobsData();
            else if (this.state.tableView === 'pending')
                this.populateWorkOrderPendingData();
        });
    }

    handleSaveEventIntEmailSuccess(alert) {
        this.setState({
            modalIntFormEmail: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message))
            this.unCheckAll();
        });
    }

    handleSaveEventRepEmailSuccess(alert) {
        this.setState({
            modalRepFormEmail: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message))
            this.unCheckAll();
        });
    }

    handleSaveEventProviderEmailSuccess(alert) {
        this.setState({
            modalProviderFormEmail: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message))
            this.unCheckAll();
        });
    }

    handleSaveEventInterpreterSuccess(alert) {
        this.setState({
            modalInterpreter: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));
        });
    }

    handleSaveEventEmailConfirmSuccess(alert) {
        this.setState({
            modalEmailConfirm: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));
        });
    }

    handleSaveEventJobNotesSuccess(alert) {
        this.setState({
            modalAddJobNote: alert.modal,
            fetchingData: false,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } }, () => {
                    this.refreshSearch()
            });
            }, Timeout(alert.message));
        });
    }

    handleSaveEventCopyJobSuccess(alert) {
        this.setState({
            modalCopyJob: alert.modal,
            selectedJob: null,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } }, () => {
                    this.refreshSearch();
                });
            }, Timeout(alert.message));
        });
    }
    
    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model }, () => {
            if (e.target.name === "MyJobsUserId") {
                this.populateWorkOrderData();
            }
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (this.state.tableView === 'default' || this.state.tableView === 'piggyback' || this.state.tableView === 'autopay' || this.state.tableView === 'audit' || this.state.tableView === 'contract')
                this.populateWorkOrderData();
            else if (this.state.tableView === 'deletedjobs') {
                this.populateDeletedJobsData();
            } else
                this.populateWorkOrderPendingData();
        }
    }

    handleSearchFocus(e) {
        if (!e) return;
        if (this.state.multiFilter === false) {
            this.setState({
                searchModel: {
                    IntId: '',
                    ClmId: '',
                    JobNum: '',
                    StartDate: '',
                    EndDate: '',
                    Claimant: '',
                    ClmClaimNumberNoDash: '',
                    CLMPhone: '',
                    Interpreter: '',
                    Rep: '',
                    ClientAbbrv: '',
                    ProviderName: '',
                    WorkFlowId: this.props.WorkFlowId,
                    MyJobsUser: this.state.searchModel.MyJobsUser,
                    SortBy: this.state.searchModel.SortBy,
                    SortDirection: this.state.searchModel.SortDirection
                }
            }, () => {

            });

            if (e.target.value !== "") {
                let model = { ...this.state.searchModel }

                if (e.target.name === "StartDate" || e.target.name === "EndDate") {
                    model[e.target.name] = moment(e.target.value).format('YYYY-MM-DD');
                }
                else
                    model[e.target.name] = e.target.value;

                this.setState({ searchModel: model });
            }
        }

        if (e.target.name === "StartDate" && e.target.type !== 'date') {
            e.target.type = 'date';
        }
        if (e.target.name === "EndDate" && e.target.type !== 'date') {
            e.target.type = 'date';
        }
    }

    handleSearchBlur(e) {
        //e.target.type = (e.target.name === "StartDate" || e.target.name === "EndDate") && e.target.value !== '' ? 'date' : 'text';
    }

    handleCheckAll = () => {
        this.setState({
            checkAll: !this.state.checkAll,
            clearSelection: false
        }, () => {
            this.state.workOrderList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: this.state.checkAll }))
        });
    }

    unCheckAll = () => {
        this.state.workOrderList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: false }));
        this.setState({ clearSelection: true})
    }

    handleCheckChange = (e, callback) => {
        this.setState({
            [e.target.name]: e.target.checked,
            clearSelection: false
        }, () => {
            // After the checkbox state is updated, check if all items are unchecked
            const uncheckedItems = this.state.workOrderList.filter((item) => !this.state["chk_" + item.jobNum]);

            this.setState({
                checkAll: uncheckedItems.length === 0
            }, () => {
                // After setting the `checkAll` state, call the callback if provided
                if (callback && typeof callback === 'function') {
                    callback();
                }
            });
        });
    }

    resetChecks = () => {
        this.setState({
            checkAll: false,
            clearSelection: true,
        }, () => {
            this.state.workOrderList.forEach((item) => this.setState({ ["chk_" + item.jobNum]: false }))
        });
    }

    handleClearChecksOnEmailModalClose() {
        this.setState({ 
            isModalOpen: false,
            clearSelection: true 
        });
    }
    handlePendingClick() {
        this.populateWorkOrderPendingData();
    }

    handleCallbackClick() {
        this.populateWorkOrderCallbackData();
    }

    handleContractClick() {
        this.setState({ tableView: 'contract' });
    }

    handleAuditClick() {
        this.setState({ tableView: 'audit' });
    }

    handlePiggyBack() {
        this.setState({ tableView: 'piggyback' }, () => {
            let model = { ...this.state.searchModel }
            FetchData("/piggyback", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.handleSaveEventSuccess({
                        message: "PiggyBack Complete.",
                        modal: false,
                        color: "success"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: "PiggyBack Failed.",
                        modal: false,
                        color: "warning"
                    });
                }
            });
        });
    }

    handleAutoPay() {
        this.setState({ tableView: 'autopay' }, () => {
            let model = {
                JobDate: null,
                UserGID: ''
            }
            model.JobDate = this.state.searchModel.StartDate;
            model.UserGID = this.user.userid;
            FetchData("/autopay", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.handleSaveEventSuccess({
                        message: "AutoPay Complete.",
                        modal: false,
                        color: "success"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: "AutoPay Failed." + data,
                        modal: false,
                        color: "warning"
                    });
                }
            });
        });
    }

    handleIntConfNo(workorder) {
        let model = {
            UserID: this.user.userid,
            FirstName: this.user.firstname,
            LastName: this.user.lastname,
            Email: this.user.email,
            JobId: workorder.jobId,
            IntId: workorder.intId
        };

        FetchData("/FinalConfirmationEmailToInterpreter", "POST", this.user.token, model).then(data => {
            this.setState({
                alert: {
                    color: alert.color,
                    message: data,
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message));
            });
        });

        //this.setState({
        //    emailConfirm: {
        //        notify_type: 'auth',
        //        JobId: workorder.jobId,
        //        IntId: workorder.intId
        //    }
        //}, () => {
        //    this.setModalEmailConfirm();
        //});
    }

    async handleSendSMS(workorder) {
        let model = {
            User: {
                UserID: this.user.userid,
                FirstName: this.user.firstname,
                LastName: this.user.lastname,
                Email: this.user.email,
                IntId: workorder.intId
            },
            JobId: workorder.jobId
        };

        //console.log(model);

        await FetchData("/RingPlanSendMessage", "POST", this.user.token, model).then(data => {
            this.setState({
                alert: {
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(data))
            });
        });
    }

    handlePrevNextButton = (day) => {
        if (this.state.searchModel.StartDate === "" || this.state.searchModel.StartDate === null) {
            alert('Please enter Job Start Date');
        } else {
            let model = { ...this.state.searchModel }
            model.StartDate = moment(model.StartDate).add(day, 'd').toDate();

            this.setState({ searchModel: model, selectedJob: null }, () => {
                this.populateWorkOrderData();
            });

        }
    }

    viewJobNotes(workorder) {
        FetchData("/getreceivables", "POST", this.user.token, { JobId: workorder.jobId }).then(data => {
            this.setState({ receivableList: data });
        });
        FetchData("/getreceivableitems", "POST", this.user.token, { JobId: workorder.jobId }).then(data => {
            this.setState({ receivableItemList: data });
        });
        this.setModalWOJobNotes();
    }

    viewInvoice(woData, i) {

        let body = {
            JobIdsList: ""
        };

        body.JobIdsList = woData.jobId;
        let currentDate = new Date();
        let invoiceFileName = "Invoice " + woData.jobNum + " " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate()) + "-" + currentDate.getFullYear() + ".pdf";

        FetchFile("/printinvoices", "POST", this.user.token, body).then(blob => {
            saveAs(blob, invoiceFileName);
        })
    }

    handleRepEmailClick() {
        let jobNumsList = [];
        let repList = [];
        var sameRepSelected = true;
        console.log("jobNumsList", jobNumsList);
        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobNumsList.push(item);
                if (repList.length === 0) {
                    repList.push(item.repEmail);
                } else {
                    for (var i = 0; i < repList.length; i++) {
                        console.log("repList", repList);
                        if (repList[i] !== item.repEmail) {
                            sameRepSelected = false;
                            break;
                        }
                    }
                }
            }
        });
    
        if (repList.length === 0) {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please select one or more work orders with the same rep.",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message));
            });
        } else if (!sameRepSelected) {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Selected Reps are not the same.",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message));
            });
        } else {
            var body = 'Dear ' + jobNumsList[0].repFirstName + ',';
    
            var subject = jobNumsList[0].clmFirstName + ' ' + jobNumsList[0].clmLastName + ' CLM # ' + jobNumsList[0].clmClaimNumber;
    
            body += '\n\n';
            body += '<table style="width:100%;border: 1px solid black;border-collapse: collapse;">';
            body += '<tr style="border: 1px solid black">';
            body += '<td style="border: 1px solid black">JobID</td><td style="border: 1px solid black">Appointment Date Time</td><td style="border: 1px solid black">Provider</td><td style="border: 1px solid black">Location</td>';
            body += '</tr>';
            for (var i = 0; i < jobNumsList.length; i++) {
                var d = new Date(jobNumsList[i].jobDateTime);
    
                body += '<tr style="border: 1px solid black">';
                body += '<td style="border: 1px solid black">' + jobNumsList[i].jobNum + '</td>';
                //
                body += '<td style="border: 1px solid black">' + moment(jobNumsList[i].jobDateTime).format('MM/DD/YYYY') + ' (' + this.state.dayNames[d.getDay()] + ') ' + moment(jobNumsList[i].jobDateTime).format('hh:mm A') + '</td>';
                //
                body += '<td style="border: 1px solid black">' + jobNumsList[i].providerName + '</td>';
                //
                body += '<td style="border: 1px solid black">' + jobNumsList[i].aptStreet + ',' + jobNumsList[i].aptCity + ',' + jobNumsList[i].aptState + ',' + jobNumsList[i].aptZip + '</td>';
                body += '</tr>';
            }
            body += '</table>';
    
            body += '\n\nThank you,';
            body += '\n' + this.user.firstname + ' ' + this.user.lastname;
    
            body += '\n\nExecutive Linguist Agency \n800-522-2320 | Text: 310-955-4513 | 8am - 5pm Nationwide \nAfter-hours Emergencies: 310-749-6521 or 562-682-3924 \nQuestions: mail@executivelinguist.com';
    
            this.setState(prevState => ({
                email: {
                    EmailFrom: this.user.email,
                    EmailTo: jobNumsList[0].repEmail,
                    EmailCC: '',
                    Subject: subject,
                    Body: body,
                    JobIds: jobNumsList.map(u => u.jobId).join(','),
                    EmailType: 'email2',
                    CreatedByUserId: this.user.userid
                }
            }));
            this.setModalRepFormEmail();
        }
    }
    

    handleIntEmailClick() {
        let jobList = [];
        let emailList = [];
        var sameIntSelected = true;
    
        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobList.push(item);
                if (emailList.length === 0)
                    emailList.push(item.intEmail);
                else {
                    for (var i = 0; i < emailList.length; i++) {
                        if (emailList[i] !== item.intEmail) {
                            sameIntSelected = false;
                            break;
                        }
                    }
                }
            }
        });
    
        if (emailList.length === 0) {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please select one or more work orders with the same interpreter.",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message));
            });
        } else if (!sameIntSelected) {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Selected Interpreters are not the same.",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message));
            });
        } else {
            var body = 'Dear ' + jobList[0].intFirstName + ',';
            body += '\n\nPlease ** REPLY TO THIS EMAIL ** to confirm this is in your book:';
    
            body += '\n\n';
            body += '<table style="width:100%;border: 1px solid black;border-collapse: collapse;">';
            body += '<tr style="border: 1px solid black">';
            body += '<td style="border: 1px solid black">JobID</td><td style="border: 1px solid black">Claimant Name</td><td style="border: 1px solid black">Appointment Date Time</td><td style="border: 1px solid black">Provider</td><td style="border: 1px solid black">Location</td>';
            body += '</tr>';
            for (var i = 0; i < jobList.length; i++) {
                var d = new Date(jobList[i].jobDateTime);
    
                body += '<tr style="border: 1px solid black">';
                body += '<td style="border: 1px solid black">' + jobList[i].jobNum + '</td>';
                //
                body += '<td style="border: 1px solid black">' + jobList[i].clmFirstName + ' ' + jobList[i].clmLastName + '</td>';
                body += '<td style="border: 1px solid black">' + moment(jobList[i].jobDateTime).format('MM/DD/YYYY') + ' (' + this.state.dayNames[d.getDay()] + ') ' + moment(jobList[i].jobDateTime).format('hh:mm A') + '</td>';
                //
                body += '<td style="border: 1px solid black">' + jobList[i].providerName + '</td>';
                //
                body += '<td style="border: 1px solid black">' + jobList[i].aptStreet + ',' + jobList[i].aptCity + ',' + jobList[i].aptState + ',' + jobList[i].aptZip + '</td>';
                body += '</tr>';
            }
            body += '</table>';
    
            body += '\n\nIf there is any problem or if the patient does not appear within 15 mins of start time, please CALL US right away. If you are not required to interpret, you MUST call us BEFORE leaving the appointment. ';
    
            body += '\n\nExecutive Linguist Agency, Inc. requires its contracted interpreters to follow all local, state, and federal health guidelines and mandates.'
            body += '\n\nMore medical offices are requiring proof of vaccination. If you are vaccinated, Executive Linguist recommends carrying proof of vaccination with you.';
            body += '\n\nIf you encounter a situation at a scheduled appointment where guidelines and mandates are not followed, please call us immediately.';
    
            body += '\n\nTo access our Portal and FAQ click at <a href="https://portal.e-ela.com" target="_blank">https://portal.e-ela.com</a>';
            body += '\n\nThank you,';
            body += '\n' + this.user.firstname + ' ' + this.user.lastname;
    
            body += '\n\nExecutive Linguist Agency \n800-522-2320 | Text: 310-955-4513 | 8am - 5pm Nationwide \n After-hours Emergencies: 310-749-6521 or 562-682-3924 \nQuestions: mail@executivelinguist.com';
    
            this.setState(prevState => ({
                showButtons: 'cancellation,remote_email,covering_email',
                email: {
                    EmailFrom: this.user.email,
                    EmailTo: jobList[0].intEmail,
                    EmailCC: '',
                    Subject: 'Interpreting Assignment',
                    Body: body,
                    JobIds: jobList.map(u => u.jobId).join(','),
                    CreatedByUserId: this.user.userid
                }
            }));
            this.setModalIntFormEmail();
        }
    }
    
handleCopyJob() {
    let jobNumsList = [];

    this.state.workOrderList.forEach((item) => {
        if (this.state["chk_" + item.jobNum]) {
            jobNumsList.push(item.jobNum);
        }
    });

    if (jobNumsList.length > 1){
        this.setState({
            alert: {
                color: "warning",
                message: "You can only copy one job at a time.",
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    } else if (jobNumsList.length === 1) {
        this.setState({
            selectedJob: this.state.workOrderList.find(item => item.jobNum === jobNumsList[0])
        }, () => {
            if (this.state.selectedJob !== null) {
                this.setState(prevState => ({
                    model: {
                        EventType: 'update',
                        JobNum: this.state.selectedJob.jobNum,
                        JobId: this.state.selectedJob.jobId,
                        JobDateTime: this.state.selectedJob.jobDateTime,
                        JobTime: this.state.selectedJob.jobTime,
                        FinishTime: this.state.selectedJob.finishTime,
                        FinishTimeString: this.state.selectedJob.finishTimeString == "00:00" ? null : this.state.selectedJob.finishTimeString,
                        FollowupDate: this.state.selectedJob.followupDate,
                        ProvId: this.state.selectedJob.provId,
                        ProviderName: this.state.selectedJob.providerName,
                        ConfCallNote: this.state.selectedJob.confCallNote,
                        AptName: this.state.selectedJob.aptName,
                        AptStreet: this.state.selectedJob.aptStreet,
                        AptCity: this.state.selectedJob.aptCity,
                        AptState: this.state.selectedJob.aptState,
                        AptZip: this.state.selectedJob.aptZip,
                        AptPhone: this.state.selectedJob.aptPhone,
                        AptDirection: this.state.selectedJob.aptDirection,
                        Failedflg: this.state.selectedJob.failedflg,
                        CallerNote: this.state.selectedJob.callerNote,
                        InvoiceNote: this.state.selectedJob.invoiceNote,
                        IntCheckDate: this.state.selectedJob.intCheckDate,
                        IntCheckNum: this.state.selectedJob.intCheckNum,
                        Invoicedflg: this.state.selectedJob.invoicedflg,
                        JobTotal: this.state.selectedJob.jobTotal,
                        JobDiscount: this.state.selectedJob.jobDiscount,
                        IntFee: this.state.selectedJob.intFee === null ? null : parseInt(this.state.selectedJob.intFee),
                        IntFeeOther: this.state.selectedJob.intFeeOther === null ? null : parseInt(this.state.selectedJob.intFeeOther),
                        IntOTFee: this.state.selectedJob.intOTFee === null ? null : parseInt(this.state.selectedJob.intOTFee),
                        IntFeeNote: this.state.selectedJob.intFeeNote,
                        Transportationflg: this.state.selectedJob.transportationflg,
                        TransConfflg: this.state.selectedJob.transConfflg,
                        IntConfflg: this.state.selectedJob.intConfflg,
                        AppointmentTypeId: this.state.selectedJob.appointmentTypeId,
                        CallerType: this.state.selectedJob.callerType,
                        ProvConfflg: this.state.selectedJob.provConfflg,
                        ClmConfflg: this.state.selectedJob.clmConfflg,
                        IsDeletedflg: this.state.selectedJob.isDeletedflg,
                        BillReviewedflg: this.state.selectedJob.billReviewedflg,
                        Auditflg: this.state.selectedJob.auditflg,
                        PurpleReportflg: this.state.selectedJob.purpleReportflg,
                        RepAuthorizedflg: this.state.selectedJob.repAuthorizedflg,
                        Selectedflg: this.state.selectedJob.selectedflg,
                        ClmId: this.state.selectedJob.clmId,
                        ClmFirstName: this.state.selectedJob.clmFirstName,
                        ClmLastName: this.state.selectedJob.clmLastName,
                        ClmClaimNumber: this.state.selectedJob.clmClaimNumber,
                        ClmClaimNumberNoDash: this.state.selectedJob.clmClaimNumberNoDash,
                        ClmPhone: this.state.selectedJob.clmPhone,
                        ClmDateOfLoss: this.state.selectedJob.clmDateOfLoss,
                        ClmDateOfInjury: this.state.selectedJob.clmDateOfInjury,
                        ClmNote: this.state.selectedJob.clmNote,
                        ClmDOB: this.state.selectedJob.clmDOB,
                        ClmInsuredParty: this.state.selectedJob.clmInsuredParty,
                        ClmEmployer: this.state.selectedJob.clmEmployer,
                        ClmInvoiceCaption: this.state.selectedJob.clmInvoiceCaption,
                        ClmLanguage: this.state.selectedJob.clmLanguage,
                        ClmWCABNumber: this.state.selectedJob.clmWCABNumber,
                        ClmInvoiceType: this.state.selectedJob.clmInvoiceType,
                        ClmStreet: this.state.selectedJob.clmStreet,
                        ClmCity: this.state.selectedJob.clmCity,
                        ClmState: this.state.selectedJob.clmState,
                        ClmZip: this.state.selectedJob.clmZip,
                        ClmSSN: this.state.selectedJob.clmSSN,
                        ClmOrderedByName: this.state.selectedJob.clmOrderedByName,
                        ClmDiagnosis: this.state.selectedJob.clmDiagnosis,
                        ClmEmail: this.state.selectedJob.clmEmail,
                        RepID: this.state.selectedJob.repID,
                        RepFirstName: this.state.selectedJob.repFirstName,
                        RepLastName: this.state.selectedJob.repLastName,
                        RepPhone: this.state.selectedJob.repPhone,
                        RepEmail: this.state.selectedJob.repEmail,
                        ClientId: this.state.selectedJob.clientId,
                        ClientAbbrv: this.state.selectedJob.clientAbbrv,
                        ClientName: this.state.selectedJob.clientName,
                        ClientStreet: this.state.selectedJob.clientStreet,
                        ClientStreet2: this.state.selectedJob.clientStreet2,
                        ClientCity: this.state.selectedJob.clientCity,
                        ClientState: this.state.selectedJob.clientState,
                        ClientZip: this.state.selectedJob.clientZip,
                        ClientMailStreet: this.state.selectedJob.clientMailStreet,
                        ClientMailStreet2: this.state.selectedJob.clientMailStreet2,
                        ClientMailCity: this.state.selectedJob.clientMailCity,
                        ClientMailState: this.state.selectedJob.clientMailState,
                        ClientMailZip: this.state.selectedJob.clientMailZip,
                        ClientPhone: this.state.selectedJob.clientPhone,
                        ClientPhoneFax: this.state.selectedJob.clientPhoneFax,
                        ClientPhone2: this.state.selectedJob.clientPhone2,
                        ClientEmail: this.state.selectedJob.clientEmail,
                        ClientInactiveFlg: this.state.selectedJob.clientInactiveFlg,
                        IntId: this.state.selectedJob.intId,
                        IntLastName: this.state.selectedJob.intLastName,
                        IntFirstName: this.state.selectedJob.intFirstName,
                        IntCellTxt: this.state.selectedJob.intCellTxt,
                        IntPhoneOnly: this.state.selectedJob.intPhoneOnly,
                        IntEmail: this.state.selectedJob.intEmail,
                        IntNote: this.state.selectedJob.intNote,
                        InHouseflg: this.state.selectedJob.inHouseflg,
                        IntCity: this.state.selectedJob.intCity,
                        IntStreet: this.state.selectedJob.intStreet,
                        IntState: this.state.selectedJob.intState,
                        IntZip: this.state.selectedJob.intZip,
                        DBA: this.state.selectedJob.dDBA,
                        Rate: this.state.selectedJob.rate,
                        HourMinimum: this.state.selectedJob.hourMinimum,
                        Prorate: this.state.selectedJob.prorate,
                        BankRoute: this.state.selectedJob.bankRoute,
                        BankAccount: this.state.selectedJob.bankAccount,
                        IntSSINumber: this.state.selectedJob.intSSINumber,
                        FirstName: this.state.selectedJob.firstName,
                        LastName: this.state.selectedJob.lastName,
                        UserType: this.state.selectedJob.userType,
                        UserIntId: this.state.selectedJob.userIntId,
                        CreateDateTime: this.state.selectedJob.createDateTime,
                        CreateByUserId: this.state.selectedJob.createByUserId,
                        UpdateDateTime: this.state.selectedJob.updateDateTime,
                        UpdateByUserId: this.state.selectedJob.updateByUserId,
                        RemIntflg: this.state.selectedJob.remIntflg,
                        RemClaimflg: this.state.selectedJob.remClaimflg,
                        RemProvflg: this.state.selectedJob.remProvflg,
                        RemFinalflg: this.state.selectedJob.remFinalflg,
                        RemType: this.state.selectedJob.remType,
                        RemTypeDesc: this.state.selectedJob.remTypeDesc,
                        RemHost: this.state.selectedJob.remHost,
                        RemHostDesc: this.state.selectedJob.remHostDesc,
                        CreatedBy: this.state.selectedJob.createdBy,
                        UpdatedBy: this.state.selectedJob.updatedBy,
                        MyJobTJobHistory: this.state.selectedJob.myJobTJobHistory,
                        MyJobWaitingOnCallBack: this.state.selectedJob.myJobWaitingOnCallBack,
                        JobBalance: this.state.selectedJob.jobBalance,
                        WorkFlowId: this.state.selectedJob.workFlowId
                    }
                }), () => {
                    this.setModalCopyJob();
                });
            } else {
                this.setState({
                    alert: {
                        color: "warning",
                        message: "No record selected.",
                        isOpen: true
                    }
                }, () => {
                    window.setTimeout(() => {
                        this.setState({ alert: { isOpen: false } });
                    }, Timeout(alert.message));
                });
            }
        });
    } else {
        this.setState({
            alert: {
                color: "warning",
                message: "No record selected.",
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }
}
    
    handleAddJobNoteClick() {
        let jobNumsList = [];
        let jobIdList = [];

        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobNumsList.push(item.jobNum);
                jobIdList.push(item.jobId);
            }
        });
        var jobNumbers = jobNumsList.join();

        if (jobNumbers === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please select one or more workorders to add notes to",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message))
            });
        }
        else {
            let model = { ...this.state.jobNotes }
            model.JobNumbers = jobNumbers;
            model.JobIds = jobIdList.join();
            model.JobHistory = [];

            this.setState({ jobNotes: model }, () => {
                this.setModalAddJobNote();
            });
        }
    }

    async exportPDF() {
        let jobNumsList = [];
        let body = {
            JobNumbers: ""
        };

        this.state.workOrderList.forEach((item) => {
            if (this.state["chk_" + item.jobNum]) {
                jobNumsList.push(item.jobNum);
            }
        });
        body.JobNumbers = jobNumsList.join();

        if (body.JobNumbers === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: "Please check any work order",
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } })
                }, Timeout(alert.message))
            });
        }
        else {
            await FetchFile("/getinvoices", "POST", this.user.token, body).then(blob => {
                saveAs(blob, "exportpdf.pdf");
            });
        }
    }

    async populateWorkOrderDataRefresh() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel }
        model.StartDate = model.StartDate === "" || model.StartDate === null ? null : moment(model.StartDate).format('YYYY-MM-DD');
        model.EndDate = model.EndDate === "" || model.EndDate === null ? null : moment(model.EndDate).format('YYYY-MM-DD');
        model.MyJobs = this.props.isMyJob;

        if (this.props.isMyJob) {

            model.CreatedByUserId = this.user.userid;

            this.setState({ searchModel: model }, () => {
                console.log(model);
                if (model.MyJobsUserId === '') {
                    FetchData("/getmyjobs?userId=" + this.user.userid, "POST", this.user.token, null).then(data => {
                        this.setState({ workOrderList: data, loading: false, fetchingData: false });
                    });
                }
                else {
                    FetchData("/getmyjobs?userId=" + model.MyJobsUserId, "POST", this.user.token, null).then(data => {
                        this.setState({ workOrderList: data, loading: false, fetchingData: false });
                    });
                }
            });
        } else {
            this.setState({ searchModel: model }, () => {
                FetchData("/getworkorders", "POST", this.user.token, model).then(data => {
                    this.setState({ workOrderList: data, loading: false, fetchingData: false });
                });
            });
        }

        this.resetChecks();
    }

    async populateDeletedJobsData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel }
        model.StartDate = model.StartDate === "" || model.StartDate === null ? null : moment(model.StartDate).format('YYYY-MM-DD');
        model.EndDate = model.EndDate === "" || model.EndDate === null ? null : moment(model.EndDate).format('YYYY-MM-DD');
        //model.MyJobs = this.props.isMyJob;

        let url = "/getdeletedjobs";

        this.setState({ searchModel: model }, () => {
            FetchData(url, "POST", this.user.token, model).then(data => {
                this.setState({ deletedJobsList: data, loading: false, fetchingData: false, tableView: 'deletedjobs' });
            });
        });
    }

    async populateWorkOrderData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel }
        model.StartDate = model.StartDate === "" || model.StartDate === null ? null : moment(model.StartDate).format('YYYY-MM-DD');
        model.EndDate = model.EndDate === "" || model.EndDate === null ? null : moment(model.EndDate).format('YYYY-MM-DD');
        model.MyJobs = this.props.isMyJob;


        if (this.props.isMyJob) {

            model.CreatedByUserId = this.user.userid;

            this.setState({ searchModel: model }, () => {
                if (model.MyJobsUserId === '') {
                    FetchData("/getmyjobs?userId=" + this.user.userid, "POST", this.user.token, null).then(data => {
                        this.setState({ workOrderList: data, loading: false, fetchingData: false });
                    });
                }
                else {
                    FetchData("/getmyjobs?userId=" + model.MyJobsUserId, "POST", this.user.token, null).then(data => {
                        this.setState({ workOrderList: data, loading: false, fetchingData: false });
                    });
                }
            });
        } else {
            this.setState({ searchModel: model }, () => {
                FetchData("/getworkorders", "POST", this.user.token, model).then(data => {
                    this.setState({ workOrderList: data, loading: false, fetchingData: false });
                });
            });
        }

        this.resetChecks();
        //await FetchData("/getworkorders", "POST", this.user.token, this.state.searchModel).then(data => {
        //    this.setState({ workOrderList: data, loading: false, fetchingData: false });
        //});
    }

    async openWorkOrderForm(jobId) {
        FetchData("/getworkorder?jobId=" + jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.IntCellTxt,
                    IntPhoneOnly: data.IntPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseflg: data.inHouseflg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    JobBalance: data.jobBalance
                },
                selectedIndex: 0,
                modal: true
            }));
        });
    }

    async populateWorkOrderPendingData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel }
        console.log("this.state.searchModel:",this.state.searchModel);
        console.log("model:",model);
        model.StartDate = model.StartDate === "" || model.StartDate === null ? null : moment(model.StartDate).format('YYYY-MM-DD');
        model.EndDate = model.EndDate === "" || model.EndDate === null ? null : moment(model.EndDate).format('YYYY-MM-DD');

        await FetchData("/getpendingworkorders", "POST", this.user.token, model).then(data => {
            this.setState({ workOrderList: data, loading: false, fetchingData: false, tableView: 'pending' });
        });
    }

    async populateWorkOrderCallbackData() {
        this.setState({ fetchingData: true });

        let model = {};
        //model.StartDate = this.state.searchModel.StartDate === "" || this.state.searchModel.StartDate === null ? null : moment(this.state.searchModel.StartDate).format('YYYY-MM-DD');
        //model.EndDate = this.state.searchModel.EndDate === "" || this.state.searchModel.EndDate === null ? null : moment(this.state.searchModel.EndDate).format('YYYY-MM-DD');

        FetchData("/getcallbackworkorders", "POST", this.user.token, model).then(data => {
            this.setState({ workOrderList: data, loading: false, fetchingData: false, tableView: 'callback' });
        });
    }

    async populateExecutiveUsers() {
        var model = {
            UserName: 'executive'
        };
        //this.setState({ searchModel: model }, () => {
        FetchData("/getusers", "POST", this.user.token, model).then(data => {
            this.setState({ executiveUsers: data });
        });
        //});

        this.resetChecks();
        //await FetchData("/getworkorders", "POST", this.user.token, this.state.searchModel).then(data => {
        //    this.setState({ workOrderList: data, loading: false, fetchingData: false });
        //});
    }

    renderWorkOrderTableDefault(workOrders) {
        return (
            <WorkOrderTableDefault
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                unCheckAll={this.unCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableDefault>
        );
    }

    renderWorkOrderTableBooking(workOrders) {
        return (
            <WorkOrderTableBooking
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                unCheckAll={this.unCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableBooking>
        );
    }

    renderWorkOrderTableAuthTransport(workOrders) {
        return (
            <WorkOrderTableAuthTransport
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                unCheckAll={this.unCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableAuthTransport>
        );
    }

    renderWorkOrderTableConfirmation(workOrders) {
        return (
            <WorkOrderTableConfirmation
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                unCheckAll={this.unCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableConfirmation>
        );
    }

    renderWorkOrderTableMyJobs(workOrders) {
        return (
            <WorkOrderTableMyJobs
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                unCheckAll={this.unCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableMyJobs>
        );
    }

    renderWorkOrderTablePending(workOrders) {
        return (
            <WorkOrderTablePending
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                handleIntConfNo={this.handleIntConfNo}
                handleSendSMS={this.handleSendSMS}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTablePending>
        );
    }

    renderWorkOrderTableCallback(workOrders) {
        return (
            <WorkOrderTableCallback
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                handleIntConfNo={this.handleIntConfNo}
                handleSendSMS={this.handleSendSMS}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableCallback>
        );
    }

    renderWorkOrderTableContract(workOrders) {
        return (
            <WorkOrderTableContract
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                handleIntConfNo={this.handleIntConfNo}
                handleSendSMS={this.handleSendSMS}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableContract>
        );
    }

    renderWorkOrderTableAudit(workOrders) {
        return (
            <WorkOrderTableAudit
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                workOrders={workOrders}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                handleIntConfNo={this.handleIntConfNo}
                handleSendSMS={this.handleSendSMS}
                openInterpreter={this.openInterpreter}
            ></WorkOrderTableAudit>
        );
    }

    renderWorkOrderTableDeletedJobs(deletedJobs) {
        return (
            <WorkOrderTableDeletedJobs
                state={this.state}
                clearSelection={this.state.clearSelection}
                isMyJob={this.props.isMyJob}
                deletedJobs={deletedJobs}
                searchModel={this.state.searchModel}
                selectedIndex={this.state.selectedIndex}
                requestSort={this.requestSort}
                setData={this.setData}
                setJobData={this.setJobData}
                handleCheckChange={this.handleCheckChange}
                checkAll={this.checkAll}
                handleCheckAll={this.handleCheckAll}
                setModal={this.setModal}
                setInterpreterEmail={this.setInterpreterEmail}
                setRepEmail={this.setRepEmail}
                setRepEmail2={this.setRepEmail2}
                setProviderEmail={this.setProviderEmail}
                viewInvoice={this.viewInvoice}
                addSchedule={this.addSchedule}
                viewJobNotes={this.viewJobNotes}
                handleIntConfNo={this.handleIntConfNo}
                handleSendSMS={this.handleSendSMS}
                openInterpreter={this.openInterpreter}
                viewJobHistory={this.viewJobHistory}
            ></WorkOrderTableDeletedJobs>
        );
    }

    setFetchingData(fetching) {
        this.setState({ fetchingData: fetching });
    }

    /// <summary>
    /// Dynamically renders different types of work order tables based on the current table view state.
    /// This method checks if the component is in a loading state and displays a loading message if true.
    /// Otherwise, it selects the appropriate rendering function from a set of predefined functions based on the current table view type.
    /// If the table view type does not match any predefined types, it defaults to rendering the default work order table.
    /// </summary>
    /// <returns>
    /// Returns a JSX element representing the appropriate work order table or a loading indicator.
    /// </returns>
    renderContent() {
        const { loading, tableView, workOrderList, deletedJobsList } = this.state;
        if (loading) {
            return <p><em>Loading...</em></p>;
        }

        const tableRenderers = {
            default: () => this.renderWorkOrderTableDefault(workOrderList),
            piggyback: () => this.renderWorkOrderTableDefault(workOrderList),
            autopay: () => this.renderWorkOrderTableDefault(workOrderList),
            auth_transport: () => this.renderWorkOrderTableAuthTransport(workOrderList),
            booking: () => this.renderWorkOrderTableBooking(workOrderList),
            confirmation: () => this.renderWorkOrderTableConfirmation(workOrderList),
            pending: () => this.renderWorkOrderTablePending(workOrderList),
            myjobs: () => this.renderWorkOrderTableMyJobs(workOrderList),
            callback: () => this.renderWorkOrderTableCallback(workOrderList),
            deletedjobs: () => this.renderWorkOrderTableDeletedJobs(deletedJobsList),
            contract: () => this.renderWorkOrderTableContract(workOrderList),
            audit: () => this.renderWorkOrderTableAudit(workOrderList)
        }
        return (tableRenderers[tableView] || tableRenderers['default'])();
    }

    render() {
        let contents = this.renderContent();

        return (
            <div style={{ height: '800px', paddingTop: '12px', overflowX: 'hidden', overflowY: 'auto' }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen}>
                        {this.state.alert.message}
                    </Alert>
                </center>
                <Row>
                    <Col>
                        <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                            {this.state.tableView !== 'callback' && this.state.tableView !== 'deletedjobs' && this.state.tableView !== 'myjobs' && (
                                <Fragment>
                                    {this.state.tableView === 'pending' ? (
                                        <Fragment>
                                            <Button color="secondary" onClick={this.handlePendingClick} className="btn" size="sm">Pending </Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Button color="secondary" outline onClick={this.handlePendingClick} className="btn" size="sm">Pending </Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    )}
                                    {this.state.tableView === 'contract' ? (
                                        <Fragment>
                                            <Button color="secondary" onClick={this.handleContractClick} className="btn" size="sm">Contract </Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Button color="secondary" outline onClick={this.handleContractClick} className="btn" size="sm">Contract </Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    )}
                                    {this.state.tableView === 'audit' ? (
                                        <Fragment>
                                            <Button color="secondary" onClick={this.handleAuditClick} className="btn" size="sm">Audit </Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Button color="secondary" outline onClick={this.handleAuditClick} className="btn" size="sm">Audit </Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    )}
                                    {this.state.tableView === 'piggyback' ? (
                                        <Fragment>
                                            <Button color="secondary" onClick={this.handlePiggyBack} className="btn" size="sm">PiggyBack</Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Button color="secondary" outline onClick={this.handlePiggyBack} className="btn" size="sm">PiggyBack</Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    )}
                                    {this.state.tableView === 'autopay' ? (
                                        <Fragment>
                                            <Button color="secondary" onClick={this.handleAutoPay} className="btn" size="sm">AutoPay</Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Button color="secondary" outline onClick={this.handleAutoPay} className="btn" size="sm">AutoPay</Button>
                                            <span style={{ paddingRight: '1px' }}></span>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}

                            {this.state.tableView !== 'callback' && this.state.tableView !== 'deletedjobs' && (
                                <Fragment>
                                    <Button color="secondary" outline className="btn" size="sm" onClick={this.handleRepEmailClick}>Rep Email</Button>
                                    <span style={{ paddingRight: '1px' }}></span>
                                    <Button color="secondary" outline className="btn" size="sm" onClick={this.handleIntEmailClick}>Int Email</Button>
                                    <span style={{ paddingRight: '1px' }}></span>
                                </Fragment>
                            )}

                            {this.state.tableView !== 'callback' && this.state.tableView !== 'deletedjobs' && this.state.tableView !== 'myjobs' && (
                                <Fragment>
                                    <Button color="secondary" outline className="btn" size="sm" onClick={this.handleCopyJob}>Copy Job</Button>
                                    <span style={{ paddingRight: '1px' }}></span>
                                </Fragment>
                            )}

                            {this.state.tableView !== 'callback' && this.state.tableView !== 'deletedjobs' && (
                                <Fragment>
                                    <Button color="secondary" outline className="btn" size="sm" onClick={this.handleAddJobNoteClick}>Add Job Notes</Button>
                                    <span style={{ paddingRight: '1px' }}></span>
                                </Fragment>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                </Row>
                <Row>
                    <Col className="col-10">
                        <div style={{ minWidth: '1300px', overflow: 'hidden' }}>
                            <InputGroup size="sm">
                                {this.state.tableView !== 'callback' && this.state.tableView !== 'deletedjobs' && (
                                    <Button color="btn" onClick={this.resetFields} size="sm">
                                        <img src={AddIcon} style={{ width: '16px', height: '16px' }} alt="Add" />{' '}New
                                    </Button>
                                )}
                                <Button color="btn" onClick={this.refreshSearch} size="sm">
                                    <img src={RefreshIcon} style={{ width: '16px', height: '16px' }} alt="Refresh" />
                                </Button>

                                {this.state.tableView !== 'callback' && this.state.tableView !== 'myjobs' ? (
                                    <Fragment>
                                        {this.state.tableView !== 'callback' && this.state.tableView !== 'deletedjobs' && (
                                            <Fragment>
                                                <Button color="btn" onClick={() => { this.handlePrevNextButton(-1); }} size="sm">
                                                    <img src={Previous} style={{ width: '16px', height: '16px' }} alt="Prev" />
                                                </Button>
                                                <Button color="btn" onClick={() => { this.handlePrevNextButton(1); }} size="sm">
                                                    <img src={Next} style={{ width: '16px', height: '16px' }} alt="Next" />
                                                </Button>
                                            </Fragment>
                                        )}
                                        <input 
                                        name="MultiFilter" 
                                        type="checkbox" 
                                        defaultChecked={this.state.multiFilter} 
                                        onChange={() => { this.setState({ multiFilter: !this.state.multiFilter }); }} 
                                        /> 
                                        <span style={{ paddingRight: '4px' }}></span>

                                        <input 
                                        name="JobNum" 
                                        ref={(input) => { this.JobNum = input; }} 
                                        type="number" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.JobNum !== null ? this.state.searchModel.JobNum : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="Job #" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="StartDate" 
                                        ref={(ref) => this.txtStartDate = ref} 
                                        type="date" 
                                        className="form-control form-control-text" 
                                        style={{ width: '50px', height: '26px' }} 
                                        value={this.state.searchModel.StartDate !== null ? this.state.searchModel.StartDate : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        onBlur={this.handleSearchBlur} 
                                        placeholder="Start Date" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="EndDate" 
                                        ref={(input) => { this.EndDate = input; }} 
                                        type="date" 
                                        className="form-control form-control-text" 
                                        style={{ width: '50px', height: '26px' }} 
                                        value={this.state.searchModel.EndDate !== null ? this.state.searchModel.EndDate : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        onBlur={this.handleSearchBlur} 
                                        placeholder="End Date" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="Claimant" 
                                        ref={(input) => { this.Claimant = input; }} 
                                        type="text" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.Claimant !== null ? this.state.searchModel.Claimant : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="Claimant" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="ClmClaimNumberNoDash" 
                                        ref={(input) => { this.ClmClaimNumberNoDash = input; }} 
                                        type="text" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.ClmClaimNumberNoDash !== null ? this.state.searchModel.ClmClaimNumberNoDash : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="Claimant #" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="CLMPhone" 
                                        ref={(input) => { this.CLMPhone = input; }} 
                                        type="text" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.CLMPhone !== null ? this.state.searchModel.CLMPhone : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="CLM Phone" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="Interpreter" 
                                        ref={(input) => { this.Interpreter = input; }} 
                                        type="text" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.Interpreter !== null ? this.state.searchModel.Interpreter : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="Interpreter" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="Rep" 
                                        ref={(input) => { this.Rep = input; }} 
                                        type="text" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.Rep !== null ? this.state.searchModel.Rep : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="Rep" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="ClientAbbrv" 
                                        ref={(input) => { this.ClientAbbrv = input; }} 
                                        type="text" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.ClientAbbrv !== null ? this.state.searchModel.ClientAbbrv : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="Client Abbrv" 
                                        onKeyPress={this.handleKeyPress} 
                                        />

                                        <input 
                                        name="ProviderName" 
                                        ref={(input) => { this.ProviderName = input; }} 
                                        type="text" 
                                        className="form-control form-control-text" 
                                        value={this.state.searchModel.ProviderName !== null ? this.state.searchModel.ProviderName : ''} 
                                        onChange={this.handleSearchChange} 
                                        onFocus={this.handleSearchFocus} 
                                        placeholder="Job Provider" 
                                        onKeyPress={this.handleKeyPress} 
                                        />
                                    </Fragment>
                                ) : (
                                    ''
                                )}
                                {this.state.tableView === 'deletedjobs' && (
                                    <Fragment>
                                        <Button color="btn" onClick={this.confirmActivation} size="sm">Activate</Button>
                                    </Fragment>
                                )}

                                {this.props.isMyJob && <span style={{ padding: '6px' }}>User: </span>}
                                {this.props.isMyJob && (
                                    <Col className="col-2">
                                        <select name="MyJobsUserId" className="form-control form-control-text" value={this.state.searchModel.MyJobsUserId} onChange={this.handleSearchChange} size="sm">
                                            <option value="" selected></option>
                                            {this.state.executiveUsers.map((usr, i) => (
                                                <option value={usr.userID} selected={usr.userID === this.props.CurrentUser}>{usr.firstName} {usr.lastName}</option>
                                            ))}
                                        </select>
                                    </Col>
                                )}
                            </InputGroup>
                        </div>
                    </Col>
                </Row>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <ReactTooltip id="rtp" place="right" type="dark" effect="solid" html={true} />
                <div>
                    {contents}
                </div>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <p style={{ paddingLeft: '60px', paddingTop: '10px', fontWeight: 'bold' }}>{this.state.workOrderList.length}</p>
                        </InputGroup>
                    </Col>
                </Row>

                {this.state.modal &&
                    <WorkOrderForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} workOrderList={this.state.workOrderList} jobHistoryList={this.state.jobHistoryList} jobInvLineList={this.state.jobInvLineList} setNextPrevData={this.setNextPrevData} selectedIndex={this.state.selectedIndex} handleSaveEventSuccess={this.handleSaveEventSuccess} tableView={this.state.tableView} setFetchingData={this.setFetchingData} viewInvoice={this.viewInvoice} />
                }

                {this.state.modalIntFormEmail &&
                    <InterpreterFormEmail model={this.state.email} job={this.state.selectedJob} modal={this.state.modalIntFormEmail} showButtons={this.state.showButtons} alert={this.state.alert} setModal={this.setModalIntFormEmail} handleSaveEventSuccess={this.handleSaveEventIntEmailSuccess} onClose={this.handleClearChecksOnEmailModalClose}/>
                }

                {this.state.modalRepFormEmail &&
                    <RepFormEmail model={this.state.email} job={this.state.selectedJob} modal={this.state.modalRepFormEmail} alert={this.state.alert} setModal={this.setModalRepFormEmail} handleSaveEventSuccess={this.handleSaveEventRepEmailSuccess} isOpen={this.state.modalRepFormEmail} onClose={this.handleClearChecksOnEmailModalClose} />
                }

                {this.state.modalProviderFormEmail &&
                    <ProviderFormEmail model={this.state.email} job={this.state.selectedJob} modal={this.state.modalProviderFormEmail} alert={this.state.alert} setModal={this.setModalProviderFormEmail} handleSaveEventSuccess={this.handleSaveEventProviderEmailSuccess} onClose={this.handleClearChecksOnEmailModalClose}/>
                }

                {this.state.modalWOJobNotes &&
                    <WorkOrderJobNotes receivableList={this.state.receivableList} receivableItemList={this.state.receivableItemList} modal={this.state.modalWOJobNotes} alert={this.state.alert} setModal={this.setModalWOJobNotes} />
                }

                {this.state.modalEmailConfirm &&
                    <WorkOrderIntConfNo modal={this.state.modalEmailConfirm} alert={this.state.alert} setModal={this.setModalEmailConfirm} notify_type={this.state.emailConfirm.notify_type} JobId={this.state.emailConfirm.JobId} IntId={this.state.emailConfirm.IntId} handleSaveEventSuccess={this.handleSaveEventEmailConfirmSuccess} onClose={this.handleClearChecksOnEmailModalClose}/>
                }

                {this.state.modalInterpreter &&
                    <InterpreterForm model={this.state.interpreter} modal={this.state.modalInterpreter} alert={this.state.alert} setModal={this.setModalInterpreter} handleSaveEventSuccess={this.handleSaveEventInterpreterSuccess} />
                }

                {this.state.modalAddJobNote &&
                    <WorkOrderAddJobNotes model={this.state.jobNotes} modal={this.state.modalAddJobNote} alert={this.state.alert} setModal={this.setModalAddJobNote} handleSaveEventSuccess={this.handleSaveEventJobNotesSuccess} setFetchingData={this.setFetchingData} />
                }
                {this.state.modalCopyJob &&
                    <WorkOrderCopyJob model={this.state.model} modal={this.state.modalCopyJob} alert={this.state.alert} setModal={this.setModalCopyJob} handleSaveEventSuccess={this.handleSaveEventCopyJobSuccess} />
                }

            </div>
        );
    }}
