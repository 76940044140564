import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, InputGroup, InputGroupText, Input, Alert } from 'reactstrap';

import { FetchData, UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png'
import { WorkOrderAddJobTableDefault } from './WorkOrderAddJobTableDefault';

export class WorkOrderAddJobNotes extends Component {
    static displayName = WorkOrderAddJobNotes.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));

        this.state = {
            alert: props.alert,
            model: props.model
        };

        this.onSave = this.onSave.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.addJobHistory = this.addJobHistory.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleRemove(index) {
        let model = { ...this.state.model }

        if (model.JobHistory.length === 1)
            model.JobHistory = [];
        else {
            model.JobHistory.splice(index, 1);
        }

        this.setState({ model });
    }

    addJobHistory() {
        if (this.cboType.value === '' || this.txtDescription.value === '') {
          this.handleSaveEventSuccess({
            message: 'Please select Type and Enter Description',
            modal: true,
            color: 'warning',
            });
        } else {
          let model = { ...this.state.model };
          model.JobHistory.push({ Type: this.cboType.value, Description: this.txtDescription.value });
    
          this.setState({ model }, () => {
            this.cboType.value = '';
            this.txtDescription.value = '';
            });
        }
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) { // ALT+S
            e.preventDefault();
            this.onSave();
        }
    }

    onSave() {
        let model = { ...this.state.model }

        if (model.JobHistory.length === 0) {
            this.handleSaveEventSuccess({
                message: "Please add Job History",
                modal: true,
                color: "warning"
            });
        }
        else {
            var formData = new FormData();
            formData.append("JobNumbers", model.JobNumbers);
            formData.append("JobIds", model.JobIds);
            formData.append("Type", model.JobHistory.map(u => u.Type).join(','));
            formData.append("Description", model.JobHistory.map(u => u.Description).join(','));

            this.props.setFetchingData(true);

            UploadFile("/savejobhistory_multiple", this.user.token, formData).then(data => {
                if (data !== 'Success') {
                    this.props.setFetchingData(false);
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }
                else {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning",
                        model: model
                    });
                }
            });
        }
    }

    renderJobNotes(jobHistory){
        console.log(jobHistory);
        return (
            <WorkOrderAddJobTableDefault
                jobHistory={jobHistory}
                handleRemove={this.handleRemove}
            />
        );
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "500px", width: "800px" }} backdrop="static">
                    <ModalHeader>Add Note</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "50px" }}>
                                        Job(s):
                                    </InputGroupText>
                                    <Input name="JobNumbers" className="form-control form-control-text" value={this.state.model.JobNumbers} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-3">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "50px" }}>
                                        Type:
                                    </InputGroupText>
                                    <select name="Type" className="form-control form-control-text" ref={(select) => { this.cboType = select; }}>
                                        <option value=""></option>
                                        <option value="To Auth Dept">To Auth Dept</option>
                                        <option value="To Booking Dept">To Booking Dept</option>
                                        <option value="To Conf Dept">To Conf Dept</option>
                                        <option value="Job Note">Job Note</option>
                                        <option value="Authorization">Authorization</option>
                                        <option value="My Job">My Job</option>
                                        <option value="Claimant Confirmed">Claimant Confirmed</option>
                                        <option value="Int Communication">Int Communication</option>
                                        <option value="Int Final Conf">Int Final Conf</option>
                                        <option value="Int Cancelation">Int Cancelation</option>
                                        <option value="Rep Email">Rep Email</option>
                                        <option value="Provider Conf">Provider Conf</option>
                                        <option value="Schedule Change">Schedule Change</option>
                                        <option value="Scheduling Note">Scheduling Note</option>
                                        <option value="Special Billing">Special Billing</option>
                                        <option value="Special Handling">Special Handling</option>
                                        <option value="Waiting on Call Back">Waiting on Call Back</option>
                                        <option value="Transport Requested">Transport Requested</option>
                                        <option value="Transport Confirmed">Transport Confirmed</option>
                                        <option value="Remote Clm Conf">Remote Clm Conf</option>
                                        <option value="Remote Int Conf">Remote Int Conf</option>
                                        <option value="Remote Prov Conf">Remote Prov Conf</option>
                                    </select>
                                </InputGroup>
                            </Col>
                            <Col className="col-6">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm" color="blue">
                                        Description:
                                    </InputGroupText>
                                    <textarea name="Description" rows="7" className="no-border form-control-text" style={{ width: '100%' }} ref={(textarea) => { this.txtDescription = textarea; }}></textarea>
                                </InputGroup>
                            </Col>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <Button color="btn" size="sm" onClick={this.addJobHistory}><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}Add</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <b>Job History</b>
                        <Row>
                            <Col>
                                {this.renderJobNotes(this.state.model.JobHistory)}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.setModal} size="sm">Cancel</Button>
                        <Button color="primary" onClick={() => { this.onSave() } } className="btn" size="sm">Save</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
