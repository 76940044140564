import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Index } from './components/Index';
import Login from './components/login';
import { FAQ } from './components/FAQ';
import { WorkOrderIntConfNoViewAppointment } from './components/WorkOrderIntConfNoViewAppointment'

import { WorkOrderInt } from './components/WorkOrderInt'
import { InterpreterAvailability } from './components/InterpreterAvailability'
import { InterpreterAvailabilityAdmin } from './components/InterpreterAvailabilityAdmin'

import { GetCookie } from './components/utils/Common';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            GetCookie("user") === null || GetCookie("user") == undefined ? <Login /> :
                <Layout>
                    <Route exact path='/' component={Index} />
                    <Route path='/int-job' component={WorkOrderInt} />
                    <Route path='/faq' component={FAQ} />
                    <Route path='/check-in' component={InterpreterAvailability} />
                    <Route path='/int-availability' component={InterpreterAvailabilityAdmin} />
                    {/*<Route path='/interpreter' component={Interpreter} />*/}
                    {/*<Route path='/rep' component={Rep} />*/}
                    {/*<Route path='/claimant' component={Claimant} />*/}
                </Layout>
        );
    }
}
