import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Input, Row, Col, Alert } from 'reactstrap';
import Draggable from 'react-draggable';

import { WorkOrderNoteForm } from './WorkOrderNoteForm';
import { WorkOrderFollowUp } from './WorkOrderFollowUp';
import { WorkOrderClaimantJobs } from './WorkOrderClaimantJobs'
import { WorkOrderPrintPreview } from './WorkOrderPrintPreview'
import { RepFormSearch } from './RepFormSearch';
import { ClaimantFormSearch } from './ClaimantFormSearch';
import { InterpreterFormSearch } from './InterpreterFormSearch';
import { ProviderFormSearch } from './ProviderFormSearch';
import { ClientForm } from './ClientForm'

import { WorkOrderTransportationForm } from './WorkOrderTransportationForm';
import { WorkOrderJobDescription } from './WorkOrderJobDescription'

import { RepForm } from './RepForm';
import { ClaimantForm } from './ClaimantForm';
import { InterpreterForm } from './InterpreterForm';

import { WorkOrderFormTableJobHistory } from './WorkOrderFormTableJobHistory';
import { WorkOrderFormTableReceivables } from './WorkOrderFormTableReceivables';

import { FetchFile, FetchData, UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import ReactTooltip from "react-tooltip";

import DeleteIcon from '../images/delete.png'
import DownIcon from '../images/down.png'
import DownloadIcon from '../images/download-icon.png'
import UpIcon from '../images/up.png'

import { saveAs } from 'file-saver';

const moment = require('moment');

export class WorkOrderForm extends Component {
    static displayName = WorkOrderForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            isMounted: false,
            modal: this.props.modal,
            alert: this.props.alert,

            deltaPosition: {
                x: 0, y: 0
            },

            hasSpecialBillingJobHistory: false,

            modalRepSearchForm: false,
            searchRepName: '',
            modalClaimantSearchForm: false,
            searchClaimantName: '',
            modalInterpreterSearchForm: false,
            searchInterpreterName: '',
            modalProviderSearchForm: false,
            searchProviderName: '',
            sameDayAppt: false,

            modalClient: false,
            modalTransportation: false,

            modalRep: false,
            modalClaimant: false,
            modalInterpreter: false,
            modalFollowUp: false,
            modalPrintPreview: false,
            modalClaimantJobs: false,

            model: props.model,

            workOrderList: props.workOrderList,
            jobHistoryList: props.jobHistoryList,
            jobInvLineList: props.jobInvLineList,
            interpreterList: [],

            remoteTypeList: JSON.parse(localStorage.getItem('RemoteTypes')),
            remoteHostList: JSON.parse(localStorage.getItem('RemoteHosts')),
            jobTypeList: JSON.parse(localStorage.getItem('JobTypes')),

            modalWorkOrderNoteForm: false,
            modalWorkOrderJobDescription: false,
            forceUpdateCounter: 0,

            selectedIndex: props.selectedIndex,
            jobHistory: {
                JobHistoryId: null,
                JobId: null,
                JobHistoryName: '',
                Description: '',
                Contact: '',
                ContactEmail: '',
                FileName: '',
                FileLocation: '',
                JobHistoryType: 'Job Note',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedflg: null,
                FormFile: null
            },
            transporation: {
                EventType: 'insert',
                TransportationId: null,
                JobId: null,
                JobNum: null,
                CallerState: '',
                FailedTransportFlg: false,
                CallerName: '',
                AuthorizedBy: '',
                CallerEmail: '',
                CallerCompany: '',
                CallerPhone: '',
                AmbulatoryFlg: false,
                RoundTripFlg: false,
                Ground: null,
                AirFlg: false,
                LodgingFlg: false,
                Other: ''
            },
            rep: {
                EventType: 'update',
                RepID: null,
                ClientID: null,
                RepLastName: '',
                RepFirstName: '',
                RepPhone: '',
                RepPhone2: '',
                RepNote: '',
                RepZip: '',
                RepEmail: '',
                RepInactiveFlg: false,
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: '',
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientEmail: ''
            },
            claimant: {
                EventType: 'update',
                ClmId: null,
                RepId: null,
                ClientId: null,
                ClmLastName: '',
                ClmFirstName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmInvoiceType: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmNote: '',
                ClmWCABNumber: '',
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmDateOfInjury: '',
                ClmDateOfLoss: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmEmail: '',
                ClmSSN: '',
                ClmDOB: null,
                ClmDiagnosis: '',
                ClmGender: '',
                ClmAppAtty: '',
                ClmAppAttyFirm: '',
                ClmAppAttyEmail: '',
                ClmAppAttyPhone: '',
                ClmDefAtty: '',
                ClmDefAttyFirm: '',
                ClmDefAttyEmail: '',
                ClmDefAttyPhone: '',
                ClmOrderedByName: '',
                ClmHeight: '',
                ClmWeight: '',
                ClmStatus: '',
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: '',
                ClientAbbrv: '',
                RepFirstName: '',
                RepLastName: ''
            },
            interpreter: {
                EventType: 'update',
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntGender: '',
                IntPronoun: '',
                IntEmail: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntFax: '',
                IntBeeper: '',
                IntStreet: '',
                IntCity: '',
                IntState: '',
                IntZip: '',
                IntCountry: '',
                IntSSINumber: '',
                IntActiveFlg: false,
                IntCorporationFlg: false,
                DBA: '',
                InHouseflg: false,
                UnVettedFlg: false,
                EmergencyContact: '',
                UnavailableReason: '',
                BankName: '',
                BankAccount: '',
                BankRoute: '',
                Rate: null,
                HourMinimum: null,
                Prorate: '',
                ConfCallRate: null,
                BusinessCardRequestDate: null,
                BusinessCardRequestToProcessFlg: false,
                IntNote: '',
                ReferredBy: '',
                IntGender: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                CreatedBy: '',
                UpdatedBy: ''
            },
            client: {
                EventType: 'update',
                ClientId: null,
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientPhoneFax: '',
                ClientName: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientCountry: '',
                ClientDiscount: null,
                ClientNotes: '',
                ClientCreditCard: '',
                ClientEmailForInvoice: '',
                ClientEmail: '',
                ClientWebsite: '',
                ClientOrg: '',
                ClientBillRateType: '',
                ClientInactiveFlg: false,
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            },
            searchModel: {
                IntId: '',
                ClmId: '',
                JobNum: '',
                StartDate: null,
                EndDate: '',
                Claimant: '',
                ClaimantNum: '',
                CLMPhone: '',
                Interpreter: '',
                Rep: '',
                ClientAbbrv: '',
                ProviderName: '',
                CreatedByUserId: null,
                MyJobs: false,
                MyJobsUser: '',
                SortBy: '',
                SortDirection: ''
            },
            jobInvLine: {
                EventType: 'insert',
                JobInvLineId: null,
                JobId: this.props.model.JobId,
                JobInvLineCodeId: null,
                LineDescription: '',
                LinePersonnel: '',
                LineRate: '',
                Amount: null,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            }
        };

        //
        this.setModal = this.setModal.bind(this);

        this.onSave = this.onSave.bind(this);
        this.printWorkOrder = this.printWorkOrder.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnKeyup = this.handleOnKeyup.bind(this);
        this.handleNumberFieldBlur = this.handleNumberFieldBlur.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        this.handleTimeFieldChange = this.handleTimeFieldChange.bind(this);
        this.handleJobTimeFieldChange = this.handleJobTimeFieldChange.bind(this);
        this.setNextPrevData = this.setNextPrevData.bind(this);
        //
        this.handleInsertJobDescriptionConferenceCall = this.handleInsertJobDescriptionConferenceCall.bind(this);
        this.handleInsertJobDescriptionMessagePhone = this.handleInsertJobDescriptionMessagePhone.bind(this);
        this.handleInsertJobDescriptionPhone = this.handleInsertJobDescriptionPhone.bind(this);
        this.handleInsertJobDescriptionMedExam = this.handleInsertJobDescriptionMedExam.bind(this);
        this.handleSaveNote = this.handleSaveNote.bind(this);
        //this.getSameDayAppointments = this.getSameDayAppointments.bind(this);
        //
        this.handleNewJobNoteViewClick = this.handleNewJobNoteViewClick.bind(this);
        this.handleNewJobNoteClick = this.handleNewJobNoteClick.bind(this);
        this.handleDeleteJobHistory = this.handleDeleteJobHistory.bind(this);
        //
        this.setModalWorkOrderNoteForm = this.setModalWorkOrderNoteForm.bind(this);
        this.setModalWorkOrderJobDescription = this.setModalWorkOrderJobDescription.bind(this);
        //
        this.handleEditJobDescriptionClick = this.handleEditJobDescriptionClick.bind(this);
        this.handleDeleteJobDescriptionClick = this.handleDeleteJobDescriptionClick.bind(this);
        //
        this.handleSaveEventWorkOrderNoteFormSuccess = this.handleSaveEventWorkOrderNoteFormSuccess.bind(this);
        this.handleSaveEventWorkOrderJobDescriptionSuccess = this.handleSaveEventWorkOrderJobDescriptionSuccess.bind(this);
        //
        this.handleSearchFocus = this.handleSearchFocus.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.interpreterHasBillingInfo = this.interpreterHasBillingInfo.bind(this);
        this.jobHasBillingLines = this.jobHasBillingLines.bind(this);

        // Rep Search
        this.setModalRepFormSearch = this.setModalRepFormSearch.bind(this);
        this.handleSearchRepKeyDown = this.handleSearchRepKeyDown.bind(this);
        this.handleSelectRep = this.handleSelectRep.bind(this);

        // Claimant Search
        this.setModalClaimantFormSearch = this.setModalClaimantFormSearch.bind(this);
        this.handleSearchClaimantKeyDown = this.handleSearchClaimantKeyDown.bind(this);
        this.handleSelectClaimant = this.handleSelectClaimant.bind(this);

        // Interpreter Search
        this.setModalInterpreterFormSearch = this.setModalInterpreterFormSearch.bind(this);
        this.handleSearchInterpreterKeyDown = this.handleSearchInterpreterKeyDown.bind(this);
        this.handleSelectInterpreter = this.handleSelectInterpreter.bind(this);

        // Provider Search
        this.setModalProviderFormSearch = this.setModalProviderFormSearch.bind(this);
        this.handleSearchProviderKeyDown = this.handleSearchProviderKeyDown.bind(this);
        this.handleSelectProvider = this.handleSelectProvider.bind(this);

        // Transportation
        this.handleSaveEventWorkOrderTransportationFormSuccess = this.handleSaveEventWorkOrderTransportationFormSuccess.bind(this);
        this.handleTransportationClick = this.handleTransportationClick.bind(this);
        this.setModalTransportation = this.setModalTransportation.bind(this);

        // Rep
        this.setModalRepForm = this.setModalRepForm.bind(this);
        this.handleRepNameClick = this.handleRepNameClick.bind(this);
        this.handleSaveEventRepFormSuccess = this.handleSaveEventRepFormSuccess.bind(this);

        // Claimant
        this.handleClaimantNameClick = this.handleClaimantNameClick.bind(this);
        this.setModalClaimantForm = this.setModalClaimantForm.bind(this);
        this.handleSaveEventClaimantFormSuccess = this.handleSaveEventClaimantFormSuccess.bind(this);

        // Interpreter
        this.handleInterpreterNameClick = this.handleInterpreterNameClick.bind(this);
        this.setModalInterpreterForm = this.setModalInterpreterForm.bind(this);
        this.handleSaveEventInterpreterFormSuccess = this.handleSaveEventInterpreterFormSuccess.bind(this);

        //Client
        this.setModalClient = this.setModalClient.bind(this);
        this.showClient = this.showClient.bind(this);
        this.handleSaveEventClientSuccess = this.handleSaveEventClientSuccess.bind(this);

        // Followup
        this.setModalFollowup = this.setModalFollowup.bind(this);
        // Print Preview
        this.setModalPrintPreview = this.setModalPrintPreview.bind(this);
        // Claimant Jobs
        this.setModalClaimantJobs = this.setModalClaimantJobs.bind(this);

        // delete
        this.confirmDelete = this.confirmDelete.bind(this);
        //
        this.cancelIntConfirmed = this.cancelIntConfirmed.bind(this);
        //
        this.showMap = this.showMap.bind(this);
        //
        this.handleDrag = this.handleDrag.bind(this);
        //
        // View JobHistory File attachment
        this.handleViewJobHistoryFile = this.handleViewJobHistoryFile.bind(this);
        //
        // View Invoice
        this.handleViewInvoice = this.handleViewInvoice.bind(this);

        //
        if (this.props.model.EventType === 'update') {
            this.getJobHistory(this.props.model.JobId);
            this.getJoInvLine(this.props.model.JobId);
        }
    }

    componentDidMount() {
        this.setState({ isMounted: true }); // Set isMounted to true when the component mounts
        // Your existing componentDidMount code
    }

    componentWillUnmount() {
        this.setState({ isMounted: false }); // Set isMounted to false when the component unmounts
        // Your existing componentWillUnmount code, if any
    }   

    handleDrag = (e, ui) => {
        const { deltaPosition } = this.state;
        this.setState({
            deltaPosition: {
                x: deltaPosition.x + ui.deltaX,
                y: deltaPosition.y + ui.deltaY,
            },
        });
    };

    setModal() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            this.props.setModal();
        });
    }

    onSave() {
        let model = { ...this.state.model }

        if (model.IntId === null || model.IntId === '') {
            if (window.confirm("Are you sure you want to create a work order without an interpreter?") === false) {
                return;
            }
        }

        if ((model.JobDateTime == null || model.JobDateTime === '')) {
            this.handleSaveEventSuccess({
                message: "Job Date and Time is required.",
                modal: true,
                color: "warning"
            });
        } else if ((model.JobTime === null || model.JobTime === '')) {
            this.handleSaveEventSuccess({
                message: "Job Date and Time is required.",
                modal: true,
                color: "warning"
            });
        }
        else {
            var strDay = moment(this.state.model.JobDateTime).format('dddd');

            if (strDay === 'Saturday' || strDay === 'Sunday') {
                if (window.confirm("The appointment date is on " + strDay + ". Are you sure you want to save this record?") === true) {

                }
                else return;
            }

            if (model.IntFee !== null)
                model.IntFee = parseInt(model.IntFee);
            if (model.IntFee == null)
                model.IntFee = 0;
            if (model.IntFeeOther !== null)
                model.IntFeeOther = parseInt(model.IntFeeOther);
            if (model.IntFeeOther == null)
                model.IntFeeOther = 0;
            if (model.IntOTFee !== null)
                model.IntOTFee = parseInt(model.IntOTFee);
            if (model.IntOTFee == null)
                model.IntOTFee = 0;
            if (model.FinishTimeString === "")
                model.FinishTimeString = " ";

            if (model.EventType === "insert") {
                model.CreateByUserId = this.user.userid;
            } else if (model.EventType === "update") {
                model.UpdateByUserId = this.user.userid;
            }

            FetchData("/saveworkorder", "POST", this.user.token, model).then(data => {
                if (data.message === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data.message,
                        modal: data.message === 'Success' ? false : true,
                        color: data.message === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data.message,
                        modal: data.message === 'Success' ? false : true,
                        color: data.message === 'Success' ? "success" : "warning"
                    });
                }

            });
        }
    }

    async onSaveWithoutClosing() {
        let model = { ...this.state.model }

        if (model.IntId === null || model.IntId === '') {
            this.handleSaveEventSuccess({
                message: "Interpreter is required.",
                modal: true,
                color: "warning"
            });
        } else if ((model.JobDateTime == null || model.JobDateTime === '')) {
            this.handleSaveEventSuccess({
                message: "Job Date and Time is required.",
                modal: true,
                color: "warning"
            });
        } else if ((model.JobTime === null || model.JobTime === '')) {
            this.handleSaveEventSuccess({
                message: "Job Date and Time is required.",
                modal: true,
                color: "warning"
            });
        }
        else {
            var strDay = moment(this.state.model.JobDateTime).format('dddd');

            if (strDay === 'Saturday' || strDay === 'Sunday') {
                if (window.confirm("The appointment date is on " + strDay + ". Are you sure you want to save this record?") === true) {

                }
                else return;
            } else if (model.EventType === 'insert') {

                let modelForWorkOrders = {  // Ensure modelForWorkOrders is initialized as an object
                    IntId: '',
                    ClmId: '',
                    JobNum: '',
                    StartDate: this.state.model.JobDateTime,
                    EndDate: this.state.model.JobDateTime,
                    Claimant: '',
                    ClmClaimNumberNoDash: this.state.model.ClmClaimNumberNoDash,
                    CLMPhone: '',
                    Interpreter: '',
                    Rep: '',
                    ClientAbbrv: '',
                    ProviderName: '',
                    CreateByUserId: '',
                    MyJobs: false,
                    MyJobsUser: '',
                    WorkFlowId: '',
                };

                let sda = false;


                await FetchData("/getworkorders", "POST", this.user.token, modelForWorkOrders).then(data => {
                    //console.log(data);
                    if (data.length > 0) {
                        this.setState({ sameDayAppt: true }, () => {
                            console.log("State Updated");

                            sda = this.getSameDayAppointments();
                        });

                    }
                });

                if (sda === true) {
                    if (window.confirm("Claimant already has a job on this day. Are you sure you want to save this record?") === true) {

                    }
                    else return;
                }
            }

            if (model.IntFee !== null)
                model.IntFee = parseInt(model.IntFee);
            if (model.IntFee == null)
                model.IntFee = 0;
            if (model.IntFeeOther !== null)
                model.IntFeeOther = parseInt(model.IntFeeOther);
            if (model.IntFeeOther == null)
                model.IntFeeOther = 0;
            if (model.IntOTFee !== null)
                model.IntOTFee = parseInt(model.IntOTFee);
            if (model.IntOTFee == null)
                model.IntOTFee = 0;
            if (model.FinishTimeString === "")
                model.FinishTimeString = " ";

            if (model.EventType === "insert") {
                model.CreateByUserId = this.user.userid;
            } else if (model.EventType === "update") {
                model.UpdateByUserId = this.user.userid;
            }

            await FetchData("/saveworkorder", "POST", this.user.token, model).then(data => {
                if (data.message === 'Success') {
                    let model = { ...this.state.model }
                    model.JobId = data.jobid;
                    model.JobNum = data.jobnum;
                    model.EventType = 'update';
                    this.setState({ model });
                    this.handleSaveEventSuccess({
                        message: data.message,
                        modal: true,
                        color: data.message === 'Success' ? "success" : "warning"
                    });
                    this.props.handleSaveEventSuccess({
                        message: data.message,
                        modal: true,
                        color: data.message === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data.message,
                        modal: data.message === 'Success' ? false : true,
                        color: data.message === 'Success' ? "success" : "warning"
                    });
                }

            });
        }
    }

    getSameDayAppointments() {
        if (this.state.sameDayAppt === true) {
            return true;
        } else {
            return false;
        }
    }

    async printWorkOrder() {
        let JobsList = [];
        let body = {
            JobIdsList: ""
        };

        let model = { ...this.state.model }
        JobsList.push(model.JobId)
        body.JobIdsList = JobsList.join();

        let workOrderFileName = "WorkOrder_" + model.JobNum + ".pdf";

        await FetchFile("/getreportworkorder", "POST", this.user.token, body).then(blob => {
            saveAs(blob, workOrderFileName);
        })
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    async keydownHandler(e) {
        //console.log(e.keyCode);
        if (this.props.tableView !== 'deletedjobs') {
            if (e.keyCode === 83 && e.altKey && this.state.modal && (
                !this.state.modalClaimantSearchForm &&
                !this.state.modalWorkOrderJobDescription &&
                !this.state.modalClaimant &&
                !this.state.modalInterpreter &&
                !this.state.modalProviderSearchForm &&
                !this.state.modalFollowUp &&
                !this.state.modalWorkOrderNoteForm)) { // Alt+S
                this.onSaveWithoutClosing();

            } else if (e.keyCode === 71 && e.altKey) { // ALT+G
                let model = { ...this.state.model }
                model.IntId = "237A27CF-1CD6-44CE-8128-DB09238E26C7";
                model.IntLastName = "A CERT A";
                model.IntFirstName = "-";
                this.setState({ model });

            } else if (e.keyCode === 78 && e.altKey) { // ALT+N
                let model = { ...this.state.model }
                model.IntId = "20CE8012-6DC4-4959-8E89-31F9D261383C";
                model.IntLastName = "A NON CERT A";
                model.IntFirstName = "-";
                this.setState({ model });

            } else if (e.keyCode === 72 && e.altKey) { // ALT+H
                let model = { ...this.state.model }
                model.IntId = "AC0AF063-3DF6-4D35-B074-EACEDA429BE4";
                model.IntLastName = "A EXOTIC A";
                model.IntFirstName = "-";
                this.setState({ model });

            } else if (e.keyCode === 49 && e.altKey) { // ALT+1
                if (this.state.model.JobId !== null)
                    this.setModalWorkOrderJobDescription();

            } else if (e.keyCode === 88 && e.altKey) { // ALT+X
                let model = { ...this.state.model }
                model.JobDateTime = moment(new Date()).format('YYYY-MM-DD');
                this.setState({ model });

            } else if (e.keyCode === 84 && e.altKey) { // ALT+T
                let model = { ...this.state.model }
                model.JobTime = moment(new Date()).format('hh:mm A');
                this.setState({ model });

            } else if (e.keyCode === 81 && e.altKey) { // ALT+Q
                let model = { ...this.state.model }
                model.JobDateTime = "2011-11-11";
                model.JobTime = "1:11 PM"; //moment(new Date()).format('hh:mm A');
                this.setState({ model });

                this.setState({ model });
            } else if (e.keyCode === 82 && e.altKey) { // ALT+R
                this.setModalRepFormSearch();

            }
            //else if (e.keyCode === 87 && e.altKey) { // ALT+W 
            //    this.printWorkOrder();

            //}
            else if (e.keyCode === 67 && e.altKey) { // ALT+C
                this.setModalClaimantFormSearch();

            } else if (e.keyCode === 73 && e.altKey) { // ALT+I
                this.setModalInterpreterFormSearch();

            } else if (e.keyCode === 80 && e.altKey) { // ALT+P
                this.setModalProviderFormSearch();
            }
            else if (e.keyCode === 66 && e.altKey) { // ALT+B
                let jobHasBillingLines = await this.jobHasBillingLines();
                let interpreterHasBilling = await this.interpreterHasBillingInfo();
                //console.log(interpreterHasBilling);
                if (this.state.model.Invoicedflg === true) {
                    this.handleSaveEventSuccess({
                        message: "Job is already printed. Cannot add billing lines.",
                        modal: false,
                        color: "warning"
                    });
                } else {
                    if (jobHasBillingLines === true) {
                        this.handleSaveEventSuccess({
                            message: "Job already has JobLines.",
                            modal: false,
                            color: "warning"
                        });
                    } else {
                        if (interpreterHasBilling === false) {
                            this.handleSaveEventSuccess({
                                message: "Interpreter Payment Incomplete.",
                                modal: false,
                                color: "warning"
                            });
                        } else {
                            if (this.state.model.JobId !== null) {
                                FetchData("/BillingCalculateClientInvoice", "POST", this.user.token, { JobId: this.state.model.JobId, UserId: this.user.userid }).then(data => {
                                    this.handleSaveEventSuccess({
                                        message: data,
                                        modal: data === 'Success' ? false : true,
                                        color: data === 'Success' ? "success" : "warning"
                                    });
                                    this.getJobHistory(this.props.model.JobId);
                                    this.getJoInvLine(this.props.model.JobId);
                                });
                            }
                        }
                    }
                }
            } else if (e.keyCode === 65 && e.altKey) { // ALT+A
                let model = { ...this.state.model }
                model.CallerNote = this.state.model.RepFirstName + ' ' + this.state.model.RepLastName;
                model.ProviderName = this.state.model.RepFirstName + ' ' + this.state.model.RepLastName;
                this.setState({ model });

                this.setState({ model });
            } else if (((e.keyCode === 76 && e.altKey) || (e.keyCode === 49 && e.altKey)) && !this.state.modalClaimantSearchForm) { // ALT+L
                this.setModalWorkOrderJobDescription();
            } else if (e.keyCode === 77 && e.altKey) { // ALT+M
                let model = { ...this.state.model }
                model.IntId = this.user.intid;
                model.IntFirstName = this.user.firstname;
                model.IntLastName = this.user.lastname
                this.setState({ model });
            } else if (e.keyCode === 78 && e.altKey) { // ALT+N
                let model = { ...this.state.model }
                model.IntId = '20CE8012-6DC4-4959-8E89-31F9D261383C';
                model.IntFirstName = '-';
                model.IntLastName = 'A NON CERT';
                this.setState({ model });
            } else if (e.keyCode === 88 && e.altKey) { // ALT+X
                let model = { ...this.state.model }
                model.JobDateTime = moment(new Date()).format('YYYY-MM-DD');
                this.setState({ model });
            } else if (e.keyCode === 89 && e.altKey) { // ALT+Y
                if (this.state.model.ClmId !== null)
                    this.handleClaimantNameClick();
            } else if (e.keyCode === 90 && e.altKey) { // ALT+Z
                if (this.state.model.JobId !== null) {
                    var formData = new FormData();
                    formData.append("EventType", "insert");
                    formData.append("JobHistoryId", null);
                    formData.append("JobId", this.state.model.JobId);
                    formData.append("Description", "My Job");
                    formData.append("JobHistoryType", "My Job");
                    formData.append("CreateByUserId", this.user.userid);

                    UploadFile("/savejobhistory", this.user.token, formData).then(data => {
                        this.handleSaveEventSuccess({
                            message: data,
                            modal: data === 'Success' ? false : true,
                            color: data === 'Success' ? "success" : "warning",
                        });
                        this.getJobHistory(this.state.model.JobId);
                    });
                }
            } else if (e.keyCode === 85 && e.altKey) { // ALT+U
                if (this.state.model.JobId !== null) {
                    this.setModalFollowup();
                } else {
                    if (this.state.model.JobDateTime === '' || this.state.model.JobDateTime === null) {
                        this.handleSaveEventSuccess({
                            message: "Job Date is required. Please have it set.",
                            modal: true,
                            color: "warning"
                        });
                    }
                }
            } else if (e.keyCode === 87 && e.altKey) { // ALT+W
                if (this.state.model.JobId !== null) {
                    this.setModalPrintPreview();
                }
            } else if (e.keyCode === 50 && e.altKey) { // Alt+2
                this.handleInsertJobDescriptionConferenceCall();
            } else if (e.keyCode === 51 && e.altKey) { //Alt+3
                this.handleInsertJobDescriptionMessagePhone();
            } else if (e.keyCode === 52 && e.altKey) { //Alt+4
                this.handleInsertJobDescriptionPhone();
            } else if (e.keyCode === 57 && e.altKey) { //Alt+9
                this.handleInsertJobDescriptionMedExam();
            } else if (e.keyCode === 74 && e.altKey) { // Alt+J
                if (this.state.model.JobId !== null) {
                    this.setModalClaimantJobs();
                } else {
                    if (this.state.model.ClmId === '' || this.state.model.ClmId === null) {
                        this.handleSaveEventSuccess({
                            message: "Please choose Claimant.",
                            modal: true,
                            color: "warning"
                        });
                    }
                }
            }
        }
    }

    async interpreterHasBillingInfo() {
        let searchModel = {
            IntId: this.state.model.IntId
        };
        let interpreter = null;
        await FetchData("/getinterpreters", "POST", this.user.token, searchModel).then(data => {
            interpreter = data[0];
        });
        //console.log(interpreter);
        //console.log('Rate: ' + interpreter.rate + ' HourMinimum: ' + interpreter.hourMinimum + ' Prorate: ' + interpreter.prorate);
        if (interpreter.rate === null || interpreter.rate === 0 || interpreter.hourMinimum === null || interpreter.prorate === '') {
            return false;
        } else {
            return true;
        }

    }

    async jobHasBillingLines() {
        let searchModel = {
            JobId: this.state.model.JobId
        };
        let jobBillingLines = []
        await FetchData("/getjobinvline", "POST", this.user.token, searchModel).then(data => {
            jobBillingLines = data;
        });
        if (jobBillingLines.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        if (this.state.model.JobDateTime !== null) {
            let model = { ...this.state.model }
            model.JobDateTime = moment(this.state.model.JobDateTime).format('YYYY-MM-DD');
            this.setState({ model });
        }

        document.addEventListener('keydown', this.keydownHandler);
        return () => {
            document.removeEventListener('keydown', this.keydownHandler);
        };

        //this.getRemoteTypes();
        //this.getRemoteHosts();
        //this.getJobTypes();
    }

    componentWillUnmount() {
        //document.removeEventListener('keydown', this.keydownHandler);
    }

    componentDidUpdate() {

        ReactTooltip.rebuild();
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.name === 'IntConfflg') {
            e.prefentDefault();
        } else if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseInt(e.target.value);
            else
                model[e.target.name] = null;
        } else if (e.target.name === "CallerType")
            model[e.target.name] = parseInt(e.target.value);
        else {
            if (e.target.name === "RemType") {
                //var index = e.nativeEvent.target.selectedIndex;
                /*if (e.nativeEvent.target[index].text === "N/A") {*/
                if (e.target.value === "d2f8a295-20e1-4653-b7c0-e456057cbd90") { // N/A
                    model["RemHost"] = "64ecfbe8-9cb7-4af8-8a41-f756aaeaca39"; // N/A
                    model["RemClaimflg"] = true;
                    model["RemIntflg"] = true;
                    model["RemProvflg"] = true;
                    model[e.target.name] = e.target.value
                } else {
                    model["RemHost"] = '';
                    model["RemClaimflg"] = false;
                    model["RemIntflg"] = false;
                    model["RemProvflg"] = false;
                    model[e.target.name] = e.target.value;
                }
            } else {
                model[e.target.name] = e.target.value;
            }
        }

        this.setState({ model });
    }

    handleOnKeyup(e) {
        let model = { ...this.state.model }
        if (/[^0-9]+/.test(e.target.value)) {
            console.log('hi');
            model[e.target.name] = parseInt(e.target.value.replace(/[^0-9]*/g, ""));
        }
        this.setState({ model });
    }

    handleNumberFieldBlur(e) {
        let model = { ...this.state.model }
        if (e.target.value !== "")
            model[e.target.name] = parseInt(e.target.value);
        else
            model[e.target.name] = null;

        this.setState({ model });
    }

    handleNewJobNoteViewClick(data) {
        this.setState(prevState => ({
            jobHistory: {
                EventType: 'update',
                JobHistoryId: data.jobHistoryId,
                JobId: data.jobId,
                Name: data.name,
                Description: data.description,
                Contact: data.contact,
                ContactEmail: data.contactEmail,
                FileName: data.fileName,
                FileLocation: data.fileLocation,
                JobHistoryType: data.jobHistoryType,
                FormFile: null
            }
        }));
        this.setModalWorkOrderNoteForm();
    }

    handleNewJobNoteClick() {
        this.setState(prevState => ({
            jobHistory: {
                EventType: 'insert',
                JobHistoryId: null,
                JobId: this.state.model.JobId,
                Name: '',
                Description: '',
                Contact: '',
                ContactEmail: '',
                FileName: '',
                FileLocation: '',
                JobHistoryType: 'Job Note',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedflg: null,
                FormFile: null
            }
        }));
        this.setModalWorkOrderNoteForm();
    }

    handleSaveNote(noteDetails) {
        let updatedModel = { ...this.state.model };
        if (noteDetails.JobHistoryType === 'Authorization') {
            updatedModel.RepAuthorizedflg = true;
        } else if (noteDetails.JobHistoryType === 'To Booking Dept') {
            updatedModel.WorkFlowId = 'Booking';
        } else if (noteDetails.JobHistoryType === 'To Auth Dept') {
            updatedModel.WorkFlowId = 'Authorization';
        } else if (noteDetails.JobHistoryType === 'To Conf Dept') {
            updatedModel.WorkFlowId = 'Confirmation';
        } else if (noteDetails.JobHistoryType === 'Provider Conf') {
            updatedModel.ProvConfflg = true;
        } else if (noteDetails.JobHistoryType === 'Int Cancelation') {
            updatedModel.IntConfflg = false;
        } else if (noteDetails.JobHistoryType === 'Int Final Conf') {
            updatedModel.IntConfflg = true;
        } else if (noteDetails.JobHistoryType === 'Claimant Confirmed'){
            updatedModel.ClmConfflg = true;
        }

        // Update the model in the state
        this.setState({ model: updatedModel });

        // Force re-render by updating an additional state variable
        // Assumes you have added forceUpdateCounter to your state initialization
        this.setState(prevState => ({
            forceUpdateCounter: prevState.forceUpdateCounter + 1
        }));

        // this.onSaveWithoutClosing();
    }

    handleDeleteJobHistory(data) {
        if (window.confirm("Remove Job History?")) {
            var model = {
                JobHistoryId: data.jobHistoryId,
                UserId: this.user.userid
            };

            FetchData("/deletejobhistory", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "success"
                });
                this.getJobHistory(this.state.model.JobId);
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }
            });
        }
    }

    handleInsertJobDescriptionConferenceCall() {
        let jobModel = { ...this.state.model };
        let rate = 2.50;
        if (!jobModel.JobId) {
            this.handleSaveEventSuccess({
                message: "Job line item cannot be added until work order has been saved.",
                modal: false,
                color: "warning"
            });

        } else if (jobModel.FinishTimeString === null && jobModel.Failedflg === false) {
            this.handleSaveEventSuccess({
                message: "Finish time must have a value or F must be selected.",
                modal: false,
                color: "warning"
            });
        } else {
            if (jobModel.ClmLanguage === 'Spanish') {
                rate = 1.50;
            }
            let jobTime = jobModel.JobTime;
            let finishTime = jobModel.FinishTimeString;
            let startTime = moment(jobTime, 'HH:mm A');
            let endTime = moment(finishTime, 'HH:mm A');
            let timeInMinutes = endTime.diff(startTime, 'minutes');
            let lineDescription = '';
            let lineRate = '';
            let amount = 0.00;
            let jobInvLineCodeId = '';


            if (timeInMinutes === 0) {
                lineDescription = 'Message Ph Call: INTERP';
                jobInvLineCodeId = '0daa5af6-5a5e-412e-ad19-f98cface0c79';
                lineRate = '@' + jobTime + ', min fee';
                amount = 15.00;
            } else if (jobModel.Failedflg === true) {
                lineDescription = 'Phone Call';
                jobInvLineCodeId = 'ae3f84cc-e9bf-4865-81bc-4518889a17bb';
                lineRate = '@' + jobTime;
                amount = 0.00;
            } else {
                lineDescription = 'Conference Call';
                jobInvLineCodeId = '9e5e4810-c5a8-428b-8f7d-71422b6b77e3';
                lineRate = '@' + jobTime + ' to ' + jobModel.FinishTimeString + ' @' + rate + '/min';
                amount = rate * timeInMinutes
            }



            let jobInvLine = {
                EventType: 'insert',
                JobId: jobModel.JobId,
                JobInvLineCodeId: jobInvLineCodeId,
                LineDescription: lineDescription,
                LinePersonnel: jobModel.ProviderName,
                LineRate: lineRate,
                Amount: amount,
                CreateByUserId: this.user.userid
            };

            FetchData("/savejobinvline", "POST", this.user.token, jobInvLine).then(data => {
                if (data === 'Success') {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                    this.getJoInvLine(this.state.model.JobId);
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                }

                this.onSaveWithoutClosing();

            });

        }
    }

    handleInsertJobDescriptionMessagePhone() {
        let jobModel = { ...this.state.model };
        let rate = 25.00;

        if (!jobModel.JobId) {
            this.handleSaveEventSuccess({
                message: "Job line item cannot be added until work order has been saved.",
                modal: false,
                color: "warning"
            });
        } else {
            if (jobModel.ClmLanguage === 'Spanish') {
                rate = 15.00;
            }
            let jobInvLine = {
                EventType: 'insert',
                JobId: jobModel.JobId,
                JobInvLineCodeId: 'aba975af-bcdc-4852-a731-e07647f9c766',
                LineDescription: 'Message Ph. Call',
                LinePersonnel: jobModel.ProviderName,
                LineRate: '@' + jobModel.JobTime + ', min fee',
                Amount: rate,
                CreateByUserId: this.user.userid
            };

            FetchData("/savejobinvline", "POST", this.user.token, jobInvLine).then(data => {
                if (data === 'Success') {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                    this.getJoInvLine(this.state.model.JobId);
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                }

                this.onSaveWithoutClosing();

            });

        }
    }

    handleInsertJobDescriptionPhone() {
        let jobModel = { ...this.state.model };

        if (!jobModel.JobId) {
            this.handleSaveEventSuccess({
                message: "Job line item cannot be added until work order has been saved.",
                modal: false,
                color: "warning"
            });
        } else {
            let jobInvLine = {
                EventType: 'insert',
                JobId: jobModel.JobId,
                JobInvLineCodeId: 'ae3f84cc-e9bf-4865-81bc-4518889a17bb',
                LineDescription: 'Phone Call',
                LinePersonnel: jobModel.ProviderName,
                LineRate: '@' + jobModel.JobTime,
                Amount: 0.00,
                CreateByUserId: this.user.userid
            };

            FetchData("/savejobinvline", "POST", this.user.token, jobInvLine).then(data => {
                if (data === 'Success') {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                    this.getJoInvLine(this.state.model.JobId);
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                }

                this.onSaveWithoutClosing();

            });

        }
    }

    handleInsertJobDescriptionMedExam() {
        let jobModel = { ...this.state.model };
        let laborCodeRate = 45.00;
        if (!jobModel.JobId) {
            this.handleSaveEventSuccess({
                message: "Job line item cannot be added until work order has been saved.",
                modal: false,
                color: "warning"
            });
        } else {
            let jobInvLine = {
                EventType: 'insert',
                JobId: jobModel.JobId,
                JobInvLineCodeId: '529d3ed1-5ccc-40df-a25b-b6ae7eca6660',
                LineDescription: 'Med Exam  INTERPRETER',
                LinePersonnel: jobModel.ProviderName,
                LineRate: '2.5 hr min @ $45/hr LABOR CODE', //LaborCodeRate
                Amount: 2.5 * laborCodeRate,
                CreateByUserId: this.user.userid
            };

            FetchData("/savejobinvline", "POST", this.user.token, jobInvLine).then(data => {
                if (data === 'Success') {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                    this.getJoInvLine(this.state.model.JobId);
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                }

                this.onSaveWithoutClosing();

            });

        }
    }

    handleEditJobDescriptionClick(jobInv) {
        this.setState(prevState => ({
            jobInvLine: {
                EventType: 'update',
                JobInvLineId: jobInv.jobInvLineId,
                JobId: jobInv.jobId,
                JobInvLineCodeId: jobInv.jobInvLineCodeId,
                LineDescription: jobInv.lineDescription,
                LinePersonnel: jobInv.linePersonnel,
                LineRate: jobInv.lineRate,
                Amount: jobInv.amount,
                CreateByUserId: jobInv.createByUserId,
                CreateDateTime: jobInv.createDateTime,
                UpdateByUserId: jobInv.updateByUserId,
                UpdateDateTime: jobInv.updateDateTime,
                IsDeletedFlg: jobInv.isDeletedFlg
            }
        }));
        this.setModalWorkOrderJobDescription();
    }

    handleDeleteJobDescriptionClick(jobInv) {
        var model = this.state.jobInvLine;
        model.EventType = 'delete';
        model.JobInvLineId = jobInv.jobInvLineId;
        model.JobId = jobInv.jobId;
        model.JobInvLineCodeId = jobInv.jobInvLineCodeId;
        model.LineDescription = jobInv.lineDescription;
        model.LinePersonnel = jobInv.linePersonnel;
        model.LineRate = jobInv.lineRate;
        model.Amount = jobInv.amount;
        model.CreateByUserId = jobInv.createByUserId;
        model.CreateDateTime = jobInv.createDateTime;
        model.UpdateByUserId = this.user.userid;
        model.UpdateDateTime = jobInv.updateDateTime;
        model.IsDeletedFlg = jobInv.isDeletedFlg;

        FetchData("/savejobinvline", "POST", this.user.token, model).then(data => {
            this.handleSaveEventSuccess({
                message: data,
                modal: data === 'Success' ? false : true,
                color: data === 'Success' ? "success" : "warning"
            });
            this.getJoInvLine(this.state.model.JobId);
        });
    }

    setModalWorkOrderNoteForm() {
        this.setState({ modalWorkOrderNoteForm: !this.state.modalWorkOrderNoteForm });
    }

    setModalWorkOrderJobDescription() {
        this.setState({ modalWorkOrderJobDescription: !this.state.modalWorkOrderJobDescription });
    }

    handleSearchFocus(e) {
        this.setState({ searchRepName: '', searchClaimantName: '', searchInterpreterName: '', searchProviderName: '' });
        if (e.target.value !== '')
            this.setState({ [e.target.name]: e.target.value });
    }

    handleSearchChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSaveEventWorkOrderNoteFormSuccess(alert) {
        this.setState({
            modalWorkOrderNoteForm: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        },
         () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
                if (alert.model.JobHistoryType === 'Authorization' || alert.model.JobHistoryType === 'To Booking Dept' || alert.model.JobHistoryType === 'To Auth Dept' || alert.model.JobHistoryType === 'To Conf Dept' || alert.model.JobHistoryType === 'Provider Conf' || alert.model.JobHistoryType === 'Int Cancelation' || alert.model.JobHistoryType === 'Int Final Conf' || alert.model.JobHistoryType === 'Claimant Confirmed') {
                    this.handleSaveNote(alert.model);
                }
                this.getJobHistory(this.state.model.JobId)
            })
        }
    );
    }

    handleSaveEventWorkOrderJobDescriptionSuccess(alert) {
        this.setState({
            modalWorkOrderJobDescription: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });

                this.getJoInvLine(this.state.model.JobId)
            }, 3000)
        });
    }

    handleSaveEventWorkOrderTransportationFormSuccess(alert) {
        this.setState({
            modalTransportation: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, 3000)
        });
    }

    handleTimeFieldChange(e) {
        let model = { ...this.state.model }
        //var newD = new Date();
        //var splitTarget = e.target.value.split(':');

        model[e.target.name] = e.target.value;

        //if (e.target.name === "JobTime")
        //    model[e.target.name] = e.target.value;
        //else
        //    model[e.target.name] = new Date(newD.getFullYear(), (newD.getMonth() + 1), newD.getDate(), splitTarget[0], splitTarget[1], 0);   

        this.setState({ model: model });
    }

    handleJobTimeFieldChange(e) {
        //console.log(e.target.value);

        var jobtime = e.target.value;

        var concatChar = e.target.value;
        var hour = '';
        var minutes = '';
        var amPm = '';

        if (jobtime.length == 4) {

            for (var i = 0, len = jobtime.length; i < len; i++) {
                if (i <= 1)
                    hour += jobtime[i];
                else
                    minutes += jobtime[i];
            }

            if (parseInt(hour) < 12)
                concatChar = parseInt(hour) + ':' + minutes + ' AM';
            else if (parseInt(hour) == 12)
                concatChar = parseInt(hour) + ':' + minutes + ' PM';
            else {
                concatChar = (parseInt(hour) - 12) + ':' + minutes + ' PM';
            }
        } else if (jobtime.length == 8) {

            for (var i = 0, len = jobtime.length; i < len; i++) {
                if (i <= 1)
                    hour += jobtime[i];
                else if (i == 3 || i == 4)
                    minutes += jobtime[i];
                else if (i == 6 || i == 7)
                    amPm += jobtime[i];
            }

            if (parseInt(hour) < 12)
                concatChar = parseInt(hour) + ':' + minutes + ' ' + amPm;
            else if (parseInt(hour) == 12)
                concatChar = parseInt(hour) + ':' + minutes + ' ' + amPm;
            else {
                concatChar = (parseInt(hour) - 12) + ':' + minutes + ' PM';
            }
        }

        let model = { ...this.state.model }
        model[e.target.name] = concatChar;
        this.setState({ model });
    }

    setNextPrevData(rowIndex) {
        let woData = []
        if (this.state.selectedIndex + rowIndex < 0) {
            this.setState({
                selectedIndex: 0
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setData(woData);
            })
        } else if (this.state.selectedIndex + rowIndex > this.state.workOrderList.length) {
            this.setState({
                selectedIndex: this.state.workOrderList.length
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setData(woData);
            })
        } else {
            this.setState({
                selectedIndex: this.state.selectedIndex + rowIndex
            }, () => {
                woData = this.state.workOrderList[this.state.selectedIndex];
                this.setData(woData);
            })
        }
    }

    setData(woData) {
        this.setState(prevState => ({
            model: {
                EventType: 'update',
                JobNum: woData.jobNum,
                JobId: woData.jobId,
                JobDateTime: woData.jobDateTime,
                JobTime: woData.jobTime,
                FinishTime: woData.finishTime,
                FinishTimeString: woData.finishTimeString === null || woData.finishTimeString === "null" ? null : woData.finishTimeString,
                FollowupDate: woData.followupDate,
                ProvId: woData.provId,
                ProviderName: woData.providerName,
                ConfCallNote: woData.confCallNote,
                AptName: woData.aptName,
                AptStreet: woData.aptStreet,
                AptCity: woData.aptCity,
                AptState: woData.aptState,
                AptZip: woData.aptZip,
                AptPhone: woData.aptPhone,
                AptDirection: woData.aptDirection,
                Failedflg: woData.failedflg,
                CallerNote: woData.callerNote,
                InvoiceNote: woData.invoiceNote,
                IntCheckDate: woData.intCheckDate,
                IntCheckNum: woData.intCheckNum,
                Invoicedflg: woData.invoicedflg,
                JobTotal: woData.jobTotal,
                JobDiscount: woData.jobDiscount,
                IntFee: woData.intFee === null ? null : parseInt(woData.intFee),
                IntFeeOther: woData.intFeeOther === null ? null : parseInt(woData.intFeeOther),
                Transportationflg: woData.transportationflg,
                TransConfflg: woData.transConfflg,
                IntConfflg: woData.intConfflg,
                AppointmentTypeId: woData.appointmentTypeId,
                CallerType: woData.callerType,
                ProvConfflg: woData.provConfflg,
                ClmConfflg: woData.clmConfflg,
                IsDeletedflg: woData.isDeletedflg,
                BillReviewedflg: woData.billReviewedflg,
                Auditflg: woData.auditflg,
                PurpleReportflg: woData.purpleReportflg,
                RepAuthorizedflg: woData.repAuthorizedflg,
                Selectedflg: woData.selectedflg,
                ClmId: woData.clmId,
                ClmFirstName: woData.clmFirstName,
                ClmLastName: woData.clmLastName,
                ClmClaimNumber: woData.clmClaimNumber,
                ClmClaimNumberNoDash: woData.clmClaimNumberNoDash,
                ClmPhone: woData.clmPhone,
                ClmDateOfLoss: woData.clmDateOfLoss,
                ClmDateOfInjury: woData.clmDateOfInjury,
                ClmNote: woData.clmNote,
                ClmDOB: woData.clmDOB,
                ClmInsuredParty: woData.clmInsuredParty,
                ClmEmployer: woData.clmEmployer,
                ClmInvoiceCaption: woData.clmInvoiceCaption,
                ClmLanguage: woData.clmLanguage,
                ClmWCABNumber: woData.clmWCABNumber,
                ClmInvoiceType: woData.clmInvoiceType,
                ClmStreet: woData.clmStreet,
                ClmCity: woData.clmCity,
                ClmState: woData.clmState,
                ClmZip: woData.clmZip,
                ClmSSN: woData.clmSSN,
                ClmOrderedByName: woData.clmOrderedByName,
                ClmDiagnosis: woData.clmDiagnosis,
                ClmEmail: woData.clmEmail,
                RepID: woData.repID,
                RepFirstName: woData.repFirstName,
                RepLastName: woData.repLastName,
                RepPhone: woData.repPhone,
                RepEmail: woData.repEmail,
                ClientId: woData.clientId,
                ClientAbbrv: woData.clientAbbrv,
                ClientName: woData.clientName,
                ClientStreet: woData.clientStreet,
                ClientStreet2: woData.clientStreet2,
                ClientCity: woData.clientCity,
                ClientState: woData.clientState,
                ClientZip: woData.clientZip,
                ClientMailStreet: woData.clientMailStreet,
                ClientMailStreet2: woData.clientMailStreet2,
                ClientMailCity: woData.clientMailCity,
                ClientMailState: woData.clientMailState,
                ClientMailZip: woData.clientMailZip,
                ClientPhone: woData.clientPhone,
                ClientPhoneFax: woData.clientPhoneFax,
                ClientPhone2: woData.clientPhone2,
                ClientEmail: woData.clientEmail,
                ClientInactiveFlg: woData.clientInactiveFlg,
                IntId: woData.intId,
                IntLastName: woData.intLastName,
                IntFirstName: woData.intFirstName,
                IntCellTxt: woData.IntCellTxt,
                IntPhoneOnly: woData.IntPhoneOnly,
                IntEmail: woData.intEmail,
                IntNote: woData.intNote,
                InHouseflg: woData.inHouseflg,
                IntCity: woData.intCity,
                IntStreet: woData.intStreet,
                IntState: woData.intState,
                IntZip: woData.intZip,
                DBA: woData.dDBA,
                Rate: woData.rate,
                HourMinimum: woData.hourMinimum,
                Prorate: woData.prorate,
                BankRoute: woData.bankRoute,
                BankAccount: woData.bankAccount,
                IntSSINumber: woData.intSSINumber,
                FirstName: woData.firstName,
                LastName: woData.lastName,
                UserType: woData.userType,
                UserIntId: woData.userIntId,
                CreateDateTime: woData.createDateTime,
                CreateByUserId: woData.createByUserId,
                UpdateDateTime: woData.updateDateTime,
                UpdateByUserId: woData.updateByUserId,
                RemIntflg: woData.remIntflg,
                RemClaimflg: woData.remClaimflg,
                RemProvflg: woData.remProvflg,
                RemFinalflg: woData.remFinalflg,
                RemType: woData.remType,
                RemTypeDesc: woData.remTypeDesc,
                RemHost: woData.remHost,
                RemHostDesc: woData.remHostDesc,
                CreatedBy: woData.createdBy,
                UpdatedBy: woData.updatedBy,
            }
        }));

        this.getJobHistory(woData.jobId);
        this.getJoInvLine(woData.jobId);
    }

    getJobHistory(jobId) {
        let model = {
            JobId: jobId
        };

        FetchData("/getjobhistory", "POST", this.user.token, model).then(data => {
            var hasSBilling = false;
            for (var val of data) {
                if (val.jobHistoryType === "Special Billing")
                    hasSBilling = true;
            }

            this.setState({ jobHistoryList: data, hasSpecialBillingJobHistory: hasSBilling, fetchingData: false });
        });
    }

    getJoInvLine(jobId) {
        let model = {
            JobId: jobId
        };
        FetchData("/getjobinvline", "POST", this.user.token, model).then(data => {
            this.setState({ jobInvLineList: data, fetchingData: false });
        });

    }

    // Rep Search
    setModalRepFormSearch() {
        this.setState({ modalRepSearchForm: !this.state.modalRepSearchForm });
    }

    handleSearchRepKeyDown(e) {
        if (e.target.value !== '' && e.keyCode === 13) {
            this.setState({ searchRepName: e.target.value });
            this.setModalRepFormSearch();
        }
    }

    handleSelectRep(rep) {
        let model = this.state.model;

        model.RepID = rep.data.repID;
        model.RepFirstName = rep.data.repFirstName;
        model.RepLastName = rep.data.repLastName;
        model.RepEmail = rep.data.repEmail;
        model.RepPhone = rep.data.repPhone;

        model.ClientId = rep.data.clientID;
        model.ClientAbbrv = rep.data.clientAbbrv;
        model.ClientName = rep.data.clientName;
        model.ClientEmail = rep.data.clientEmail;
        model.ClientPhone = rep.data.clientPhone;

        this.setState({ model: model }, () => {
            this.setModalRepFormSearch();
            if (this.state.model.ClmId === '')
                this.setModalClaimantFormSearch();
        });
    }

    // Rep
    handleRepNameClick() {
        FetchData("/getreps", "POST", this.user.token, { RepId: this.state.model.RepID }).then(data => {
            if (data.length > 0) {
                var repData = data[0];

                this.setState(prevState => ({
                    rep: {
                        EventType: 'update',
                        ClientId: repData.clientId,
                        RepID: repData.repID,
                        ClientID: repData.clientID,
                        RepLastName: repData.repLastName,
                        RepFirstName: repData.repFirstName,
                        RepPhone: repData.repPhone,
                        RepPhone2: repData.repPhone2,
                        RepNote: repData.repNote,
                        RepZip: repData.repZip,
                        RepEmail: repData.repEmail,
                        RepInactiveFlg: repData.repInactiveFlg,
                        CreateDateTime: repData.createDateTime,
                        CreatebyUserId: repData.createbyUserId,
                        UpdateByUserId: repData.updateByUserId,
                        UpdateDateTime: repData.updateDateTime,
                        IsDeletedFlg: repData.isDeletedFlg,
                        ClientName: repData.clientName,
                        ClientAbbrv: repData.clientAbbrv,
                        ClientPhone: repData.clientPhone,
                        ClientPhone2: repData.clientPhone2,
                        ClientEmail: repData.clientEmail
                    }
                }));
            } else {
                this.setState(prevState => ({
                    rep: {
                        EventType: 'insert',
                        RepID: null,
                        ClientID: null,
                        RepLastName: '',
                        RepFirstName: '',
                        RepPhone: '',
                        RepPhone2: '',
                        RepNote: '',
                        RepZip: '',
                        RepEmail: '',
                        RepInactiveFlg: false,
                        CreateDateTime: null,
                        CreatebyUserId: null,
                        UpdateByUserId: null,
                        UpdateDateTime: null,
                        IsDeletedFlg: false,
                        ClientName: '',
                        ClientAbbrv: '',
                        ClientPhone: '',
                        ClientPhone2: '',
                        ClientEmail: ''
                    }
                }));
            }

            this.setModalRepForm()
        });
    }

    setModalRepForm() {
        this.setState({ modalRep: !this.state.modalRep });
    }

    handleSaveEventRepFormSuccess(alert) {
        this.setState({
            modalRep: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, 3000);
        });
    }

    // Claimant
    handleClaimantNameClick() {
        if (this.state.model.ClmId != null && this.state.model.ClmId != '') {
            FetchData("/getclaimants", "POST", this.user.token, { ClmId: this.state.model.ClmId }).then(data => {
                var claimantData = data[0];

                this.setState(prevState => ({
                    claimant: {
                        EventType: 'update',
                        ClmId: claimantData.clmId,
                        RepId: claimantData.repId,
                        ClientId: claimantData.clientId,
                        ClmLastName: claimantData.clmLastName,
                        ClmFirstName: claimantData.clmFirstName,
                        ClmClaimNumber: claimantData.clmClaimNumber,
                        ClmClaimNumberNoDash: claimantData.clmClaimNumberNoDash,
                        ClmPhone: claimantData.clmPhone,
                        ClmInvoiceType: claimantData.clmInvoiceType,
                        ClmInvoiceCaption: claimantData.clmInvoiceCaption,
                        ClmLanguage: claimantData.clmLanguage,
                        ClmNote: claimantData.clmNote,
                        ClmWCABNumber: claimantData.clmWCABNumber,
                        ClmInsuredParty: claimantData.clmInsuredParty,
                        ClmEmployer: claimantData.clmEmployer,
                        ClmDateOfInjury: claimantData.clmDateOfInjury,
                        ClmDateOfLoss: claimantData.clmDateOfLoss,
                        ClmStreet: claimantData.clmStreet,
                        ClmCity: claimantData.clmCity,
                        ClmState: claimantData.clmState,
                        ClmZip: claimantData.clmZip,
                        ClmEmail: claimantData.clmEmail,
                        ClmSSN: claimantData.clmSSN,
                        ClmDOB: claimantData.clmDOB,
                        ClmDiagnosis: claimantData.clmDiagnosis,
                        ClmGender: claimantData.clmGender,
                        ClmAppAtty: claimantData.clmAppAtty,
                        ClmAppAttyFirm: claimantData.clmAppAttyFirm,
                        ClmAppAttyEmail: claimantData.clmAppAttyEmail,
                        ClmAppAttyPhone: claimantData.clmAppAttyPhone,
                        ClmDefAtty: claimantData.clmDefAtty,
                        ClmDefAttyFirm: claimantData.clmDefAttyFirm,
                        ClmDefAttyEmail: claimantData.clmDefAttyEmail,
                        ClmDefAttyPhone: claimantData.clmDefAttyPhone,
                        ClmOrderedByName: claimantData.clmOrderedByName,
                        ClmHeight: claimantData.clmHeight,
                        ClmWeight: claimantData.clmWeight,
                        ClmStatus: claimantData.clmStatus,
                        CreateDateTime: claimantData.createDateTime,
                        CreatebyUserId: claimantData.createbyUserId,
                        UpdateByUserId: claimantData.updateByUserId,
                        UpdateDateTime: claimantData.updateDateTime,
                        IsDeletedFlg: claimantData.isDeletedFlg,
                        ClientName: claimantData.clientName,
                        ClientAbbrv: claimantData.clientAbbrv,
                        RepFirstName: claimantData.repFirstName,
                        RepLastName: claimantData.repLastName,
                    }
                }))
                this.setModalClaimantForm()
            });
        }
    }

    setModalClaimantForm() {
        this.setState({ modalClaimant: !this.state.modalClaimant });
    }

    handleSaveEventClaimantFormSuccess(alert) {
        this.setState({
            modalClaimant: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, 3000);
        });
    }

    // Claimant Search
    setModalClaimantFormSearch() {
        if ((this.state.model.RepID === '' || this.state.model.RepID === null)) {
            this.handleSaveEventSuccess({
                message: 'Please choose Rep first before choosing Claimant',
                modal: true,
                color: "warning"
            });
        } else
            this.setState({ modalClaimantSearchForm: !this.state.modalClaimantSearchForm });
    }

    handleSearchClaimantKeyDown(e) {
        if ((this.state.model.RepID === '' || this.state.model.RepID === null) && e.keyCode === 13) {
            this.handleSaveEventSuccess({
                message: 'Please choose Rep first before choosing Claimant',
                modal: true,
                color: "warning"
            });
        }
        else {
            if (e.target.value !== '' && e.keyCode === 13) {
                this.setState({ searchClaimantName: e.target.value });
                this.setModalClaimantFormSearch();
            }
        }
    }

    handleSelectClaimant(claimant) {
        let model = this.state.model;

        model.ClmId = claimant.data.clmId;
        model.ClmFirstName = claimant.data.clmFirstName;
        model.ClmLastName = claimant.data.clmLastName;
        model.ClmClaimNumber = claimant.data.clmClaimNumber;
        model.ClmClaimNumberNoDash = claimant.data.clmClaimNumberNoDash;
        model.ClmPhone = claimant.data.clmPhone;
        model.ClmDateOfLoss = claimant.data.clmDateOfLoss;
        model.ClmDateOfInjury = claimant.data.clmDateOfInjury;
        model.ClmNote = claimant.data.clmPhone;
        model.ClmDOB = claimant.data.clmDOB;
        model.ClmInsuredParty = claimant.data.clmInsuredParty;
        model.ClmEmployer = claimant.data.clmEmployer;
        model.ClmInvoiceCaption = claimant.data.clmInvoiceCaption;
        model.ClmLanguage = claimant.data.clmLanguage;
        model.ClmWCABNumber = claimant.data.clmWCABNumber;

        this.setState({ model: model });

        this.setModalClaimantFormSearch();
    }

    // Interpreter Search
    setModalInterpreterFormSearch() {
        this.setState({ modalInterpreterSearchForm: !this.state.modalInterpreterSearchForm });
    }

    handleSearchInterpreterKeyDown(e) {
        if (e.target.value !== '' && e.keyCode === 13) {
            this.setState({ searchInterpreterName: e.target.value });
            this.setModalInterpreterFormSearch();
        }
    }

    handleSelectInterpreter(intrprtr) {
        let model = { ...this.state.model }

        model.IntId = intrprtr.data.intId;
        model.IntLastName = intrprtr.data.intLastName;
        model.IntFirstName = intrprtr.data.intFirstName;
        model.IntCellTxt = intrprtr.data.intCellTxt;
        model.IntPhoneOnly = intrprtr.data.intPhoneOnly;
        model.IntEmail = intrprtr.data.intEmail;
        model.IntNote = intrprtr.data.intNote;
        model.InHouseflg = intrprtr.data.inHouseflg;
        model.IntCity = intrprtr.data.intCity;
        model.IntStreet = intrprtr.data.intStreet;
        model.IntState = intrprtr.data.intState;
        model.IntZip = intrprtr.data.intZip;
        model.Rate = intrprtr.data.rate;
        model.HourMinimum = intrprtr.data.hourMinimum;
        model.Prorate = intrprtr.data.prorate;

        this.setState({ model: model }, () => {
            this.setModalInterpreterFormSearch();
        });

    }

    // Interpreter
    handleInterpreterNameClick() {
        if (this.state.model.IntId !== null && this.state.model.IntId !== '') {
            FetchData("/getinterpreters", "POST", this.user.token, { IntId: this.state.model.IntId }).then(data => {
                var intData = data[0];

                this.setState(prevState => ({
                    interpreter: {
                        EventType: 'update',
                        IntId: intData.intId,
                        IntLastName: intData.intLastName,
                        IntFirstName: intData.intFirstName,
                        IntGender: intData.intGender,
                        IntPronoun: intData.intPronoun,
                        IntEmail: intData.intEmail,
                        IntCellTxt: intData.intCellTxt,
                        IntPhoneOnly: intData.intPhoneOnly,
                        IntFax: intData.intFax,
                        IntBeeper: intData.intBeeper,
                        IntStreet: intData.intStreet,
                        IntCity: intData.intCity,
                        IntState: intData.intState,
                        IntZip: intData.intZip,
                        IntCountry: intData.intCountry,
                        IntSSINumber: intData.intSSINumber,
                        IntActiveFlg: intData.intActiveFlg,
                        IntCorporationFlg: intData.intCorporationFlg,
                        DBA: intData.dBA,
                        InHouseflg: intData.inHouseFlg,
                        UnVettedFlg: intData.vnVettedFlg,
                        EmergencyContact: intData.emergencyContact,
                        UnavailableReason: intData.unavailableReason,
                        BankName: intData.bankName,
                        BankAccount: intData.bankAccount,
                        BankRoute: intData.bankRoute,
                        Rate: intData.rate,
                        HourMinimum: intData.hourMinimum,
                        Prorate: intData.prorate,
                        ConfCallRate: intData.confCallRate,
                        BusinessCardRequestDate: intData.businessCardRequestDate,
                        BusinessCardRequestToProcessFlg: intData.businessCardRequestToProcessFlg,
                        IntNote: intData.intNote,
                        ReferredBy: intData.referredBy,
                        IntGender: intData.intGender,
                        CreateDateTime: intData.createDateTime,
                        CreateByUserId: intData.createByUserId,
                        UpdateByUserId: intData.updateByUserId,
                        UpdateDateTime: intData.updateDateTime,
                        IsDeletedFlg: intData.isDeletedFlg,
                        CreatedBy: intData.createdBy,
                        UpdatedBy: intData.updatedBy
                    }
                }));
                this.setModalInterpreterForm();
            });
        }
    }

    setModalInterpreterForm() {
        this.setState({ modalInterpreter: !this.state.modalInterpreter });
    }

    handleSaveEventInterpreterFormSuccess(alert) {
        this.setState({
            modalInterpreter: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, 3000);
        });
    }

    // Provider Search
    setModalProviderFormSearch() {
        this.setState({ modalProviderSearchForm: !this.state.modalProviderSearchForm });
    }

    handleSearchProviderKeyDown(e) {
        if (e.target.value !== '' && e.keyCode === 13) {
            this.setState({ searchProviderName: e.target.value });
            this.setModalProviderFormSearch();
        }
    }

    handleSelectProvider(provider) {
        let model = this.state.model;
        model.ProvId = provider.data.provId;
        model.ProviderName = provider.data.provFirstName + ' ' + provider.data.provLastName;
        model.AptName = provider.data.provSpeciality;
        model.AptPhone = provider.data.provPhone;
        model.AptStreet = provider.data.provStreet;
        model.AptCity = provider.data.provCity;
        model.AptState = provider.data.provState;
        model.AptZip = provider.data.provZip;
        model.ProvId = provider.data.provId;
        model.AptDirection = ''; //provider.provNote;

        this.setState({ model: model });

        this.setModalProviderFormSearch();
    }

    // Transportation
    setModalTransportation() {
        this.setState({ modalTransportation: !this.state.modalTransportation });
    }

    //
    setModalClient() {
        this.setState({ modalClient: !this.state.modalClient });
    }

    showClient(clientId) {
        let searchModel = {
            ClientId: clientId,
            ClientName: '',
        };

        FetchData("/getclients", "POST", this.user.token, searchModel).then(data => {
            var clientData = data[0];

            this.setState(prevState => ({
                client: {
                    EventType: 'update',
                    ClientId: clientData.clientId,
                    ClientAbbrv: clientData.clientAbbrv,
                    ClientPhone: clientData.clientPhone,
                    ClientPhone2: clientData.clientPhone2,
                    ClientPhoneFax: clientData.clientPhoneFax,
                    ClientName: clientData.clientName,
                    ClientMailStreet: clientData.clientMailStreet,
                    ClientMailStreet2: clientData.clientMailStreet2,
                    ClientMailCity: clientData.clientMailCity,
                    ClientMailState: clientData.clientMailState,
                    ClientMailZip: clientData.clientMailZip,
                    ClientStreet: clientData.clientStreet,
                    ClientStreet2: clientData.clientStreet2,
                    ClientCity: clientData.clientCity,
                    ClientState: clientData.clientState,
                    ClientZip: clientData.clientZip,
                    ClientCountry: clientData.clientCountry,
                    ClientDiscount: clientData.clientDiscount,
                    ClientNotes: clientData.clientNotes,
                    ClientCreditCard: clientData.clientCreditCard,
                    ClientEmailForInvoice: clientData.clientEmailForInvoice,
                    ClientEmail: clientData.clientEmail,
                    ClientWebsite: clientData.clientWebsite,
                    ClientOrg: clientData.clientOrg,
                    ClientBillRateType: clientData.clientBillRateType,
                    ClientInactiveFlg: clientData.clientInactiveFlg,
                    CreateDateTime: clientData.createDateTime,
                    CreateByUserId: clientData.createByUserId,
                    UpdateByUserId: clientData.updateByUserId,
                    UpdateDateTime: clientData.updateDateTime,
                    IsDeletedFlg: clientData.isDeletedFlg
                }
            }));
            this.setModalClient();
        });
    }

    setModalFollowup() {
        this.setState({ modalFollowUp: !this.state.modalFollowUp });
    }

    setModalPrintPreview() {
        this.setState({ modalPrintPreview: !this.state.modalPrintPreview }, () => {
            setTimeout(
                () => this.getJobHistory(this.state.model.JobId),
                4000
            );
        });
    }

    setModalClaimantJobs() {
        this.setState({ modalClaimantJobs: !this.state.modalClaimantJobs });
    }

    handleSaveEventClientSuccess(alert) {
        this.setState({
            modalClient: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, 3000);
        });
    }

    handleTransportationClick() {
        this.setState(prevState => ({
            transporation: {
                EventType: 'insert',
                TransportationId: null,
                JobId: this.state.model.JobId,
                JobNum: this.state.model.JobNum,
                CallerState: '',
                FailedTransportFlg: false,
                CallerName: '',
                AuthorizedBy: '',
                CallerEmail: '',
                CallerCompany: '',
                CallerPhone: '',
                AmbulatoryFlg: false,
                RoundTripFlg: false,
                Ground: null,
                AirFlg: false,
                LodgingFlg: false,
                Other: ''
            }
        }));
        this.setModalTransportation();
    }

    cancelIntConfirmed() {
        if (this.state.model.JobId !== null) {
            var data = {
                Jobid: this.state.model.JobId,
                UpdateByUserId: this.user.userid,
                UserEmail: this.user.email
            }

            FetchData("/cancelintconfirmed", "POST", this.user.token, data).then(data => {
                this.handleSaveEventSuccess({
                    message: data === 'Success' ? "Cancel Int. Confirm" : data,
                    modal: true,
                    color: data === 'Success' ? "success" : "warning"
                });

                if (data === 'Success') {
                    let model = { ...this.state.model }
                    model.IntConfflg = false;
                    this.setState({ model: model }, () => {
                        this.getJobHistory(this.state.model.JobId);
                    });
                }
            });
        }
    }

    //getRemoteTypes() {
    //    FetchData("/getjobinvlinecode", "POST", this.user.token, { AppointmentTypeFlag: 2 }).then(data => {
    //        this.setState({ remoteTypeList: data, fetchingData: false });
    //    });
    //}

    //getRemoteHosts() {
    //    FetchData("/getjobinvlinecode", "POST", this.user.token, { AppointmentTypeFlag: 3 }).then(data => {
    //        this.setState({ remoteHostList: data, fetchingData: false });
    //    });
    //}

    //getJobTypes() {
    //    FetchData("/getjobinvlinecode", "POST", this.user.token, { AppointmentTypeFlag: 1 }).then(data => {
    //        this.setState({ jobTypeList: data, fetchingData: false });
    //    });
    //}

    confirmDelete() {
        if (this.state.model.JobId !== null) {
            var note = window.prompt("Why are you deleting this job?", "");
            if (note !== null) {
                var data = {
                    JobNum: this.state.model.JobNum,
                    UpdateByUserId: this.user.userid,
                    UserEmail: this.user.email,
                    UserFirstName: this.user.firstname,
                    InvoiceNote: note
                }

                this.props.setFetchingData(true);

                FetchData("/deletejob", "POST", this.user.token, data).then(data => {
                    if (data === 'Success') {
                        this.props.handleSaveEventSuccess({
                            message: data,
                            modal: data === 'Success' ? false : true,
                            color: data === 'Success' ? "success" : "warning"
                        });
                    } else {
                        this.props.setFetchingData(false);
                        this.handleSaveEventSuccess({
                            message: data,
                            modal: data === 'Success' ? false : true,
                            color: data === 'Success' ? "success" : "warning"
                        });
                    }
                });
            }
        }
    }

    showMap() {

        let model = { ...this.state.model }

        var origin = model.IntStreet.replace(/\s/g, "+").replace(/[^a-zA-Z0-9]/g, '+');
        var orgCity = model.IntCity;
        var orgState = model.IntState;
        var orgZip = model.IntZip;

        if (orgCity != "")
            origin += ",+" + orgCity.replace(/\s/g, "+");
        if (orgState != "")
            origin += "+" + orgState.replace(/\s/g, "+");
        if (orgZip != "")
            origin += "+" + orgZip.replace(/\s/g, "+");

        var dest = model.AptStreet.replace(/\s/g, "+").replace(/[^a-zA-Z0-9]/g, '+');
        var desCity = model.AptCity;
        var desState = model.AptState;
        var desZip = model.AptZip;

        if (desCity != "")
            dest += ",+" + desCity.replace(/\s/g, "+");
        if (desState != "")
            dest += "+" + desState.replace(/\s/g, "+");
        if (desZip != "")
            dest += "+" + desZip.replace(/\s/g, "+");

        var url = "https://www.google.com/maps/dir/?api=1&origin=" + origin + "&destination=" + dest + "&travelmode=driving";
        //console.log(url);
        window.open(url, '_blank');
    }

    async handleViewJobHistoryFile(item) {
        let body = {
            FileLocationS3: item.fileLocationS3,
            FileName: item.fileName
        };

        await FetchFile("/getjobhistoryfile", "POST", this.user.token, body).then(blob => {
            saveAs(blob, item.fileName);
        });
    }

    //getSubTotal() {
    //    var sum = 0;
    //    for (var i = 0; i < this.state.jobInvLineList.length; i++) {
    //        //console.log(this.state.jobInvLineList[i].amount);
    //        sum += (this.state.jobInvLineList[i].amount == null ? 0 : this.state.jobInvLineList[i].amount);
    //    }
    //    return sum;
    //}

    handleViewInvoice = (event) => {
        let model = {
            jobId: this.state.model.JobId,
            jobNum: this.state.model.JobNum
        }

        this.props.viewInvoice(model);
    }

    renderJobHistoryTable() {
        return (
            <WorkOrderFormTableJobHistory
                state={this.state}
                handleViewJobHistoryFile={this.handleViewJobHistoryFile}
                handleNewJobNoteViewClick={this.handleNewJobNoteViewClick}
                handleDeleteJobHistory={this.handleDeleteJobHistory}
                jobHistory={this.state.jobHistoryList}
            ></WorkOrderFormTableJobHistory>
        );

    }

    renderReceivablesTable() {
        return (
            <WorkOrderFormTableReceivables
                state={this.state}
                jobInvLine={this.state.jobInvLineList}
                handleEditJobDescriptionClick={this.handleEditJobDescriptionClick}
                handleDeleteJobDescriptionClick={this.handleDeleteJobDescriptionClick}
            ></WorkOrderFormTableReceivables>
        );

    }
        
    render() {
        //let subTotal = this.getSubTotal();
        //let total = 0;
        //let discount = this.state.model.JobDiscount == null ? 0 : this.state.model.JobDiscount;
        //let discountAmt = subTotal * (discount / 100);
        //if (subTotal !== 0)
        //    total = subTotal - discountAmt;

        let isNew = this.state.model.JobId === null ? true : false;

        const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
        const { deltaPosition } = this.state;

        let jobHistoryContent = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderJobHistoryTable();

        let receivablesContent = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderReceivablesTable();


        return (
            <Draggable handle=".handle" onDrag={this.handleDrag} {...dragHandlers}>
                <div>
                    <Modal id="woForm" isOpen={this.props.modal} toggle={this.setModal} style={{ transform: `translate(${deltaPosition.x}px, ${deltaPosition.y}px)`, width: '1280px', height: '480px', maxWidth: '100%' }} backdrop="static">
                        <ModalHeader className="handle">
                            {(!isNew && (this.props.model.JobNum !== null || this.state.model.JobNum !== null)) ? (
                                <div>
                                    <span style={{ color: "red", fontSize: "14px" }}>Id: {this.props.model.JobNum || this.state.model.JobNum}</span> {' '}
                                    <span style={{ fontSize: "14px" }}>Created: {moment(this.props.model.CreateDateTime).format('MM/DD/YYYY')}</span>  {' '}
                                    <span style={{ fontSize: "14px" }}>By: {this.props.model.CreatedBy}</span>
                                </div>
                            ) : "New Work Order"
                            }
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        {this.state.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <ReactTooltip className="tooltip" id="rtp2" place="top" type="dark" effect="solid" html={true} />
                            <div>
                                <Row>
                                    <Col className="col-12">
                                        <InputGroup size="sm">
                                            <Input name="searchRepName" type="text" className="form-control-text" placeholder="Search Rep" value={this.state.searchRepName} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onKeyDown={this.handleSearchRepKeyDown} /> {' '}
                                            <Input name="searchProviderName" type="text" className="form-control-text" placeholder="Search Provider" value={this.state.searchProviderName} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onKeyDown={this.handleSearchProviderKeyDown} /> {' '}
                                            <Input name="searchInterpreterName" type="text" className="form-control-text" placeholder="Search Interperter" value={this.state.searchInterpreterName} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onKeyDown={this.handleSearchInterpreterKeyDown} /> {' '}
                                            <Input name="searchClaimantName" type="text" className="form-control-text" placeholder="Search Claimant" value={this.state.searchClaimantName} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onKeyDown={this.handleSearchClaimantKeyDown} /> {' '}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText size="sm" color="blue">
                                                Caller Type:
                                            </InputGroupText>
                                            <select name="CallerType" value={this.state.model.CallerType} className="form-control form-control-text" onChange={this.handleChange}>
                                                <option value={null}></option>
                                                <option value={1}>Adjuster</option>
                                                <option value={2}>Insured/Clmnt</option>
                                                <option value={3}>Other</option>
                                                <option value={4}>Follow-up</option>
                                            </select>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-6">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Caller Notes:
                                            </InputGroupText>
                                            <Input name="CallerNote" className="form-control form-control-text" value={this.state.model.CallerNote} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                    {/*<Col className="col-1">*/}
                                    {/*    <span className="input-group-text">Job Status:</span>*/}

                                    {/*</Col>*/}
                                    <Col className="col-2">
                                        <Button className="btn-secondary-no-background form-control-text btn btn-secondary btn-sm" onClick={() => { this.props.setNextPrevData(-1); this.setNextPrevData(-1) }}>
                                            <img src={UpIcon} style={{ width: "16px", height: "16px" }} alt="Prev" />
                                        </Button>
                                        <Button className="btn-secondary-no-background form-control-text btn btn-secondary btn-sm" onClick={() => { this.props.setNextPrevData(1); this.setNextPrevData(1) }}>
                                            <img src={DownIcon} style={{ width: "16px", height: "16px" }} alt="Next" />
                                        </Button>
                                    </Col>
                                    <Col className="col-2">
                                        <Button color="secondary" outline className="btn" size="sm" data-for="rtp2" data-tip="Alt-X Inserts today's date<br>Alt-T Insert Time Now<br>Alt-Y Edit claimant<br>Alt-J Claimant jobs<br>Alt-U Has Followups<br>Alt-l Choose interpreter<br>Alt-M Me as Interpreter<br>Alt-N A Non-Cert A<br>Alt-G ACertA<br>Alt-H A Exotic A<br>Alt-P Choose provider<br>Alt-A Add Provider Note<br>Alt-W Print work order<br>Alt-S Save Work Order<br>Alt-V Print Travel Request<br>Alt-O Print Interpreter Fax Sheet">HotKeys</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <InputGroup size="sm">
                                            <InputGroupText style={{ width: '70px' }}>
                                                Job Date:
                                            </InputGroupText>
                                            <Input name="JobDateTime" type="date" className="form-control-text" value={this.state.model.JobDateTime === null ? '' : moment(this.state.model.JobDateTime).format('YYYY-MM-DD')} onChange={this.handleChange} />
                                            {this.state.model.JobDateTime === null ? null : <span className="form-control-text" style={{ color: 'red', paddingl: '5px', paddingTop: '5px' }}><small>{moment(this.state.model.JobDateTime).format('dddd')}</small></span>}
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Time:
                                            </InputGroupText>
                                            <Input name="JobTime" type="text" className="form-control-text" value={this.state.model.JobTime} onChange={this.handleChange} onBlur={this.handleJobTimeFieldChange} />
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Trans Needed:
                                            </InputGroupText>
                                            <input name="Transportationflg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.Transportationflg} onChange={this.handleChange} />

                                            <InputGroupText>
                                                Trans Conf:
                                            </InputGroupText>
                                            <Input name="TransConfflg" type="checkbox" className={this.state.model.TransConfflg === true ? "input-checkbox input-checkbox-red-check" : "input-checkbox input-checkbox-red"} defaultChecked={this.state.model.TransConfflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                    {/*<Col className="col-1_5">*/}
                                    {/*    <InputGroup size="sm">*/}
                                    {/*        <InputGroupText>*/}
                                    {/*            Trans Conf:*/}
                                    {/*        </InputGroupText>*/}
                                    {/*        <Input name="TransConfflg" type="checkbox" className="input-checkbox input-checkbox-red" defaultChecked={this.state.model.TransConfflg} onChange={this.handleChange} />*/}
                                    {/*    </InputGroup>*/}
                                    {/*</Col>*/}
                                    <Col className="col-1">
                                        <Button className="btn-secondary-no-background form-control-text" onClick={this.handleTransportationClick}>Transportation</Button>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Work Flow:
                                            </InputGroupText>
                                            <select name="WorkFlowId" className="form-control form-control-text" value={this.state.model.WorkFlowId} onChange={this.handleChange} >
                                                <option value=""></option>
                                                <option value="Authorization">Authorization</option>
                                                <option value="Booking">Booking</option>
                                                <option value="Confirmation">Confirmation</option>
                                            </select>
                                        </InputGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Remote Type:
                                            </InputGroupText>
                                            <select name="RemType" className="form-control form-control-text" value={this.state.model.RemType} onChange={this.handleChange} >
                                                <option value={null}></option>
                                                {this.state.remoteTypeList.map((jobType) =>
                                                    <option value={jobType.jobInvLineCodeId}>{jobType.appointmentType}</option>
                                                )}
                                            </select>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Remote Host:
                                            </InputGroupText>
                                            <select name="RemHost" className="form-control form-control-text" value={this.state.model.RemHost} onChange={this.handleChange} >
                                                <option value={null}></option>
                                                {this.state.remoteHostList.map((jobType) =>
                                                    <option value={jobType.jobInvLineCodeId}>{jobType.appointmentType}</option>
                                                )}
                                            </select>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Remote Clm Check:
                                            </InputGroupText>
                                            <Input name="RemClaimflg" type="checkbox" className="input-checkbox" checked={this.state.model.RemClaimflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Remote Int Check:
                                            </InputGroupText>
                                            <Input name="RemIntflg" type="checkbox" className="input-checkbox" checked={this.state.model.RemIntflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Remote Prov Check:
                                            </InputGroupText>
                                            <Input name="RemProvflg" type="checkbox" className="input-checkbox" checked={this.state.model.RemProvflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Rep Authorized:
                                            </InputGroupText>
                                            <Input name="RepAuthorizedflg" type="checkbox" className="input-checkbox" checked={this.state.model.RepAuthorizedflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Rep:
                                            </InputGroupText>
                                            <a style={{ color: "blue", textDecoration: "underline" }} className="form-control no-border text-area-no-background form-control-text-padding" href="javascript:void(0)" onClick={this.handleRepNameClick}>{this.state.model.RepFirstName}  {this.state.model.RepLastName}</a>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-3">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Phone:
                                            </InputGroupText>
                                            {/*<Input type="text" className="no-border text-area-no-background form-control-text" readOnly="true" value={this.state.model.RepPhone} />*/}
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.RepPhone}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Email:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.RepEmail}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Client:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding"><a style={{ color: "blue", textDecoration: "underline" }} href="javascript:void(0);" onClick={() => this.showClient(this.state.model.ClientId)}>{this.state.model.ClientAbbrv}</a></label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Client Phone:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.ClientPhone}</label>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Clm Name:
                                            </InputGroupText>
                                            <a style={{ color: "green", textDecoration: "underline" }} className="form-control no-border text-area-no-background form-control-text-padding" href="javascript:void(0)" onClick={this.handleClaimantNameClick}>{this.state.model.ClmLastName}, {this.state.model.ClmFirstName}</a>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Clm #:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmClaimNumber}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-3">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Phone:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmPhone}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Lang:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmLanguage}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-1">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                DOB:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmDOB === null ? "" : moment(this.state.model.ClmDOB).format('MM/DD/YY')}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Email:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmEmail}</label>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Employer:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmEmployer}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-1">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                DoI:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmDateOfInjury}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-1">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Insured:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmInsuredParty}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-1">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                DoL:
                                            </InputGroupText>
                                            <label className="form-control no-border-green text-area-no-background form-control-text-padding">{this.state.model.ClmDateOfLoss}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Has Attorney:
                                            </InputGroupText>
                                            <Input type="checkbox" className="input-checkbox form-check-input" disabled />
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                CLM Status:
                                            </InputGroupText>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                CLM Confirmation:
                                            </InputGroupText>
                                            <Input name="ClmConfflg" type="checkbox" className="input-checkbox" checked={this.state.model.ClmConfflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green" style={{ alignItems: 'unset' }}>
                                                Claimant Notes:
                                            </InputGroupText>
                                            <label className="input-font-normal no-border-red text-area-no-background form-control-text-padding" style={{ flexGrow: 1, flexBasis: "0%" }}>{this.state.model.ClmNote}</label>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Interpreter:
                                            </InputGroupText>
                                            <a style={{ color: "blue", textDecoration: "underline" }} className="form-control no-border text-area-no-background form-control-text-padding" href="javascript:void(0)" onClick={this.handleInterpreterNameClick}>{this.state.model.IntLastName}, {this.state.model.IntFirstName}</a>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Cell/Text:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.IntCellTxt}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Phone Only:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.IntPhoneOnly}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <Button className="btn-secondary-no-background form-control-text" size="sm" id="btnCancelIntConf" onClick={this.cancelIntConfirmed}>Cancel Int. Confirm</Button>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Int. Confirmed:
                                            </InputGroupText>
                                            <Input name="IntConfflg" type="checkbox" className="input-checkbox" checked={this.state.model.IntConfflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Rate:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.Rate}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Minimum:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.HourMinimum}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-6">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Overtime:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.Prorate}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Chk #:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.IntCheckNum}</label>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-10">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border-green">
                                                Note:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding" style={{ flexGrow: 1, flexBasis: "0%", wordWrap: 'break-word', whiteSpace: 'initial' }}>{this.state.model.IntNote}</label>
                                        </InputGroup>
                                    </Col>
                                    <Col className="col-2">
                                        <InputGroup size="sm">
                                            <InputGroupText>
                                                Chk Date:
                                            </InputGroupText>
                                            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.IntCheckDate == null ? null : moment(this.state.model.IntCheckDate).format('MM/DD/YYYY')}</label>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col className="col-9">
                                        <InputGroup size="sm">
                                            <Input type="text" name="ProviderName" className="form-control-text" placeholder="Provider" value={this.state.model.ProviderName} onChange={this.handleChange} /> {' '}
                                            <label className="no-border text-area-no-background form-control-text-padding" style={{ color: "blue", paddingLeft: "12px" }}>Job Type:</label>
                                            <select name="AppointmentTypeId" className="form-control form-control-text" value={this.state.model.AppointmentTypeId} onChange={this.handleChange}>  {' '}
                                                {this.state.jobTypeList.map((jobType) =>
                                                    <option value={jobType.jobInvLineCodeId} selected={this.state.model.AppointmentTypeId === jobType.jobInvLineCodeId} >{jobType.appointmentType}</option>
                                                )}
                                            </select>  {' '}
                                            <Input name="AptName" type="text" className="form-control-text" placeholder="Appt Name" value={this.state.model.AptName} onChange={this.handleChange} />  {' '}
                                            <Input name="AptPhone" type="text" className="form-control-text" placeholder="Appt Phone" value={this.state.model.AptPhone} onChange={this.handleChange} />

                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup size="sm">
                                            <InputGroupText className="input-group-text">
                                                Provider Confirmation:
                                            </InputGroupText>
                                            <Input name="ProvConfflg" type="checkbox" className="input-checkbox" checked={this.state.model.ProvConfflg} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                    {/*<Col>*/}
                                    {/*    <InputGroup size="sm">*/}
                                    {/*        <InputGroupText>*/}
                                    {/*            Job Type:*/}
                                    {/*        </InputGroupText>*/}
                                    {/*        <select className="form-control">*/}
                                    {/*            <option value="Accupt">Accupt</option>*/}
                                    {/*            <option value="Other-Legal">Other-Legal</option>*/}
                                    {/*            <option value="Other">Other</option>*/}
                                    {/*            <option value="AME">AME</option>*/}
                                    {/*        </select>*/}
                                    {/*    </InputGroup>*/}
                                    <Row>
                                        {/*</Col>*/}
                                    </Row>
                                    <Col className="col-12">
                                        <InputGroup size="sm">
                                            <Input name="AptStreet" type="text" className="form-control-text" placeholder="Appt Street" value={this.state.model.AptStreet} onChange={this.handleChange} />  {' '}
                                            <Input name="AptCity" type="text" className="form-control-text" placeholder="Appt City" value={this.state.model.AptCity} onChange={this.handleChange} />  {' '}
                                            <Input name="AptState" type="text" className="form-control-text" placeholder="Appt State" value={this.state.model.AptState} onChange={this.handleChange} />  {' '}
                                            <Input name="AptZip" type="text" className="form-control-text" placeholder="Appt Zip" value={this.state.model.AptZip} onChange={this.handleChange} />  {' '}
                                            <Input name="AptDirection" type="text" className="form-control-text" placeholder="Appt Details" value={this.state.model.AptDirection} onChange={this.handleChange} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="row-padding16">
                                    <Col className="col-9 x-panel-body x-layout-fit x-panel-body-default" style={{ overflow: "scroll", height: "180px", backgroundColor: 'white' }}>
                                        {jobHistoryContent}
                                    </Col>
                                    <Col className="col-3">
                                        <InputGroup size="sm">
                                            <InputGroupText className="no-border">
                                                Invoice Notes:
                                            </InputGroupText>
                                            <textarea
                                                name="InvoiceNote"
                                                rows="7"
                                                className="no-border form-control-text"
                                                style={{ width: "100%" }}
                                                value={this.state.model.InvoiceNote} // Use value prop instead of children
                                                onChange={this.handleChange}
                                            />                                        
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {!isNew &&
                                    <Row>
                                        <Col>
                                            <InputGroup size="sm">
                                                <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.handleNewJobNoteClick}>New Job Note</Button>
                                                <span className="input-group-text">Start:</span>
                                                <label className="no-border text-area-no-background form-control form-control-text-padding">{this.state.model.JobTime}</label>
                                                <span className="input-group-text">Finish:</span>
                                                <Input name="FinishTimeString" type="text" className="form-control-text" placeholder="--:-- AM/PM" value={this.state.model.FinishTimeString} onChange={this.handleChange} onBlur={this.handleJobTimeFieldChange} />
                                                {/*<input name="FinishTimeString" type="time" className="form-control form-control-text" value={this.state.model.FinishTimeString === null ? "" : this.state.model.FinishTimeString} onChange={this.handleTimeFieldChange} />*/}
                                                <span className="input-group-text">Dur:</span>
                                                <span className="input-group-text">F:</span>
                                                <Input name="Failedflg" type="checkbox" className="input-checkbox form-check-input" defaultChecked={this.state.model.Failedflg} onChange={this.handleChange} />
                                                <span className="input-group-text">IntFee:</span>
                                                <input name="IntFee" type="text" className="form-control form-control-text" value={this.state.model.IntFee <= 0 ? "" : this.state.model.IntFee} onKeyUp={this.handleOnKeyup} onChange={this.handleChange} onBlur={this.handleNumberFieldBlur} />
                                                <span className="input-group-text">OTFee:</span>
                                                <input name="IntOTFee" type="text" className="form-control form-control-text" value={this.state.model.IntOTFee <= 0 ? "" : this.state.model.IntOTFee} onKeyUp={this.handleOnKeyup} onChange={this.handleChange} onBlur={this.handleNumberFieldBlur} />
                                                <span className="input-group-text">Oth:</span>
                                                <input name="IntFeeOther" type="text" className="form-control form-control-text" value={this.state.model.IntFeeOther <= 0 ? "" : this.state.model.IntFeeOther} onKeyUp={this.handleOnKeyup} onChange={this.handleChange} onBlur={this.handleNumberFieldBlur} />
                                                <Button className="btn btn-secondary-no-background form-control-text" size="sm" onClick={this.showMap}>Show Map</Button>
                                                <span className="input-group-text">Disc:</span>
                                                <input name="JobDiscount" type="number" className="form-control-text" style={{ width: "50px" }} value={this.state.model.JobDiscount} onChange={this.handleChange} /> {'  '}
                                                <Button className="btn-secondary-no-background form-control-text" size="sm"><img src={DownloadIcon} onClick={this.handleViewInvoice} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Delete" /></Button>
                                                <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.confirmDelete} ><img src={DeleteIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Delete" /></Button>
                                                <span className="input-group-text">R:</span>
                                                <Input name="BillReviewedflg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.BillReviewedflg} onChange={this.handleChange} />
                                                <span className="input-group-text">Aud:</span>
                                                <Input name="Auditflg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.Auditflg} onChange={this.handleChange} />
                                                <span className="input-group-text">PurpleRpt:</span>
                                                <Input name="PurpleReportflg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.PurpleReportflg} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                }
                                <Row style={{ width: '99.98%' }} >
                                    <Col className="col-12 x-panel-body x-layout-fit x-panel-body-default col" style={{ overflowY: "scroll", height: "100px", backgroundColor: 'unset' }}>
                                        {receivablesContent}
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>

                    {this.state.modalWorkOrderNoteForm &&
                        <WorkOrderNoteForm modal={this.state.modalWorkOrderNoteForm} model={this.state.jobHistory} alert={this.props.alert} setModal={this.setModalWorkOrderNoteForm} handleSaveEventSuccess={this.handleSaveEventWorkOrderNoteFormSuccess} />
                    }
                    {this.state.modalWorkOrderJobDescription &&
                        <WorkOrderJobDescription model={this.state.jobInvLine} modal={this.state.modalWorkOrderJobDescription} alert={this.state.alert} setModal={this.setModalWorkOrderJobDescription} handleSaveEventSuccess={this.handleSaveEventWorkOrderJobDescriptionSuccess} />
                    }
                    {
                        this.state.modalRepSearchForm &&
                        <RepFormSearch modal={this.state.modalRepSearchForm} alert={this.props.alert} setModal={this.setModalRepFormSearch} RepName={this.state.searchRepName} handleDoubleClick={this.handleSelectRep} />
                    }
                    {
                        this.state.modalClaimantSearchForm &&
                        <ClaimantFormSearch modal={this.state.modalClaimantSearchForm} alert={this.props.alert} setModal={this.setModalClaimantFormSearch} Name={this.state.searchClaimantName} workorder={this.state.model} handleDoubleClick={this.handleSelectClaimant} />
                    }
                    {
                        this.state.modalInterpreterSearchForm &&
                        <InterpreterFormSearch modal={this.state.modalInterpreterSearchForm} alert={this.props.alert} setModal={this.setModalInterpreterFormSearch} Name={this.state.searchInterpreterName} handleDoubleClick={this.handleSelectInterpreter} />
                    }
                    {
                        this.state.modalProviderSearchForm &&
                        <ProviderFormSearch modal={this.state.modalProviderSearchForm} setModal={this.setModalProviderFormSearch} alert={this.props.alert} Name={this.state.searchProviderName} handleDoubleClick={this.handleSelectProvider} />
                    }
                    {
                        this.state.modalTransportation &&
                        <WorkOrderTransportationForm model={this.state.transporation} modal={this.state.modalTransportation} alert={this.props.alert} setModal={this.setModalTransportation} job={this.state.model} handleSaveEventSuccess={this.handleSaveEventWorkOrderTransportationFormSuccess} />
                    }
                    {this.state.modalRep &&
                        <RepForm model={this.state.rep} modal={this.state.modalRep} alert={this.props.alert} setModal={this.setModalRepForm} handleSaveEventSuccess={this.handleSaveEventRepFormSuccess} />
                    }
                    {this.state.modalClaimant &&
                        <ClaimantForm model={this.state.claimant} modal={this.state.modalClaimant} alert={this.props.alert} setModal={this.setModalClaimantForm} handleSaveEventSuccess={this.handleSaveEventClaimantFormSuccess} />
                    }
                    {this.state.modalInterpreter &&
                        <InterpreterForm model={this.state.interpreter} modal={this.state.modalInterpreter} alert={this.props.alert} setModal={this.setModalInterpreterForm} handleSaveEventSuccess={this.handleSaveEventInterpreterFormSuccess} />
                    }
                    {this.state.modalClient &&
                        <ClientForm model={this.state.client} modal={this.state.modalClient} alert={this.state.alert} setModal={this.setModalClient} handleSaveEventSuccess={this.handleSaveEventClientSuccess} />
                    }
                    {this.state.modalFollowUp &&
                        <WorkOrderFollowUp model={this.state.model} modal={this.state.modalFollowUp} alert={this.state.alert} setModal={this.setModalFollowup} handleSaveEventSuccess={this.handleSaveEventWorkOrderNoteFormSuccess} />
                    }
                    {this.state.modalPrintPreview &&
                        <WorkOrderPrintPreview model={this.state.model} modal={this.state.modalPrintPreview} alert={this.state.alert} setModal={this.setModalPrintPreview} jobHistoryList={this.state.jobHistoryList} />
                    }
                    {this.state.modalClaimantJobs &&
                        <WorkOrderClaimantJobs model={this.state.model} modal={this.state.modalClaimantJobs} alert={this.state.alert} setModal={this.setModalClaimantJobs} />
                    }
                </div>
            </Draggable>

        )
    }
}