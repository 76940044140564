import React, { Component, } from 'react';
import { Button, Row, Col, InputGroup, Alert, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import ReactTooltip from "react-tooltip";

import { LoadingModal } from './LoadingModal';
import { PostChecksForm } from './PostChecksForm';
import { PostChecksAutoBulk } from './PostChecksAutoBulk';
import { PostChecksTableDefault } from './PostChecksTableDefault';
import { PostChecksTableReceivables } from './PostChecksTableReceivables';

import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';
import { saveAs } from 'file-saver';


export class PostChecks extends Component {
    static displayName = PostChecks.name;

    constructor(props) {
        super(props);
        this.state = {
            //columnRefs: columns.map(() => createRef()),
            //isResizing: -1,

            workOrderList: [],
            receivableList: [],
            modal: false,
            modalAutoBulk: false,
            fetchingData: false,
            isKeyPress: false,

            selectedIndex: -1,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                JobNum: null,
                Claimant: '',
                ClmClaimNumberNoDash: '',
                ClientAbbrv: ''
            },
            receivable: {
                EventType: 'insert',
                JobReceivableId: null,
                JobId: null,
                PaymentCompleteFlg: false,
                ActionCode: '',
                CheckDate: '',
                CheckNumber: '',
                Amount: null,
                TransferFrom: null,
                TransferTo: null,
                SelectedFlg: false,
                TransID: null,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                //
                IsRefund: false,
                ClientCreditCard: '',
                JobNum: null,
                JobDateTime: null,
                ClientZip: ''
            }
        };
        //
        this.user = JSON.parse(GetCookie('user'));
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);

        this.setData = this.setData.bind(this);
        this.setModal = this.setModal.bind(this);
        this.setModalAutoBulk = this.setModalAutoBulk.bind(this);

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleSaveEventAutoBulkSuccess = this.handleSaveEventAutoBulkSuccess.bind(this);
        //
        this.handlePostCheckSingleClick = this.handlePostCheckSingleClick.bind(this);
        this.handlePostCheckDoubleClick = this.handlePostCheckDoubleClick.bind(this);
        this.handleAutoBulkClick = this.handleAutoBulkClick.bind(this);
        this.viewInvoice = this.viewInvoice.bind(this);
        //
        this.renderPostCheckTable = this.renderPostCheckTable.bind(this);
        this.renderReceivableTable = this.renderReceivableTable.bind(this);
        //
    }

    componentDidMount() {
    }

    async populatePostChecksData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModel }

        this.setState({ searchModel: model }, () => {
            FetchData("/getpostchecks", "POST", this.user.token, model).then(data => {
                if (this.state.isKeyPress && this.state.searchModel.JobNum != null) {
                    if (data.length === 0) {
                        this.setState({
                            alert: {
                                color: "warning",
                                message: 'There is no return on your search.',
                                isOpen: true
                            }
                        }, () => {
                            window.setTimeout(() => {
                                this.setState({ alert: { isOpen: false } });
                            }, Timeout(alert.message));
                        });
                    }
                    else {
                        var job = data[0];
                        this.setData(job);
                        this.setModal();
                    }
                }

                this.setState({ workOrderList: data, loading: false, fetchingData: false, isKeyPress: false });
            });
        });
    }

    async getJobReceivable(jobId) {
        this.setState({ fetchingData: true });

        FetchData("/getreceivables", "POST", this.user.token, { JobId: jobId }).then(data => {
            this.setState({ receivableList: data, loading: false, fetchingData: false });
        });
    }

    handlePostCheckSingleClick(job) {
        this.getJobReceivable(job.jobId);
    }


    handlePostCheckDoubleClick(job) {
        this.setData(job);
        this.setModal();
    }

    handleAutoBulkClick() {
        this.setModalAutoBulk();
    }
    setData(woData, rowIndex) {
        this.setState(prevState => ({
            receivable: {
                EventType: 'insert',
                JobReceivableId: null,
                JobId: woData.jobId,
                JobNum: woData.jobNum,
                JobDateTime: woData.jobDateTime,
                PaymentCompleteFlg: false,
                ActionCode: '',
                CheckDate: null,
                CheckNumber: '',
                Amount: woData.jobBalance,
                TransferFrom: null,
                TransferTo: null,
                SelectedFlg: false,
                TransID: null,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                IsRefund: false,
                ClientCreditCard: woData.clientCreditCard,
                ClientZip: woData.clientZip
            },
        }), () => {
            this.setModal();
        });
    };


    setModal() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (!this.state.modal) {
                this.setState({
                    selectedIndex: -1
                });
            }
        });
    }

    setModalAutoBulk() {
        this.setState({
            modalAutoBulk: !this.state.modalAutoBulk
        }, () => {
            if (!this.state.modal) {
                this.setState({
                    selectedIndex: -1
                });
            }
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }

        if (e.target.type === 'number' && (e.target.value !== null || e.target.value !== ''))
            model[e.target.name] = parseInt(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({ receivableList: [], isKeyPress: true }, () => {
                this.populatePostChecksData();
            });
        }
    }
    ////add a call to populatePostChecks after setModal completes

    //handleSaveEventSuccess(alert) {
    //    this.setState({
    //        modal: alert.modal,
    //        alert: {
    //            color: alert.color,
    //            message: alert.message,
    //            isOpen: true
    //        }
    //    }, () => {
    //        window.setTimeout(() => {
    //            this.setState({ alert: { isOpen: false } })
    //        }, Timeout(alert.message))
    //    });
    //}

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            this.populatePostChecksData();
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message))
        });
    }
    handleSaveEventAutoBulkSuccess(alert) {
        this.setState({
            modalAutoBulk: alert.modalAutoBulk,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            this.populatePostChecksData();
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message))
        });
    }

    viewInvoice(woData, i) {
        let body = {
            JobIdsList: ""
        };

        body.JobIdsList = woData.jobId;
        let currentDate = new Date();
        let invoiceFileName = "Invoice " + woData.jobNum + " " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate() + 1) + "-" + currentDate.getFullYear() + ".pdf";

        FetchFile("/printinvoices", "POST", this.user.token, body).then(blob => {
            saveAs(blob, invoiceFileName);
        })
    }


    renderPostCheckTable() {
        return (
            <PostChecksTableDefault
                state={this.state}
                setData={this.setData}
                handlePostCheckSingleClick={this.handlePostCheckSingleClick}
                handlePostCheckDoubleClick={this.handlePostCheckDoubleClick}
                setModal={this.setModal}
                workOrders={this.state.workOrderList}
                viewInvoice={this.viewInvoice}
            ></PostChecksTableDefault>
        );

    }

    renderReceivableTable() {
        return (
            <PostChecksTableReceivables
                state={this.state}
                receivables={this.state.receivableList}
            ></PostChecksTableReceivables>
        )
    }

    render() {
        let workOrdersContent = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderPostCheckTable();

        let receivablesContent = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderReceivableTable();

        return (
            <div style={{ paddingTop: "12px" }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <Row>
                    <Col className="col-9">
                        <InputGroup size="sm">
                            <input name="JobNum" type="number" className="form-control form-control-text" value={this.state.searchModel.JobNum} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Invoice #" onKeyPress={this.handleKeyPress} />
                            <input name="Claimant" type="text" className="form-control form-control-text" value={this.state.searchModel.Claimant} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant" onKeyPress={this.handleKeyPress} />
                            <input name="ClmClaimNumberNoDash" type="text" className="form-control form-control-text" value={this.state.searchModel.ClmClaimNumberNoDash} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Claimant #" onKeyPress={this.handleKeyPress} />
                            <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Abbrv" onKeyPress={this.handleKeyPress} />
                            {' '}<span style={{ paddingRight: "4px" }}></span><Button color="btn" className="btn btn-secondary" size="sm" onClick={this.handleAutoBulkClick}>Auto Bulk</Button>
                        </InputGroup>
                    </Col>
                </Row>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <ReactTooltip id="rtp" place="right" type="dark" effect="solid" />
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {workOrdersContent}
                </div>

                <hr />
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className="active"
                            onClick={function noRefCheck() { }}
                        >
                            Receivables
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab="1">
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    {receivablesContent}
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>

                {this.state.modal &&
                    <PostChecksForm model={this.state.receivable} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }
                {this.state.modalAutoBulk &&
                    <PostChecksAutoBulk model={null} modal={this.state.modalAutoBulk} alert={this.state.alert} setModal={this.setModalAutoBulk} handleSaveEventSuccess={this.handleSaveEventAutoBulkSuccess} />
                }

            </div>
        );

    }
}