import React, {
    Component, createRef, useCallback, useMemo, useRef, useState, StrictMode,
} from 'react';
import { Button, Row, Col, Alert, InputGroup } from 'reactstrap';

import { RolodexForm } from './RolodexForm';
import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png';

//import * as ReactDOMServer from 'react-dom/server.js';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};


const columns = [
    { headerName: "Last Name", field: "lastName", width: 100 },
    { headerName: "First Name", field: "firstName", width: 100 },
    { headerName: "Title", field: "title", width: 100 },
    { headerName: "Speciality", field: "speciality", width: 150 },
    { headerName: "Phone", field: "phone", width: 75 },
    { headerName: "Fax", field: "fax", width: 75 },
    { headerName: "Last Name", field: "lastName", width: 100 },
    { headerName: "First Name", field: "firstName", width: 100 },
    { headerName: "Title", field: "title", width: 100 },
    { headerName: "Speciality", field: "speciality", width: 150 },
    { headerName: "Phone", field: "phone", width: 75 },
    { headerName: "Fax", field: "fax", width: 75 }
];

export class Rolodex extends Component {
    static displayName = Rolodex.name;

    constructor(props) {
        super(props);
        this.state = {
            columnRefs: columns.map(() => createRef()),
            isResizing: -1,

            rolodexList: [],
            loading: false,
            fetchingData: false,
            modal: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                RolodexId: null,
                LastName: '',
                FirstName: '',
                Title: '',
                Speciality: '',
                Phone: '',
                Fax: '',
                Street: '',
                City: '',
                State: '',
                Zip: '',
                Note: '',
                Secretary: '',
                Label: '',
                Selected: false,

                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            },
            searchModel: {
                Name: '',
                Title: '',
                Speciality: '',
                Phone: '',
                SortBy: 'lastName',
                SortDirection: 'asc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.setModal = this.setModal.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.resetFields = this.resetFields.bind(this);

        //this.renderRolodexTable = this.renderRolodexTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        //this.populateRolodexData();

    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                RolodexId: null,
                LastName: '',
                FirstName: '',
                Title: '',
                Speciality: '',
                Phone: '',
                Fax: '',
                Street: '',
                City: '',
                State: '',
                Zip: '',
                Note: '',
                Secretary: '',
                Label: '',
                Selected: false,

                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            }
        }));

        this.setModal();
    }

    handleDoubleClick = (event) => {
        const rolData = event.data; // Accessing row data from the event object

        // Now, you can use this rowData to set your component's state or perform other actions
        this.setState(prevState => ({
            model: {
                EventType: 'update',
                RolodexId: rolData.rolodexId,
                LastName: rolData.lastName,
                FirstName: rolData.firstName,
                Title: rolData.fitle,
                Speciality: rolData.speciality,
                Phone: rolData.phone,
                Fax: rolData.fax,
                Street: rolData.street,
                City: rolData.city,
                State: rolData.state,
                Zip: rolData.zip,
                Note: rolData.note,
                Secretary: rolData.secretary,
                Label: rolData.label,
                Selected: rolData.selected,
                CreateDateTime: rolData.createDateTime,
                CreatebyUserId: rolData.createbyUserId,
                UpdateByUserId: rolData.updateByUserId,
                UpdateDateTime: rolData.updateDateTime
                //IsDeletedFlg: false
            }
        }))
        this.setModal();
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateRolodexData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRolodexData();
        }
    }

    async populateRolodexData() {
        this.setState({ fetchingData: true });

        FetchData("/getrolodex", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ rolodexList: data, loading: false, fetchingData: false });
        });
    }

    render() {
        let gridOptions = {
            columnDefs: columns,
            rowData: this.state.rolodexList,
            domLayout: 'autoHeight', // Optional: adjusts the grid size to its content
            onRowDoubleClicked: this.handleDoubleClick, // Use AG Grid's double-click event
            defaultColDef: defaultColDef,
        };

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : (
                <div className="ag-theme-balham ag-theme-ela" style={{ width: '100%' }}>
                    <AgGridReact {...gridOptions} />
                </div>
            );
        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Rolodex</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}</Button>
                            <input name="Name" type="text" className="form-control form-control-text" value={this.state.searchModel.Name} onChange={this.handleSearchChange} placeholder="Contact Name (Lastname Firstname)" onKeyPress={this.handleKeyPress} />
                            <input name="Title" type="text" className="form-control form-control-text" value={this.state.searchModel.Title} onChange={this.handleSearchChange} placeholder="Title" onKeyPress={this.handleKeyPress} />
                            <input name="Phone" type="text" className="form-control form-control-text" value={this.state.searchModel.Phone} onChange={this.handleSearchChange} placeholder="Phone" onKeyPress={this.handleKeyPress} />
                            <input name="Speciality" type="text" className="form-control form-control-text" value={this.state.searchModel.Speciality} onChange={this.handleSearchChange} placeholder="Speciality" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <RolodexForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}







