import React, { Component } from 'react';
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col } from 'reactstrap';
import ReactTooltip from "react-tooltip";

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

const moment = require('moment');

export class WorkOrderJobNotes extends Component {
    static displayName = WorkOrderJobNotes.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model
        };

    }

    componentDidMount() {
        //document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        //document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                <ModalHeader><span style={{ fontSize: "18px" }}>Job Notes</span></ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <span style={{ fontSize: "14px", fontWeight: 'bold' }}>Receivables</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className=" x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: "scroll", height: "200px", backgroundColor: 'unset', width: '100%', marginLeft: '8px', marginRight: '10px' }}>
                                <Table striped size="sm" className="table-bordered" className="table-bordered table-bordered-compact" hover style={{ tableLayout: "fixed", lineHeight: "8px", alignItems: 'center', padding: "0.3rem 0.3rem 0.1rem 0.3rem" }}>
                                    <thead>
                                        <tr>
                                            {/*<th width="100px">JobId</th>*/}
                                            <th width="100px">Action</th>
                                            <th>Transaction Date</th>
                                            <th>Check Date</th>
                                            <th>Check Number</th>
                                            <th>Transfer From</th>
                                            <th>Transfer To</th>
                                            <th width="150px" style={{ textAlign: 'right' }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.receivableList.map((jobRCVB, i) =>
                                            <tr key={jobRCVB.jobReceivableId}>
                                                {/*<td className="x-grid-cell-inner">{jobRCVB.jobId}</td>*/}
                                                <td className="x-grid-cell-inner">{jobRCVB.actionCode}</td>
                                                <td className="x-grid-cell-inner">{moment(jobRCVB.createDateTime).format('MM/DD/YYYY')}</td>
                                                <td className="x-grid-cell-inner">{moment(jobRCVB.checkDate).format('MM/DD/YYYY')}</td>
                                                <td className="x-grid-cell-inner">{jobRCVB.checkNumber}</td>
                                                <td className="x-grid-cell-inner">{jobRCVB.transferFrom}</td>
                                                <td className="x-grid-cell-inner">{jobRCVB.transferTo}</td>
                                                <td className="x-grid-cell-inner" style={{ textAlign: 'right' }}>{jobRCVB.amount}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span style={{ fontSize: "14px", fontWeight: 'bold' }}>Notes</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className=" x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: "scroll", height: "200px", backgroundColor: 'unset', width: '100%', marginLeft: '8px', marginRight: '10px' }}>
                                <Table striped size="sm" className="table-bordered" className="table-bordered table-bordered-compact" hover style={{ tableLayout: "fixed", lineHeight: "8px", alignItems: 'center', padding: "0.3rem 0.3rem 0.1rem 0.3rem" }}>
                                    <thead>
                                        <tr>
                                            <th width="100px">CreatedBy</th>
                                            <th width="100px">CreatedDate</th>
                                            <th width="100px">ReminderDate</th>
                                            <th>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.receivableItemList.map((jobRCVB, i) =>
                                            <tr key={jobRCVB.jobReceivableId}>
                                                <td className="x-grid-cell-inner">{jobRCVB.createdBy}</td>
                                                <td className="x-grid-cell-inner">{moment(jobRCVB.createDateTime).format('MM/DD/YYYY')}</td>
                                                <td className="x-grid-cell-inner">{moment(jobRCVB.reminderDate).format('MM/DD/YYYY')}</td>
                                                <td className="x-grid-cell-inner"><span data-for="rtp" data-tip={"Notes: " + jobRCVB.notes}> {jobRCVB.notes}</span></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                    <ReactTooltip id="rtp" place="right" type="dark" effect="solid" />

                </ModalBody>
                {/*<ModalFooter>*/}
                {/*    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>*/}
                {/*    <Button color="primary" onClick={() => { if (window.confirm("Are you sure to save this record?")) { this.onSave() } }} size="sm">Save</Button>*/}
                {/*</ModalFooter>*/}
            </Modal>
        )
    }
}