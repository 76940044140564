import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup, InputGroupText } from 'reactstrap';

import { FetchData } from '../utils/DataFetch';
import { GetCookie, Timeout } from '../utils/Common';
import { RptClientInvoiceSummaryTableDefault } from './RptClientInvoiceSummaryTableDefault';




export class RptClientInvoiceSummary extends Component {
    static displayName = RptClientInvoiceSummary.name;

    constructor(props) {
        super(props);
        this.state = {

            rptClientInvoiceSummaryList: [],
            jobCount: 0,
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                ClientABBRV: '',
                StartDate: '',
                EndDate: ''
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';


        this.renderRptClientInvoiceSummaryTable = this.renderRptClientInvoiceSummaryTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        //this.populateRolodexData();

        document.onmousemove = this.handleOnMouseMove;
        document.onmouseup = this.handleOnMouseUp;
        return () => {
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }





    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRptClientInvoiceSummaryData();
        }
    }

    async populateRptClientInvoiceSummaryData() { 
        this.setState({ fetchingData: true });

        FetchData("/getrptclientinvoicesummary", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({rptClientInvoiceSummaryList: data, loading: false, fetchingData: false });
        });


    }

    renderRptClientInvoiceSummaryTable(rptClientInvoiceSummary) {
        return (
            <RptClientInvoiceSummaryTableDefault
             rptClientInvoiceSummary={this.state.rptClientInvoiceSummaryList}
             state={this.state}
            ></RptClientInvoiceSummaryTableDefault>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRptClientInvoiceSummaryTable(this.state.rptClientInvoiceSummaryList);


        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Client Invoice Summary Report</h5>
                <Row>
                    <Col className="col-4">
                        <InputGroup size="sm">
                            <input name="ClientAbbrv" ref={(input) => { this.ClientAbbrv = input; }} type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                            <InputGroupText>
                                Start Date
                            </InputGroupText>
                            <input name="StartDate" ref={ref => this.txtStartDate = ref} type="date" className="form-control form-control-text" value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Start Date" onKeyPress={this.handleKeyPress} />
                            <InputGroupText>
                                End Date
                            </InputGroupText>
                           <input name="EndDate" ref={(input) => { this.EndDate = input; }} type="date" className="form-control form-control-text"  value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="End Date" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />



                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
