import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, InputGroup, InputGroupText, Row, Col, Alert, TabContent, Table, TabPane } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetParameterByName, GetCookie, RemoveCookie } from './utils/Common';

import Logo from '../images/logo1.png'

const moment = require('moment');

export class WorkOrderIntForm extends Component {
    static displayName = WorkOrderIntForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            display_message: [],
            showButtons: true,
            showButtonSubmit: true,
            showCommentReason: false
        };

        //
        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleNoCommentClick = this.handleNoCommentClick.bind(this);
        //
        this.setShowCommentReason = this.setShowCommentReason.bind(this);
    }

    componentDidMount() {
        var jobId = this.props.model.JobId;

        //if (GetParameterByName('jobid') !== null) // when the page load with query string
        //    jobId = GetParameterByName('jobid');
        //else if (this.props.model != null) // when user double click
        //    jobId = this.props.model.JobId;

        // get the query string parameters.
        if (jobId != null) {

            FetchData("/InterpreterConfirmationPageLoad/?jobId=" + jobId, "POST", this.user.token, {}).then(data => {
                if (data.message === "Invalid") {
                    RemoveCookie('user');
                    localStorage.removeItem('RemoteTypes');
                    localStorage.removeItem('RemoteHosts');
                    localStorage.removeItem('JobTypes');
                    localStorage.removeItem('JobInvLineCodes');
                    window.location.reload();
                }
                else {
                    this.setState({
                        display_message: data.displayMessage
                    });

                    localStorage.removeItem('IsPageLoad');
                }
            });

            //FetchData("/ValidateJobInterpreter/?jobId=" + jobId + '&intId=' + this.user.intid, "POST", this.user.token, {}).then(data => {
            //    if (data === "Success") {
            //        FetchData("/InterpreterConfirmationPageLoad/?q=" + jobId + '&userId=' + this.user.userid, "POST", this.user.token, {}).then(data => {
            //            this.setState({
            //                display_message: data.displayMessage
            //            });

            //            localStorage.removeItem('IsPageLoad');
            //        });
            //    } else {
            //        RemoveCookie('user');
            //        localStorage.removeItem('RemoteTypes');
            //        localStorage.removeItem('RemoteHosts');
            //        localStorage.removeItem('JobTypes');
            //        localStorage.removeItem('JobInvLineCodes');
            //        window.location.reload();
            //    }
            //});           
        } else {
            this.setState({
                display_message: 'Invalid Request.'
            });
        }
    }

    handleYesClick() {
        var jobId = this.props.model.JobId;

        ////if (GetParameterByName('jobid') !== null) // when the page load with query string
        ////    jobId = GetParameterByName('jobid');
        ////else if (this.props.model != null) // when user double click
        ////    jobId = this.props.model.JobId;

        FetchData("/FinalConfirmationYesButton/?q=" + jobId + '&userId=' + this.user.userid, "POST", this.user.token, {}).then(data => {
            var msg = [];
            msg.push(data)

            this.props.handleSaveEventSuccess({
                message: msg,
                modal: false,
                color: "success"
            });

            //this.setState({
            //    display_message: msg,
            //    showButtons: false
            //});
        });
    }

    handleNoClick() {
        //var note = window.prompt("Cannot Cover Reason", "");
        //if (note !== null) {
        var jobId = this.props.model.JobId;

        //if (GetParameterByName('jobid') !== null) // when the page load with query string
        //    jobId = GetParameterByName('jobid');
        //else if (this.props.model != null) // when user double click
        //    jobId = this.props.model.JobId;

        FetchData("/FinalConfirmationNoButton/?jobId=" + jobId + '&comment=NoButtonClick', "POST", this.user.token, {}).then(data => {
            console.log(data);
            var msg = [];
            msg.push(data.message)
            this.setState({
                display_message: msg,
                showButtons: false
            });

            this.setShowCommentReason();
        });
        //}       
    }

    handleNoCommentClick() {
        var jobId = this.props.model.JobId;

        //if (GetParameterByName('jobid') !== null) // when the page load with query string
        //    jobId = GetParameterByName('jobid');
        //else if (this.props.model != null) // when user double click
        //    jobId = this.props.model.JobId;

        FetchData("/FinalConfirmationNoButtonCommentSubmit/?jobId=" + jobId + '&comment=' + this.commentText.value, "POST", this.user.token, {}).then(data => {
            //console.log(data);

            this.setShowCommentReason();

            this.props.handleSaveEventSuccess({
                message: data,
                modal: false,
                color: data === 'Success' ? "success" : 'warning'
            });  

            //var msg = [];
            //msg.push(data.message)
            //this.setState({
            //    display_message: msg,
            //    showButtons: false
            //});            
        });
    }

    setShowCommentReason() {
        this.setState({
            showCommentReason: !this.state.showCommentReason
        }, () => {

        });
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "500px", width: "800px" }} backdrop="static">
                    <ModalHeader>Final Confirmation #{this.props.model.JobNum}</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <center><img src={Logo} alt="Logo" /></center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <br />
                                        {this.state.display_message.map((str, i) =>
                                            <p>{str} <br /></p>
                                        )}
                                    </center>
                                </Col>
                            </Row>
                            {(this.state.display_message != "" && this.state.showButtons) &&
                                <Row>
                                    <Col>
                                        <center>
                                            <Button color="success" size="sm" onClick={this.handleYesClick}>Yes</Button> {' '}
                                            <Button color="danger" size="sm" onClick={this.handleNoClick}>No</Button>
                                        </center>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </ModalBody>
                    {/*<ModalFooter>*/}

                    {/*</ModalFooter>*/}
                </Modal>

                <Modal isOpen={this.state.showCommentReason} toggle={this.state.setShowCommentReason} style={{ height: "500px", width: "800px" }}>
                    <ModalHeader>Final Confirmation #{this.props.model.JobNum}</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <center><img src={Logo} alt="Logo" /></center>
                                </Col>
                            </Row>
                                <Col>
                                    <center>
                                        <p><b>Cannot Cover Reason</b></p>
                                    </center>
                                </Col>
                            <Row>
                            </Row>
                            <Row>
                                <Col className="col-3"></Col>
                                <Col className="col-6">
                                    <center>
                                        <textarea className="form-control" cols={5} ref={(input) => { this.commentText = input; }} ></textarea>
                                    </center>
                                </Col>
                                <Col className="col-3"></Col>
                            </Row>
                            {this.state.showButtonSubmit &&
                                <Row>
                                    <Col>
                                        <center>
                                            <Button color="danger" size="sm" onClick={this.handleNoCommentClick}>Submit</Button>
                                        </center>
                                    </Col>
                                </Row>
                            }

                        </div>
                    </ModalBody>
                </Modal>
            </div >
        );

    }
}
