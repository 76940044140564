import React, { Component } from 'react';
import { Button, Row, Col, Alert, Input, InputGroup, InputGroupText } from 'reactstrap';

import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';
import { BulkTransferTableDefault } from './BulkTransferTableDefault';
import { BulkTransferTableTo } from './BulkTransferTableTo';
import { BulkTransferTableDetails } from './BulkTransferTableDetails';


import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

const moment = require('moment');

export class BulkTransfer extends Component {
    static displayName = BulkTransfer.name;

    constructor(props) {
        super(props);
        this.state = {
            bulkList: [],
            bulkToList: [],
            bulkDetailsList: [],
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
            },
            transferData: {
                CheckNumber: null,
                InvoiceOrCheckDate: null,
                TransferToJobNum: null,
                TransferToJobId: null,
                Amount: null,
                UserId: null
            },
            searchModelTo: {
                JobNum: null,
                Claimant: '',
                ClmNum: '',
                SortBy: 'jobNum',
                SortDirection: 'desc'
            },

        };

        this.user = JSON.parse(GetCookie('user'));

        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleBulkClick = this.handleBulkClick.bind(this);
        this.handleBulkToClick = this.handleBulkToClick.bind(this);
        this.handleKeyPressTo = this.handleKeyPressTo.bind(this);
        this.handleSearchChangeTo = this.handleSearchChangeTo.bind(this);
        this.handleChangeTransferAmount = this.handleChangeTransferAmount.bind(this);
        this.resetTransferData = this.resetTransferData.bind(this);

        this.renderBulkTransferTable = this.renderBulkTransferTable.bind(this);
        this.renderBulkTransferTableDetail = this.renderBulkTransferTableDetail.bind(this);
        this.renderBulkTransferToTable = this.renderBulkTransferToTable.bind(this);
    }

    componentDidMount() {
        this.populateBulkTransferData();
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, 3000);

            this.populateBulkTransferData();
            this.populateBulkToData();
            this.resetTransferData();
        });
    }

    handleBulkClick(fromData) {
        let model = { ...this.state.transferData }

        model.CheckNumber = fromData.data.checkNumber;
        model.InvoiceOrCheckDate = fromData.data.checkDate;

        this.setState({ transferData: model });
        this.getJobReceivable(fromData.data.checkNumber);
    }

    handleBulkToClick(toData) {
        let model = { ...this.state.transferData }

        model.TransferToJobNum = toData.data.jobNum;
        model.TransferToJobId = toData.data.jobId;

        this.setState({ transferData: model });

    }

    handleKeyPressTo(e) {
        if (e.key === 'Enter') {
            this.setState({ bulkToList: [], isKeyPress: true }, () => {
                this.populateBulkToData();
            });
        }
    }

    handleSearchChangeTo(e) {
        let model = { ...this.state.searchModelTo }

        if (e.target.type === 'number' && (e.target.value !== null || e.target.value != ''))
            model[e.target.name] = parseInt(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ searchModelTo: model });
    }

    handleChangeTransferAmount(e) {
        let model = { ...this.state.transferData }

        if (e.target.type === "number")
            model[e.target.name] = e.target.value === null || e.target.value === "" ? null : parseFloat(e.target.value);
        

        this.setState({ transferData: model });

    }

    onSave() {
        let model = { ...this.state.transferData }
        model.UserId = this.user.userid;


        if (this.state.transferData.CheckNumber === null || this.state.transferData.CheckNumber === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please choose a Check to transfer from.',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });

        } else if (this.state.transferData.TransferToJobNum === null || this.state.transferData.TransferToJobNum === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please choose an Invoice to transfer to',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });
        } else if (this.state.transferData.Amount === null || this.state.transferData.Amount === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please enter an Amount to transfer',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });
        } else {
            FetchData("/BulkTransfer", "POST", this.user.token, model).then(data => {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            });
            //console.log(this.state.transferData);
        }
    }


    resetTransferData() {
        let model = { ...this.state.transferData };

        model.CheckNumber = null;
        model.TransferToJobNum = null;
        model.TransferToJobId = null;
        model.Amount = null;
        model.UserId = null;

        this.setState({ transferData: model });

        this.txtAmount.value = null;
        this.txtFromCheckNumber.value = '';
        this.txtToJobNum.value = '';
    }

    async getJobReceivable(checkNumber) {
        let model = {
            JobId: null,
            ActionCode: 'BT',
            CheckNumber: checkNumber,
        }
        this.setState({ fetchingData: true });

        FetchData("/getreceivables", "POST", this.user.token, model).then(data => {
            this.setState({ bulkDetailsList: data, loading: false, fetchingData: false });
        });
    }

    async populateBulkTransferData() {
        this.setState({ fetchingData: true });

        await FetchData("/GetBulkChecks", "POST", this.user.token, null).then(data => {
            this.setState({ bulkList: data, loading: false, fetchingData: false });
        });
    }

    async populateBulkToData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModelTo }

        this.setState({ searchModelTo: model }, () => {
            FetchData("/GetTransferCash", "POST", this.user.token, model).then(data => {
                if (this.state.isKeyPress && this.state.searchModelTo.JobNum != null) {
                    if (data.length === 0) {
                        this.setState({
                            alert: {
                                color: "warning",
                                message: 'There is no return on your search.',
                                isOpen: true
                            }
                        }, () => {
                            window.setTimeout(() => {
                                this.setState({ alert: { isOpen: false } });
                            }, Timeout(alert.message));
                        });
                    }
                    //    else {
                    //        var job = data[0];
                    //        this.setData(job);
                    //        this.setModal();
                    //    }
                }

                this.setState({ bulkToList: data, loading: false, fetchingData: false, isKeyPress: false });
            });
        });
    }

    renderBulkTransferTable(bulkChecks) {

        return (
            <BulkTransferTableDefault
                state={this.state}
                bulkChecks={this.state.bulkList}
                handleBulkClick={this.handleBulkClick}
            ></BulkTransferTableDefault>
        );
    }

    renderBulkTransferToTable(bulkChecks) {

        return (
            <BulkTransferTableTo
                state={this.state}
                bulkToList={this.state.bulkToList}
                handleBulkToClick={this.handleBulkToClick}
            ></BulkTransferTableTo>
        );
    }

    renderBulkTransferTableDetail(bulkChecks) {

        return (
            <BulkTransferTableDetails
                state={this.state}
                bulkDetailsList={this.state.bulkDetailsList}
            ></BulkTransferTableDetails>
        );
    }

    render() {
        let bulkTransferContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderBulkTransferTable(this.state.bulkList);
        let detailsContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderBulkTransferTableDetail(this.state.bulkDetailsList);

        let bulkToContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderBulkTransferToTable(this.state.bulkToList);

        return (
            <div style={{ paddingTop: "12px" }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <Row>
                    <Col className="col-6" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <h5>Transfer From</h5>
                        <p className="form-control form-control-text"><strong>Name:</strong> Executive Linquist Agency [Bulk Account] &nbsp;&nbsp;&nbsp;&nbsp; <strong>Client:</strong> ELA &nbsp;&nbsp;&nbsp;&nbsp; <strong>CLM #:</strong> ELABULK</p>
                        {bulkTransferContents}
                    </Col>
                    <Col className="col-6" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <h5>Transfer To</h5>
                        <Row>
                            <Col style={{ width: '95%' }} >
                                <InputGroup size="sm">
                                <input name="JobNum" type="number" className="form-control form-control-text" value={this.state.searchModelTo.JobNum} onChange={this.handleSearchChangeTo} onFocus={this.handleSearchFocus} placeholder="Invoice #" onKeyPress={this.handleKeyPressTo} />
                                    <input name="Claimant" type="text" className="form-control form-control-text" value={this.state.searchModelTo.Claimant} onChange={this.handleSearchChangeTo} onFocus={this.handleSearchFocus} placeholder="Claimant" onKeyPress={this.handleKeyPressTo} />
                                    <input name="ClaimantNum" type="text" className="form-control form-control-text" value={this.state.searchModelTo.ClmNum} onChange={this.handleSearchChangeTo} onFocus={this.handleSearchFocus} placeholder="Claimant #" onKeyPress={this.handleKeyPressTo} />
                                </InputGroup>
                                {bulkToContents}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>Transfer From Check:</InputGroupText>
                            <input disabled={true} name="FromCheckNum" value={this.state.transferData.CheckNumber} className="form-control-text" placeholder="Check Number" ref={(input) => { this.txtFromCheckNumber = input; }} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>Transfer To Job Id:</InputGroupText>
                            <input disabled={true} name="ToJobNum" value={this.state.transferData.TransferToJobNum} className="form-control-text" placeholder="Job Id To" ref={(input) => { this.txtToJobNum = input; }} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>Amount:</InputGroupText>
                            <input name="Amount" onChange={this.handleChangeTransferAmount} value={this.state.transferData.Amount} type="number" className="form-control-text" ref={(input) => { this.txtAmount = input; }} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2 d-flex align-items-center">
                        <Button
                            color="secondary"
                            onClick={() => {
                                if (window.confirm(`Are you sure you want to transfer money From Check: ${this.state.transferData.CheckNumber} to JobId: ${this.state.transferData.TransferToJobNum}?`)) {
                                    this.onSave();
                                }
                            }}
                            className="btn"
                            outline
                            size="sm"
                        >
                            Transfer
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        {detailsContents}
                    </Col>
                </Row>
            </div>
        );

    }
}
