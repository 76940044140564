import React, { Component, } from 'react';
import ReactTooltip from "react-tooltip";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';
import DeleteIcon from '../images/delete.png'
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import * as CellRenderers from './AgGridCellRendererComponents';

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: "Delete", field: "", cellRenderer: 'deleteCellRenderer', width: 80, },
    { headerName: "Date", field: "availableDate", cellRenderer: 'dateCellRenderer', width: 100, },
    { headerName: "Type", field: "availableType", width: 80, },
    { headerName: "Notes", field: "scheduleNotes", width: null, }
];

export class InterpreterAvailabilityTableDefault extends Component {
    static displayName = InterpreterAvailabilityTableDefault.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            clickCount: 0,
        };
        this.previousSelection = [];
        this.user = JSON.parse(GetCookie('user'));

        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
        this.handleDeleteAvailability = this.handleDeleteAvailability.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimer);
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };

    handleDeleteAvailability(intAvailabilityID) {
        let model = {
            EventType: 'delete',
            InterpreterAvailabilityID: intAvailabilityID,
            UpdateByUserId: this.user.userid
        }

        FetchData("/deleteinterpreteravailability", "POST", this.user.token, model).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: "Availability Deleted.",
                    modal: false,
                    color: "success"
                });
            } else {
                this.props.handleSaveEventSuccess({
                    message: "Delete Failed." + data,
                    modal: false,
                    color: "warning"
                });
            }
        });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns(false);
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    //handleDoubleClick() {
    //    console.log('Doubleclicked');
    //}

    render() {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: this.props.schedules,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                state: this.props.state,
                handleDeleteAvailability: this.handleDeleteAvailability,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                 dateCellRenderer: CellRenderers.DateCellRenderer,
                 deleteCellRenderer: DeleteCellRenderer,
                        },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
        };

        return (
            <div className="ag-theme-balham custom-header-viewport" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                    rowSelection={"multiple"}
                />
            </div>
        );

    }
}

class DeleteCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { data, context } = this.props;
        context.handleDeleteAvailability(data.interpreterAvailabilityID);
    }

    refresh(params) {
        return false; // Return false to indicate that re-rendering wasn't needed
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <a href="javascript:void(0)">
                    <img 
                        src={DeleteIcon} 
                        style={{ width: "14px", height: "14px", cursor: 'pointer' }} 
                        onClick={this.handleClick} 
                        alt="Delete"
                    />
                </a>
            </div>
        );
    }
}