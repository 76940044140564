import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

const moment = require('moment');

export class PostChecksForm extends Component {
    static displayName = PostChecksForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
    }

    onSave() {
        let model = this.state.model;

        if (this.state.model.CheckDate === null || this.state.model.CheckDate === '') {
            this.props.handleSaveEventSuccess({
                message: 'Please choose Check Date.',
                modal: true,
                color: "warning"
            });
        } else {
            if (model.EventType === "insert") {
                model.CreateByUserId = this.user.userid;
            } else if (model.EventType === "update") {
                model.UpdateByUserId = this.user.userid;
            }

            FetchData("/savejobreceivable", "POST", this.user.token, model).then(data => {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            });
        }
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "number")
            model[e.target.name] = parseInt(e.target.value);
        else if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else
            model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "700px" }} backdrop="static">
                <ModalHeader>Post Checks</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.props.alert.color} isOpen={this.props.alert.isOpen} >
                                    {this.props.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Job #:
                                    </InputGroupText>
                                    <input type="text" name="JobNum" className="form-control form-control-text" value={this.state.model.JobNum} onChange={this.handleChange} readOnly />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Job Date:
                                    </InputGroupText>
                                    <input type="text" name="JobDateTime" className="form-control form-control-text" value={moment(this.state.model.JobDateTime).format('MM/DD/YYYY')} onChange={this.handleChange} readOnly />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Check Date:
                                    </InputGroupText>
                                    <input type="date" name="CheckDate" className="form-control form-control-text" value={this.state.model.CheckDate} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Check Number:
                                    </InputGroupText>
                                    <input type="text" name="CheckNumber" className="form-control form-control-text" value={this.state.model.CheckNumber} onChange={this.handleChange} maxLength={25} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Amount:
                                    </InputGroupText>
                                    <input type="number" name="Amount" className="form-control form-control-text" value={this.state.model.Amount} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "90px" }}>
                                        Is Refund:
                                    </InputGroupText>
                                    <input type="checkbox" name="IsRefund" defaultChecked={this.state.model.IsRefund} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col className="col-8">*/}
                        {/*        <InputGroup size="sm">*/}
                        {/*            <InputGroupText>*/}
                        {/*                Client Credit Card:*/}
                        {/*            </InputGroupText>*/}
                        {/*            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.ClientCreditCard}</label>*/}
                        {/*        </InputGroup>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col className="col-8">*/}
                        {/*        <InputGroup size="sm">*/}
                        {/*            <InputGroupText style={{ width: "90px" }}>*/}
                        {/*                Client Zip:*/}
                        {/*            </InputGroupText>*/}
                        {/*            <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.ClientZip}</label>*/}
                        {/*        </InputGroup>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                    {/*<Button color="primary" onClick={() => { if (window.confirm("Are you sure to save this record?")) { this.onSave() } }} size="sm">Save</Button>*/}
                    <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                </ModalFooter>
            </Modal>
        )
    }
}