import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup, InputGroupText } from 'reactstrap';

import { FetchData } from '../utils/DataFetch';
import { GetCookie, Timeout } from '../utils/Common';
import { RptChecksReceivedTableDefault } from './RptChecksReceivedTableDefault';



export class RptChecksReceived extends Component {
    static displayName = RptChecksReceived.name;

    constructor(props) {
        super(props);
        this.state = {
            rptChecksReceivedList: [],
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                StartDate: '',
                EndDate: ''
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.renderProductionReportTable = this.renderRptChecksReceived.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRptChecksReceived();
        }
    }


    async populateRptChecksReceived() {
        this.setState({ fetchingData: true });

        FetchData("/getrptchecksreceived", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({rptChecksReceivedList: data, loading: false, fetchingData: false });
        });


    }

    renderRptChecksReceived(rptChecksReceived) {
        return (
            <RptChecksReceivedTableDefault rptChecksReceived={rptChecksReceived} />
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRptChecksReceived(this.state.rptChecksReceivedList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Checks Received Report</h5>
                <Row>
                    <Col className="col-3">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Start Date
                            </InputGroupText>
                            <input name="StartDate" ref={ref => this.txtStartDate = ref} type="date" className="form-control form-control-text"  value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Start Date" onKeyPress={this.handleKeyPress} />
                            <InputGroupText>
                                End Date
                            </InputGroupText>
                           <input name="EndDate" ref={(input) => { this.EndDate = input; }} type="date" className="form-control form-control-text"  value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="End Date" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />



                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
