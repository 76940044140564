import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup, InputGroupText } from 'reactstrap';

import { WorkOrderJobNotes } from './WorkOrderJobNotes';
import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DollarIcon from '../images/dollar-icon.png'

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 70;
const DEFAULT_MAX_WIDTH_CELL = 800;

const columns = [
    { headerName: "JobId", sortableField: "jobNum", width: null },
    { headerName: "Claimant #", sortableField: "claimantELANumber", width: null },
    { headerName: "Action", sortableField: "actionCode", width: null },
    { headerName: "Notes", sortableField: "notes", width: null },
    { headerName: "Transaction Date", sortableField: "transactionDate", width: null },
    { headerName: "Check Date", sortableField: "checkDate", width: null },
    { headerName: "Check Number", sortableField: "checkNumber", width: null },
    { headerName: "Transfer From", sortableField: "transferFrom", width: null },
    { headerName: "Transfer To", sortableField: "transferTo", width: null },
    { headerName: "Amount", sortableField: "amount", width: null },
    { headerName: "Balance", sortableField: "balance", width: null },
    { headerName: "Claimant", sortableField: "claimant", width: null }

];

export class AccountsReceivable extends Component {
    static displayName = AccountsReceivable.name;

    constructor(props) {
        super(props);
        this.state = {
            columnRefs: columns.map(() => createRef()),
            isResizing: -1,

            acctRecList: [],
            receivableList: [],
            receivableItemList: [],
            loading: false,
            fetchingData: false,
            modalWOJobNotes: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                JobId: null,
                JobNum: null,
                ActionCode: '',
                TransactionDate: null,
                CheckDate: null,
                CheckNumber: '',
                Amount: null,
                Balance: null,
                TransferFrom: null,
                TransferTo: null,
                SelectedFlg: false,
                TransId: null,
                ClaimantName: '',
                ClaimantELANum: ''
            },
            searchModel: {
                CheckNumber: '',
                SortBy: 'jobNum',
                SortDirection: 'desc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.setModalWOJobNotes = this.setModalWOJobNotes.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderAccountsReceivableTable = this.renderAccountsReceivableTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
        this.sortData = this.sortData.bind(this);
        this.requestSort = this.requestSort.bind(this);
        //
        this.viewAcctRecNotes = this.viewAcctRecNotes.bind(this);
        //
        this.onClickResizeColumn = this.onClickResizeColumn.bind(this);
        this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    }

    componentDidMount() {
        //this.populateRepData();
        document.onmousemove = this.handleOnMouseMove;
        document.onmouseup = this.handleOnMouseUp;
        return () => {
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }

    setModal() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (!this.state.modal) {
                this.setState({
                    selectedIndex: -1
                });
            }
        });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                JobId: null,
                JobNum: null,
                ActionCode: '',
                TransactionDate: null,
                CheckDate: null,
                CheckNumber: '',
                Amount: null,
                Balance: null,
                TransferFrom: null,
                TransferTo: null,
                SelectedFlg: false,
                TransId: null,
                ClaimantName: '',
                ClaimantELANum: ''
            }
        }));

        this.setModal();
    }
    
    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        if (e.target.name === "Inactive") {
            model[e.target.name] = !this.state.searchModel.Inactive;
        }
        else {
            model[e.target.name] = e.target.value;
        }

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateAccountsReceivableData();
        }
    }

    sortData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.repList;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }

    requestSort(pSortBy) {
        var sortBy = this.state.searchModel.SortBy;
        var sortOrder = this.state.searchModel.SortDirection;
        return event => {
            if (pSortBy === this.state.searchModel.SortBy) {
                sortOrder = sortOrder === "asc" ? "desc" : "asc";
            } else {
                sortBy = pSortBy;
                sortOrder = "asc";
            }
            var sortedItems = this.sortData(sortBy, sortOrder);
            this.setState({
                searchModel: {
                    SortBy: sortBy,
                    SortDirection: sortOrder
                },
                repList: sortedItems
            });
        };
    }

    setModalWOJobNotes() {
        this.setState({ modalWOJobNotes: !this.state.modalWOJobNotes });
    }
    
    viewAcctRecNotes(acctRec) {
        FetchData("/getreceivables", "POST", this.user.token, { JobId: acctRec.jobId }).then(data => {
            this.setState({ receivableList: data });
        });
        FetchData("/getreceivableitems", "POST", this.user.token, { JobId: acctRec.jobId }).then(data => {
            this.setState({ receivableItemList: data });
        });
        this.setModalWOJobNotes();
    }

    renderAccountsReceivableTable(acctRecs) {

        return (
            <Paper>
                <Table striped size="sm" className="table-bordered table-striped" hover>
                    <TableHead>
                        <TableRow>
                            {columns.map((col, colIndex) => (
                                <TableCell className={"tableCell resizable"} style={{ width: col?.width ?? "auto" }}>
                                    {col.sortableField !== "" ? <TableSortLabel
                                        active={this.state.searchModel.SortBy === col.sortableField}
                                        direction={this.state.searchModel.SortDirection}
                                        onClick={this.requestSort(col.sortableField)}
                                    >{col.headerName}
                                    </TableSortLabel> : col.headerName
                                    }
                                    <div onMouseDown={() => this.onClickResizeColumn(colIndex)}
                                        ref={this.state.columnRefs[colIndex]}
                                        className={"resizeLine"}
                                    />
                                </TableCell>
                            ))}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "jobNum"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("jobNum")}*/}
                            {/*    >JobId*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "claimantELANumber"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("claimantELANumber")}*/}
                            {/*    >Claimant #*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "actionCode"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("actionCode")}*/}
                            {/*    >Action*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "notes"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("notes")}*/}
                            {/*    >Notes*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "transactionDate"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("transactionDate")}*/}
                            {/*    >Transaction Date*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "checkDate"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("checkDate")}*/}
                            {/*    >Check Date*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "checkNumber"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("checkNumber")}*/}
                            {/*    >Check Number*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "transferFrom"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("transferFrom")}*/}
                            {/*    >Transfer From*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "transferTo"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("transferTo")}*/}
                            {/*    >Transfer To*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "amount"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("amount")}*/}
                            {/*    >Amount*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "balance"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("balance")}*/}
                            {/*    >Balance*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                            {/*<TableCell>*/}
                            {/*    <TableSortLabel*/}
                            {/*        active={this.state.searchModel.SortBy === "claimant"}*/}
                            {/*        direction={this.state.searchModel.SortDirection}*/}
                            {/*        onClick={this.requestSort("claimant")}*/}
                            {/*    >Claimant*/}
                            {/*    </TableSortLabel>*/}
                            {/*</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <tbody>
                        {acctRecs.map(acctRec =>
                            <tr key={acctRec.acctRecID} >
                                <td className="x-grid-cell-inner">{acctRec.jobNum}</td>
                                <td className="x-grid-cell-inner">{acctRec.claimantELANum}</td>
                                <td className="x-grid-cell-inner">{acctRec.actionCode}</td>
                                <td className="x-grid-cell-inner"> <img src={DollarIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} alt="Notes" onClick={() => { this.viewAcctRecNotes(acctRec) }} /> </td>
                                <td className="x-grid-cell-inner">{moment(acctRec.transactionDate).format('MM/DD/YYYY')}</td>
                                <td className="x-grid-cell-inner">{moment(acctRec.checkDate).format('MM/DD/YYYY')}</td>
                                <td className="x-grid-cell-inner">{acctRec.checkNumber}</td>
                                <td className="x-grid-cell-inner">{acctRec.transferFrom}</td>
                                <td className="x-grid-cell-inner">{acctRec.transferTo}</td>
                                <td className="x-grid-cell-inner">{acctRec.amount}</td>
                                <td className="x-grid-cell-inner">{acctRec.balance}</td>
                                <td className="x-grid-cell-inner">{acctRec.claimantName}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Paper>
        );
    }

    async populateAccountsReceivableData() {
        this.setState({ fetchingData: true });

        FetchData("/getaccountsreceivable", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ acctRecList: data, loading: false, fetchingData: false });
        });
    }

    adjustWidthColumn = (index, width) => {
        const minWidth = columns[index]?.minWidth ?? DEFAULT_MIN_WIDTH_CELL;
        const maxWidth = this.state.columnRefs[index]?.maxWidth ?? DEFAULT_MAX_WIDTH_CELL;
        const newWidth =
            width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

        var colRefs = this.state.columnRefs;
        colRefs[index].current.parentElement.style.width = newWidth + "px";

        this.setState({
            columnRefs: colRefs
        });
    }

    setCursorDocument = (isResizing) => {
        document.body.style.cursor = isResizing ? "col-resize" : "auto";
    }

    handleOnMouseMove = (e) => {
        if (this.state.isResizing >= 0) {
            const newWidth =
                e.clientX -
                this.state.columnRefs[
                    this.state.isResizing
                ].current.parentElement?.getBoundingClientRect().left;
            this.adjustWidthColumn(this.state.isResizing, newWidth);
        }
    }

    handleOnMouseUp = () => {
        this.setState({ isResizing: -1 }, () => {
            //this.saveColumnInfoLocalStorage();
            this.setCursorDocument(false);
        });
    }

    onClickResizeColumn = (index) => {
        this.setState({ isResizing: index }, () => {
            this.setCursorDocument(true);
        });
    }


    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderAccountsReceivableTable(this.state.acctRecList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Accounts Receivable</h5>
                <Row>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Check Number: 
                            </InputGroupText>
                            <input id="CheckNumber" name="CheckNumber" type="text" className="form-control form-control-text" value={this.state.searchModel.CheckNumber} onChange={this.handleSearchChange} placeholder="Check Number" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modalWOJobNotes &&
                    <WorkOrderJobNotes receivableList={this.state.receivableList} receivableItemList={this.state.receivableItemList} modal={this.state.modalWOJobNotes} alert={this.state.alert} setModal={this.setModalWOJobNotes} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
