import React, { Component, } from 'react';
import ReactTooltip from "react-tooltip";


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

import DeleteIcon from '../images/delete.png'

const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: 'Type', field: 'Type', width: 150 },
    { headerName: 'Description', field: 'Description', width: 150 },
    { headerName: '', field: 'Actions', cellRenderer: 'actionsCellRenderer', width: 50, },
  ];



export class WorkOrderAddJobTableDefault extends Component {
    static displayName = WorkOrderAddJobTableDefault.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            clickCount: 0,
        };
        this.previousSelection = [];
        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    render() {
        const rowData = (Array.isArray(this.props.jobHistory) && this.props.jobHistory.length > 0)
        ? this.props.jobHistory.map((history, i) => ({
            ...history,
            Actions: i,
          }))
        : [];
                          
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: rowData,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                state: this.props.state,
                handleRemove: this.props.handleRemove,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                actionsCellRenderer: ActionsCellRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                    rowSelection={"multiple"}
                />
            </div>
        );

    }
}

class ActionsCellRenderer extends Component {
    render() {
        const { data } = this.props;

        return (
            <img
            src={DeleteIcon}
            onClick={() => this.props.context.handleRemove(data)}
            style={{ width: '16px', cursor: 'pointer' }}
            alt="Delete"
            />
        );
    }
  }
