import React, { Component } from 'react';
import {
    Row, Col, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { WorkOrder } from './WorkOrder';
import { WorkOrderInt } from './WorkOrderInt';
import { Client } from './Client';
import { Interpreter } from './Interpreter';
import { Rep } from './Rep';
import { ProviderOffice } from './ProviderOffice';
import { Rolodex } from './Rolodex';
import { Claimant } from './Claimant';
import { PostChecks } from './PostChecks';
import { InterpreterPayment } from './InterpreterPayment';
import { PrintInvoice } from './PrintInvoice';
import { InterpreterAvailabilityAdmin } from './InterpreterAvailabilityAdmin';
import { BulkTransfer } from './BulkTransfer';
import { TransferCash } from './TransferCash';
import { LateInvoices } from './LateInvoices';
import { AccountsReceivable } from './AccountsReceivable';
import { BusinessCards } from './BusinessCards';
import { RptProduction } from './Reports/RptProduction';
import { RptClientInvoiceSummary } from './Reports/RptClientInvoiceSummary';
import { RptAccountsReceivable } from './Reports/RptAccountsReceivable';
import { RptChecksReceived } from './Reports/RptChecksReceived';
import { RptInterpreter1099 } from './Reports/RptInterpreter1099';
import { RptRepCount } from './Reports/RptRepCount';
import { RptClientSummary } from './Reports/RptClientSummary';
import { User } from './User';
import { TimeOff } from './TimeOff';
import { GetCookie } from './utils/Common';
// import { GetLookUps } from './GetLookUps';
import RemoveIcon from '../images/remove-icon.png';
import Cookies from 'js-cookie';
import { FetchData } from './utils/DataFetch';

export class Index extends Component {
    constructor(props) {
        super(props);
        this.user = JSON.parse(GetCookie('user'));

        let defaultTab = [];
        if (this.user.userrolename === 'Interpreter') {
            defaultTab = [{
                tabId: "workorderint",
                tabTitle: "Work Order (Int)",
                tabContent: <WorkOrderInt isMyJob={false} />
            }];
        } else {
            defaultTab = [{
                tabId: "workorder",
                tabTitle: "Work Order",
                tabContent: <WorkOrder isMyJob={false} WorkFlowId='' TableView='default' />
            }];
        }

        this.state = {
            activeTab: this.user.userrolename === 'Interpreter' ? 'workorderint' : 'workorder',
            tabList: defaultTab
        };

        this.addTab = this.addTab.bind(this);
        this.removeTab = this.removeTab.bind(this);
    }

    componentDidMount() {
        const user = JSON.parse(Cookies.get('user'));
        if (user && user.token) {
            this.getRemoteTypes(user.token).then(rt => {
                this.getRemoteHosts(user.token).then(rtH => {
                    this.getJobTypes(user.token).then(jt => {
                        this.getJobInvLineCode(user.token).then(tOT => {
                            this.getTimeOffType(user.token).then(tOU => {
                                this.getTimeOffUser(user.token).then(tOS => {
                                    this.getTimeOffStatus(user.token).then(jlc => {
                                        // Handle the fetched data as needed
                                    });
                                });
                            });
                        });
                    });
                });
            });
        }
    }

    async getRemoteTypes(token) {
        await FetchData("/getjobinvlinecode", "POST", token, { AppointmentTypeFlag: 2 }).then(data => {
            localStorage.setItem('RemoteTypes', JSON.stringify(data));
        });
    }

    async getRemoteHosts(token) {
        await FetchData("/getjobinvlinecode", "POST", token, { AppointmentTypeFlag: 3 }).then(data => {
            localStorage.setItem('RemoteHosts', JSON.stringify(data));
        });
    }

    async getJobTypes(token) {
        await FetchData("/getjobinvlinecode", "POST", token, { AppointmentTypeFlag: 1 }).then(data => {
            localStorage.setItem('JobTypes', JSON.stringify(data));
        });
    }

    // get all the line codes needed for the Job Description
    async getJobInvLineCode(token) {
        await FetchData("/getjobinvlinecode", "POST", token, { AppointmentTypeFlag: null }).then(data => {
            localStorage.setItem('JobInvLineCodes', JSON.stringify(data));
        });
    }

    async getTimeOffType(token) {
        await FetchData("/gettimeoffuser", "POST", token).then(data => {
            localStorage.setItem('TimeOffUsers', JSON.stringify(data));
        })
    }

    async getTimeOffUser(token) {
        await FetchData("/getlookups", "POST", token, "TimeOff.Type").then(data => {
            localStorage.setItem('TimeOffTypes', JSON.stringify(data));
        })
    }

    async getTimeOffStatus(token) {
        await FetchData("/getlookups", "POST", token, "TimeOff.Status").then(data => {
            localStorage.setItem('TimeOffStatuses', JSON.stringify(data));
        })
    }

    addTab(tabId, tabTitle, tabContent) {
        let tabs = [...this.state.tabList];

        var isTabIndexExists = tabs.filter(function (obj) {
            return obj.tabId === tabId;
        });

        if (isTabIndexExists.length === 0) {
            tabs.push({
                tabId: tabId,
                tabTitle: tabTitle,
                tabContent: tabContent
            });

            this.setState({
                tabList: tabs, activeTab: tabId
            });
        } else {
            this.setState({
                tabList: tabs, activeTab: tabId
            });
        }
    }

    removeTab(tabId) {
        let tabs = [...this.state.tabList];

        var newTabList = tabs.filter(function (obj) {
            return obj.tabId !== tabId;
        });

        this.setState({
            tabList: newTabList
        }, () => {
            if (this.state.tabList.length > 0) {
                this.setState({
                    activeTab: this.state.tabList[0].tabId
                });
            }
        });
    }

    render() {
        return (
            <div>
                {this.user.userrolename === 'Super Administrator' && this.user.userrolename !== null && (
                    <div className="sidebar x-panel x-border-item x-box-item x-panel-default">
                        <div className="x-panel x-autowidth-table x-grid-header-hidden x-panel-default x-tree-panel x-tree-lines x-grid">
                            <span style={{ padding: "0 6px 1px" }}>Navigation</span>
                            <Nav vertical>
                                <NavItem>
                                    {this.user.userrolename === 'Interpreter' ? (
                                        <NavLink onClick={() => this.addTab("workorderint", "Work Order (Int)", <WorkOrderInt isMyJob={false} />)} className="menu-text">
                                            <img alt="Work Order" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                            Work Order (Int)
                                        </NavLink>
                                    ) : (
                                        <NavLink onClick={() => this.addTab("workorder", "Work Order", <WorkOrder isMyJob={false} WorkFlowId='' TableView='default' />)} className="menu-text">
                                            <img alt="Work Order" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                            Work Order
                                        </NavLink>
                                    )}
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("CallBack", "CallBack", <WorkOrder isMyJob={false} TableView='callback' />)} className="menu-text">
                                        <img alt="CallBack" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        CallBacks
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("myjobs", "My Jobs", <WorkOrder isMyJob={true} TableView='myjobs' WorkFlowId='' CurrentUser={this.user.userid} />)} className="menu-text">
                                        <img alt="My Jobs" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        My Jobs
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("auth_transport", "Auth/Transport", <WorkOrder isMyJob={false} WorkFlowId='Authorization' TableView='auth_transport' />)} className="menu-text">
                                        <img alt="Auth/Transport" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Auth/Transport
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("booking", "Booking", <WorkOrder isMyJob={false} WorkFlowId='Booking' TableView='booking' />)} className="menu-text">
                                        <img alt="Booking" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Booking
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("confirmation", "Confirmation", <WorkOrder isMyJob={false} WorkFlowId='Confirmation' TableView='confirmation' />)} className="menu-text">
                                        <img alt="Booking" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Confirmation
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("accountsreceivable", "Accounts Receivable", <AccountsReceivable />)} className="menu-text">
                                        <img alt="AccountsReceivable" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Accounts Receivable
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("deletedjob", "Deleted Jobs", <WorkOrder isMyJob={false} TableView='deletedjobs' />)} className="menu-text">
                                        <img alt="DeletedJob" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Deleted Jobs
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("claimant", "Claimant", <Claimant />)} className="menu-text">
                                        <img alt="Claimant" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Claimant
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("interpreter", "Interpreter", <Interpreter />)} className="menu-text">
                                        <img alt="Interpreter" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("intavailability", "Interpreter Availability", <InterpreterAvailabilityAdmin />)} className="menu-text">
                                        <img alt="Interpreter Availability" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter Availability
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("client", "Client", <Client />)} className="menu-text">
                                        <img alt="Client" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Client
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("rep", "Rep", <Rep />)} className="menu-text">
                                        <img alt="Rep" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Rep
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("provideroffice", "ProviderOffice", <ProviderOffice />)} className="menu-text">
                                        <img alt="ProviderOffice" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Provider Office
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("rolodex", "Rolodex", <Rolodex />)} className="menu-text">
                                        <img alt="Rolodex" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Rolodex
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("user", "Users", <User />)} className="menu-text">
                                        <img alt="User" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        User
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("timeoff", "TimeOff", <TimeOff />)} className="menu-text">
                                        <img alt="Time Off" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Time Off
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <br />
                            <span style={{ paddingLeft: "12px", fontSize: "13px" }}>Accounting</span>
                            <Nav vertical>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("printinvoice", "Print Invoice", <PrintInvoice isMyJob={false} />)} className="menu-text">
                                        <img alt="Print Invoices" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Print Invoices
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("postchecks", "Post Checks", <PostChecks />)} className="menu-text">
                                        <img alt="Post Checks" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Post Checks
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("transfer_cash", "Transfer Cash", <TransferCash />)} className="menu-text">
                                        <img alt="Transfer Cash" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Transfer Cash
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("bulk_transfer", "Bulk Transfer", <BulkTransfer />)} className="menu-text">
                                        <img alt="Bulk Transfer" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Bulk Transfer
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("late_invoices", "Late Invoices", <LateInvoices />)} className="menu-text">
                                        <img alt="Late Invoices" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Late Invoices
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("InterpreterPayment", "InterpreterPayment", <InterpreterPayment />)} className="menu-text">
                                        <img alt="Interpreter Payments" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter Payments
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <br />
                            <span style={{ paddingLeft: "12px", fontSize: "13px" }}>Admin</span>
                            <Nav vertical>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("BusinessCards", "BusinessCards", <BusinessCards />)} className="menu-text">
                                        <img alt="Business Cards" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Business Cards
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Production_Report", "Production Report", <RptProduction />)} className="menu-text">
                                        <img alt="Production Report" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Production Report
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Client_Invoice_Summary", "Client Invoice Summary", <RptClientInvoiceSummary />)} className="menu-text">
                                        <img alt="Client Summary" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Client Invoice Summary
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Accounts_Receivable", "Accounts Receivable", <RptAccountsReceivable />)} className="menu-text">
                                        <img alt="Accounts Receivable" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Accounts Receivable
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Checks_Received", "Checks Received", <RptChecksReceived />)} className="menu-text">
                                        <img alt="Checks Received" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Checks Received
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Interpreter1099", "Interpreter 1099", <RptInterpreter1099 />)} className="menu-text">
                                        <img alt="Interpreter 1099" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter 1099
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Rep_Count", "Rep Count", <RptRepCount />)} className="menu-text">
                                        <img alt="Rep Count" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Rep Count
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Client_Summary", "Client Summary", <RptClientSummary />)} className="menu-text">
                                        <img alt="Client Summary" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Client Summary
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                )}

                {this.user.userrolename !== 'Interpreter' && this.user.userrolename !== null && this.user.userrolename !== "Super Administrator" && (
                    <div className="sidebar x-panel x-border-item x-box-item x-panel-default">
                        <div className="x-panel x-autowidth-table x-grid-header-hidden x-panel-default x-tree-panel x-tree-lines x-grid">
                            <span style={{ padding: "0 6px 1px" }}>Navigation</span>
                            <Nav vertical>
                                <NavItem>
                                    {this.user.userrolename === 'Interpreter' ? (
                                        <NavLink onClick={() => this.addTab("workorderint", "Work Order (Int)", <WorkOrderInt isMyJob={false} />)} className="menu-text">
                                            <img alt="Work Order" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                            Work Order (Int)
                                        </NavLink>
                                    ) : (
                                        <NavLink onClick={() => this.addTab("workorder", "Work Order", <WorkOrder isMyJob={false} WorkFlowId='' TableView='default' />)} className="menu-text">
                                            <img alt="Work Order" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                            Work Order
                                        </NavLink>
                                    )}
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("CallBack", "CallBack", <WorkOrder isMyJob={false} TableView='callback' />)} className="menu-text">
                                        <img alt="CallBack" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        CallBacks
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("myjobs", "My Jobs", <WorkOrder isMyJob={true} TableView='myjobs' WorkFlowId='' CurrentUser={this.user.userid} />)} className="menu-text">
                                        <img alt="My Jobs" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        My Jobs
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("auth_transport", "Auth/Transport", <WorkOrder isMyJob={false} WorkFlowId='Authorization' TableView='auth_transport' />)} className="menu-text">
                                        <img alt="Auth/Transport" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Auth/Transport
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("booking", "Booking", <WorkOrder isMyJob={false} WorkFlowId='Booking' TableView='booking' />)} className="menu-text">
                                        <img alt="Booking" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Booking
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("confirmation", "Confirmation", <WorkOrder isMyJob={false} WorkFlowId='Confirmation' TableView='confirmation' />)} className="menu-text">
                                        <img alt="Booking" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Confirmation
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("accountsreceivable", "Accounts Receivable", <AccountsReceivable />)} className="menu-text">
                                        <img alt="AccountsReceivable" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Accounts Receivable
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("deletedjob", "Deleted Jobs", <WorkOrder isMyJob={false} TableView='deletedjobs' />)} className="menu-text">
                                        <img alt="DeletedJob" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Deleted Jobs
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("claimant", "Claimant", <Claimant />)} className="menu-text">
                                        <img alt="Claimant" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Claimant
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("interpreter", "Interpreter", <Interpreter />)} className="menu-text">
                                        <img alt="Interpreter" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("intavailability", "Interpreter Availability", <InterpreterAvailabilityAdmin />)} className="menu-text">
                                        <img alt="Interpreter Availability" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter Availability
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("client", "Client", <Client />)} className="menu-text">
                                        <img alt="Client" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Client
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("rep", "Rep", <Rep />)} className="menu-text">
                                        <img alt="Rep" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Rep
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("provideroffice", "ProviderOffice", <ProviderOffice />)} className="menu-text">
                                        <img alt="ProviderOffice" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Provider Office
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("rolodex", "Rolodex", <Rolodex />)} className="menu-text">
                                        <img alt="Rolodex" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Rolodex
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("timeoff", "TimeOff", <TimeOff />)} className="menu-text">
                                        <img alt="Time Off" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Time Off
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <br />
                            <span style={{ paddingLeft: "12px", fontSize: "13px" }}>Accounting</span>
                            <Nav vertical>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("printinvoice", "Print Invoice", <PrintInvoice isMyJob={false} />)} className="menu-text">
                                        <img alt="Print Invoices" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Print Invoices
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("postchecks", "Post Checks", <PostChecks />)} className="menu-text">
                                        <img alt="Post Checks" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Post Checks
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("transfer_cash", "Transfer Cash", <TransferCash />)} className="menu-text">
                                        <img alt="Transfer Cash" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Transfer Cash
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("bulk_transfer", "Bulk Transfer", <BulkTransfer />)} className="menu-text">
                                        <img alt="Bulk Transfer" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Bulk Transfer
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("late_invoices", "Late Invoices", <LateInvoices />)} className="menu-text">
                                        <img alt="Late Invoices" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Late Invoices
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("InterpreterPayment", "InterpreterPayment", <InterpreterPayment />)} className="menu-text">
                                        <img alt="Interpreter Payments" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter Payments
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <br />
                            <span style={{ paddingLeft: "12px", fontSize: "13px" }}>Admin</span>
                            <Nav vertical>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("BusinessCards", "BusinessCards", <BusinessCards />)} className="menu-text">
                                        <img alt="Business Cards" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Business Cards
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Production_Report", "Production Report", <RptProduction />)} className="menu-text">
                                        <img alt="Production Report" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Production Report
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Client_Invoice_Summary", "Client Invoice Summary", <RptClientInvoiceSummary />)} className="menu-text">
                                        <img alt="Client Summary" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Client Invoice Summary
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Accounts_Receivable", "Accounts Receivable", <RptAccountsReceivable />)} className="menu-text">
                                        <img alt="Accounts Receivable" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Accounts Receivable
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Checks_Received", "Checks Received", <RptChecksReceived />)} className="menu-text">
                                        <img alt="Checks Received" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Checks Received
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Interpreter1099", "Interpreter 1099", <RptInterpreter1099 />)} className="menu-text">
                                        <img alt="Interpreter 1099" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Interpreter 1099
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Rep_Count", "Rep Count", <RptRepCount />)} className="menu-text">
                                        <img alt="Rep Count" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Rep Count
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.addTab("Client_Summary", "Client Summary", <RptClientSummary />)} className="menu-text">
                                        <img alt="Client Summary" src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="x-tree-icon x-tree-icon-leaf" /> {' '}
                                        Client Summary
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                )}

                <div className="x-panel-body x-panel-body-default x-layout-fit x-panel-body-default" style={{ paddingLeft: '5px' }}>
                    {this.state.tabList.length > 0 && (
                        <div>
                            <Nav tabs>
                                {this.state.tabList.map(tabItem => (
                                    <NavItem key={tabItem.tabId}>
                                        <NavLink
                                            className={this.state.activeTab === tabItem.tabId ? "active" : ""}
                                            onClick={() => { this.setState({ activeTab: tabItem.tabId }) }}
                                        >
                                            {tabItem.tabTitle} {'  '} <img src={RemoveIcon} style={{ width: "13px", height: "13px", cursor: "pointer" }} alt="Remove" onClick={() => { this.removeTab(tabItem.tabId) }} />
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>

                            {this.state.tabList.map(tabItem => (
                                <TabContent activeTab={this.state.activeTab} key={tabItem.tabId}>
                                    <TabPane tabId={tabItem.tabId}>
                                        <Row>
                                            <Col>
                                                {tabItem.tabContent}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
