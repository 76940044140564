import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup } from 'reactstrap';

import { InterpreterAvailabilityForm } from './InterpreterAvailabilityForm';
import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';
import { InterpreterAvailabilityTableDefault } from './InterpreterAvailabilityTableDefault';

import AddIcon from '../images/add.png'


const moment = require('moment');

export class InterpreterAvailability extends Component {
    static displayName = InterpreterAvailability.name;

    constructor(props) {
        super(props);
        this.state = {

            intScheduleList: [],
            loading: false,
            fetchingData: false,
            modal: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                IntId: null,
                StartDate: null,
                EndDate: null,
                ScheduleType: '',
                SchedNotes: '',
                CreateByUserId: ''
                
            },
            searchModel: {
                IntId: null,
                AvailableDate: null,
                IntLanguage: '',                
                SortBy: 'interpreterName',
                SortDirection: 'asc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.setModal = this.setModal.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderIntAvailabilityTable = this.renderIntAvailabilityTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        this.populateScheduleData();
    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                IntId: null,
                StartDate: null,
                EndDate: null,
                ScheduleType: '',
                SchedNotes: '',
                CreateByUserId: ''
            }
        }));

        this.setModal();
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateScheduleData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateScheduleData();
        }
    }


    handleDeleteAvailability(intAvailabilityID) {
        let model = {
            EventType: 'delete',
            InterpreterAvailabilityID: intAvailabilityID,
            UpdateByUserId: this.user.userid
        }

        FetchData("/deleteinterpreteravailability", "POST", this.user.token, model).then(data => {
            if (data === 'Success') {
                this.handleSaveEventSuccess({
                    message: "Availability Deleted.",
                    modal: false,
                    color: "success"
                });
            } else {
                this.handleSaveEventSuccess({
                    message: "Delete Failed." + data,
                    modal: false,
                    color: "warning"
                });
            }
        });
    }

    async populateScheduleData() {
        this.setState({ fetchingData: true });

        FetchData("/getinterpreterschedules", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ intScheduleList: data, loading: false, fetchingData: false });
        });
    }

    renderIntAvailabilityTable(schedules) {

        return (
            <InterpreterAvailabilityTableDefault
                schedules={schedules}
                handleSaveEventSuccess={this.handleSaveEventSuccess}
            ></InterpreterAvailabilityTableDefault>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderIntAvailabilityTable(this.state.intScheduleList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
               
                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <InterpreterAvailabilityForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto', padding: '5px' }}>
                    <Button color="btn" className="btn btn-success" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" /> Add Availability</Button>
                    <p></p>
                    {contents}
                </div>
            </div>
        );

    }
}
