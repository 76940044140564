import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, Nav, NavItem, NavLink, TabContent, TabPane, InputGroup } from 'reactstrap';

import { InterpreterForm } from './InterpreterForm';
import { WorkOrderForm } from './WorkOrderForm';

import { LoadingModal } from './LoadingModal';
import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png'

import { saveAs } from 'file-saver';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

import * as CellRenderers from './AgGridCellRendererComponents';


const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },

};


const interpreterColumns = [
    { headerName: "Interpreter Name", field: "intLastName", cellRenderer: 'intNameCellRenderer', width: 125 },
    { headerName: "Fax", field: "intFax", width: 150 },
    { headerName: "Cell/Text", field: "intCellTxt", width: 100 },
    { headerName: "Phone Only", field: "intPhoneOnly", width: 100 },
    { headerName: "City", field: "intCity", width: 100 },
    { headerName: "State", field: "intState", width: 100 },
    { headerName: "Zip", field: "intZip", width: 75 },
    { headerName: "Gender", field: "intGender", width: 60 },
    { headerName: "Notes", field: "intNote", width: 350 }
];

const jobColumns = [
    { headerName: "Invoice #", field: "jobNum", sortable: true, width: 100 },
    { headerName: "Date", field: "jobDateTime", cellRenderer: 'jobDateCellRenderer', sortable: true, width: 150, },
    { headerName: "Clm #", field: "clmClaimNumber", sortable: true, width: 150 },
    { headerName: "Claimant", field: "clmLastName", sortable: true, width: 150 },
    { headerName: "Client", field: "clientName", sortable: true, width: 150 },
    { headerName: "Provider", field: "providerName", sortable: true, width: 150 },
    { headerName: "IntFee", field: "intFee", sortable: true, width: 100 },
    { headerName: "Overtime", field: "intOTFee", sortable: true, width: 100 },
    { headerName: "OtherIntFee", field: "intFeeOther", sortable: true, width: 100 },
];

export class Interpreter extends Component {
    static displayName = Interpreter.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: interpreterColumns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,

            interpreterList: [],
            clickCount: 0,
            selectedInterpreter: null,
            loading: false,
            fetchingData: false,
            modal: false,
            modalWorkOrder: false,
            multiFilter: false,
            activeTab: '1',
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntGender: '',
                IntPronoun: '',
                IntEmail: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntFax: '',
                IntBeeper: '',
                IntStreet: '',
                IntCity: '',
                IntState: '',
                IntZip: '',
                IntCountry: '',
                IntSSINumber: '',
                IntActiveFlg: true,
                IntCorporationFlg: false,
                DBA: '',
                InHouseFlg: false,
                UnVettedFlg: false,
                EmergencyContact: '',
                UnavailableReason: '',
                BankName: '',
                BankAccount: '',
                BankRoute: '',
                Rate: null,
                HourMinimum: null,
                Prorate: '',
                ConfCallRate: null,
                BusinessCardRequestDate: null,
                BusinessCardRequestToProcessFlg: false,
                IntNote: '',
                ReferredBy: '',
                DefaultLanguage: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                CreatedBy: '',
                UpdatedBy: ''
            },
            searchModel: {
                Name: '',
                Language: '',
                Phone: '',
                Email: '',
                City: '',
                State: '',
                Zip: '',
                ShowAll: false,
                UnVetted: false,
                SortBy: 'intLastName',
                SortDirection: 'asc'
            },
            job: {
                EventType: 'insert',
                JobNum: 0,
                JobId: null,
                JobDateTime: null,
                JobTime: null,
                FinishTime: null,
                FinishTimeString: null,
                FollowupDate: null,
                ProvId: null,
                ProviderName: '',
                ConfCallNote: '',
                AptName: '',
                AptStreet: '',
                AptCity: '',
                AptState: '',
                AptZip: '',
                AptPhone: '',
                AptDirection: '',
                Failedflg: false,
                CallerNote: '',
                InvoiceNote: '',
                IntCheckDate: null,
                IntCheckNum: null,
                Invoicedflg: false,
                JobTotal: null,
                JobDiscount: null,
                IntFee: null,
                IntFeeOther: null,
                IntOTFee: null,
                IntFeeNote: '',
                Transportationflg: false,
                TransConfflg: false,
                IntConfflg: false,
                AppointmentTypeId: '9e5e4810-c5a8-428b-8f7d-71422b6b77e3', // conf call
                CallerType: null,
                ProvConfflg: false,
                ClmConfflg: false,
                IsDeletedflg: false,
                BillReviewedflg: false,
                Auditflg: false,
                PurpleReportflg: false,
                RepAuthorizedflg: false,
                Selectedflg: false,
                ClmId: '',
                ClmFirstName: '',
                ClmLastName: '',
                ClmClaimNumber: '',
                ClmClaimNumberNoDash: '',
                ClmPhone: '',
                ClmDateOfLoss: '',
                ClmDateOfInjury: '',
                ClmNote: '',
                ClmDOB: null,
                ClmInsuredParty: '',
                ClmEmployer: '',
                ClmInvoiceCaption: '',
                ClmLanguage: '',
                ClmWCABNumber: '',
                ClmInvoiceType: '',
                ClmStreet: '',
                ClmCity: '',
                ClmState: '',
                ClmZip: '',
                ClmSSN: '',
                ClmOrderedByName: '',
                ClmDiagnosis: '',
                ClmEmail: '',
                RepID: null,
                RepFirstName: '',
                RepLastName: '',
                RepPhone: '',
                RepEmail: '',
                ClientId: '',
                ClientAbbrv: '',
                ClientName: '',
                ClientStreet: '',
                ClientStreet2: '',
                ClientCity: '',
                ClientState: '',
                ClientZip: '',
                ClientMailStreet: '',
                ClientMailStreet2: '',
                ClientMailCity: '',
                ClientMailState: '',
                ClientMailZip: '',
                ClientPhone: '',
                ClientPhoneFax: '',
                ClientPhone2: '',
                ClientEmail: '',
                ClientInactiveFlg: false,
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntEmail: '',
                IntNote: '',
                InHouseFlg: false,
                IntCity: '',
                IntStreet: '',
                IntState: '',
                IntZip: '',
                DBA: '',
                Rate: 0,
                HourMinimum: 0,
                Prorate: '',
                BankRoute: '',
                BankAccount: '',
                IntSSINumber: '',
                FirstName: '',
                LastName: '',
                UserType: '',
                UserIntId: null,
                CreateDateTime: null,
                CreateByUserId: null,
                RemIntflg: false,
                RemClaimflg: false,
                RemProvflg: false,
                RemFinalflg: false,
                RemType: null,
                RemTypeDesc: '',
                RemHost: null,
                RemHostDesc: '',
                CreatedBy: '',
                UpdatedBy: '',
                MyJobTJobHistory: '',
                JobBalance: null
            },
            jobList: [],
            jobSortModel: {
                SortBy: 'jobNum',
                SortDirection: 'asc'
            },
        };
        this.user = JSON.parse(GetCookie('user'));

        this.setModal = this.setModal.bind(this);
        this.setModalWorkOrder = this.setModalWorkOrder.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleSaveEventWorkOrderSuccess = this.handleSaveEventWorkOrderSuccess.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderInterpreterTable = this.renderInterpreterTable.bind(this);
        this.renderJobTable = this.renderJobTable.bind(this);
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchFocus = this.handleSearchFocus.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //

        this.viewInvoice = this.viewInvoice.bind(this);
        //
        this.viewWorkOrder = this.viewWorkOrder.bind(this);
        //
    }

    componentDidMount() {

    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    setModalWorkOrder() {
        this.setState({ modalWorkOrder: !this.state.modalWorkOrder });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                IntId: null,
                IntLastName: '',
                IntFirstName: '',
                IntGender: '',
                IntPronoun: '',
                IntEmail: '',
                IntCellTxt: '',
                IntPhoneOnly: '',
                IntFax: '',
                IntBeeper: '',
                IntStreet: '',
                IntCity: '',
                IntState: '',
                IntZip: '',
                IntCountry: '',
                IntSSINumber: '',
                IntActiveFlg: true,
                IntCorporationFlg: false,
                DBA: '',
                InHouseFlg: false,
                UnVettedFlg: false,
                EmergencyContact: '',
                UnavailableReason: '',
                BankName: '',
                BankAccount: '',
                BankRoute: '',
                Rate: null,
                HourMinimum: null,
                Prorate: '',
                ConfCallRate: null,
                BusinessCardRequestDate: null,
                BusinessCardRequestToProcessFlg: false,
                IntNote: '',
                ReferredBy: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                CreatedBy: '',
                UpdatedBy: ''
            }
        }));

        this.setModal();
    }

    handleDoubleClick(intData) {
        clearTimeout(this.clickTimer);  // prevent single click action
        this.setState({ clickCount: 0 });  // reset click count

        let searchModel = {
            IntId: intData.data.intId,
            Name: '',
            Language: '',
            Phone: '',
            Email: '',
            City: '',
            State: '',
            Zip: '',
            ShowAll: false,
            UnVetted: false,
            SortBy: '',
            SortDirection: ''
        }

        FetchData("/getinterpreters", "POST", this.user.token, searchModel).then(data => {
            this.setState(prevState => ({
                model: {
                    EventType: 'update',
                    IntId: intData.data.intId,
                    IntLastName: intData.data.intLastName,
                    IntFirstName: intData.data.intFirstName,
                    IntGender: intData.data.intGender,
                    IntPronoun: intData.data.intPronoun,
                    IntEmail: intData.data.intEmail,
                    IntCellTxt: intData.data.intCellTxt,
                    IntPhoneOnly: intData.data.intPhoneOnly,
                    IntFax: intData.data.intFax,
                    IntBeeper: intData.data.intBeeper,
                    IntStreet: intData.data.intStreet,
                    IntCity: intData.data.intCity,
                    IntState: intData.data.intState,
                    IntZip: intData.data.intZip,
                    IntCountry: intData.data.intCountry,
                    IntSSINumber: intData.data.intSSINumber,
                    IntActiveFlg: intData.data.intActiveFlg,
                    IntCorporationFlg: intData.data.intCorporationFlg,
                    DBA: intData.data.dBA,
                    InHouseFlg: intData.data.inHouseFlg,
                    UnVettedFlg: intData.data.unVettedFlg,
                    EmergencyContact: intData.data.emergencyContact,
                    UnavailableReason: intData.data.unavailableReason,
                    BankName: intData.data.bankName,
                    BankAccount: intData.data.bankAccount,
                    BankRoute: intData.data.bankRoute,
                    Rate: intData.data.rate,
                    HourMinimum: intData.data.hourMinimum,
                    Prorate: intData.data.prorate,
                    ConfCallRate: intData.data.confCallRate,
                    BusinessCardRequestDate: intData.data.businessCardRequestDate,
                    BusinessCardRequestToProcessFlg: intData.data.businessCardRequestToProcessFlg,
                    IntNote: intData.data.intNote,
                    ReferredBy: intData.data.referredBy,
                    CreateDateTime: intData.data.createDateTime,
                    CreateByUserId: intData.data.createByUserId,
                    UpdateByUserId: intData.data.updateByUserId,
                    UpdateDateTime: intData.data.updateDateTime,
                    IsDeletedFlg: intData.data.isDeletedFlg,
                    CreatedBy: intData.data.createdBy,
                    UpdatedBy: intData.data.updatedBy
                }
            }))
            this.setModal();
        });
    }


    handleClick(interpreterData) {
        this.setState({ clickCount: this.state.clickCount + 1 });
        this.clickTimer = setTimeout(() => {
            if (this.state.clickCount === 1) {
                this.setState({ selectedInterpreter: interpreterData.data, fetchingData: true });
                let model = {
                    IntId: interpreterData.data.intId
                };
                FetchData("/getworkorders", "POST", this.user.token, model).then(data => {
                    this.setState({ jobList: data, fetchingData: false });
                });
            }
            this.setState({ clickCount: 0 });  // reset count after action
        }, 300);  // 300 ms delay
    };

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            modalWorkOrder: false,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            },
            selectedInterpreter: null
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));

            this.populateInterpreterData();
        });
    }

    handleSaveEventWorkOrderSuccess(alert) {
        this.setState({
            modalWorkOrder: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else
            model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleSearchFocus(e) {
        if (this.state.multiFilter === false) {
            this.setState({
                searchModel: {
                    Name: '',
                    Language: '',
                    Phone: '',
                    Email: '',
                    City: '',
                    State: '',
                    Zip: ''
                }
            }, () => {

            });

            if (e.target.value !== "") {
                let model = { ...this.state.searchModel }
                model[e.target.name] = e.target.value;

                this.setState({ searchModel: model });
            }
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({ interpreterList: [] }, () => {
                this.populateInterpreterData();
            });
        }
    }


    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = params => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths });
        }
    };

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    viewInvoice(e, woData, i) {
        e.preventDefault();

        let body = {
            JobId: ""
        };

        body.JobId = woData.jobId;
        let currentDate = new Date();
        let invoiceFileName = "Invoice " + woData.jobNum + " " + (currentDate.getMonth() + 1) + "-" + (currentDate.getDate() + 1) + "-" + currentDate.getFullYear() + ".pdf";

        FetchFile("/printinvoices", "POST", this.user.token, body).then(blob => {
            saveAs(blob, invoiceFileName);
        })
    }

    viewWorkOrder(workOrder, showModal) {
        clearTimeout(this.clickTimer);  // prevent single click action
        this.setState({ clickCount: 0 });  // reset click count


        FetchData("/getworkorder?jobId=" + workOrder.data.jobId, "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                job: {
                    EventType: 'update',
                    JobNum: data.jobNum,
                    JobId: data.jobId,
                    JobDateTime: data.jobDateTime,
                    JobTime: data.jobTime,
                    FinishTime: data.finishTime,
                    FinishTimeString: data.finishTimeString == "00:00" ? null : data.finishTimeString,
                    FollowupDate: data.followupDate,
                    ProvId: data.provId,
                    ProviderName: data.providerName,
                    ConfCallNote: data.confCallNote,
                    AptName: data.aptName,
                    AptStreet: data.aptStreet,
                    AptCity: data.aptCity,
                    AptState: data.aptState,
                    AptZip: data.aptZip,
                    AptPhone: data.aptPhone,
                    AptDirection: data.aptDirection,
                    Failedflg: data.failedflg,
                    CallerNote: data.callerNote,
                    InvoiceNote: data.invoiceNote,
                    IntCheckDate: data.intCheckDate,
                    IntCheckNum: data.intCheckNum,
                    Invoicedflg: data.invoicedflg,
                    JobTotal: data.jobTotal,
                    JobDiscount: data.jobDiscount,
                    IntFee: data.intFee === null ? null : parseInt(data.intFee),
                    IntFeeOther: data.intFeeOther === null ? null : parseInt(data.intFeeOther),
                    IntOTFee: data.intOTFee === null ? null : parseInt(data.intOTFee),
                    IntFeeNote: data.intFeeNote,
                    Transportationflg: data.transportationflg,
                    TransConfflg: data.transConfflg,
                    IntConfflg: data.intConfflg,
                    AppointmentTypeId: data.appointmentTypeId,
                    CallerType: data.callerType,
                    ProvConfflg: data.provConfflg,
                    ClmConfflg: data.clmConfflg,
                    IsDeletedflg: data.isDeletedflg,
                    BillReviewedflg: data.billReviewedflg,
                    Auditflg: data.auditflg,
                    PurpleReportflg: data.purpleReportflg,
                    RepAuthorizedflg: data.repAuthorizedflg,
                    Selectedflg: data.selectedflg,
                    ClmId: data.clmId,
                    ClmFirstName: data.clmFirstName,
                    ClmLastName: data.clmLastName,
                    ClmClaimNumber: data.clmClaimNumber,
                    ClmClaimNumberNoDash: data.clmClaimNumberNoDash,
                    ClmPhone: data.clmPhone,
                    ClmDateOfLoss: data.clmDateOfLoss,
                    ClmDateOfInjury: data.clmDateOfInjury,
                    ClmNote: data.clmNote,
                    ClmDOB: data.clmDOB,
                    ClmInsuredParty: data.clmInsuredParty,
                    ClmEmployer: data.clmEmployer,
                    ClmInvoiceCaption: data.clmInvoiceCaption,
                    ClmLanguage: data.clmLanguage,
                    ClmWCABNumber: data.clmWCABNumber,
                    ClmInvoiceType: data.clmInvoiceType,
                    ClmStreet: data.clmStreet,
                    ClmCity: data.clmCity,
                    ClmState: data.clmState,
                    ClmZip: data.clmZip,
                    ClmSSN: data.clmSSN,
                    ClmOrderedByName: data.clmOrderedByName,
                    ClmDiagnosis: data.clmDiagnosis,
                    ClmEmail: data.clmEmail,
                    RepID: data.repID,
                    RepFirstName: data.repFirstName,
                    RepLastName: data.repLastName,
                    RepPhone: data.repPhone,
                    RepEmail: data.repEmail,
                    ClientId: data.clientId,
                    ClientAbbrv: data.clientAbbrv,
                    ClientName: data.clientName,
                    ClientStreet: data.clientStreet,
                    ClientStreet2: data.clientStreet2,
                    ClientCity: data.clientCity,
                    ClientState: data.clientState,
                    ClientZip: data.clientZip,
                    ClientMailStreet: data.clientMailStreet,
                    ClientMailStreet2: data.clientMailStreet2,
                    ClientMailCity: data.clientMailCity,
                    ClientMailState: data.clientMailState,
                    ClientMailZip: data.clientMailZip,
                    ClientPhone: data.clientPhone,
                    ClientPhoneFax: data.clientPhoneFax,
                    ClientPhone2: data.clientPhone2,
                    ClientEmail: data.clientEmail,
                    ClientInactiveFlg: data.clientInactiveFlg,
                    IntId: data.intId,
                    IntLastName: data.intLastName,
                    IntFirstName: data.intFirstName,
                    IntCellTxt: data.intCellTxt,
                    IntPhoneOnly: data.intPhoneOnly,
                    IntEmail: data.intEmail,
                    IntNote: data.intNote,
                    InHouseFlg: data.inHouseFlg,
                    IntCity: data.intCity,
                    IntStreet: data.intStreet,
                    IntState: data.intState,
                    IntZip: data.intZip,
                    DBA: data.dDBA,
                    Rate: data.rate,
                    HourMinimum: data.hourMinimum,
                    Prorate: data.prorate,
                    BankRoute: data.bankRoute,
                    BankAccount: data.bankAccount,
                    IntSSINumber: data.intSSINumber,
                    FirstName: data.firstName,
                    LastName: data.lastName,
                    UserType: data.userType,
                    UserIntId: data.userIntId,
                    CreateDateTime: data.createDateTime,
                    CreateByUserId: data.createByUserId,
                    UpdateDateTime: data.updateDateTime,
                    UpdateByUserId: data.updateByUserId,
                    RemIntflg: data.remIntflg,
                    RemClaimflg: data.remClaimflg,
                    RemProvflg: data.remProvflg,
                    RemFinalflg: data.remFinalflg,
                    RemType: data.remType,
                    RemTypeDesc: data.remTypeDesc,
                    RemHost: data.remHost,
                    RemHostDesc: data.remHostDesc,
                    CreatedBy: data.createdBy,
                    UpdatedBy: data.updatedBy,
                    MyJobTJobHistory: data.myJobTJobHistory,
                    JobBalance: data.jobBalance,
                    WorkFlowId: data.workFlowId
                },
                modalWorkOrder: showModal
            }));
        });
    }

    renderInterpreterTable(interpreters) {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: interpreterColumns,
            rowData: interpreters,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                state: this.state,

            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderer
                intNameCellRenderer: IntNameCellRenderer,

            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleDoubleClick,
            onRowClicked: this.handleClick,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }

    renderJobTable(jobList) {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: jobColumns,
            rowData: jobList,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModalWorkOrder: this.setModalWorkOrder,
                viewInvoice: this.viewInvoice,
                state: this.state,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderer
                jobNumCellRenderer: CellRenderers.JobNumCellRenderer,
                jobDateCellRenderer: JobDateCellRenderer,
                clmNameCelRenderer: ClmNameCellRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: (params) => this.viewWorkOrder(params, !this.state.modalWorkOrder),
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>

        );
    }

    async populateInterpreterData() {
        this.setState({ fetchingData: true });

        await FetchData("/getinterpreters", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ interpreterList: data, jobList: [], loading: false, fetchingData: false });
        });
    }



    render() {
        let interpreterContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderInterpreterTable(this.state.interpreterList);

        let jobsContent = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderJobTable(this.state.jobList);

        return (
            <div style={{ paddingTop: "12px" }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Interpreters</h5>

                <Row>
                    <Col className="col-9">
                        <InputGroup size="sm" style={{ verticalAlign: "middle" }}>
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                            <label><input name="MultiFilter" type="checkbox" className="input-checkbox" defaultChecked={this.state.multiFilter} onChange={() => { this.setState({ multiFilter: !this.state.multiFilter }); }} /></label>
                            <input name="Name" type="text" className="form-control form-control-text" value={this.state.searchModel.Name} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Interpreter Name (Lastname, Firstname)" onKeyPress={this.handleKeyPress} />
                            <input name="Language" type="text" className="form-control form-control-text" value={this.state.searchModel.Language} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Language" onKeyPress={this.handleKeyPress} />
                            <input name="Phone" type="text" className="form-control form-control-text" value={this.state.searchModel.Phone} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Cell/Text/Phone Only" onKeyPress={this.handleKeyPress} />
                            <input name="Email" type="text" className="form-control form-control-text" value={this.state.searchModel.Email} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Email" onKeyPress={this.handleKeyPress} />
                            <input name="City" type="text" className="form-control form-control-text" value={this.state.searchModel.City} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="City" onKeyPress={this.handleKeyPress} />
                            <input name="State" type="text" className="form-control form-control-text" value={this.state.searchModel.State} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="State" onKeyPress={this.handleKeyPress} />
                            <input name="Zip" type="text" className="form-control form-control-text" value={this.state.searchModel.Zip} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} placeholder="Zip" onKeyPress={this.handleKeyPress} />
                            <label style={{ paddingLeft: '3px', paddingBottom: '3px' }}><input name="UnVetted" type="checkbox" className="input-checkbox" defaultChecked={this.state.searchModel.UnVetted} onChange={this.handleSearchChange} />UnVetted</label>
                            <label style={{ paddingLeft: '3px' }}><input name="ShowAll" type="checkbox" className="input-checkbox" defaultChecked={this.state.searchModel.ShowAll} onChange={this.handleSearchChange} />Show All</label>
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <InterpreterForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }
                {this.state.modalWorkOrder &&
                    <WorkOrderForm model={this.state.job} modal={this.state.modalWorkOrder} alert={this.state.alert} setModal={this.setModalWorkOrder} workOrderList={[]} jobHistoryList={[]} jobInvLineList={[]} setNextPrevData={null} selectedIndex={0} handleSaveEventSuccess={this.handleSaveEventWorkOrderSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {interpreterContents}
                </div>
                <hr />

                {this.state.selectedInterpreter !== null &&
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={this.state.activeTab === '1' ? "active" : ""}
                                    onClick={() => { this.setState({ activeTab: '1' }) }}
                                >
                                    Jobs
                                </NavLink>
                            </NavItem>
                            {/*    <NavItem>*/}
                            {/*        <NavLink className={this.state.activeTab === '2' ? "active" : ""} onClick={() => { this.setState({ activeTab: '2' }) }}>*/}
                            {/*            Interpreter Info*/}
                            {/*        </NavLink>*/}
                            {/*    </NavItem>*/}
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            {jobsContent}
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            interpreter info
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                }
            </div>
        );

    }
}

class ClmNameCellRenderer extends Component {
    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.clmLastName}, ${data.clmFirstName},` + '(' + `${data.clientAbbrv}` + ')';

        if (data.hasClaimantDupWO) {
            return (
                <span style={{ color: 'red' }}>{name}</span>
            );
        } else {
            return (
                <span>{name}</span>
            );
        }
    }
}

class IntNameCellRenderer extends Component {
    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.intLastName}, ${data.intFirstName}`;

        return (
            <span>{name}</span>
        );
    }
}

class JobDateCellRenderer extends React.Component {
    render() {
        const { data } = this.props;
        const formattedJobDate = moment(data.jobDateTime).format('MM/DD/YYYY');
        const formattedFinishTime = data.finishTime ? ` to ${moment(data.finishTime).format('hh:mm A')}` : '';
        const jobStatus = data.Failedflg ? ' (Failed)' : ' (Success)';

        return (
            <div className="x-grid-cell-inner">
                {formattedJobDate}{formattedFinishTime}{jobStatus}
            </div>
        );
    }
}