import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { ClientFormSearch } from './ClientFormSearch';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DeleteIcon from '../images/delete.png'
import SearchIcon from '../images/search-icon.png'

export class RepForm extends Component {
    static displayName = RepForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            modalClientSearchForm: false,
            model: props.model,
            alert: props.alert
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.handleSelectClient = this.handleSelectClient.bind(this);
        //
        this.setModalClientFormSearch = this.setModalClientFormSearch.bind(this);
        //
        this.confirmDeleteRep = this.confirmDeleteRep.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
    }

    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        if (model.ClientID === null) {
            this.handleSaveEventSuccess({
                message: 'Please choose a Client.',
                modal: true,
                color: "warning"
            });
        } else {
            FetchData("/saverep", "POST", this.user.token, model).then(data => {
                console.log(data);
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }                
            });
        }
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseFloat(e.target.value);
            else
                model[e.target.name] = null;
        }
        else
            model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    handleSelectClient(client) {
        let model = { ...this.state.model }
        model.ClientID = client.data.clientId;
        model.ClientName = client.data.clientName;
        model.ClientAbbrv = client.data.clientAbbrv;
        model.ClientPhone = client.data.clientPhone;
        model.ClientPhone2 = client.data.clientPhone2;

        this.setState({ model }, () => {
            this.setModalClientFormSearch();
        });
    }

    setModalClientFormSearch() {
        this.setState({ modalClientSearchForm: !this.state.modalClientSearchForm });
    }

    confirmDeleteRep() {
        if (this.state.model.RepID !== null) {
            if (window.confirm("Are you sure you want to delete this Rep??")) {
                var data = {
                    RepId: this.state.model.RepID,
                    UserEmail: this.user.email,
                    UpdateByUserId: this.user.userid
                }

                FetchData("/deleterep", "POST", this.user.token, data).then(data => {
                    if (data === 'Success') {
                        this.props.handleSaveEventSuccess({
                            message: 'Rep has been deleted successfully',
                            modal: false,
                            color: "success"
                        });
                    }
                    else {
                        this.handleSaveEventSuccess({
                            message: data,
                            modal: true,
                            color: "warning"
                        });
                    }
                });
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>New/Edit Rep</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        <center>{this.state.alert.message}</center>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Lastname:
                                        </InputGroupText>
                                        <input type="text" name="RepLastName" className="form-control form-control-text" value={this.state.model.RepLastName} maxLength={25} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Firstname:
                                        </InputGroupText>
                                        <input type="text" name="RepFirstName" className="form-control form-control-text" value={this.state.model.RepFirstName} maxLength={25} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Phone:
                                        </InputGroupText>
                                        <input type="text" name="RepPhone" className="form-control form-control-text" value={this.state.model.RepPhone} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Zip:
                                        </InputGroupText>
                                        <input type="text" name="RepZip" className="form-control form-control-text" value={this.state.model.RepZip} maxLength={12} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Notes:
                                        </InputGroupText>
                                        <textarea type="text" name="RepNote" className="form-control form-control-text" rows={6} maxLength={250} onChange={this.handleChange}>{this.state.model.RepNote}</textarea>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Email:
                                        </InputGroupText>
                                        <input type="text" name="RepEmail" className="form-control form-control-text" value={this.state.model.RepEmail} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Inactive:
                                        </InputGroupText>
                                        <input name="RepInactiveFlg" type="checkbox" className="input-checkbox form-check-input" defaultChecked={this.state.model.RepInactiveFlg} onChange={this.handleChange} />
                                        <span style={{ paddingLeft: '10px' }} ></span>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.setModalClientFormSearch} ><img src={SearchIcon} style={{ width: "14px", height: "14px" }} alt="Search Client" /></Button> {' '}
                                            Client:
                                        </InputGroupText>
                                        <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.ClientName}</label>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Abbrv:
                                        </InputGroupText>
                                        <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.ClientAbbrv}</label>
                                    </InputGroup>
                                </Col>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Phone:
                                        </InputGroupText>
                                        <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.ClientPhone}</label>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick = { this.confirmDeleteRep } size="sm">Delete</Button>
                    </ModalFooter>
                </Modal>

                {
                    this.state.modalClientSearchForm &&
                    <ClientFormSearch modal={this.state.modalClientSearchForm} setModal={this.setModalClientFormSearch} handleDoubleClick={this.handleSelectClient} />
                }
            </div>
        )
    }
}