//Main Interpreter Display Form

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, InputGroup, InputGroupText, Row, Col, Alert, TabContent, TabPane } from 'reactstrap';

import { InterpreterLanguageForm } from './InterpreterLanguageForm';
import { InterpreterItemForm } from './InterpreterItemForm';
import { InterpreterFormTableLanguages } from './InterpreterFormTableLanguages';
import { InterpreterFormTableItems } from './InterpreterFormTableItems';

import AddIcon from '../images/add.png'
import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import SaveIcon from '../images/save-icon.png'
import { saveAs } from 'file-saver';

const moment = require('moment');

export class InterpreterForm extends Component {
    static displayName = InterpreterForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            modalIntLanguage: false,
            modalItem: false,
            interpreterLanguagesList: [],
            interpreterItems: [],

            alert: props.alert,

            model: props.model,
            modelSchedule: {
                IntId: props.model.IntId,
                StartDate: null,
                EndDate: null,
                SchedAm: false,
                SchedPM: false,
                SchedNotes: '',
                CreateByUserId: null
            },
            modelInterpreterLanguage: {
                EventType: 'insert',
                IntLangID: null,
                IntID: props.model.IntId,
                IntLanguage: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false
            },
            modelInterpreterItem: {
                EventType: 'insert',
                IntItemId: null,
                IntId: props.model.IntId,
                ItemName: '',
                ItemDescription: '',
                Contact: '',
                ContactEmail: '',
                Notes: '',
                FileLocation: '',
                FileName: '',
                IntItemType: 'Note',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                FormFile: null
            },
            activeTab: '1',
            sortModelItems: {               
                SortBy: 'intLastName',
                SortDirection: 'asc'
            },
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //
        this.onSaveSchedule = this.onSaveSchedule.bind(this);
        this.handleChangeSchedule = this.handleChangeSchedule.bind(this);
        //
        this.keydownHandler = this.keydownHandler.bind(this);
        this.handleSelectGender = this.handleSelectGender.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        //
        this.handleSaveEventIntLanguageSuccess = this.handleSaveEventIntLanguageSuccess.bind(this);
        this.setModalIntLanguage = this.setModalIntLanguage.bind(this);
        this.handleNewLanguageClick = this.handleNewLanguageClick.bind(this);
        this.handleEditLanguageClick = this.handleEditLanguageClick.bind(this);
        //
        this.handleNewItemClick = this.handleNewItemClick.bind(this);
        this.handleEditItemClick = this.handleEditItemClick.bind(this);
        this.handleItemViewClick = this.handleItemViewClick.bind(this);
        this.handleSaveEventItemSuccess = this.handleSaveEventItemSuccess.bind(this);
        this.setModalItem = this.setModalItem.bind(this);
        //
        this.handleDeleteLanguage = this.handleDeleteLanguage.bind(this);
        //
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        //
        this.requestSort = this.requestSort.bind(this);
        //
        this.renderInterpreterItemTable = this.renderInterpreterItemTable.bind(this);
        this.renderLanguagesTable = this.renderLanguagesTable.bind(this);
    }

    getLanguages() {
        // load sub data
        if (this.state.model.IntId !== null) {
            FetchData("/getinterpreterlanguages", "POST", this.user.token, { IntID: this.state.model.IntId }).then(data => {
                this.setState({ interpreterLanguagesList: data });
            });
        }
    }

    getItems() {
        FetchData("/getinterpreteritems", "POST", this.user.token, { IntID: this.state.model.IntId }).then(data => {
            this.setState({ interpreterItems: data });
        });
    }

    onSave() {
        let model = { ...this.state.model };

        if (model.IntId !== null && this.state.interpreterLanguagesList.length <= 0 && this.state.model.DefaultLanguage === '') {
            this.handleSaveEventSuccess({
                message: "Please enter a language",
                modal: true,
                color: "warning"
            });
        } else {
            if (model.EventType === "insert") {
                model.CreateByUserId = this.user.userid;
            } else if (model.EventType === "update") {
                model.UpdateByUserId = this.user.userid;
            }
            if (model.Rate == null)
                model.Rate = 0;

            model.HourMinimum = model.HourMinimum === null || model.HourMinimum === "" ? null : parseInt(model.HourMinimum);

            FetchData("/saveinterpreter", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }
            });
        }
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout());
        });
    }

    onSaveSchedule() {
        let model = this.state.modelSchedule;

        //if (model.EventType === "insert") {
        model.CreateByUserId = this.user.userid;
        //} else if (model.EventType === "update") {
        //    model.UpdateByUserId = this.user.userid;
        //}

        FetchData("/saveinterpreteravailability", "POST", this.user.token, model).then(data => {
            alert(data);
            this.setState(prevState => ({
                modelSchedule: {
                    IntId: this.state.model.IntId,
                    StartDate: null,
                    EndDate: null,
                    SchedAm: false,
                    SchedPM: false,
                    SchedNotes: '',
                    CreateByUserId: null
                }
            }));
        });
    }

    onDelete() {
        let model = { ...this.state.model };

        model.EventType = 'delete';
        model.UpdateByUserId = this.user.userid;

        FetchData("/saveinterpreter", "POST", this.user.token, model).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            }
            else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "warning"
                });
            }
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseFloat(e.target.value);
            else
                model[e.target.name] = null;
        }
        else
            model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    handleChangeSchedule(e) {
        let model = { ...this.state.modelSchedule }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else
            model[e.target.name] = e.target.value;

        this.setState({ modelSchedule: model });
    }

    handleSelectGender(e) {
        let model = { ...this.state.model }
        model.IntGender = e.target.value;

        this.setState({ model });
    }

    setModalIntLanguage() {
        this.setState({ modalIntLanguage: !this.state.modalIntLanguage });
    }

    handleNewLanguageClick() {
        if (this.state.model.IntId === null || this.state.model.IntId === '') {
            this.handleSaveEventSuccess({
                message: "Cannot add a Language before saving new Interpreter. Please go back to the details tab and press Alt + S keys to save.",
                modal: true,
                color: "warning"
            });
        }
        else {
            this.setState(prevState => ({
                modelInterpreterLanguage: {
                    EventType: 'insert',
                    IntLangID: null,
                    IntID: this.state.model.IntId,
                    IntLanguage: '',
                    CreateDateTime: null,
                    CreateByUserId: null,
                    UpdateByUserId: null,
                    UpdateDateTime: null,
                    IsDeletedFlg: false
                }
            }));
            this.setModalIntLanguage();
        }
        
    }

    handleEditLanguageClick(language) {
        this.setState(prevState => ({
            modelInterpreterLanguage: {
                EventType: 'update',
                IntLangID: language.intLangID,
                IntID: this.state.model.IntId,
                IntLanguage: language.intLanguage,
                CreateDateTime: language.createDateTime,
                CreateByUserId: language.createByUserId,
                UpdateByUserId: language.updateByUserId,
                UpdateDateTime: language.updateDateTime,
                IsDeletedFlg: language.isDeletedFlg
            }
        }));
        this.setModalIntLanguage();
    }

    handleSaveEventIntLanguageSuccess(alert) {
        this.setState({
            modalIntLanguage: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
                this.getLanguages();
            }, Timeout())
        });
    }

    setModalItem() {
        this.setState({ modalItem: !this.state.modalItem });
    }

    handleSaveEventItemSuccess(alert) {
        this.setState({
            modalItem: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
                this.getItems();
            }, Timeout())
        });
    }

    handleNewItemClick() {
        this.setState(prevState => ({
            modelInterpreterItem: {
                EventType: 'insert',
                IntItemId: null,
                IntId: this.state.model.IntId,
                ItemName: '',
                ItemDescription: '',
                Contact: '',
                ContactEmail: '',
                Notes: '',
                FileLocation: '',
                FileName: '',
                IntItemType: 'Note',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                FormFile: null
            }
        }));
        this.setModalItem();
    }

    handleEditItemClick(item) {
        this.setState(prevState => ({
            modelInterpreterItem: {
                EventType: 'update',
                IntItemId: item.intItemId,
                IntId: item.intId,
                ItemName: item.itemName,
                ItemDescription: item.itemDescription,
                Contact: item.contact,
                ContactEmail: item.contactEmail,
                Notes: item.notes,
                FileLocation: item.fileLocation,
                FileName: item.fileName,
                IntItemType: item.intItemType,
                CreateDateTime: item.createDateTime,
                CreateByUserId: item.createByUserId,
                UpdateByUserId: this.user.userid,
                UpdateDateTime: item.updateDateTime,
                IsDeletedFlg: item.isDeletedFlg,
                FormFile: null
            }
        }));
        this.setModalItem();
    }

    async handleItemViewClick(item) {
        let body = {
            FileLocationS3: item.fileLocationS3,
            FileName: item.fileName
        };

        await FetchFile("/getinterpreteritemfile", "POST", this.user.token, body).then(blob => {
            saveAs(blob, item.fileName);
        });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        } else if (e.keyCode === 76 && e.altKey) { // ALT + L
            let model = { ...this.state.model }
            model.DefaultLanguage = 'Spanish';
            this.setState({ model }, () => {

            });
        }
    }

    handleDeleteLanguage(language) {
        if (window.confirm("Are you sure you want to delete this Language?")) {
            let model = { ...this.state.modelInterpreterLanguage }
            model.EventType = 'delete';
            model.IntLangID = language.intLangID;
            model.IntID = language.intId;
            model.IntLanguage = language.intLanguage;
            model.CreateDateTime = language.createDateTime;
            model.CreateByUserId = language.createByUserId;
            model.UpdateByUserId = this.user.userid;
            model.UpdateDateTime = null;
            model.IsDeletedFlg = true;

            FetchData("/saveinterpreterlanguage", "POST", this.user.token, model).then(data => {
                this.handleSaveEventSuccess({
                    message: 'Record has been deleted successfully',
                    modal: true,
                    color: "success"
                });
                this.getLanguages();
            });
        }
    }

    handleDeleteItem(item) {
        if (window.confirm("You're about to delete this record. Are you sure?")) {
            let model = {
                ItemId: item.intItemId,
                UserId: this.user.userid
            };

            FetchData("/deleteinterpreteritem", "POST", this.user.token, model).then(data => {
                this.handleSaveEventSuccess({
                    message: 'Record has been deleted successfully',
                    modal: true,
                    color: "success"
                });
                this.getItems();
            });
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);

        if (this.props.model.IntId !== null) {
            this.getLanguages();
            this.getItems();
        }

        if (this.state.model.BusinessCardRequestDate !== null) {
            let model = { ...this.state.model }
            model.BusinessCardRequestDate = moment(this.state.model.BusinessCardRequestDate).format('YYYY-MM-DD');
            this.setState({ model });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    sortData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.interpreterItems;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }

    requestSort(pSortBy) {
        var sortBy = this.state.sortModelItems.SortBy;
        var sortOrder = this.state.sortModelItems.SortDirection;
        return event => {
            if (pSortBy === this.state.sortModelItems.SortBy) {
                sortOrder = sortOrder === "asc" ? "desc" : "asc";
            } else {
                sortBy = pSortBy;
                sortOrder = "asc";
            }
            var sortedItems = this.sortData(sortBy, sortOrder);
            this.setState({
                sortModelItems: {
                    SortBy: sortBy,
                    SortDirection: sortOrder
                },
                interpreterItems: sortedItems
            });
        };
    }

    renderLanguagesTable (interpreterLanguagesList) {
        return (
            <InterpreterFormTableLanguages
            	interpreterLanguagesList={interpreterLanguagesList}
            	handleDeleteLanguage={this.handleDeleteLanguage}
            	handleEditLanguageClick={this.handleEditLanguageClick} 
            ></InterpreterFormTableLanguages>
        )
    }

    renderInterpreterItemTable(interpreterItems){
        return (
            <InterpreterFormTableItems
                interpreterItems={interpreterItems}
                handleItemViewClick={this.handleItemViewClick}
                handleDeleteItem={this.handleDeleteItem}
                handleEditItemClick={this.handleEditItemClick}
            ></InterpreterFormTableItems>
        )
    }


    render() {

        let bankinfo ;
        bankinfo = <Row></Row>

    this.user = JSON.parse(GetCookie('user'));
    if (this.user.userrolename === "Administrator" || this.user.userrolename === "Super Administrator"){
        bankinfo = <Row>
        <Col className="col-md-3">
            <InputGroup size="sm">
                <InputGroupText>
                    BankRoute:
                </InputGroupText>
                <input type="text" name="BankRoute" className="form-control form-control-text" value={this.state.model.BankRoute} onChange={this.handleChange} maxLength={9} />
            </InputGroup>
        </Col>
        <Col className="col-md-3">
            <InputGroup size="sm">
                <InputGroupText>
                    BankAccount:
                </InputGroupText>
                <input type="text" name="BankAccount" className="form-control form-control-text" value={this.state.model.BankAccount} onChange={this.handleChange} maxLength={17} />
            </InputGroup>
        </Col>
        <Col className="col-md-3">
            <InputGroup size="sm">
                <InputGroupText>
                    IRS# / SSN#:
                </InputGroupText>
                <input type="text" name="IntSSINumber" className="form-control form-control-text" value={this.state.model.IntSSINumber} onChange={this.handleChange} maxLength={11} />
            </InputGroup>
        </Col>
    </Row>
    }

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>New/Edit Interpreter</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        <center>{this.state.alert.message}</center>
                                    </Alert>
                                </Col>
                            </Row>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={this.state.activeTab === '1' ? "active" : ""}
                                        onClick={() => { this.setState({ activeTab: '1' }) }}
                                    >
                                        Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === '2' ? "active" : ""} onClick={() => { this.setState({ activeTab: '2' }) }}>
                                        Languages
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === '3' ? "active" : ""} onClick={() => { this.setState({ activeTab: '3' }) }}>
                                        Schedules
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <hr />
                                    <Row>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText className="no-border-red" style={{ alignItems: 'unset' }}>
                                                    LastName:
                                                </InputGroupText>
                                                <input type="text" name="IntLastName" className="form-control form-control-text" value={this.state.model.IntLastName} maxLength={15} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText className="no-border-red" style={{ alignItems: 'unset' }}>
                                                    Firstname:
                                                </InputGroupText>
                                                <input type="text" name="IntFirstName" className="form-control form-control-text" value={this.state.model.IntFirstName} maxLength={25} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    IntId:
                                                </InputGroupText>
                                                <input type="text" name="IntFirstName" className="form-control form-control-text" readOnly={true} value={this.state.model.IntId} maxLength={25} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Default Language:
                                                </InputGroupText>
                                                <input type="text" name="DefaultLanguage" className="form-control form-control-text" value={this.state.model.DefaultLanguage} maxLength={100} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "63px" }}>
                                                    DBA:
                                                </InputGroupText>
                                                <input type="text" name="DBA" className="form-control form-control-text" value={this.state.model.DBA} maxLength={50} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "63px" }}>
                                                    Cell/Text:
                                                </InputGroupText>
                                                <input type="text" name="IntCellTxt" className="form-control form-control-text" value={this.state.model.IntCellTxt} maxLength={30} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Phone Only:
                                                </InputGroupText>
                                                <input type="text" name="IntPhoneOnly" className="form-control form-control-text" value={this.state.model.IntPhoneOnly} maxLength={30} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Fax:
                                                </InputGroupText>
                                                <input type="text" name="IntFax" className="form-control form-control-text" value={this.state.model.IntFax} maxLength={30} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Beeper:
                                                </InputGroupText>
                                                <input type="text" name="IntBeeper" className="form-control form-control-text" value={this.state.model.IntBeeper} maxLength={35} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText className="no-border-red" style={{ alignItems: 'unset', width: '63px' }}>
                                                    Email:
                                                </InputGroupText>
                                                <input type="text" name="IntEmail" className="form-control form-control-text" value={this.state.model.IntEmail} maxLength={200} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "63px" }}>
                                                    Gender:
                                                </InputGroupText>
                                                <select className="form-control form-control-text" value={this.state.model.IntGender} onChange={this.handleSelectGender}>
                                                    <option value=""></option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other/Not Specified">Other/Not Specified</option>
                                                </select>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "63px" }}>
                                                    Street:
                                                </InputGroupText>
                                                <input type="text" name="IntStreet" className="form-control form-control-text" value={this.state.model.IntStreet} maxLength={100} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    City:
                                                </InputGroupText>
                                                <input type="text" name="IntCity" className="form-control form-control-text" value={this.state.model.IntCity} maxLength={100} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    State:
                                                </InputGroupText>
                                                <input type="text" name="IntState" className="form-control form-control-text" value={this.state.model.IntState} maxLength={2} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Zip:
                                                </InputGroupText>
                                                <input type="text" name="IntZip" className="form-control form-control-text" value={this.state.model.IntZip} maxLength={11} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-1_8">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Corporation:
                                                </InputGroupText>
                                                <input name="IntCorporationFlg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.IntCorporationFlg} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Country:
                                                </InputGroupText>
                                                <input type="text" name="IntCountry" className="form-control form-control-text" value={this.state.model.IntCountry} maxLength={30} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Status:
                                                </InputGroupText>
                                                <input type="checkbox" name="IntActiveFlg" className="input-checkbox" defaultChecked={this.state.model.IntActiveFlg} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    {/*<Row>*/}
                                    {/*    <Col className="col-md-3">*/}
                                    {/*        <InputGroup size="sm">*/}
                                    {/*            <InputGroupText>*/}
                                    {/*                State1099:*/}
                                    {/*            </InputGroupText>*/}
                                    {/*            <input type="number" name="" className="form-control form-control-text" value={''} onChange={this.handleChange} />*/}
                                    {/*        </InputGroup>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col className="col-md-3">*/}
                                    {/*        <InputGroup size="sm">*/}
                                    {/*            <InputGroupText>*/}
                                    {/*                State1099Amt:*/}
                                    {/*            </InputGroupText>*/}
                                    {/*            <input type="number" name="" className="form-control form-control-text" value={''} onChange={this.handleChange} />*/}
                                    {/*        </InputGroup>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/* <Row>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    IRS# / SSN#:
                                                </InputGroupText>
                                                <input type="text" name="IntSSINumber" className="form-control form-control-text" value={this.state.model.IntSSINumber} onChange={this.handleChange} maxLength={11} />
                                            </InputGroup>
                                        </Col> */}
                                        {/*<Col className="col-md-3">*/}
                                        {/*    <InputGroup size="sm">*/}
                                        {/*        <InputGroupText>*/}
                                        {/*            OrigDate:*/}
                                        {/*        </InputGroupText>*/}
                                        {/*        <input type="date" name="" className="form-control form-control-text" value={''} onChange={this.handleChange} />*/}
                                        {/*    </InputGroup>*/}
                                        {/*</Col>*/}
                                        {/*<Col className="col-md-3">*/}
                                        {/*    <InputGroup size="sm">*/}
                                        {/*        <InputGroupText>*/}
                                        {/*            Updated:*/}
                                        {/*        </InputGroupText>*/}
                                        {/*        <input type="text" name="UpdateDateTime" readOnly className="form-control form-control-text" value={moment(this.state.model.UpdateDateTime).format('MM/DD/YYYY hh:mm A')} />*/}
                                        {/*    </InputGroup>*/}
                                        {/*</Col>*/}
                                    {/* </Row> */}
                                    
                                    {bankinfo} 

                                    <hr />
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Emergency Contact:
                                                </InputGroupText>
                                                <input type="text" name="EmergencyContact" className="form-control form-control-text" value={this.state.model.EmergencyContact} onChange={this.handleChange} maxLength={11} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-6">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "113px" }}>
                                                    Basic Rate:
                                                </InputGroupText>
                                                <input type="number" name="Rate" className="form-control form-control-text" value={this.state.model.Rate <= 0 ? "" : this.state.model.Rate} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Minimum:
                                                </InputGroupText>
                                                <select name="HourMinimum" className="form-control form-control-text" value={this.state.model.HourMinimum} onChange={this.handleChange}>
                                                    <option value={0}></option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                </select>
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Overtime:
                                                </InputGroupText>
                                                <select name="Prorate" className="form-control form-control-text" value={this.state.model.Prorate} onChange={this.handleChange}>
                                                    <option value=""></option>
                                                    <option value="15 min">15 min</option>
                                                    <option value="30 min">30 min</option>
                                                    <option value="1 hr">1 hr</option>
                                                    <option value="3 hrs">3 hrs</option>
                                                    <option value="Special">Special</option>
                                                </select>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "113px" }}>
                                                    Note:
                                                </InputGroupText>
                                                <textarea name="IntNote" cols={6} className="form-control form-control-text" onChange={this.handleChange}>{this.state.model.IntNote}</textarea>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "113px" }}>
                                                    Referred By:
                                                </InputGroupText>
                                                <textarea name="ReferredBy" cols={4} className="form-control form-control-text" onChange={this.handleChange}>{this.state.model.ReferredBy}</textarea>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "113px" }}>
                                                    Unavailable:
                                                </InputGroupText>
                                                <textarea name="UnavailableReason" cols={4} className="form-control form-control-text" onChange={this.handleChange}>{this.state.model.UnavailableReason}</textarea>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-1_5">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    InHouse:
                                                </InputGroupText>
                                                <input name="InHouseFlg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.InHouseFlg} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-1_5">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Unvetted:
                                                </InputGroupText>
                                                <input name="UnVettedFlg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.UnVettedFlg} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Business Cards Last Sent On:
                                                </InputGroupText>
                                                <input type="date" name="BusinessCardRequestDate" className="form-control form-control-text" value={this.state.model.BusinessCardRequestDate} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Add to Business Card To-Send List:
                                                </InputGroupText>
                                                <input name="BusinessCardRequestToProcessFlg" type="checkbox" className="input-checkbox" defaultChecked={this.state.model.BusinessCardRequestToProcessFlg} onChange={this.handleChange} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.handleNewItemClick}>New Item</Button>
                                    <Row className="row-padding-left-right-16">
                                        <Col className="col-12 x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: 'scroll', height: "140px", backgroundColor: 'unset' }}>
                                            {this.renderInterpreterItemTable(this.state.interpreterItems)}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <hr />
                                    <Row>
                                        <Col sm="12">
                                            <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.handleNewLanguageClick}><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}New</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                {this.renderLanguagesTable(this.state.interpreterLanguagesList)}
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <hr />
                                    <Row>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    Start Date:
                                                </InputGroupText>
                                                <input name="StartDate" type="date" className="form-control form-control-text" value={this.state.modelSchedule.StartDate} onChange={this.handleChangeSchedule} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-md-3">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    End Date:
                                                </InputGroupText>
                                                <input name="EndDate" type="date" className="form-control form-control-text" value={this.state.modelSchedule.EndDate} onChange={this.handleChangeSchedule} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    AM:
                                                </InputGroupText>
                                                <input name="SchedAm" type="checkbox" defaultChecked={this.state.modelSchedule.SchedAm} onChange={this.handleChangeSchedule} />
                                            </InputGroup>
                                        </Col>
                                        <Col className="col-2">
                                            <InputGroup size="sm">
                                                <InputGroupText>
                                                    PM:
                                                </InputGroupText>
                                                <input name="SchedPM" type="checkbox" defaultChecked={this.state.modelSchedule.SchedPM} onChange={this.handleChangeSchedule} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <InputGroup size="sm">
                                                <InputGroupText style={{ width: "63px" }}>
                                                    Notes:
                                                </InputGroupText>
                                                <textarea name="SchedNotes" cols={10} className="form-control form-control-text" onChange={this.handleChangeSchedule}>{this.state.modelSchedule.SchedNotes}</textarea>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm="12">
                                            <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={() => { if (window.confirm("Are you sure to save this record?")) { this.onSaveSchedule() } }}><img src={SaveIcon} style={{ width: "16px", height: "16px" }} alt="Delete" />{' '} Submit</Button>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    </ModalBody>
                    {this.state.activeTab === '1' &&
                        <ModalFooter>
                            <Button color="danger" onClick={() => { if (window.confirm("Are you sure you want to delete this record?")) { this.onDelete() } }} size="sm">Delete</Button>
                        </ModalFooter>
                    }
                </Modal>

                {this.state.modalIntLanguage &&
                    <InterpreterLanguageForm model={this.state.modelInterpreterLanguage} modal={this.state.modalIntLanguage} alert={this.state.alert} setModal={this.setModalIntLanguage} handleSaveEventSuccess={this.handleSaveEventIntLanguageSuccess} />
                }

                {this.state.modalItem &&
                    <InterpreterItemForm model={this.state.modelInterpreterItem} modal={this.state.modalItem} alert={this.state.alert} setModal={this.setModalItem} handleSaveEventSuccess={this.handleSaveEventItemSuccess} />
                }
            </div>
        )
    }
}