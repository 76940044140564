import React, { Component } from 'react';
import { Collapse, Navbar, NavItem, NavLink, NavbarBrand, NavbarToggler, Nav, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

import { FetchData } from './utils/DataFetch';
import { GetCookie, RemoveCookie } from './utils/Common';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            appversion: ''
        };
        this.user = JSON.parse(GetCookie('user'));

        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        // get app version
        FetchData("/getappversion", "POST", this.user.token, null).then(data => {
            this.setState(prevState => ({
                appversion: data
            }))
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleLogout() {
        fetch("/logout", {
            method: "POST"
        })
            .then(res => res.json())
            .then(
                (result) => {
                    RemoveCookie('user');
                    localStorage.removeItem('RemoteTypes');
                    localStorage.removeItem('RemoteHosts');
                    localStorage.removeItem('JobTypes');
                    localStorage.removeItem('JobInvLineCodes');
                    window.location.reload();
                },
                (error) => { }
            )
    }


    render() {
        var appVersion = this.state.appversion;

        return (
            <header>
                <Navbar id={this.user.userrolename === "Interpreter" ? "navbarInterpreter" : "navbarAdmin"} className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <NavbarBrand tag={Link} to="/">Executive Linguist Agency version: {appVersion}</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />

                    {this.user.userrolename === 'Interpreter' &&
                        <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} to="/int-job">Jobs and Payments</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/check-in">Share Your Schedule</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/faq">FAQ</NavLink>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
                                {/*</NavItem>*/}
                            </ul>
                        </Collapse>
                    }
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" navbar>
                        <Nav className="me-auto">
                            <UncontrolledDropdown inNavbar nav>
                                <DropdownToggle caret nav>
                                    {this.user.firstname + ' ' + this.user.lastname}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <a href="#" onClick={this.handleLogout}>
                                            Logout
                                        </a>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
