import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { FetchData, FetchFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import { saveAs } from 'file-saver';

import { ClientFormTableItems } from './ClientFormTableItems';
import { ClientItemForm } from './ClientItemForm';

export class ClientForm extends Component {
    static displayName = ClientForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,
            clientItemsList: [],
            modalItem: false,
            alert: props.alert,
            ClientItem: {
                EventType: 'insert',
                ClientItemId: null,
                ClientId: props.model.ClientId,
                ClientItemType: '',
                ClientItemName: '',
                ClientItemDescription: '',
                FileLocation: '',
                FileName: '',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                FormFile: null
            },
        }

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.setModalItem = this.setModalItem.bind(this);
        this.handleNewItemClick = this.handleNewItemClick.bind(this);
        this.handleItemViewClick = this.handleItemViewClick.bind(this);
        this.handleEditItemClick = this.handleEditItemClick.bind(this);
        this.handleSaveEventItemSuccess = this.handleSaveEventItemSuccess.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);

    }

    componentDidMount() {
        this.getItems();
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        FetchData("/saveclient", "POST", this.user.token, model).then(data => {
            this.props.handleSaveEventSuccess({
                message: data,
                modal: data === 'Success' ? false : true,
                color: data === 'Success' ? "success" : "warning"
            });
        });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    setModalItem() {
        this.setState({ modalItem: !this.state.modalItem });
    }

    getItems() {
        if (this.state.model.ClientId != null) {
            FetchData("/getclientitems", "POST", this.user.token, { ClientId: this.state.model.ClientId }).then(data => {
                this.setState({ clientItemsList: data });
            });
        }
        console.log(this.state.clientItemsList);
    }

    handleSaveEventItemSuccess(alert) {
        this.setState({
            modalItem: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
                this.getItems();
            }, 3000)
        });
    }

    handleEditItemClick(item) {
        this.setState(prevState => ({
            ClientItem: {
                EventType: 'update',
                ClientItemId: item.clientItemId,
                ClientId: item.clientId,
                ClientItemName: item.clientItemName,
                ClientItemDescription: item.clientItemDescription,
                FileLocation: item.fileLocation,
                FileLocationS3: item.fileLocationS3,
                FileName: item.fileName,
                ClientItemType: item.clientItemType,
                CreateDateTime: item.createDateTime,
                CreateByUserId: item.createByUserId,
                UpdateByUserId: this.user.userid,
                UpdateDateTime: item.updateDateTime,
                IsDeletedFlg: item.isDeletedFlg,
                FormFile: null
            }
        }));
        this.setModalItem();
    }

    handleNewItemClick() {
        this.setState(prevState => ({
            ClientItem: {
                EventType: 'insert',
                ClientItemId: null,
                ClientId: this.state.model.ClientId,
                ClientItemName: '',
                ClientItemDescription: '',
                FileLocation: '',
                FileLocationS3: '',
                FileName: '',
                ClientItemType: 'Note',
                CreateDateTime: null,
                CreateByUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                FormFile: null
            }
        }));
        this.setModalItem();
    }

    async handleItemViewClick(e, item) {
        e.preventDefault();

        let body = {
            FileLocationS3: item.fileLocationS3,
            FileName: item.fileName
        };

        await FetchFile("/getclientitemfile", "POST", this.user.token, body).then(blob => {
            saveAs(blob, item.fileName);
        });
    }

    handleDeleteItem(item) {
        if (window.confirm("You're about to delete this record. Are you sure?")) {
            let model = {
                ClientItemId: item.clientItemId,
                UserId: this.user.userid
            };

            FetchData("/deleteclientitem", "POST", this.user.token, model).then(data => {
                this.handleSaveEventSuccess({
                    message: 'Record has been marked as deleted',
                    modal: true,
                    color: "success"
                });
                this.getItems();
            });
        }
    }

    renderClientItems(clientItems) {
        return (
            <ClientFormTableItems
                clientItems={clientItems}
                handleDeleteItem={this.handleDeleteItem}
                handleItemViewClick={this.handleItemViewClick}
                handleEditItemClick={this.handleEditItemClick}
                handleNewItemClick={this.handleNewItemClick}
            />
        )
    }

    render() {

        let ccinfo ;
        if (this.user.userrolename !="Super Administrator")
        {
            ccinfo = <Row></Row>
        }
        else
        {ccinfo = <Row>
        <Col>
            <InputGroup size="sm">
                <InputGroupText>
                    Credit Card:
                </InputGroupText>
                <input name="ClientCreditCard" type="text" className="form-control form-control-text" value={this.state.model.ClientCreditCard} onChange={this.handleChange} />
            </InputGroup>
        </Col>
    </Row>}


        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>New/Edit Client</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.props.alert.color} isOpen={this.props.alert.isOpen} >
                                        {this.props.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Abbrv:
                                        </InputGroupText>
                                        <input type="text" name="ClientAbbrv" className="form-control form-control-text" value={this.state.model.ClientAbbrv} maxLength={15} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Name:
                                        </InputGroupText>
                                        <input type="text" name="ClientName" className="form-control form-control-text" value={this.state.model.ClientName} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Org:
                                        </InputGroupText>
                                        <input type="text" name="ClientOrg" className="form-control form-control-text" value={this.state.model.ClientOrg} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Phone:
                                        </InputGroupText>
                                        <input type="text" name="ClientPhone" className="form-control form-control-text" value={this.state.model.ClientPhone} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            800 Phone:
                                        </InputGroupText>
                                        <input type="text" name="ClientPhone2" className="form-control form-control-text" value={this.state.model.ClientPhone2} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Fax:
                                        </InputGroupText>
                                        <input type="text" name="ClientPhoneFax" className="form-control form-control-text" value={this.state.model.ClientPhoneFax} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Notes:
                                        </InputGroupText>
                                        <textarea rows="4" name="ClientNotes" className="form-control form-control-text" onChange={this.handleChange} value={this.state.model.ClientNotes}></textarea>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Invoice Notes:
                                        </InputGroupText>
                                        <textarea rows="4" name="ClientInvoiceNote" className="form-control form-control-text" maxLength={500} onChange={this.handleChange} value={this.state.model.ClientInvoiceNote}></textarea>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Email:
                                        </InputGroupText>
                                        <input type="text" name="ClientEmail" className="form-control form-control-text" value={this.state.model.ClientEmail} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Email For Invoice:
                                        </InputGroupText>
                                        <input type="text" name="ClientEmailForInvoice" className="form-control form-control-text" value={this.state.model.ClientEmailForInvoice} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Bill Rate Type:
                                        </InputGroupText>
                                        <select name="ClientBillRateType" value={this.state.model.ClientBillRateType} onChange={this.handleChange} className="form-control form-control-text">
                                            <option value=""></option>
                                            <option value="Standard">Standard</option>
                                            <option value="Special Billing">Special Billing</option>
                                        </select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Website:
                                        </InputGroupText>
                                        <input name="ClientWebsite" type="text" className="form-control form-control-text" value={this.state.model.ClientWebsite} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Inactive:
                                        </InputGroupText>
                                        <input name="ClientInactiveFlg" className="input-checkbox" type="checkbox" value={this.state.model.ClientInactiveFlg} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: '53px' }}>
                                            DCT:
                                        </InputGroupText>
                                        <input name="ClientDiscount" type="number" className="form-control form-control-text" value={this.state.model.ClientDiscount} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Updated:
                                        </InputGroupText>
                                        <input name="UpdateDateTime" type="text" className="form-control form-control-text" value={this.state.model.UpdateDateTime} readOnly="true" />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: '53px' }}>
                                            Mail St:
                                        </InputGroupText>
                                        <input name="ClientMailStreet" type="text" className="form-control form-control-text" value={this.state.model.ClientMailStreet} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Street:
                                        </InputGroupText>
                                        <input name="ClientMailStreet2" type="text" className="form-control form-control-text" value={this.state.model.ClientMailStreet2} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: '53px' }}>
                                            City:
                                        </InputGroupText>
                                        <input name="ClientMailCity" type="text" className="form-control form-control-text" value={this.state.model.ClientMailCity} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            State:
                                        </InputGroupText>
                                        <input name="ClientMailState" type="text" className="form-control form-control-text" value={this.state.model.ClientMailState} maxLength={2} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Zip:
                                        </InputGroupText>
                                        <input name="ClientMailZip" type="text" className="form-control form-control-text" value={this.state.model.ClientMailZip} maxLength={12} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Country:
                                        </InputGroupText>
                                        <input name="ClientCountry" type="text" className="form-control form-control-text" value={this.state.model.ClientCountry} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: '53px' }}>
                                            Act St:
                                        </InputGroupText>
                                        <input name="ClientStreet" type="text" className="form-control form-control-text" value={this.state.model.ClientStreet} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Street:
                                        </InputGroupText>
                                        <input name="ClientStreet2" type="text" className="form-control form-control-text" value={this.state.model.ClientStreet2} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: '53px' }}>
                                            City:
                                        </InputGroupText>
                                        <input name="ClientCity" type="text" className="form-control form-control-text" value={this.state.model.ClientCity} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            State:
                                        </InputGroupText>
                                        <input name="ClientState" type="text" className="form-control form-control-text" value={this.state.model.ClientState} maxLength={2} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Zip:
                                        </InputGroupText>
                                        <input name="ClientZip" type="text" className="form-control form-control-text" value={this.state.model.ClientZip} maxLength={12} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {ccinfo}
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Button className="btn-secondary-no-background form-control-text" size="sm" onClick={this.handleNewItemClick}>New Item</Button>
                                <Row className="row-padding-left-right-16">
                                    <Col className="col-12 x-panel-body x-layout-fit x-panel-body-default" style={{ overflowY: 'scroll', height: "140px", backgroundColor: 'unset' }}>
                                    {this.renderClientItems(this.state.clientItemsList)}
                                    </Col>
                                </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
                {this.state.modalItem &&
                    <ClientItemForm model={this.state.ClientItem} modal={this.state.modalItem} alert={this.state.alert} setModal={this.setModalItem} handleSaveEventSuccess={this.handleSaveEventItemSuccess} />
                }
            </div>
        )
    }
}