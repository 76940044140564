import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';
import * as CellRenderers from './AgGridCellRendererComponents';
const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: '', field: 'editDelete', cellRenderer: 'editDeleteCellRenderer', cellClass: 'x-grid-cell-inner', width: 50 },
    { headerName: 'Job Description', field: 'lineDescription', cellClass: 'x-grid-cell-inner', width: 300 },
    { headerName: 'Provider', field: 'linePersonnel', cellClass: 'x-grid-cell-inner', width: 300 },
    {
        headerName: 'Time', field: 'lineRate', cellClass: 'x-grid-cell-inner', width: 300, cellStyle: params => {
            if (params.node.rowPinned) {
                return { textAlign: 'right', fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: 'Amount', field: 'amount', cellClass: 'x-grid-cell-inner', valueFormatter: params => {
            const value = params.value;
            return value !== null && value !== undefined ? `$${value}` : '$0.00';
        }, width: 265
    }
];

export class WorkOrderFormTableReceivables extends Component {
    static displayName = WorkOrderFormTableReceivables.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            clickCount: 0,
            rowData: this.props.jobInvLine || [],
            subTotal: 0,
            total: 0,
            discount: 0,
            discountAmt: 0,
        };
        this.previousSelection = [];
        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
        this.updatePinnedBottomRowData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobInvLine !== this.props.jobInvLine) {
            this.setState({ rowData: this.props.jobInvLine }, this.updatePinnedBottomRowData);
        }
    }

    componentWillUnmount() {
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns(false);
        this.updatePinnedBottomRowData();
    };

    onDataLoaded = (newData) => {
        this.setState({ rowData: newData }, this.updatePinnedBottomRowData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    updatePinnedBottomRowData = () => {
        const subTotal = this.state.rowData.reduce((acc, row) => acc + (row.amount || 0), 0);
        let total = 0;
        let discount = this.props.state.model.JobDiscount == null ? 0 : this.props.state.model.JobDiscount;
        let discountAmount = subTotal * (discount / 100);
        total = subTotal - discountAmount;

        const pinnedBottomRowData = [
            {
                editDelete: '',
                lineDescription: '',
                linePersonnel: '',
                lineRate: 'Subtotal:',
                amount: `${subTotal.toFixed(2)}`,
                cellStyle: {
                    lineRate: { textAlign: 'right', fontWeight: 'bold' }
                }
            },
            {
                editDelete: '',
                lineDescription: '',
                linePersonnel: '',
                lineRate: 'Discount:',
                amount: `${discountAmount.toFixed(2)} (${discount}%)`,
                cellStyle: {
                    lineRate: { textAlign: 'right', fontWeight: 'bold' }
                }
            },
            {
                editDelete: '',
                lineDescription: '',
                linePersonnel: '',
                lineRate: 'Total:',
                amount: `${total.toFixed(2)}`,
                cellStyle: {
                    lineRate: { textAlign: 'right', fontWeight: 'bold' }
                }
            },
        ];
        this.setState({ subTotal: subTotal, total: total, discountAmt: discountAmount, discount: discount }, () => {
            if (this.gridApi) {
                this.gridApi.setPinnedBottomRowData(pinnedBottomRowData);
            }
        });
    };
    render() {

        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: this.state.rowData,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                state: this.props.state,
                handleEditJobDescriptionClick: this.props.handleEditJobDescriptionClick,
                handleDeleteJobDescriptionClick: this.props.handleDeleteJobDescriptionClick,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                editDeleteCellRenderer: CellRenderers.EditDeleteCellRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            pinnedBottomRowData: [
                {
                    editDelete: '',
                    lineDescription: '',
                    linePersonnel: '',
                    lineRate: 'Subtotal:',
                    amount: `${this.state.subTotal.toFixed(2)}`,
                    cellStyle: {
                        lineRate: { textAlign: 'right', fontWeight: 'bold' }
                    }
                },
                {
                    editDelete: '',
                    lineDescription: '',
                    linePersonnel: '',
                    lineRate: 'Discount:',
                    amount: `${this.state.discountAmt.toFixed(2)} (${this.state.discount}%)`,
                    cellStyle: {
                        lineRate: { textAlign: 'right', fontWeight: 'bold' }
                    }
                },
                {
                    editDelete: '',
                    lineDescription: '',
                    linePersonnel: '',
                    lineRate: 'Total:',
                    amount: `${this.state.total.toFixed(2)}`,
                    cellStyle: {
                        lineRate: { textAlign: 'right', fontWeight: 'bold' }
                    }
                },

            ]
            };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }
}
