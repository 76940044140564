import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup } from 'reactstrap';

import { RepForm } from './RepForm';
import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DeleteIcon from '../images/remove-icon.png'
import AddIcon from '../images/add.png'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';


const DEFAULT_MIN_WIDTH_CELL = 50;
const DEFAULT_MAX_WIDTH_CELL = 800;

const columns = [
    { headerName: "Rep Name", field: "repName", width: 150, cellRendererFramework: 'repNameCellRenderer' },
    { headerName: "Phone", field: "repPhone", width: 100 },
    { headerName: "Email", field: "repEmail", width: 200 },
    { headerName: "Client", field: "clientName", width: 200 },
    { headerName: "Notes", field: "repNote", width: 600 }
];

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

export class Rep extends Component {
    static displayName = Rep.name;

    constructor(props) {
        super(props);
        this.state = {
            repList: [],
            loading: false,
            fetchingData: false,
            modal: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                RepID: null,
                ClientID: null,
                RepLastName: '',
                RepFirstName: '',
                RepPhone: '',
                RepPhone2: '',
                RepNote: '',
                RepZip: '',
                RepEmail: '',
                RepInactiveFlg: false,
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: '',
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: '',
                ClientEmail: ''
            },
            searchModel: {
                RepName: '',
                RepPhone: '',
                Client: '',
                ClientAbbrv: '',
                Inactive: false,
                SortBy: 'repLastName',
                SortDirection: 'asc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));

        this.setModal = this.setModal.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderRepTable = this.renderRepTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        //this.populateRepData();

    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                RepID: null,
                ClientID: null,
                RepLastName: '',
                RepFirstName: '',
                RepPhone: '',
                RepPhone2: '',
                RepNote: '',
                RepZip: '',
                RepEmail: '',
                RepInactiveFlg: false,
                CreateDateTime: null,
                CreatebyUserId: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                ClientName: '',
                ClientAbbrv: '',
                ClientPhone: '',
                ClientPhone2: ''
            }
        }));

        this.setModal();
    }

    handleDoubleClick(repData) {
        this.setState(prevState => ({
            model: {
                EventType: 'update',
                ClientId: repData.data.clientId,
                RepID: repData.data.repID,
                ClientID: repData.data.clientID,
                RepLastName: repData.data.repLastName,
                RepFirstName: repData.data.repFirstName,
                RepPhone: repData.data.repPhone,
                RepPhone2: repData.data.repPhone2,
                RepNote: repData.data.repNote,
                RepZip: repData.data.repZip,
                RepEmail: repData.data.repEmail,
                RepInactiveFlg: repData.data.repInactiveFlg,
                CreateDateTime: repData.data.createDateTime,
                CreatebyUserId: repData.data.createbyUserId,
                UpdateByUserId: repData.data.updateByUserId,
                UpdateDateTime: repData.data.updateDateTime,
                IsDeletedFlg: repData.data.isDeletedFlg,
                ClientName: repData.data.clientName,
                ClientAbbrv: repData.data.clientAbbrv,
                ClientPhone: repData.data.clientPhone,
                ClientPhone2: repData.data.clientPhone2,
                ClientEmail: repData.data.clientEmail
            }
        }))
        this.setModal();
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateRepData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        if (e.target.name === "Inactive") {
            model[e.target.name] = !this.state.searchModel.Inactive;
        }
        else {
            model[e.target.name] = e.target.value;
        }

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRepData();
        }
    }

    async populateRepData() {
        this.setState({ fetchingData: true });

        FetchData("/getreps", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ repList: data, loading: false, fetchingData: false });
        });
    }

    renderRepTable(reps) {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: reps,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.setModal,
                state: this.state,

            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            frameworkComponents: {  // Register the cell renderer
                repNameCellRenderer: RepNameCellRenderer,

            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleDoubleClick,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }




    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRepTable(this.state.repList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Rep</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}</Button>
                            <input name="RepName" type="text" className="form-control form-control-text" value={this.state.searchModel.RepName} onChange={this.handleSearchChange} placeholder="RepName (Lastname Firstname)" onKeyPress={this.handleKeyPress} />
                            <input name="RepPhone" type="text" className="form-control form-control-text" value={this.state.searchModel.RepPhone} onChange={this.handleSearchChange} placeholder="Phone" onKeyPress={this.handleKeyPress} />
                            <input name="Client" type="text" className="form-control form-control-text" value={this.state.searchModel.Client} onChange={this.handleSearchChange} placeholder="Client" onKeyPress={this.handleKeyPress} />
                            <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleSearchChange} placeholder="Client Abbrv" onKeyPress={this.handleKeyPress} />
                            <span className="input-group-text">Inactive:</span><input name="Inactive" className="input-checkbox" type="checkbox" defaultChecked={this.state.searchModel.Inactive} onChange={this.handleSearchChange} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />

                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <RepForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}

class RepNameCellRenderer extends Component {
    render() {
        const { data } = this.props;  // ag-Grid passes the data prop automatically to the renderer
        const name = `${data.repLastName}, ${data.repFirstName}`;

        return (
            <span>{name}</span>
        );
    }
}
