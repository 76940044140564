import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup, InputGroupText } from 'reactstrap';

import { FetchData, FetchFile } from '../utils/DataFetch';
import { GetCookie, Timeout } from '../utils/Common';

import { saveAs } from 'file-saver';
import { RptClientInvoiceSummaryTableDefault } from './RptInterpreter1099jTableDefault';



const DEFAULT_MIN_WIDTH_CELL = 70;
const DEFAULT_MAX_WIDTH_CELL = 800;


export class RptInterpreter1099 extends Component {
    static displayName = RptInterpreter1099.name;

    constructor(props) {
        super(props);
        this.state = {
            rptInterpreter1099List: [],
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                StartDate: '',
                EndDate: ''
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';


        this.renderRptInterpreter1099Table = this.renderRptInterpreter1099Table.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.exportInterpreter1099TXT = this.exportInterpreter1099TXT.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        //this.populateRolodexData();
    }

    async exportInterpreter1099TXT() {
        let report = this.state.rptInterpreter1099List;

        var fileName = 'Interpreter1099report.csv';

        await FetchFile("/rptInterpreter1099", "POST", this.user.token, report).then(blob => {
            saveAs(blob, fileName);
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRptInterpreter1099();
        }
    }

    async populateRptInterpreter1099() {
        this.setState({ fetchingData: true });

        FetchData("/getrptinterpreter1099", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({rptInterpreter1099List: data, loading: false, fetchingData: false });
        });


    }

    renderRptInterpreter1099Table(rptInterpreter1099) {
        return (
            <RptClientInvoiceSummaryTableDefault 
                rptInterpreter1099={rptInterpreter1099}
            ></RptClientInvoiceSummaryTableDefault> 
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRptInterpreter1099Table(this.state.rptInterpreter1099List);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Interpreter 1099 Report</h5>
                <Row>
                    <Col className="col-3">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Start Date
                            </InputGroupText>
                            <input name="StartDate" ref={ref => this.txtStartDate = ref} type="date" className="form-control form-control-text"  value={this.state.searchModel.StartDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Start Date" onKeyPress={this.handleKeyPress} />
                            <InputGroupText>
                                End Date
                            </InputGroupText>
                           <input name="EndDate" ref={(input) => { this.EndDate = input; }} type="date" className="form-control form-control-text"  value={this.state.searchModel.EndDate} onChange={this.handleSearchChange} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="End Date" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup size="sm">
                            {' '}<span style={{ paddingRight: "4px" }}></span><Button color="secondary" className="btn" size="sm" outline onClick={this.exportInterpreter1099TXT}>Export Interpreter 1099 Report</Button>

                        </InputGroup>
                    </Col>
                </Row>
                <hr />



                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
