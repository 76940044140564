import React, { Component } from 'react';
import { Button, Row, Col, Alert, Nav, NavItem, NavLink, TabContent, TabPane, Input, InputGroup, InputGroupText } from 'reactstrap';


import { LoadingModal } from './LoadingModal';
import { FetchData} from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';
import { TransferCashTableFrom } from './TransferCashTableFrom';
import { TransferCashTableTo } from './TransferCashTableTo';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

const moment = require('moment');

export class TransferCash extends Component {
    static displayName = TransferCash.name;

    constructor(props) {
        super(props);
        this.state = {
            transferToList: [],
            transferFromList: [],
            modal: false,
            modalAutoBulk: false,
            fetchingData: false,
            isKeyPress: false,

            selectedIndex: -1,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModelFrom: {
                JobNum: null,
                Claimant: '',
                ClmNum: '',
                SortBy: 'jobNum',
                SortDirection: 'desc'
           },
            searchModelTo: {
                JobNum: null,
                Claimant: '',
                ClmNum: '',
                SortBy: 'jobNum',
                SortDirection: 'desc'
            },
            transferData: {
                FromJobNum: null,
                FromJobId: null,
                ToJobNum: null,
                ToJobId: null,
                TransferAmount: null,
                TransId: '',
                UserId: null
            },
            receivable: {
                EventType: 'insert',
                JobReceivableId: null,
                JobId: null,
                PaymentCompleteFlg: false,
                ActionCode: '',
                CheckDate: null,
                CheckNumber: '',
                Amount: null,
                TransferFrom: null,
                TransferTo: null,
                SelectedFlg: false,
                TransID: null,
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: false,
                //
                IsRefund: false,
                ClientCreditCard: '',
                JobNum: null,
                JobDateTime: null,
                ClientZip: ''
            },
        };
        this.user = JSON.parse(GetCookie('user'));

        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);

        this.handleKeyPressFrom = this.handleKeyPressFrom.bind(this);
        this.handleSearchChangeFrom = this.handleSearchChangeFrom.bind(this);
        this.handleKeyPressTo = this.handleKeyPressTo.bind(this);
        this.handleSearchChangeTo = this.handleSearchChangeTo.bind(this);
        this.handleFromClick = this.handleFromClick.bind(this);
        this.handleToClick = this.handleToClick.bind(this);
        this.handleChangeTransferAmount = this.handleChangeTransferAmount.bind(this);
        this.onSave = this.onSave.bind(this);

        this.renderTransferFromTable = this.renderTransferFromTable.bind(this);
        this.renderTransferToTable = this.renderTransferToTable.bind(this);
        //
        this.requestFromSort = this.requestFromSort.bind(this);
        this.sortFromData = this.sortFromData.bind(this);

        this.requestToSort = this.requestToSort.bind(this);
        this.sortToData = this.sortToData.bind(this);

        this.resetTransferData = this.resetTransferData.bind(this);
    }

    componentDidMount() {
        //    this.populateTransferFromData();
        //    this.populateTransferToData();
    }


    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } })
            }, Timeout(alert.message));

            this.populateTransferFromData();
            this.populateTransferToData();
            this.resetTransferData();

        });
    }

    handleSearchChangeFrom(e) {
        let model = { ...this.state.searchModelFrom }

        if (e.target.type === 'number' && (e.target.value !== null || e.target.value != ''))
            model[e.target.name] = parseInt(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ searchModelFrom: model });
    }

    handleKeyPressFrom(e) {
        if (e.key === 'Enter') {
            this.setState({ transferFromList: [], isKeyPress: true }, () => {
                this.populateTransferFromData();
            });
        }
    }

    handleSearchChangeTo(e) {
        let model = { ...this.state.searchModelTo }

        if (e.target.type === 'number' && (e.target.value !== null || e.target.value != ''))
            model[e.target.name] = parseInt(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ searchModelTo: model });
    }

    handleKeyPressTo(e) {
        if (e.key === 'Enter') {
            this.setState({ transferToList: [], isKeyPress: true }, () => {
                this.populateTransferToData();
            });
        }
    }

    handleFromClick(fromData) {
        let model = { ...this.state.transferData }

        model.FromJobNum = fromData.data.jobNum;
        model.FromJobId = fromData.data.jobId;

        this.setState({ transferData: model });

    }

    handleToClick(toData) {
        let model = { ...this.state.transferData }

        model.ToJobNum = toData.data.jobNum;
        model.ToJobId = toData.data.jobId;

        this.setState({ transferData: model });

    }

    handleChangeTransferAmount(e) {
        let model = { ...this.state.transferData }

        if (e.target.type === "number")
            model[e.target.name] = e.target.value === null || e.target.value === "" ? null : parseFloat(e.target.value);
        else
            model[e.target.name] = e.target.value;

        this.setState({ transferData: model });

    }

    onSave() {
        let model = { ...this.state.transferData }
        model.UserId = this.user.userid;

        //Generate the Transaction Id (TransId) from the current seconds + ToJobNum
        let date = new Date();
        let string = new String();
        let transId = string.concat(date.getSeconds(), model.ToJobNum);
        model.TransId = transId;

        if (this.state.transferData.FromJobNum === null || this.state.transferData.FromJobNum === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please choose an Invoice to transfer from.',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });

        } else if (this.state.transferData.ToJobNum === null || this.state.transferData.ToJobNum === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please choose an Invoice to transfer to',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });
        } else if (this.state.transferData.TransferAmount === null || this.state.transferData.TransferAmount === '') {
            this.setState({
                alert: {
                    color: "warning",
                    message: 'Please enter an Amount to transfer',
                    isOpen: true
                }
            }, () => {
                window.setTimeout(() => {
                    this.setState({ alert: { isOpen: false } });
                }, Timeout(alert.message));
            });
        } else {
            FetchData("/TransferCash", "POST", this.user.token, model).then(data => {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            });
            //console.log(this.state.transferData);
        }
    }


    sortFromData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.transferFromList;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }

    requestFromSort(pSortBy) {
        var sortBy = this.state.searchModelFrom.SortBy;
        var sortOrder = this.state.searchModelFrom.SortDirection;
        return event => {
            if (pSortBy === this.state.searchModelFrom.SortBy) {
                sortOrder = sortOrder === "asc" ? "desc" : "asc";
            } else {
                sortBy = pSortBy;
                sortOrder = "asc";
            }
            var sortedItems = this.sortFromData(sortBy, sortOrder);
            this.setState({
                searchModelFrom: {
                    SortBy: sortBy,
                    SortDirection: sortOrder
                },
                transferFromList: sortedItems
            });
        };
    }

    sortToData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.transferToList;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }

    requestToSort(pSortBy) {
        var sortBy = this.state.searchModelTo.SortBy;
        var sortOrder = this.state.searchModelTo.SortDirection;
        return event => {
            if (pSortBy === this.state.searchModelTo.SortBy) {
                sortOrder = sortOrder === "asc" ? "desc" : "asc";
            } else {
                sortBy = pSortBy;
                sortOrder = "asc";
            }
            var sortedItems = this.sortToData(sortBy, sortOrder);
            this.setState({
                searchModelTo: {
                    SortBy: sortBy,
                    SortDirection: sortOrder
                },
                transferToList: sortedItems
            });
        };
    }

    resetTransferData() {
        let model = { ...this.state.transferData };

        model.FromJobNum = null;
        model.FromJobId = null;
        model.ToJobNum = null;
        model.ToJobId = null;
        model.TransferAmount = null;
        model.TransId = '';
        model.UserId = null;

        this.setState({ transferData: model });

        this.txtAmount.value = null;
        this.txtFromJobNum.value = '';
        this.txtToJobNum.value = '';
    }

    async populateTransferFromData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModelFrom }

        this.setState({ searchModelFrom: model }, () => {
            FetchData("/GetTransferCash", "POST", this.user.token, model).then(data => {
                if (this.state.isKeyPress && this.state.searchModelFrom.JobNum != null) {
                    if (data.length === 0) {
                        this.setState({
                            alert: {
                                color: "warning",
                                message: 'There is no return on your search.',
                                isOpen: true
                            }
                        }, () => {
                            window.setTimeout(() => {
                                this.setState({ alert: { isOpen: false } });
                            }, Timeout(alert.message));
                        });
                    }
                    //    else {
                    //        var job = data[0];
                    //        this.setData(job);
                    //        this.setModal();
                    //    }
                }

                this.setState({ transferFromList: data, loading: false, fetchingData: false, isKeyPress: false });
            });
        });
    }

    async populateTransferToData() {
        this.setState({ fetchingData: true });

        let model = { ...this.state.searchModelTo }

        this.setState({ searchModelTo: model }, () => {
            FetchData("/GetTransferCash", "POST", this.user.token, model).then(data => {
                if (this.state.isKeyPress && this.state.searchModelTo.JobNum != null) {
                    if (data.length === 0) {
                        this.setState({
                            alert: {
                                color: "warning",
                                message: 'There is no return on your search.',
                                isOpen: true
                            }
                        }, () => {
                            window.setTimeout(() => {
                                this.setState({ alert: { isOpen: false } });
                            }, Timeout(alert.message));
                        });
                    }
                    //    else {
                    //        var job = data[0];
                    //        this.setData(job);
                    //        this.setModal();
                    //    }
                }

                this.setState({ transferToList: data, loading: false, fetchingData: false, isKeyPress: false });
            });
        });
    }

    renderTransferFromTable(transferFrom) {

        return (
            <TransferCashTableFrom
                state={this.state}
                transferFrom={this.state.transferFromList}
                handleFromClick={this.handleFromClick}
            ></TransferCashTableFrom>
        );
    }

    renderTransferToTable(transferTo) {

        return (
            <TransferCashTableTo
                state={this.state}
                transferTo={this.state.transferToList}
                handleToClick={this.handleToClick}
            ></TransferCashTableTo>
        );
    }

   render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTransferFromTable(this.state.transferFromList);

        let contents2 = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTransferToTable(this.state.transferToList);

        return (
            <div style={{ paddingTop: "12px" }}>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>

                <LoadingModal fetchingData={this.state.fetchingData} />

                <Row>
                    <Col className="col-6" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <h5>Transfer From</h5>
                        <Row>
                            <Col style={{ width: '95%' }} >
                                <InputGroup size="sm">
                                    <input name="JobNum" type="number" className="form-control form-control-text" value={this.state.searchModelFrom.JobNum} onChange={this.handleSearchChangeFrom} onFocus={this.handleSearchFocus} placeholder="Invoice #" onKeyPress={this.handleKeyPressFrom} />
                                    <input name="Claimant" type="text" className="form-control form-control-text" value={this.state.searchModelFrom.Claimant} onChange={this.handleSearchChangeFrom} onFocus={this.handleSearchFocus} placeholder="Claimant" onKeyPress={this.handleKeyPressFrom} />
                                    <input name="ClaimantNum" type="text" className="form-control form-control-text" value={this.state.searchModelFrom.ClmNum} onChange={this.handleSearchChangeFrom} onFocus={this.handleSearchFocus} placeholder="Claimant #" onKeyPress={this.handleKeyPressFrom} />
                                </InputGroup>
                                {contents}
                            </Col>
                        </Row>

                    </Col>
                    <Col className="col-6" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <h5>Transfer To</h5>
                        <Row>
                            <Col style={{ width: '95%' }} >
                                <InputGroup size="sm">
                                    <input name="JobNum" type="number" className="form-control form-control-text" value={this.state.searchModelTo.JobNum} onChange={this.handleSearchChangeTo} onFocus={this.handleSearchFocus} placeholder="Invoice #" onKeyPress={this.handleKeyPressTo} />
                                    <input name="Claimant" type="text" className="form-control form-control-text" value={this.state.searchModelTo.Claimant} onChange={this.handleSearchChangeTo} onFocus={this.handleSearchFocus} placeholder="Claimant" onKeyPress={this.handleKeyPressTo} />
                                    <input name="ClaimantNum" type="text" className="form-control form-control-text" value={this.state.searchModelTo.ClmNum} onChange={this.handleSearchChangeTo} onFocus={this.handleSearchFocus} placeholder="Claimant #" onKeyPress={this.handleKeyPressTo} />
                                </InputGroup>
                                {contents2}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <center>
                            <p style={{ color: 'red', fontWeight: 'bold', fontSize: '13px' }}>WARNING: You are transfering money to a different Client.</p>
                        </center>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Transfer From Job Id:
                            </InputGroupText>
                            <input disabled={true} name="FromJobNum" value={this.state.transferData.FromJobNum} className="form-control-text" placeholder="Job Id From" ref={(input) => { this.txtFromJobNum = input; }} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Transfer To Job Id:
                            </InputGroupText>
                            <input disabled={true} name="ToJobNum" value={this.state.transferData.ToJobNum} className="form-control-text" placeholder="Job Id To" ref={(input) => { this.txtToJobNum = input; }} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Amount:
                            </InputGroupText>
                            <input name="TransferAmount" onChange={this.handleChangeTransferAmount} value={this.state.transferData.TransferAmount} type="number" className="form-control-text" ref={(input) => { this.txtAmount = input; }} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>

                            </InputGroupText>
                            <Button color="secondary" onClick={() => { if (window.confirm("Are you sure you want to transfer money From JobId: " + this.state.transferData.FromJobNum + " to JobId: " + this.state.transferData.ToJobNum + "?")) { this.onSave() } }} className="btn" outline size="sm">Transfer</Button>
                        </InputGroup>
                    </Col>
                </Row>
                <br />
                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        {contentDetails}*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </div>
        );

    }
}
