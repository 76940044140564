import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';
import DeleteIcon from '../images/delete.png'

export class ClientItemForm extends Component {
    static displayName = ClientItemForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,
            alert: {
                color: '',
                message: '',
                isOpen: false
            }
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
    }

    handleChange(e) {
        let model = { ...this.state.model };
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    handleUploadFile(e) {
        let model = { ...this.state.model };
        model.FileName = e.target.files[0].name;
        model.FormFile = e.target.files[0];

        this.setState({ model });
    }

    handleDeleteFile() {
        this.fileUpload.value = null;

        let model = { ...this.state.model };
        model.FileName = '';
        model.FileLocation = '';

        this.setState({ model });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    async onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        var formData = new FormData();
        formData.append("EventType", model.EventType);
        formData.append("ClientItemId", model.ClientItemId);
        formData.append("ClientId", model.ClientId);
        formData.append("ClientItemName", model.ClientItemName);
        formData.append("ClientItemDescription", model.ClientItemDescription);  // Corrected the state property
        formData.append("FileLocation", model.FileLocation);
        formData.append("FileName", model.FileName);
        formData.append("ClientItemType", model.ClientItemType);
        formData.append("CreateDateTime", model.CreateDateTime);
        formData.append("CreateByUserId", model.CreateByUserId);
        formData.append("UpdateByUserId", model.UpdateByUserId);
        formData.append("UpdateDateTime", model.UpdateDateTime);
        formData.append("IsDeletedFlg", model.IsDeletedFlg);
        formData.append("FormFile", model.FormFile);

        UploadFile("/saveclientitem", this.user.token, formData).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning",
                    model: model
                })
            } else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "warning"
                })
            }
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "150px", width: "500px" }} backdrop="static">
                <ModalHeader>New/Edit Client Item</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "70px" }}>
                                        Type:
                                    </InputGroupText>
                                    <select name="ClientItemType" value={this.state.model.ClientItemType} className="form-control" onChange={this.handleChange} >
                                        <option value="Note">Note</option>
                                        <option value="Attachment">Attachment</option>
                                    </select>
                                </InputGroup>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText>
                                        Description:
                                    </InputGroupText>
                                    <textarea name="ClientItemDescription" value={this.state.model.ClientItemDescription} className="form-control" onChange={this.handleChange}></textarea>  {/* Corrected name attribute */}
                                </InputGroup>
                            </Col>
                        </Row>
                        {this.state.model.ClientItemType === "Attachment" && <hr />}
                        {this.state.model.ClientItemType === "Attachment" && <Row>
                            <Col className={this.state.model.FileName === '' ? "col-12" : "col-11"}>
                                <input id="file" type="file" name="fileUpload" className="form-control" onChange={this.handleUploadFile} ref={ref => this.fileUpload = ref} />
                                <span style={{ left: '20px', top: '50px', position: 'absolute' }} >{this.state.model.FileName === "" ? "Drag your file here!" : this.state.model.FileName}</span>
                            </Col>
                            <Col className="col-1">
                                <img src={DeleteIcon} style={{ width: "14px", height: "14px", cursor: 'pointer', display: this.state.model.FileName === '' ? 'none' : null }} onClick={this.handleDeleteFile} alt="Delete" />
                            </Col>
                        </Row>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                    <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
