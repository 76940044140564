import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, InputGroup, InputGroupText } from 'reactstrap';

import { FetchData } from '../utils/DataFetch';
import { GetCookie, Timeout } from '../utils/Common';
import { RptAccountsReceivableTableByAge } from './RptAccountsReceivableTableByAge'

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";
import { RptAccountsReceivableTableByCompany } from './RptAccountsReceivableTableByCompany';


export class RptAccountsReceivable extends Component {
    static displayName = RptAccountsReceivable.name;

    constructor(props) {
        super(props);
        this.state = {
            rptAccountsReceivable: null,
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            searchModel: {
                ClientABBRV: '',
                StartDate: '',
                EndDate: ''
            }

        };
        this.user = JSON.parse(GetCookie('user'));

        this.renderRptAccountsReceivableByCompanyTable = this.renderRptAccountsReceivableByCompanyTable.bind(this);
        this.renderRptAccountsReceivableByAgeTable = this.renderRptAccountsReceivableByAgeTable.bind(this);
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        this.populateRptAccountsReceivableData();
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateRptAccountsReceivableData();
        }
    }


    renderRptAccountsReceivableByCompanyTable(rptAccountsReceivable) {
        if (!rptAccountsReceivable || !rptAccountsReceivable.accountsReceivableByCompany) {
            // Add a condition to handle the case when rptAccountsReceivable is null or undefined
            return null; // or handle it accordingly
        }

        return (
            <RptAccountsReceivableTableByCompany
                rptAccountsReceivableByCompany={rptAccountsReceivable.accountsReceivableByCompany}
                state={this.state}
            ></RptAccountsReceivableTableByCompany>
        );
    }
    renderRptAccountsReceivableByAgeTable(rptAccountsReceivable) {
        if (!rptAccountsReceivable || !rptAccountsReceivable.accountsReceivableByAge) {
            // Add a condition to handle the case when rptAccountsReceivable is null or undefined
            return null; // or handle it accordingly
        }

        return (
            <RptAccountsReceivableTableByAge
                rptAccountsReceivableByAge={rptAccountsReceivable.accountsReceivableByAge}
                state={this.state}
            ></RptAccountsReceivableTableByAge>
        );
    }

    async populateRptAccountsReceivableData() {
        this.setState({ fetchingData: true });

        FetchData("/getrptaccountsreceivable", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ rptAccountsReceivable: data, loading: false, fetchingData: false });
            //let test = FetchData("/getrptaccountsreceivable", "POST", this.user.token, this.state.searchModel);

        });

        console.log(this.state.rptAccountsReceivable);
    }


    render() {
        let byAgeContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderRptAccountsReceivableByAgeTable(this.state.rptAccountsReceivable);

        let byCompanyContents = this.state.loading 
            ? <p><em>Loading...</em></p> 
            : this.renderRptAccountsReceivableByCompanyTable(this.state.rptAccountsReceivable);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Accounts Receivable Report</h5>

                <hr />

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                By Age:
                    {byAgeContents}
                </div>
                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                By Company:
                    {byCompanyContents}
                </div>
            </div>
        );

    }
}
