import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

export class InterpreterLanguageForm extends Component {
    static displayName = InterpreterLanguageForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
    }

    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        FetchData("/saveinterpreterlanguage", "POST", this.user.token, model).then(data => {
            this.props.handleSaveEventSuccess({
                message: data,
                modal: data === 'Success' ? false : true,
                color: data === 'Success' ? "success" : "warning"
            });
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        } else if (e.keyCode === 76 && e.altKey) { // ALT + L
            let model = { ...this.state.model }
            model.IntLanguage = 'Spanish';
            this.setState({ model }, () => {

            });
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "600px" }} backdrop="static">
                <ModalHeader>Language Form</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <Alert color={this.props.alert.color} isOpen={this.props.alert.isOpen} >
                                    {this.props.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText>
                                        Language:
                                    </InputGroupText>
                                    <input type="text" name="IntLanguage" className="form-control form-control-text" value={this.state.model.IntLanguage} maxLength={50} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                           
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                    <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                    {/*<Button color="primary" onClick={() => { if (window.confirm("Are you sure to save this record?")) { this.onSave() } }} size="sm">Save</Button>*/}
                </ModalFooter>
            </Modal>
        )
    }
}