import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import '../ag-theme-ela.css';

import * as CellRenderers from '../AgGridCellRendererComponents';

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: "0 to 30", field: "acSum0to30", width: null },
    { headerName: "31 to 60", field: "acSum31to60", width: null },
    { headerName: "61 to 90", field: "acSum61to90", width: null },
    { headerName: "91 to 120", field: "acSum91to120", width: null },
    { headerName: "Over 120", field: "acSumOver120", width: null }
];

export class RptAccountsReceivableTableByAge extends Component {
    static displayName = RptAccountsReceivableTableByAge.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: columns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            clickCount: 0,
        };
        this.previousSelection = [];
        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimer);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rptAccountsReceivableByAge !== this.props.rptAccountsReceivableByAge) {
            this.onDataLoaded(this.props.rptAccountsReceivableByAge);
        }
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns(false);
        this.onDataLoaded(this.props.rptAccountsReceivableByAge); // Load the initial data
    };

    onDataLoaded = (newData) => {
        const rowData = Array.isArray(newData) ? newData : [newData];
        this.gridApi.setRowData(rowData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    render() {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: Array.isArray(this.props.rptAccountsReceivableByAge) ? this.props.rptAccountsReceivableByAge : [this.props.rptAccountsReceivableByAge],            context: {
                componentParent: this,
                gridApi: this.gridApi,
                state: this.props.state,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {},
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                    rowSelection={"multiple"}
                />
            </div>
        );
    }
}
