import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Input, Row, Col, Table, Alert } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DeleteIcon from '../images/delete.png'

const moment = require('moment');

export class TimeOffForm extends Component {
    static displayName = TimeOffForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,

            timeOffTypeList: JSON.parse(localStorage.getItem('TimeOffTypes')),
            timeOffUserList: JSON.parse(localStorage.getItem('TimeOffUsers')),
            timeOffStatusList: JSON.parse(localStorage.getItem('TimeOffStatuses')),
            email: {
                EmailFrom: '',
                EmailTo: '',
                EmailCC: '',
                Subject: '',
                Body: '',
                JobIds: ''
            },
            alert: props.alert
        };

        this.onSave = this.onSave.bind(this);
        this.onSaveAndEmail = this.onSaveAndEmail.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
    }

    onSave() {
        if (window.confirm("Are you sure you want save this record?")) {
            let model = this.state.model;

            if (model.EventType === "insert") {
                model.CreateByUserId = this.user.userid;
            } else if (model.EventType === "update") {
                model.UpdateByUserId = this.user.userid;
            }

            if (model.PartialDayFlg === false) {
                model.Hours = 8;
            }

            FetchData("/saveupdatedeletetimeoff", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }
            });

        }
    }


    onSaveAndEmail() {
        if (window.confirm("Are you sure you want save this record?")) {
            let model = this.state.model;

            if (model.EventType === "insert") {
                model.CreateByUserId = this.user.userid;
            } else if (model.EventType === "update") {
                model.UpdateByUserId = this.user.userid;
            }

            if (model.PartialDayFlg === false) {
                model.Hours = 8;
            }

            FetchData("/sendtimeoffemailandsave", "POST", this.user.token, model).then(data => {
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }
            });

        }
    }

    onDelete() {
        let model = this.state.model;
        model.EventType = 'delete';
        model.IsDeletedFlg = true;
        model.UpdateByUserId = this.user.userid;

        FetchData("/saveupdatedeletetimeoff", "POST", this.user.token, model).then(data => {
            if (data === 'Success') {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: data === 'Success' ? false : true,
                    color: data === 'Success' ? "success" : "warning"
                });
            } else {
                this.handleSaveEventSuccess({
                    message: data,
                    modal: true,
                    color: "warning"
                });
            }
        });
    }
    

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseFloat(e.target.value);
            else
                model[e.target.name] = null;
        }
        else
            { model[e.target.name] = e.target.value };

        this.setState({ model }, this.test);
    }


    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    //handleSaveAndEmailClick() {
    //    let model = this.state.model;

    //    FetchData("/sendtimeoffemailandsave", "POST", this.user.token, model).then(data => {
    //        console.log(data);

    //        this.props.handleSaveEventSuccess({
    //            message: data === 'Success' ? "Time Off Request has been successfully saved and emailed." : data,
    //            modal: data === 'Success' ? false : true,
    //            color: data === 'Success' ? "success" : "warning"
    //        });

    //    });

    //}

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>Add Time Off</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        {this.state.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            From Date:
                                        </InputGroupText>
                                        <input name="FromDate" type="date" className="form-control form-control-text" value={this.state.model.FromDate === null ? null : moment(this.state.model.FromDate).format('YYYY-MM-DD')} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Partial:
                                        </InputGroupText>
                                        <input type="checkbox" name="PartialDayFlg" className="input-checkbox" defaultChecked={this.state.model.PartialDayFlg} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            To Date:
                                        </InputGroupText>
                                        <input name="ToDate" type="date" className="form-control form-control-text" value={this.state.model.ToDate === null ? null : moment(this.state.model.ToDate).format('YYYY-MM-DD')} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    {this.state.model.PartialDayFlg &&
                                        <InputGroup size="sm">
                                            <InputGroupText style={{ width: "63px" }}>
                                                Hours:
                                            </InputGroupText>
                                            <select className="form-control form-control-text" value={this.state.model.Hours} onChange={this.handleChange}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                            </select>
                                        </InputGroup>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <col className="col-3"></col>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "63px" }}>
                                            Type:
                                        </InputGroupText>
                                        <select name="TypeId" className="form-control form-control-text" value={this.state.model.TypeId} onChange={this.handleChange} >
                                            <option value={null}></option>
                                            {this.state.timeOffTypeList.map((type) => 
                                                <option value={type.lookupsId}>{type.lookupsValue}</option>
                                            )}
                                        </select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "63px" }}>
                                            User:
                                        </InputGroupText>
                                        <select name="UserId" className="form-control form-control-text" value={this.state.model.UserId} onChange={this.handleChange}>
                                            <option value=""></option>
                                            {this.state.timeOffUserList.map((user) =>
                                                <option value={user.userId}>{user.timeOffUserFullName}</option>
                                            )}
                                       </select>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "63px" }}>
                                            Status:  
                                        </InputGroupText>
                                        <select name="StatusId" className="form-control form-control-text" value={this.state.model.StatusId} onChange={this.handleChange}>
                                            <option value=""></option>
                                            {this.state.timeOffStatusList.map((status) =>
                                                <option value={status.lookupsId}>{status.lookupsValue}</option>
                                            )}
                                        </select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText className="no-border">
                                            Notes:
                                        </InputGroupText>
                                        <textarea name="Notes" rows="7" className="no-border form-control-text" style={{ width: "100%" }} onChange={this.handleChange}>{this.state.model.Notes}</textarea>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onSaveAndEmail} size="sm">Save and Email</Button>
                        <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                        {this.state.model.EventType !== "insert" &&
                            <Button color="dark" onClick={this.onDelete} size="sm">Delete</Button>
                        }
                   </ModalFooter>
                </Modal>
            </div>
        )
    }
}