import React, { Component } from 'react';
import { Button, Row, Col, Alert, InputGroup } from 'reactstrap';

import { UserForm } from './UserForm';
import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png'

import { UserTableDefault } from './UserTableDefault';
export class User extends Component {
    static displayName = User.name;

    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            loading: false,
            fetchingData: false,
            modal: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                EventType: 'insert',
                UserID: null,
                UserRoleName: '',
                FirstName: '',
                LastName: '',
                Email: '',
                EmailConfirmedflg: null,
                PasswordHash: '',
                SecurityStamp: '',
                PhoneNumber: '',
                PhoneNumberConfirmedflg: null,
                TwoFactorEnabledflg: null,
                LockoutEndDateUtc: null,
                LockoutEnabledflg: null,
                AccessFailedCount: null,
                UserName: '',
                UnhashPassword: '',
                UserType: '',
                IntId: null,
                InActiveflg: null,
                ResetPasswordConfirmflg: null,
                Config: '',
                Country: '',
                City: '',
                Phone: '',
                Department: '',
                PhoneAlt: '',
                TimeZone: '',
                MFACode: '',
                MFACodeExpirationDateTime: '',
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: null
            },
            searchModel: {
                UserId: '',
                UserFullName: '',
                UserName: '',
                IntId: null,
                SortBy: 'lastName',
                SortDirection: 'asc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.setModal = this.setModal.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.resetFields = this.resetFields.bind(this);

        this.renderUserTable = this.renderUserTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        //this.populateUserData();

    }

    setModal() {
        this.setState({ modal: !this.state.modal });
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                EventType: 'insert',
                UserID: null,
                UserRoleName: '',
                FirstName: '',
                LastName: '',
                Email: '',
                EmailConfirmedflg: null,
                PasswordHash: '',
                SecurityStamp: '',
                PhoneNumber: '',
                PhoneNumberConfirmedflg: null,
                TwoFactorEnabledflg: null,
                LockoutEndDateUtc: null,
                LockoutEnabledflg: null,
                AccessFailedCount: null,
                UserName: '',
                UnhashPassword: '',
                UserType: '',
                IntId: null,
                InActiveflg: null,
                ResetPasswordConfirmflg: null,
                Config: '',
                Country: '',
                City: '',
                Phone: '',
                Department: '',
                PhoneAlt: '',
                TimeZone: '',
                MFACode: '',
                MFACodeExpirationDateTime: '',
                CreateByUserId: null,
                CreateDateTime: null,
                UpdateByUserId: null,
                UpdateDateTime: null,
                IsDeletedFlg: null
            },
        }));

        this.setModal();
    }

    handleDoubleClick(userData) {
        this.setState(prevState => ({
            model: {
                EventType: 'update',
                UserID: userData.data.userID,
                UserRoleName: userData.data.userRoleName,
                FirstName: userData.data.firstName,
                LastName: userData.data.lastName,
                Email: userData.data.email,
                EmailConfirmedflg: userData.data.emailConfirmedflg,
                PasswordHash: userData.data.passwordHash,
                SecurityStamp: userData.data.securityStamp,
                PhoneNumber: userData.data.phoneNumber,
                PhoneNumberConfirmedflg: userData.data.phoneNumberConfirmedflg,
                TwoFactorEnabledflg: userData.data.twoFactorEnabledflg,
                LockoutEndDateUtc: userData.data.lockoutEndDateUtc,
                LockoutEnabledflg: userData.data.lockoutEnabledflg,
                AccessFailedCount: userData.data.accessFailedCount,
                UserName: userData.data.userName,
                UnhashPassword: userData.data.unhashPassword,
                UserType: userData.data.userType,
                IntId: userData.data.intId,
                InActiveflg: userData.data.inActiveflg,
                ResetPasswordConfirmflg: userData.data.resetPasswordConfirmflg,
                Config: userData.data.config,
                Country: userData.data.country,
                City: userData.data.city,
                Phone: userData.data.phone,
                Department: userData.data.department,
                PhoneAlt: userData.data.phoneAlt,
                TimeZone: userData.data.timeZone,
                MFACode: userData.data.mfaCode,
                MFACodeExpirationDateTime: userData.data.mfaCodeExpirationDateTime,
                CreateByUserId: userData.data.createByUserId,
                CreateDateTime: userData.data.createDateTime,
                UpdateByUserId: userData.data.updateByUserId,
                UpdateDateTime: userData.data.updateDateTime,
                IsDeletedFlg: userData.data.isDeletedFlg
            }
        }))
        this.setModal();
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateUserData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        if (e.target.name === "Inactive") {
            model[e.target.name] = !this.state.searchModel.Inactive;
        }
        else {
            model[e.target.name] = e.target.value;
        }

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateUserData();
        }
    }

    sortData(sortBy, sortOrder, rows) {
        var itemsToSort = this.state.userList;
        var sortedItems = [];
        var compareFn = null;

        compareFn = (i, j) => {
            if (i[sortBy] < j[sortBy]) {
                return sortOrder === "asc" ? -1 : 1;
            } else {
                if (i[sortBy] > j[sortBy]) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        };

        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;
    }



    async populateUserData() {
        this.setState({ fetchingData: true });

        FetchData("/getusers", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ userList: data, loading: false, fetchingData: false });
        });
    }

    renderUserTable(users) {

        return (
            <UserTableDefault
                state={this.state}
                users={this.state.userList}
                handleDoubleClick={this.handleDoubleClick}
            ></UserTableDefault>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderUserTable(this.state.userList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >User</h5>
                <Row>
                    <Col>
                        <InputGroup size="sm">
                            <Button color="btn" onClick={this.resetFields} size="sm"><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}</Button>
                            <input name="UserFullName" type="text" className="form-control form-control-text" value={this.state.searchModel.RepName} onChange={this.handleSearchChange} placeholder="User FullName (Lastname Firstname)" onKeyPress={this.handleKeyPress} />
                            <input name="UserName" type="text" className="form-control form-control-text" value={this.state.searchModel.UserName} onChange={this.handleSearchChange} placeholder="UserName" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                </Row>
                
                <LoadingModal fetchingData={this.state.fetchingData} />

                {this.state.modal &&
                    <UserForm model={this.state.model} modal={this.state.modal} alert={this.state.alert} setModal={this.setModal} handleSaveEventSuccess={this.handleSaveEventSuccess} />
                }

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
