import React, { Component, Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Input, Row, Col, Alert } from 'reactstrap';

import ReactTooltip from "react-tooltip";

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

import DeleteIcon from '../images/delete.png';

const moment = require('moment');

export class WorkOrderFollowUpForm extends Component {
    static displayName = WorkOrderFollowUpForm.name;
    constructor(props) {
        super(props);
        this.state = {
            workOrders: [],

            model: this.props.model,
            alert: this.props.alert,
            sortModel: {
                SortBy: 'jobDateTime',
                SortDirection: 'desc'
            }
        }
        //
        this.user = JSON.parse(GetCookie('user'));

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleJobTimeFieldChange = this.handleJobTimeFieldChange.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
    }

    componentDidMount() {
        let model = { ...this.state.model }
        model.JobDateTime = null;
        model.JobTime = "";
        this.setState({ model });

        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) { // Alt+S
            this.onSave();
        }
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseInt(e.target.value);
            else
                model[e.target.name] = null;
        } else if (e.target.name === "CallerType")
            model[e.target.name] = parseInt(e.target.value);
        else {
            if (e.target.name === "RemType") {
                //var index = e.nativeEvent.target.selectedIndex;
                /*if (e.nativeEvent.target[index].text === "N/A") {*/
                if (e.target.value === "d2f8a295-20e1-4653-b7c0-e456057cbd90") { // N/A
                    model["RemHost"] = "64ecfbe8-9cb7-4af8-8a41-f756aaeaca39"; // N/A
                    model["RemClaimflg"] = true;
                    model["RemIntflg"] = true;
                    model["RemProvflg"] = true;
                    model[e.target.name] = e.target.value
                } else
                    model[e.target.name] = e.target.value;
            } else {
                model[e.target.name] = e.target.value;
            }
        }

        this.setState({ model });
    }

    handleJobTimeFieldChange(e) {
        var jobtime = e.target.value;

        var concatChar = e.target.value;
        var hour = '';
        var minutes = '';
        var amPm = '';

        if (jobtime.length == 4) {

            for (var i = 0, len = jobtime.length; i < len; i++) {
                if (i <= 1)
                    hour += jobtime[i];
                else
                    minutes += jobtime[i];
            }

            if (parseInt(hour) < 12)
                concatChar = parseInt(hour) + ':' + minutes + ' AM';
            else if (parseInt(hour) == 12)
                concatChar = parseInt(hour) + ':' + minutes + ' PM';
            else {
                concatChar = (parseInt(hour) - 12) + ':' + minutes + ' PM';
            }
        } else if (jobtime.length == 8) {

            for (var i = 0, len = jobtime.length; i < len; i++) {
                if (i <= 1)
                    hour += jobtime[i];
                else if (i == 3 || i == 4)
                    minutes += jobtime[i];
                else if (i == 6 || i == 7)
                    amPm += jobtime[i];
            }

            if (parseInt(hour) < 12)
                concatChar = parseInt(hour) + ':' + minutes + ' ' + amPm;
            else if (parseInt(hour) == 12)
                concatChar = parseInt(hour) + ':' + minutes + ' ' + amPm;
            else {
                concatChar = (parseInt(hour) - 12) + ':' + minutes + ' PM';
            }
        }

        let model = { ...this.state.model }
        model[e.target.name] = concatChar;
        this.setState({ model });
    }

    onSave() {
        let model = { ...this.state.model };
    
        if (model.IntId === null || model.IntId === '') {
            if (window.confirm("Are you sure you want to create a work order without an interpreter?") === false) {
                return;
            }
        }
    
        if ((model.JobDateTime == null || model.JobDateTime === '')) {
            this.handleSaveEventSuccess({
                message: "Job Date and Time is required.",
                modal: true,
                color: "warning"
            });
        } else if ((model.JobTime === null || model.JobTime === '')) {
            this.handleSaveEventSuccess({
                message: "Job Date and Time is required.",
                modal: true,
                color: "warning"
            });
        }
        else {
            var strDay = moment(this.state.model.JobDateTime).format('dddd');
    
            if (strDay === 'Saturday' || strDay === 'Sunday') {
                if (window.confirm("The appointment date is on " + strDay + ". Are you sure you want to save this record?") === true) {
    
                }
                else return;
            }


            // model.JobNum = null;
            model.EventType = "insert";
            model.JobDateTime = moment(model.JobDateTime).toISOString();
            model.CallerNote = "Scheduled from Calendar " + moment(model.JobDateTime).format("mm/dd/yyyy") + " " + model.JobTime + " By: " + this.user.firstname + " " + this.user.lastname;
            model.FinishTime = null;
            model.FinishTimeString = null;
            model.IntFee = 0;
            model.IntFeeOther = 0;
            model.IntOTFee = 0;
            model.CreateByUserId = this.user.userid;
            
            console.log("Sending payload:", model);
    
            FetchData("/savefollowupworkorder", "POST", this.user.token, model).then(data => {
                if (data.message === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data.message,
                        modal: data.message === 'Success' ? false : true,
                        color: data.message === 'Success' ? "success" : "warning",
                        model: model
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data.message,
                        modal: data.message === 'Success' ? false : true,
                        color: data.message === 'Success' ? "success" : "warning"
                    });
                }
    
            });
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "400px", width: "600px" }} backdrop="static">
                    <ModalHeader>
                        New Followup
                    </ModalHeader>
                    <ModalBody style={{ height: "350px" }}>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Date:
                                    </InputGroupText>
                                    <Input name="JobDateTime" type="date" className="form-control-text" value={this.state.model.JobDateTime} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Time:
                                    </InputGroupText>
                                    <Input name="JobTime" type="text" className="form-control-text" value={this.state.model.JobTime} onChange={this.handleChange} onBlur={this.handleJobTimeFieldChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Provider:
                                    </InputGroupText>
                                    <Input type="text" name="ProviderName" className="form-control-text" placeholder="Provider" value={this.state.model.ProviderName} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Appt Name:
                                    </InputGroupText>
                                    <Input name="AptName" type="text" className="form-control-text" placeholder="Appt Name" value={this.state.model.AptName} onChange={this.handleChange} />                                 </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Appt Phone:
                                    </InputGroupText>
                                    <Input name="AptPhone" type="text" className="form-control-text" placeholder="Appt Phone" value={this.state.model.AptPhone} onChange={this.handleChange} />                               </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Appt City:
                                    </InputGroupText>
                                    <Input name="AptCity" type="text" className="form-control-text" placeholder="Appt City" value={this.state.model.AptCity} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Appt State:
                                    </InputGroupText>
                                    <Input name="AptState" type="text" className="form-control-text" placeholder="Appt State" value={this.state.model.AptState} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '74px' }}>
                                        Appt Zip:
                                    </InputGroupText>
                                    <Input name="AptZip" type="text" className="form-control-text" placeholder="Appt Zip" value={this.state.model.AptZip} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <InputGroupText>
                                        Appt Details:
                                    </InputGroupText>
                                    <Input name="AptDirection" type="text" className="form-control-text" placeholder="Appt Details" value={this.state.model.AptDirection} onChange={this.handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-secondary-no-background form-control-text" onClick={this.onSave}>Save</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}