import React, { Component, } from 'react';
import ReactTooltip from "react-tooltip";

import AddIcon from '../images/add.png'
import EmailIcon from '../images/email-icon.png'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core styles
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme
import './ag-theme-ela.css';

import * as CellRenderers from './AgGridCellRendererComponents';

const moment = require('moment');

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    //suppressRowClickSelection: false,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const lateInvoicesColumns = [
    { headerName: "", width: 30, headerCheckboxSelection: true, checkboxSelection: true, sortable: false, filter: false },
    { headerName: '', field: 'addIcon', width: 20, cellRenderer: 'addNoteCellRenderer' },
    { headerName: 'Reminder Date', field: 'reminderDate', cellRenderer: 'dateCellRenderer', width: 90 },
    { headerName: 'Reminder Created By', field: 'reminderCreatedBy', width: 130 },
    { headerName: 'Type', field: 'arType', width: 65 },
    { headerName: '', field: 'emailIcon', cellRenderer: 'repEmail3CellRenderer', width: 30 },
    { headerName: 'Inv #', field: 'jobNum', cellRenderer: 'jobNumCellRenderer', width: 60 },
    { headerName: 'Job Date', field: 'jobDateTime', cellRenderer: 'jobDateCellRenderer', width: 150 },
    { headerName: 'Balance', field: 'jobBalance', width: 80 },
    { headerName: 'Job Total', field: 'jobJobTotal', width: 80 },
    { headerName: 'Clm #', field: 'claimantELANum', width: 105 },
    { headerName: 'Claimant', field: 'claimantFullName', width: 155 },
    { headerName: 'Client', field: 'clientABBRV', width: 68 },
    { headerName: 'Rep', field: 'repFullName', width: 155 },
    { headerName: 'Phone', field: 'repPhone', width: 140 },
    { headerName: 'Email', field: 'repEmail', width: 140 }
];

export class LateInvoicesTableDefault extends Component {
    static displayName = LateInvoicesTableDefault.name;

    constructor(props) {
        super(props);
        this.state = {
            columnWidths: lateInvoicesColumns.reduce((acc, col) => {
                acc[col.field] = col.width || DEFAULT_MIN_WIDTH_CELL; // Initialize widths
                return acc;
            }, {}),
            isResizing: -1,
            clickCount: 0,
        };
        this.previousSelection = [];
        // Bind methods
        this.onColumnResized = this.onColumnResized.bind(this);
        this.handleLateInvoiceRowDoubleClick = this.handleLateInvoiceRowDoubleClick.bind(this);
        this.handleLateInvoiceRowSingleClick = this.handleLateInvoiceRowSingleClick.bind(this);
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimer);
    }

    autoSizeUnspecifiedColumns = () => {
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            // Check if the width is not set in the column definition
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    handleLateInvoiceRowDoubleClick = (event) => {
        clearTimeout(this.clickTimer);  // prevent single click action
        this.setState({ clickCount: 0 });  // reset click count

        const workorder = event.data;
        this.props.viewWorkOrder(workorder, 'workorder');
    }

    handleLateInvoiceRowSingleClick(lateInvoiceData) {
        this.setState({ clickCount: this.state.clickCount + 1 });
        this.clickTimer = setTimeout(() => {
            if (this.state.clickCount === 1) {
                this.props.handleLateInvoiceClick(lateInvoiceData.data);
            }
            this.setState({ clickCount: 0 });  // reset count after action
        }, 300);  // 300 ms delay

    }


    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                // Ensure that you have access to the clientHeight property correctly
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });

            // Adding some padding or extra space if necessary
            maxHeight += 10; // Adjust this value based on your CSS and padding needs

            // Directly set the new header height
            gridApi.setHeaderHeight(maxHeight);
        });
    };

    onSelectionChanged = () => {
        const currentSelection = this.gridApi.getSelectedRows();
        const currentSelectionIds = currentSelection.map(item => item.jobId);
        const previousSelectionIds = this.previousSelection.map(item => item.jobId);

        console.log('Current Selection:', currentSelection);
        console.log('Previous Selection:', this.previousSelection);

        const newlySelected = currentSelection.filter(node =>
            !previousSelectionIds.includes(node.jobId)
        );
        const newlyDeselected = this.previousSelection.filter(node =>
            !currentSelectionIds.includes(node.jobId)
        );

        console.log('Newly Selected:', newlySelected);
        console.log('Newly Deselected:', newlyDeselected);

        // Handling changes based on newly selected or deselected
        newlySelected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: true
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        newlyDeselected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: false
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        // Update the previous selection with current
        this.previousSelection = [...currentSelection];
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridApiReady: true });
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns(false);
    };

    onDataLoaded = (newData) => {
        this.gridApi.setRowData(newData);
        this.autoSizeUnspecifiedColumns(true);
    };

    onColumnResized = (params) => {
        if (params.finished) {
            // Update the state with new column width
            const newWidths = { ...this.state.columnWidths };
            params.columns.forEach(column => {
                newWidths[column.getColId()] = column.getActualWidth();
            });
            this.setState({ columnWidths: newWidths }, () => {
                // Call adjustHeaderHeight after state update to ensure it uses the latest widths
                this.adjustHeaderHeight(this.gridApi);
            });
        }
    };

    render() {
        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: lateInvoicesColumns,
            rowData: this.props.lateInvoices,
            context: {
                componentParent: this,
                gridApi: this.gridApi,
                setModal: this.props.setModal,
                state: this.props.state,
                addNoteClick: this.props.addNoteClick,
                handleCheckChange: this.props.handleCheckChange,
                handleEmailIconRepClick: this.props.handleEmailIconRepClick,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                addNoteCellRenderer: AddNoteCellRenderer,
                dateCellRenderer: DateCellRenderer,
                jobDateCellRenderer: CellRenderers.JobDateSuccessCellRenderer,
                jobNumCellRenderer: CellRenderers.JobNumCellRenderer,
                repEmail3CellRenderer: RepEmail3CellRenderer,
            },
            onColumnResized: this.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleLateInvoiceRowDoubleClick,
            onRowClicked: this.handleLateInvoiceRowSingleClick,
            onSelectionChanged: this.onSelectionChanged,
            suppressRowClickSelection: true,
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                    rowSelection={"multiple"}
                />
            </div>
        );

    }
}

class AddNoteCellRenderer extends React.Component {
    handleAddClick = () => {
        const { data, context } = this.props;

        //console.log(context.gridApi.getSelectedRows())
        let selectedRowData = context.gridApi.getSelectedRows();

        context.componentParent.props.addNoteClick(data, selectedRowData);
    }

    render() {
        return (
            <img
                src={AddIcon}
                style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                alt="Add"
                onClick={this.handleAddClick}
            />
        );
    }
}


class DateCellRenderer extends React.Component {
    render() {
        const { value } = this.props;
        return <span>{value ? moment(value).format('MM/DD/YYYY') : ''}</span>;
    }
}

class RepEmail3CellRenderer extends React.Component {
    handleEmailClick = () => {
        const { data, context } = this.props;
        context.componentParent.props.handleEmailIconRepClick(data);
    }

    render() {
        return (
            <img
                src={EmailIcon}
                style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                alt="Email"
                onClick={this.handleEmailClick}
            />
        );
    }
}
