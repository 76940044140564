import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, InputGroup, InputGroupText, Row, Col, Alert, TabContent, Table, TabPane } from 'reactstrap';

import { FetchData, UploadFile } from './utils/DataFetch';
import { CreateAsUserIfNotExist, GetCookie } from './utils/Common';

import Logo from '../images/logo1.png'

export class WorkOrderIntConfNo extends Component {
    static displayName = WorkOrderIntConfNo.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));

        this.state = {
            display_message: ''
        };

        this.confirmEmail = this.confirmEmail.bind(this);
    }

    componentDidMount() {
        this.confirmEmail();
    }

    async confirmEmail() {
        let model = {
            UserID: this.user.userid,
            FirstName: this.user.firstname,
            LastName: this.user.lastname,
            Email: this.user.email,
            JobId: this.props.JobId,
            IntId: this.props.IntId
        };

        if (this.props.notify_type === 'auth') {
            await FetchData("/FinalConfirmationEmailToInterpreter", "POST", this.user.token, model).then(data => {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: false,
                    color: "success"
                });
                //this.setState({
                //    display_message: data
                //});
            });
        } else {
            await FetchData("/interpretersendcheckinemail", "POST", this.user.token, model).then(data => {
                this.props.handleSaveEventSuccess({
                    message: data,
                    modal: false,
                    color: "success"
                });
                //this.setState({
                //    display_message: data
                //});
            });
        }        
    }

    //createUserAndInsertJobHistory() {
    //    let model = {
    //        UserID: this.user.userid,
    //        FirstName: this.user.firstname,
    //        LastName: this.user.lastname,
    //        Email: this.user.email,
    //        IntId: this.props.IntId
    //    };

    //    // create the interpreter if not exists in the user table.
    //    CreateAsUserIfNotExist(this.user.token, model).then(data => { });

    //    var formData = new FormData();
    //    formData.append("EventType", "insert");
    //    formData.append("JobHistoryId", null);
    //    formData.append("JobId", this.props.JobId);
    //    formData.append("JobHistoryName", "");
    //    formData.append("Description", "Interpreter confirmation requested by confirmation email");
    //    formData.append("Contact", "");
    //    formData.append("ContactEmail", "");
    //    formData.append("FileLocation", "");
    //    formData.append("FileName", "");
    //    formData.append("JobHistoryType", "Request Int Conf");
    //    formData.append("CreateByUserId", this.user.userid);

    //    UploadFile("/savejobhistory", this.user.token, formData).then(data => {

    //    });
    //}

    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "500px", width: "800px" }}>
                    <ModalHeader>Final Appointment Confirmation (Interpreter)</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <center><img src={Logo} alt="Logo" /></center>
                                </Col>
                            </Row>
                            <Row>
                                <Col><center><p>{this.state.display_message}</p></center></Col>
                            </Row>
                        </div>
                    </ModalBody>
                    {/*<ModalFooter>*/}

                    {/*</ModalFooter>*/}
                </Modal>
            </div>
        );

    }
}
