import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import moment from 'moment';
import './ag-theme-ela.css';

const DEFAULT_MIN_WIDTH_CELL = 10;
const DEFAULT_MAX_WIDTH_CELL = 800;

const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: DEFAULT_MIN_WIDTH_CELL,
    maxWidth: DEFAULT_MAX_WIDTH_CELL,
    cellStyle: {
        borderRight: '1px solid #ccc', // Adds a light gray divider
    },
};

const columns = [
    { headerName: 'INV#', field: 'jobNum', width: 70 },
    { headerName: 'Date', field: 'jobDateTime', width: 210, cellRenderer: 'dateCellRenderer' },
    { headerName: 'Dur', field: 'jobDuration', width: 60 },
    { headerName: 'Provider', field: 'providerName', width: 200 },
    { headerName: 'Appt Name', field: 'aptName' },
    { headerName: 'Job Total', field: 'jobTotal', width: 70 },
    { headerName: 'Interpreter', field: 'interpreter', valueGetter: params => `${params.data.intLastName}, ${params.data.intFirstName}` },    
    { headerName: 'Interpreter Phone', field: 'intCellTxt', width: 100 },
    { headerName: 'Fee', field: 'intFee', width: 60 },
    { headerName: 'OT', field: 'intOTFee', width: 50 },
    { headerName: 'OTH', field: 'intFeeOther', width: 60 }
];

export class WorkOrderClaimantJobsTableDefault extends Component {
    constructor(props) {
        super(props);
        this.gridApi = null;
        this.gridColumnApi = null;
        this.previousSelection = [];
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentDidUpdate(prevProps) {
        if (this.props.clearSelection && !prevProps.clearSelection) {
            if (this.gridApi) {
                this.gridApi.deselectAll();
            }
        }
    }

    onSelectionChanged = () => {
        if (!this.gridApi) return;

        const currentSelection = this.gridApi.getSelectedRows();
        const currentSelectionIds = currentSelection.map(item => item.jobId);
        const previousSelectionIds = this.previousSelection.map(item => item.jobId);

        const newlySelected = currentSelection.filter(node =>
            !previousSelectionIds.includes(node.jobId)
        );
        const newlyDeselected = this.previousSelection.filter(node =>
            !currentSelectionIds.includes(node.jobId)
        );

        newlySelected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: true
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        newlyDeselected.forEach(node => {
            const syntheticEvent = {
                target: {
                    name: "chk_" + node.jobNum,
                    checked: false
                }
            };
            this.props.handleCheckChange(syntheticEvent);
        });

        this.previousSelection = [...currentSelection];
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (this.props.onGridReady) {
            this.props.onGridReady(params);
        }
        this.adjustHeaderHeight(params.api);
        this.autoSizeUnspecifiedColumns();
    };

    autoSizeUnspecifiedColumns = () => {
        if (!this.gridColumnApi) return;

        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            if (column.getColDef().width === undefined) {
                allColumnIds.push(column.colId);
            }
        });
        if (allColumnIds.length > 0) {
            this.gridColumnApi.autoSizeColumns(allColumnIds, false);
        }
    };

    adjustHeaderHeight = (gridApi) => {
        window.requestAnimationFrame(() => {
            const allColumnHeaders = document.querySelectorAll('.ag-header-cell-text');
            let maxHeight = 0;
            allColumnHeaders.forEach(header => {
                if (header.parentElement.clientHeight > maxHeight) {
                    maxHeight = header.parentElement.clientHeight;
                }
            });
            maxHeight += 10;
            gridApi.setHeaderHeight(maxHeight);
        });
    };

    handleRowDoubleClick = (event) => {
        const workorder = event.data;
        const rowIndex = event.rowIndex;
        this.props.handleDoubleClick(workorder);
    };

    render() {
        const { workOrders, ...props } = this.props;

        const gridOptions = {
            suppressSizeToFit: true,
            columnDefs: columns,
            rowData: workOrders,
            context: {
                componentParent: this,
                handleCheckChange: this.props.handleCheckChange,
                gridApi: this.gridApi,
                checkboxStates: props.checkboxStates,
                setModal: props.setModal,
                setData: props.setData,
                state: props.state,
            },
            domLayout: 'autoHeight',
            animateRows: true,
            defaultColDef: defaultColDef,
            components: {
                dateCellRenderer: DateCellRenderer,
            },
            rowSelection: "multiple",
            // onRowClicked: props.onRowClicked,
            onColumnResized: props.onColumnResized,
            rowHeight: 20,
            tooltipShowDelay: 0,
            enableBrowserTooltips: true,
            onRowDoubleClicked: this.handleRowDoubleClick,
            // onSelectionChanged: this.onSelectionChanged
        };

        return (
            <div className="ag-theme-balham" style={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...gridOptions}
                    onGridReady={this.onGridReady}
                />
            </div>
        );
    }
}

class DateCellRenderer extends React.Component {
    render() {
        const { value, data } = this.props;
        const createdDate = moment(data.createDateTime).format('MM/DD/YYYY hh:mm A');
        const updatedDate = moment(data.updateDateTime).format('MM/DD/YYYY hh:mm A');
        const jobDate = moment(value).format('MM/DD/YYYY');
        const jobTime = value === null ? "" : moment(value).format('hh:mm A');
        const finishTime = data.finishTimeString === null ? null : " to " + data.finishTimeString;

        return (
            <span data-for="rtp" data-tip={`CreatedBy: ${data.createdBy}<br/> Created On: ${createdDate}<br/>Updated By: ${data.updatedBy}<br/>Updated On: ${updatedDate}`}>
                {`${jobDate} ${jobTime} ${finishTime}`}
            </span>
        );
    }
}
