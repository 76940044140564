import React, { Component } from 'react';
import './FAQ.css';

export class FAQ extends Component {
    static displayName = FAQ.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="card-faq card-container">
                <h4><center>How We Work</center></h4>
                <p><b>When you get a call/text/email from us,</b></p>
                <p><b>You </b>can respond by phone (800-522-2320), text (number) or email; <a href="mailto:mail@executivelinguist.com<">mail@executivelinguist.com</a></p>
                <p>We usually allow a few hours or till the end of the day to respond.</p>

                <br />
                <p><b>For last-minute/emergency jobs, </b>we will call as many people as we can and assign it to the first available interpreter we contact. Even if you are not available, please get back to us ASAP so that we know to keep looking.
                </p>
                <br />
                <p><b>For a job outside of your area,</b> please confirm mileage rates and/or travel time before accepting it.</p>
                <br />
                <p><b>You will get an email from us prior to the job;</b> follow the instructions in the email to <b>confirm</b> the job.
                    If you have not received the email by <b>NOON</b> of the day prior to the job, please call us immediately.</p>
                <p>_</p>
                <p><b>If you FAIL to confirm the job</b> by the end of the day, we remove you from the job and start looking for a replacement.</p>
                <br />
                <p><b>Always plan to arrive ten minutes early.</b> 90% of issues with jobs can be solved with 10 extra minutes.
                    This is time we can use to help you if there's a problem.</p>
                <br />
                <p><b>What do I do if...?</b></p>
                <br />
                <p><b>...I might be late for a job?</b> <br />
                    <b>Call us IMMEDIATELY</b> so we can notify the client you are on your way.
                    Failing to do so means that we may have to not submit a charge for the work, and subsequently can't pay you.</p>
                <br />
                <p><b>...I can't find the location or client?</b> <br />
                    <b>Call us.</b> Do not call the location.</p>
                <br />
                <p><b>I show up and another interpreter is there?</b> <br />
                    <b>Call us.</b> Don't argue, and don't leave. Call us and let our staff figure out the mix up.</p>
                <br />
                <p><b>...I show up and the patient doesn't show?</b> <br />
                    <b>Call us before you leave the office.</b> Do not leave the location without being dismissed by an ELA staff member. <b>Do NOT report via the portal.</b></p>
                <br />
                <p><b>...I show up for a medical appointment and the appointment is not on the schedule or the doctor is not there?</b> <br />
                    <b>Call us.</b> Do NOT leave the office until we release you.
                </p>
                <br />
                <p><b>I am asked to give out my phone number?</b> <br />
                    <b>Do not give out your phone number</b> if asked by anyone at an appointment. Politely ask them to call us at 800-522-2320 and offer one of our business cards. You can request business cards here [LINK]</p>
                <br />
                <p><b>As soon as the assignment is completed: Report via the portal</b> the ending time along with any follow-ups/referrals, any other relevant information or fees. Mileage must be reported each time. Parking stub or receipt is required for all reimbursements</p>
                <br />
                <p><b>How do I report?</b> <br />
                    Click on the <b>Report Ending Time</b> link in the confirmation email you got the day before. <br />
                    Or <br />
                    Log into the ELA Portal at: <a href="http://portal.e-ela.com" target="_blank">http://portal.e-ela.com</a>, scroll down to the job and click on "End Time"</p>
                <br />
                <p><b>Follow-ups</b> <br/>
                    Any follow-ups reported are automatically assigned to you, unless told otherwise, so please ADD them to your schedule. We will email, call AND text you with any changes. <br />
                    Any time you are not able to cover a follow-up you reported, notify us as soon as possible by calling 800-522-2320 or by sending an email to <a href="mailto:mail@executivelinguist.com">mail@executivelinguist.com</a>
                </p>
                <br />
                <p><b>Payments</b> <br/>
                    We do not require invoices as we do not use them to generate your payment. If you want to send one, please email it before 8:00 AM the following day as that is when we start to bill.
                </p>
                <p>Please log onto the ELA portal at the end of the following day to make sure your payment has been correctly calculated.</p>
                <p>Payments covering all assignments from the 1st of the month through the 15th are deposited on the 2nd business day after the 15th. <br/>
                    Payments covering all assignments from the 16th through the last day of the month are deposited on the 2nd business day after the last day of the month.</p>
                <p>On the 15th and the last day of the month, please go to the Portal and check all payments due to be sure they are correct. Call us immediately if any payments are not what you expected.</p>
                <p><b>Cancellations</b> <br/>
                    Many interpreters have a 24 hour cancellation policy so if an appointment is cancelled or rescheduled with less than 24 hours notice, they still charge their interpreting fee. Others are flexible depending on the circumstances. Let us know how you handle late cancellations.</p>
                <p>Appointments cancelled the same day are almost always billed and paid normally.</p>
                <p>If you arrive punctually, but the appointment does not take place, you are paid the agreed fee.</p>
                <p><b>Contact Information</b> <br/>
                    <b>Phone:</b> 800-522-2320 Mon-Friday, 5:00AM - 5:00PM, All messages received after hours are returned the next business day. <br/>
                    <b>Text:</b> 310-955-4513 (NON-EMERGENCY ONLY) If you don�t receive a response within 2 hrs during business hours, please call us as we did not receive it. <br/>
                    <b>Confirmation email link</b> <br/>
                    <b>General Email:</b> mail@executivelinguist.com (which goes to all schedulers, IT and the CEO) <br/>
                    <b>Ending time/Billing/Accounting:</b> mail@executivelinguist.com or billing@executivelinguist.com <br/>
                    <b>Fax:</b> 310-376-9285 <br/>
                    <b>After Hours Emergency Only:</b> 562-682-3924 or 310-749-2759
                    <br /><br />
                    <b>Portal:</b> <a href="http://portal.e-ela.com" target="_blank">http://portal.e-ela.com</a> See attachment for logging on.</p>
            </div>
        );
    }
}
