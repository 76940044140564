import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

export class InterpreterAvailabilityForm extends Component {
    static displayName = InterpreterAvailabilityForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            model: props.model,
            alert: props.alert
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
    }

    onSave() {
        let model = this.state.model;

        model.IntId = this.user.intid;
        model.CreateByUserId = this.user.userid;

        if (model.StartDate === null) {
            this.handleSaveEventSuccess({
                message: 'Please choose a Start Date.',
                modal: true,
                color: "warning"
            });
        } else if (model.ScheduleType === '') {
            this.handleSaveEventSuccess({
                message: 'Please choose a Type.',
                modal: true,
                color: "warning"
            });
        } else {
            FetchData("/saveinterpreteravailability", "POST", this.user.token, model).then(data => {
                console.log(data);
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: true,
                        color: "warning"
                    });
                }                
            });
        }
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }

        if (e.target.type === "checkbox")
            model[e.target.name] = e.target.checked;
        else if (e.target.type === "number") {
            if (e.target.value !== "")
                model[e.target.name] = parseFloat(e.target.value);
            else
                model[e.target.name] = null;
        }
        else
            model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) {
            this.onSave();
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>New Schedule</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                        {this.state.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Job Date From:
                                        </InputGroupText>
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            Job Date To:
                                        </InputGroupText>
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                        </InputGroupText>
                                        <input type="date" name="StartDate" className="form-control form-control-text" value={this.state.model.StartDate} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-6">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                        </InputGroupText>
                                        <input type="date" name="EndDate" className="form-control form-control-text" value={this.state.model.EndDate} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Type:
                                        </InputGroupText>                                        
                                    </InputGroup>
                                </Col>                               
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <InputGroup size="sm">
                                        <InputGroupText></InputGroupText>
                                        <p>
                                            <input name="ScheduleType" value="AM" type="radio" onChange={this.handleChange}></input> AM Only <br />
                                            <input name="ScheduleType" value="PM" type="radio" onChange={this.handleChange}></input> PM Only <br />
                                            <input name="ScheduleType" value="All Day" type="radio" onChange={this.handleChange}></input> Full Day
                                        </p>
                                    </InputGroup>
                                </Col>  
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "61px" }}>
                                            Notes:
                                        </InputGroupText>
                                        <textarea type="text" name="SchedNotes" className="form-control form-control-text" rows={6} onChange={this.handleChange}>{this.state.model.SchedNotes}</textarea>
                                    </InputGroup>
                                </Col>
                            </Row>                                                  
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" onClick={this.props.setModal} size="sm">Cancel</Button>
                        {/*<Button color="primary" onClick={() => { if (window.confirm("Are you sure to save this record?")) { this.onSave() } }} size="sm">Save</Button>*/}
                        <Button color="primary" onClick={this.onSave} size="sm">Save</Button>
                    </ModalFooter>
                </Modal>


            </div>
        )
    }
}