import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

import { FetchData, UploadFile } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import DeleteIcon from '../images/delete.png'


export class LateInvoicesAddNote extends Component {
    static displayName = LateInvoicesAddNote.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));

        this.state = {
            alert: props.alert,
            model: props.model
        };

        this.onSave = this.onSave.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        //
        this.handleChange = this.handleChange.bind(this);
        //
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
        });
    }

    onSave() {
        if (this.txtReminderDate.value === null || this.txtReminderDate.value === '') {
            this.handleSaveEventSuccess({
                message: 'Please choose Reminder Date.',
                modal: true,
                color: "warning"
            });
        } else if (this.txtNotes.value === '') {
            this.handleSaveEventSuccess({
                message: 'Please enter Notes.',
                modal: true,
                color: "warning"
            });
        } else {
            if (this.props.rowData.length <= 1) {
                let model = { ...this.state.model }

                var formData = new FormData();
                formData.append("EventType", model.EventType);
                formData.append("JobReceivableItemId", model.JobReceivableItemId);
                formData.append("JobId", model.JobId);
                formData.append("UserInsertedFlg", model.UserInsertedFlg);
                formData.append("ReminderDate", model.ReminderDate);
                formData.append("Notes", model.Notes);
                formData.append("FileLocationS3", model.FileLocationS3);
                formData.append("FileName", model.FileName);
                formData.append("CreateDateTime", model.CreateDateTime);
                formData.append("CreateByUserId", model.CreateByUserId);
                formData.append("UpdateByUserId", model.UpdateByUserId);
                formData.append("UpdateDateTime", model.UpdateDateTime);
                //formData.append("IsDeletedFlg", model.IsDeletedFlg);
                formData.append("FormFile", model.FormFile);

                UploadFile("/savejobreceivableitem", this.user.token, formData).then(data => {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: false,
                        color: "success"
                    });
                });
            } else {
                let model = { ...this.state.model }

                for (var key in this.props.rowData) {
                    //console.log(this.props.rowData[key]);

                    var formData = new FormData();
                    formData.append("EventType", model.EventType);
                    formData.append("JobReceivableItemId", model.JobReceivableItemId);
                    formData.append("JobId", this.props.rowData[key].jobId);
                    formData.append("UserInsertedFlg", model.UserInsertedFlg);
                    formData.append("ReminderDate", model.ReminderDate);
                    formData.append("Notes", model.Notes);
                    formData.append("FileLocationS3", model.FileLocationS3);
                    formData.append("FileName", model.FileName);
                    formData.append("CreateDateTime", model.CreateDateTime);
                    formData.append("CreateByUserId", model.CreateByUserId);
                    formData.append("UpdateByUserId", model.UpdateByUserId);
                    formData.append("UpdateDateTime", model.UpdateDateTime);
                    //formData.append("IsDeletedFlg", model.IsDeletedFlg);
                    formData.append("FormFile", model.FormFile);

                    UploadFile("/savejobreceivableitem", this.user.token, formData).then(data => {
                        
                    });
                }

                window.setTimeout(() => {
                    this.props.handleSaveEventSuccess({
                        message: "Success",
                        modal: false,
                        color: "success"
                    });
                }, Timeout(alert.message));
            }
        }
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    handleFileChange(e) {
        let model = { ...this.state.model }
        model.FileName = e.target.files[0].name;
        model.FormFile = e.target.files[0];

        this.setState({ model });
    }

    handleDeleteFile() {
        this.fileUpload.value = null;

        let model = { ...this.state.model }
        model.FileName = '';
        model.FileLocation = '';

        this.setState({ model });
    }

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "600px", width: "500px" }} backdrop="static">
                    <ModalHeader>Note Editor</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "100px" }}>
                                        Reminder Date:
                                    </InputGroupText>
                                    <input type="date" name="ReminderDate" className="form-control form-control-text" value={this.state.model.ReminderDate} onChange={this.handleChange} ref={(input) => { this.txtReminderDate = input; }} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "100px" }}>
                                        Notes:
                                    </InputGroupText>
                                    <textarea name="Notes" cols={10} className="form-control form-control-text" value={this.state.model.Notes} onChange={this.handleChange} ref={(textarea) => { this.txtNotes = textarea; }} maxLength={500}></textarea>
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col className={"col-2"} />
                            <Col className={this.state.model.FileName === '' ? "col-10" : "col-10"} >
                                <input id="file" type="file" name="fileUpload" className="form-control" onChange={this.handleFileChange} ref={ref => this.fileUpload = ref} />
                                <span style={{ left: '20px', top: '50px', position: 'absolute' }} >{this.state.model.FileName === "" ? "Drag and Drop" : this.state.model.FileName}</span>
                            </Col>
                            <Col className="col-1">
                                <img src={DeleteIcon} style={{ width: "14px", height: "14px", cursor: 'pointer', display: this.state.model.FileName === '' ? 'none' : null }} onClick={this.handleDeleteFile} alt="Delete" />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.setModal} size="sm">Cancel</Button>
                        <Button color="primary" onClick={this.onSave} className="btn" size="sm">Save</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );

    }
}
