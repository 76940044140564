import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import Loading from '../images/loading.gif'


export class LoadingModal extends Component {
    static displayName = LoadingModal.name;

    render() {
        return (
            <div>
                <Modal isOpen={this.props.fetchingData} size="lg" style={{ width: '30%', top: '30%' }}>
                    <ModalBody>
                        <center>
                            <img src={Loading} alt="Please wait..." /> {' '}
                        </center>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}