import React, { Component, Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Input, Row, Col, Alert } from 'reactstrap';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    Paper
} from "@material-ui/core";

import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import AddIcon from '../images/add.png'
import DeleteIcon from '../images/delete.png'
import EditIcon from '../images/edit-icon.png'

const moment = require('moment');

export class WorkOrderCopyJob extends Component {
    static displayName = WorkOrderCopyJob.name;
    constructor(props) {
        super(props);
        this.state = {
            jobHistoryList: [],
            jobDateTimeList: [],
            jobTypeList: [],

            modal: false,

            model: this.props.model,
            copyJobDateModel: {
                SingleDate: '',
                Time: '',
                Type: '',
                Notes: ''
            },
            alert: this.props.alert,
            sortModel: {
                SortBy: 'jobDateTime',
                SortDirection: 'desc'
            }
        }
        //
        this.user = JSON.parse(GetCookie('user'));

        this.getJobHistory = this.getJobHistory.bind(this);
        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleJobTimeFieldChange = this.handleJobTimeFieldChange.bind(this);
        this.handleAddJobDateTime = this.handleAddJobDateTime.bind(this);
        this.handleAddJobTypes = this.handleAddJobTypes.bind(this);
        this.handleAddJobHistory = this.handleAddJobHistory.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleRemoveJobDateTime = this.handleRemoveJobDateTime.bind(this);
        this.handleRemoveJobHistory = this.handleRemoveJobHistory.bind(this);
    }

    componentDidMount() {
        this.getJobHistory();

    }
    componentDidUpdate() {

    }

    handleChange(e) {
        let model = { ...this.state.copyJobDateModel }

        model[e.target.name] = e.target.value;

        this.setState({ copyJobDateModel: model });
    }

    handleJobTimeFieldChange(e) {
        //console.log(e.target.value);

        var jobtime = e.target.value;

        var concatChar = e.target.value;
        var hour = '';
        var minutes = '';
        var amPm = '';

        if (jobtime.length == 4) {

            for (var i = 0, len = jobtime.length; i < len; i++) {
                if (i <= 1)
                    hour += jobtime[i];
                else
                    minutes += jobtime[i];
            }

            if (parseInt(hour) < 12)
                concatChar = parseInt(hour) + ':' + minutes + ' AM';
            else if (parseInt(hour) == 12)
                concatChar = parseInt(hour) + ':' + minutes + ' PM';
            else {
                concatChar = (parseInt(hour) - 12) + ':' + minutes + ' PM';
            }
        } else if (jobtime.length == 8) {

            for (var i = 0, len = jobtime.length; i < len; i++) {
                if (i <= 1)
                    hour += jobtime[i];
                else if (i == 3 || i == 4)
                    minutes += jobtime[i];
                else if (i == 6 || i == 7)
                    amPm += jobtime[i];
            }

            if (parseInt(hour) < 12)
                concatChar = parseInt(hour) + ':' + minutes + ' ' + amPm;
            else if (parseInt(hour) == 12)
                concatChar = parseInt(hour) + ':' + minutes + ' ' + amPm;
            else {
                concatChar = (parseInt(hour) - 12) + ':' + minutes + ' PM';
            }
        }

        let model = { ...this.state.copyJobDateModel }
        model[e.target.name] = concatChar;
        this.setState({ copyJobDateModel: model });
    }

    handleAddJobDateTime() {
        if (this.state.copyJobDateModel.SingleDate === '' || this.state.copyJobDateModel.Time === '') {
            this.handleSaveEventSuccess({
                message: "Date and Time is required.",
                modal: true,
                color: "warning"
            });
        } else {
            var validDate = moment(this.state.copyJobDateModel.SingleDate + ' ' + this.state.copyJobDateModel.Time);
            if (validDate.isValid()) {
                let model = this.state.jobDateTimeList;
                model.push({
                    DateTime: this.state.copyJobDateModel.SingleDate + ' ' + this.state.copyJobDateModel.Time,
                    Date: this.state.copyJobDateModel.SingleDate,
                    Time: this.state.copyJobDateModel.Time
                });
                this.setState({ jobDateTimeList: model }, () => {
                    let model2 = { ...this.state.copyJobDateModel }
                    model2.SingleDate = '';
                    model2.Time = '';

                    this.setState({ copyJobDateModel: model2 });
                });
            } else {
                this.handleSaveEventSuccess({
                    message: "Date and Time is not valid.",
                    modal: true,
                    color: "warning"
                });
            }
        }
    }

    handleAddJobTypes() {
        if (this.state.copyJobDateModel.Type === '' || this.state.copyJobDateModel.Notes === '') {
            this.handleSaveEventSuccess({
                message: "Type and Notes is required.",
                modal: true,
                color: "warning"
            });
        } else {
            let model = this.state.jobTypeList;
            model.push({
                TypeNotes: this.state.copyJobDateModel.Type + '|' + this.state.copyJobDateModel.Notes,
                Type: this.state.copyJobDateModel.Type,
                Notes: this.state.copyJobDateModel.Notes
            });
            this.setState({ jobTypeList: model }, () => {
                let model2 = { ...this.state.copyJobDateModel }
                model2.Type = '';
                model2.Notes = '';

                this.setState({ copyJobDateModel: model2 });
            });
        }
    }

    handleAddJobHistory(jobHistory) {
        let model = this.state.jobTypeList;
        model.push({
            TypeNotes: jobHistory.jobHistoryType + '|' + jobHistory.description,
            Type: jobHistory.jobHistoryType,
            Notes: jobHistory.description
        });
        this.setState({ jobTypeList: model });
    }

    getJobHistory() {
        let model = {
            JobId: this.state.model.JobId
        };

        FetchData("/getjobhistory", "POST", this.user.token, model).then(data => {
            this.setState({ jobHistoryList: data, fetchingData: false });
        });
    }

    handleSaveEventSuccess(alert) {
        this.setState({
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));

        });
    }

    handleRemoveJobDateTime(index) {
        let model = this.state.jobDateTimeList;
        model.splice(index, 1);

        this.setState({ jobDateTimeList: model });
    }

    handleRemoveJobHistory(index) {
        let model = this.state.jobTypeList;
        model.splice(index, 1);

        this.setState({ jobTypeList: model });
    }

    onSave() {
        if (this.state.jobDateTimeList.length === 0) {
            this.handleSaveEventSuccess({
                message: 'No Date and Time has been added in the list.',
                modal: true,
                color: "warning"
            });
        } else {
            let body = {
                JobId: this.state.model.JobId,
                JobDateTime: this.state.jobDateTimeList.map(u => u.DateTime).join(','),
                JobHistory: this.state.jobTypeList.map(u => u.TypeNotes).join('^'),
                CreatedById: this.user.userid
            };

            FetchData("/CopyJob", "POST", this.user.token, body).then(data => {
                if (data === 'Success') {
                    this.props.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                } else {
                    this.handleSaveEventSuccess({
                        message: data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                }
            })
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ height: "600px", width: "1280px" }} backdrop="static">
                    <ModalHeader>
                        Copy Job
                    </ModalHeader>
                    <ModalBody style={{ height: "500px" }}>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                                    {this.state.alert.message}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '100px' }}>
                                        Copy this Job:
                                    </InputGroupText>
                                    {/*<Input name="JobNum" type="text" className="form-control-text" value={this.state.model.JobNum} readonly />*/}
                                    <label className="form-control no-border text-area-no-background form-control-text-padding">{this.state.model.JobNum}</label>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '100px' }}>
                                        Add Single Date:
                                    </InputGroupText>
                                    <Input name="SingleDate" type="date" value={this.state.copyJobDateModel.SingleDate} onChange={this.handleChange} className="form-control-text" />
                                </InputGroup>
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: '50px' }}>
                                        Time:
                                    </InputGroupText>
                                    <Input name="Time" type="text" className="form-control-text" onChange={this.handleChange} onBlur={this.handleJobTimeFieldChange} value={this.state.copyJobDateModel.Time} />
                                </InputGroup>
                            </Col>
                            <Col className="col-1">
                                <InputGroup size="sm">

                                </InputGroup>
                                <Button color="btn" size="sm" onClick={this.handleAddJobDateTime}><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}Add</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText style={{ width: "100px" }}>
                                        Type:
                                    </InputGroupText>
                                    <select name="Type" className="form-control form-control-text" ref={(select) => { this.cboType = select; }} onChange={this.handleChange} value={this.state.copyJobDateModel.Type}>
                                        <option value=""></option>
                                        <option value="To Auth Dept">To Auth Dept</option>
                                        <option value="To Conf Dept">To Conf Dept</option>
                                        <option value="Job Note">Job Note</option>
                                        <option value="Authorization">Authorization</option>
                                        <option value="My Job">My Job</option>
                                        <option value="Claimant Confirmed">Claimant Confirmed</option>
                                        <option value="Int Communication">Int Communication</option>
                                        <option value="Int Final Conf">Int Final Conf</option>
                                        <option value="Int Cancelation">Int Cancelation</option>
                                        <option value="Rep Email">Rep Email</option>
                                        <option value="Provider Conf">Provider Conf</option>
                                        <option value="Schedule Change">Schedule Change</option>
                                        <option value="Scheduling Note">Scheduling Note</option>
                                        <option value="Special Billing">Special Billing</option>
                                        <option value="Special Handling">Special Handling</option>
                                        <option value="Waiting on Call Back">Waiting on Call Back</option>
                                        <option value="Transport Requested">Transport Requested</option>
                                        <option value="Transport Confirmed">Transport Confirmed</option>
                                        <option value="Remote Clm Conf">Remote Clm Conf</option>
                                        <option value="Remote Int Conf">Remote Int Conf</option>
                                        <option value="Remote Prov Conf">Remote Prov Conf</option>
                                    </select>
                                </InputGroup>
                            </Col>
                            <Col className="col-4">
                                <InputGroup size="sm">
                                    <InputGroupText size="sm" color="blue" style={{ width: "50px" }}>
                                        Notes:
                                    </InputGroupText>
                                    <textarea name="Notes" rows="3" className="no-border form-control-text" onChange={this.handleChange} style={{ width: '87%' }} ref={(textarea) => { this.txtDescription = textarea; }} value={this.state.copyJobDateModel.Notes}></textarea>
                                </InputGroup>
                            </Col>
                            <Col className="col-2">
                                <InputGroup size="sm">
                                    <Button color="btn" size="sm" onClick={this.handleAddJobTypes}><img src={AddIcon} style={{ width: "16px", height: "16px" }} alt="Add" />{' '}Add</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{ fontWeight: 'bold' }}>Old Job History</p>
                                <div style={{ height: '150px', overflowX: 'scroll' }}>
                                    <Table striped size="sm" className="table-bordered table-striped" hover style={{ tableLayout: "fixed", width: '99.5%', lineHeight: "8px", alignItems: 'center', padding: "0.3rem 0.3rem 0.1rem 0.3rem" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="135px">Type</TableCell>
                                                <TableCell>Detail</TableCell>
                                                <TableCell width="40px">Select</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.jobHistoryList.map(jobhistory =>
                                                <tr key={jobhistory.jobHistoryId}>
                                                    <td className="x-grid-cell-inner">{jobhistory.jobHistoryType}</td>
                                                    <td className="x-grid-cell-inner">{jobhistory.description}</td>
                                                    <td className="x-grid-cell-inner"><a href="javascript:void(0)" onClick={() => { this.handleAddJobHistory(jobhistory) }} ><img src={AddIcon} style={{ width: "14px", height: "14px", cursor: 'pointer' }} alt="Add" /></a></td>
                                                </tr>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                {/*<option value="To Booking Dept">To Booking Dept</option>*/}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <p style={{ fontWeight: 'bold' }}>Job Date Time</p>
                                <Table striped size="sm" className="table-bordered table-striped" hover style={{ tableLayout: "fixed", lineHeight: "8px", alignItems: 'center', padding: "0.3rem 0.3rem 0.1rem 0.3rem" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="135px"></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell width="60px"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.jobDateTimeList.map((jobDateTime, index) =>
                                            <tr>
                                                <td className="x-grid-cell-inner">{moment(jobDateTime.Date).format('MM/DD/YYYY')} </td>
                                                <td className="x-grid-cell-inner">{jobDateTime.Time}</td>
                                                <td className="x-grid-cell-inner"><a href="javascript:void(0)" ><img src={EditIcon} style={{ width: "14px", height: "14px", cursor: 'pointer' }} alt="Edit" /></a> &nbsp; <a href="javascript:void(0)" onClick={() => { this.handleRemoveJobDateTime(index) }} ><img src={DeleteIcon} style={{ width: "14px", height: "14px", cursor: 'pointer' }} alt="Delete" /></a></td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{ fontWeight: 'bold' }}>New Job History</p>
                                <Table striped size="sm" className="table-bordered table-striped" hover style={{ tableLayout: "fixed", lineHeight: "8px", alignItems: 'center', padding: "0.3rem 0.3rem 0.1rem 0.3rem" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="135px"></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell width="60px"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.jobTypeList.map((jobTypes, index) =>
                                            <tr>
                                                <td className="x-grid-cell-inner">{jobTypes.Type} </td>
                                                <td className="x-grid-cell-inner">{jobTypes.Notes}</td>
                                                <td className="x-grid-cell-inner"><a href="javascript:void(0)" onClick={() => { this.handleRemoveJobHistory(index) }} ><img src={DeleteIcon} style={{ width: "14px", height: "14px", cursor: 'pointer' }} alt="Delete" /></a></td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-secondary-no-background form-control-text" onClick={this.onSave}>Save</Button>
                    </ModalFooter>
                </Modal>


            </div>
        );
    }
}