import React, { Component, createRef } from 'react';
import { Button, Row, Col, Alert, Input, InputGroup, InputGroupText } from 'reactstrap';

import { LoadingModal } from './LoadingModal';
import { FetchData } from './utils/DataFetch';
import { GetCookie, Timeout } from './utils/Common';

import SearchIcon from '../images/search-icon.png'

import { InterpreterAvailabilityAdminTableDefault } from './InterpreterAvailabilityAdminTableDefault';

const moment = require('moment');


export class InterpreterAvailabilityAdmin extends Component {
    static displayName = InterpreterAvailabilityAdmin.name;

    constructor(props) {
        super(props);
        this.state = {

            intScheduleList: [],
            loading: false,
            fetchingData: false,
            alert: {
                color: "info",
                message: "",
                isOpen: false
            },
            model: {
                IntId: null,
                StartDate: null,
                EndDate: null,
                ScheduleType: '',
                SchedNotes: '',
                CreateByUserId: ''

            },
            searchModel: {
                IntId: null,
                AvailableDate: moment().format('YYYY-MM-DD'),
                IntLanguage: 'Cert Spanish',
                SortBy: 'interpreterName',
                SortDirection: 'asc'
            }
        };
        this.user = JSON.parse(GetCookie('user'));
        this.clickCount = 0;
        this.singleClickTimer = '';

        this.handleSaveEventSuccess = this.handleSaveEventSuccess.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.resetSearchFields = this.resetSearchFields.bind(this)
        this.renderIntAvailabilityTable = this.renderIntAvailabilityTable.bind(this)
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //
    }

    componentDidMount() {
        this.populateScheduleData();
    }

    resetFields() {
        this.setState(prevState => ({
            model: {
                IntId: null,
                StartDate: null,
                EndDate: null,
                ScheduleType: '',
                SchedNotes: '',
                CreateByUserId: ''
            }
        }));
    }

    resetSearchFields() {
        this.setState(prevState => ({
            searchModel: {
                IntId: null,
                AvailableDate: moment().format('YYYY-MM-DD'),
                IntLanguage: 'Cert Spanish',
                SortBy: 'interpreterName',
                SortDirection: 'asc'
            }
        }));

    }
    handleSaveEventSuccess(alert) {
        this.setState({
            modal: alert.modal,
            alert: {
                color: alert.color,
                message: alert.message,
                isOpen: true
            }
        }, () => {
            window.setTimeout(() => {
                this.setState({ alert: { isOpen: false } });
            }, Timeout(alert.message));
            this.populateScheduleData();
        });
    }

    handleSearchChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateScheduleData();
        }
    }


    renderIntAvailabilityTable(schedules) {
        return (
            <InterpreterAvailabilityAdminTableDefault
                schedules={schedules}
            ></InterpreterAvailabilityAdminTableDefault>
        );
    }

    async populateScheduleData() {
        this.setState({ fetchingData: true });

        let searchModel = this.state.searchModel;

        if (searchModel.AvailableDate === null || searchModel.AvailableDate === '') {
            this.handleSaveEventSuccess({
                message: "Available Date cannot be blank",
                modal: false,
                color: "warning"
            });
            searchModel.AvailableDate = moment().format('YYYY-MM-DD')
            FetchData("/getinterpreterschedules", "POST", this.user.token, searchModel).then(data => {
                this.setState({ intScheduleList: data, loading: false, fetchingData: false });
            });
        } else {
            FetchData("/getinterpreterschedules", "POST", this.user.token, this.state.searchModel).then(data => {
                this.setState({ intScheduleList: data, loading: false, fetchingData: false });
            });

        }
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderIntAvailabilityTable(this.state.intScheduleList);

        return (
            <div>
                <center>
                    <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} >
                        {this.state.alert.message}
                    </Alert>
                </center>
                <h5 id="tabelLabel" >Interpreter Availability</h5>
                <Row>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Date
                            </InputGroupText>
                            <Input name="AvailableDate" type="date" className="form-control-text" value={this.state.searchModel.AvailableDate} onChange={this.handleSearchChange} placeholder="Available Date" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">

                    </Col>
                    <Col className="col-3">
                        <InputGroup size="sm">
                            <InputGroupText>
                                Language
                            </InputGroupText>
                            <Input name="IntLanguage" type="text" className="form-control-text" value={this.state.searchModel.IntLanguage} onChange={this.handleSearchChange} placeholder="Int Language" onKeyPress={this.handleKeyPress} />
                        </InputGroup>
                    </Col>
                    <Col className="col-2">

                    </Col>
                    <Col className="col-2">
                        <InputGroup size="sm">
                            <InputGroupText>
                            </InputGroupText>
                            <Button color="success" size="sm" onClick={() => { this.populateScheduleData() }}>Search <img src={SearchIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }}></img></Button>
                        </InputGroup>
                    </Col>
                </Row>
                <LoadingModal fetchingData={this.state.fetchingData} />

                <div style={{ maxHeight: '700px', overflowY: 'auto', padding: '5px' }}>
                    {contents}
                </div>
            </div>
        );

    }
}
