import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Row, Col, Alert } from 'reactstrap';

//import { ClientFormSearch } from './ClientFormSearch';
import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';

import DeleteIcon from '../images/delete.png'
import SearchIcon from '../images/search-icon.png'

export class ProviderOfficeForm extends Component {
    static displayName = ProviderOfficeForm.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            modalClientSearchForm: false,
            model: props.model
        };

        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        //
        //this.handleSelectClient = this.handleSelectClient.bind(this);
        ////
        //this.setModalClientFormSearch = this.setModalClientFormSearch.bind(this);
        //
        this.confirmDeleteProviderOffice = this.confirmDeleteProviderOffice.bind(this);
    }

    keydownHandler(e) {
        if (e.keyCode === 83 && e.altKey) { // Alt+S
            this.onSave();
        }
    }

    onSave() {
        let model = this.state.model;

        if (model.EventType === "insert") {
            model.CreateByUserId = this.user.userid;
        } else if (model.EventType === "update") {
            model.UpdateByUserId = this.user.userid;
        }

        FetchData("/saveupdateprovideroffices", "POST", this.user.token, model).then(data => {
            this.props.handleSaveEventSuccess({
                message: data,
                modal: data === 'Success' ? false : true,
                color: data === 'Success' ? "success" : "warning"
            });
        });
    }

    handleChange(e) {
        let model = { ...this.state.model }
        model[e.target.name] = e.target.value;

        this.setState({ model });
    }

    //handleSelectClient(client) {
    //    let model = { ...this.state.model }
    //    model.ClientID = client.clientId;
    //    model.ClientName = client.clientName;
    //    model.ClientAbbrv = client.clientAbbrv;
    //    model.ClientPhone = client.clientPhone;
    //    model.ClientPhone2 = client.clientPhone2;

    //    this.setState({ model }, () => {
    //        this.setModalClientFormSearch();
    //    });
    //}

    //setModalClientFormSearch() {
    //    this.setState({ modalClientSearchForm: !this.state.modalClientSearchForm });
    //}

    confirmDeleteProviderOffice() {
        if (this.props.model.ProvId !== null) {
            let provModel = this.props.model
            let model = { ...this.state.searchModel }
            let userModel = { ...this.user }
            if (provModel.ProvId !== null && provModel.ProvId !== '') {
                var note = window.prompt("Why are you deleting this Provider Office?", "");
                var data = {
                    ProvId: provModel.ProvId,
                    UpdateByUserId: this.user.userid,
                    UserName: this.user.firstname + " " + this.user.lastname,
                    DeletionReason: note
                }

                FetchData("/deleteprovider", "POST", this.user.token, data).then(data => {
                    this.props.handleSaveEventSuccess({
                        message: data === 'Success' ? 'ProviderOffice has been deleted successfully' : data,
                        modal: data === 'Success' ? false : true,
                        color: data === 'Success' ? "success" : "warning"
                    });
                });
            }
            else {
                window.confirm("Please enter a reason for deleting this Provider Office.")
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "1024px" }} backdrop="static">
                    <ModalHeader>Provider Form</ModalHeader>
                    <ModalBody>
                        <div>
                            <Row>
                                <Col>
                                    <Alert color={this.props.alert.color} isOpen={this.props.alert.isOpen} >
                                        {this.props.alert.message}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Last Name:
                                        </InputGroupText>
                                        <input type="text" name="ProvLastName" className="form-control form-control-text" value={this.state.model.ProvLastName} maxLength={25} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText>
                                            First Name:
                                        </InputGroupText>
                                        <input type="text" name="ProvFirstName" className="form-control form-control-text" value={this.state.model.ProvFirstName} maxLength={25} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Title:
                                        </InputGroupText>
                                        <input type="text" name="ProvTitle" className="form-control form-control-text" value={this.state.model.ProvTitle} maxLength={20} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px"}}>
                                            Speciality:
                                        </InputGroupText>
                                        <input type="text" name="ProvSpeciality" className="form-control form-control-text" value={this.state.model.ProvSpeciality} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Phone:
                                        </InputGroupText>
                                        <input type="text" name="ProvPhone" className="form-control form-control-text" value={this.state.model.ProvPhone} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Fax:
                                        </InputGroupText>
                                        <input type="text" name="ProvPhone2" className="form-control form-control-text" value={this.state.model.ProvPhone2} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Email:
                                        </InputGroupText>
                                        <input type="text" name="ProvIntNote" className="form-control form-control-text" value={this.state.model.ProvIntNote} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                           </Row>
                            <Row>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Street:
                                        </InputGroupText>
                                        <input type="text" name="ProvStreet" className="form-control form-control-text" value={this.state.model.ProvStreet} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>

                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            City:
                                        </InputGroupText>
                                        <input type="text" name="ProvCity" className="form-control form-control-text" value={this.state.model.ProvCity} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            State:
                                        </InputGroupText>
                                        <input type="text" name="ProvState" className="form-control form-control-text" value={this.state.model.ProvState} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col className="col-md-4">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Zip:
                                        </InputGroupText>
                                        <input type="text" name="ProvZip" className="form-control form-control-text" value={this.state.model.ProvZip} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Note:
                                        </InputGroupText>
                                        <textarea name="ProvNote" rows="3" className="form-control form-control-text" onChange={this.handleChange}>{this.state.model.ProvNote}</textarea>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <span>     </span> 
                            <Row>
                                <Col className="col-12">
                                    <InputGroup size="sm">
                                        <InputGroupText style={{ width: "70px" }}>
                                            Secretary:
                                        </InputGroupText>
                                        <input type="text" name="ProvReceptionist" className="form-control form-control-text" value={this.state.model.ProvReceptionist} maxLength={36} onChange={this.handleChange} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.confirmDeleteProviderOffice} size="sm" style={{ display: this.state.model.EventType === 'insert' ? 'none' : null }}>Delete</Button>
                    </ModalFooter>
                </Modal>

            {/*    {*/}
            {/*        this.state.modalClientSearchForm &&*/}
            {/*        <ClientFormSearch modal={this.state.modalClientSearchForm} setModal={this.setModalClientFormSearch} handleDoubleClick={this.handleSelectClient} />*/}
            {/*    }*/}
            </div>
        )
    }
}