import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, InputGroup, Row, Col, Table } from 'reactstrap';

import { FetchData } from './utils/DataFetch';
import { GetCookie } from './utils/Common';
import { ClientFormSearchTableDefault } from './ClientFormSearchTableDefault';

export class ClientFormSearch extends Component {
    static displayName = ClientFormSearch.name;

    constructor(props) {
        super(props);

        this.user = JSON.parse(GetCookie('user'));
        this.state = {
            clientList: [],
            searchModel: {
                ClientId: '',
                ClientName: '',
                ClientAbbrv: '',
                Org: '',
                Phone: ''
            }
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        let model = { ...this.state.searchModel }
        model[e.target.name] = e.target.value;

        this.setState({ searchModel: model });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.populateClientData();
            console.log("wath up bro");
        }
    }

    async populateClientData() {
        await FetchData("/getclients", "POST", this.user.token, this.state.searchModel).then(data => {
            this.setState({ clientList: data, loading: false });
        });
    }

    renderClientTable(clientList){
        return (
            <ClientFormSearchTableDefault 
                clientList={clientList}
                handleDoubleClick={this.props.handleDoubleClick} 
            ></ClientFormSearchTableDefault>
        )
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.setModal} style={{ width: "600px" }} backdrop="static">
                <ModalHeader>Search Client</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col>
                                <InputGroup size="sm">
                                    <input name="ClientAbbrv" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientAbbrv} onChange={this.handleChange} placeholder="Abbrv" onKeyPress={this.handleKeyPress} />
                                    <input name="ClientName" type="text" className="form-control form-control-text" value={this.state.searchModel.ClientName} onChange={this.handleChange} placeholder="Client Name" onKeyPress={this.handleKeyPress} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.renderClientTable(this.state.clientList)}
                            </Col>
                        </Row>
                    </div>
                </ModalBody>               
            </Modal>
        )
    }
}